import { DefaultUserSettings, UserSettings } from './userSettingsContext';

export const get = () => {
  const userSettings = JSON.parse(
    window.localStorage.getItem('userSettings') ?? JSON.stringify(DefaultUserSettings)
  ) as UserSettings;

  return userSettings;
};

export const set = (settings: UserSettings) => window.localStorage.setItem('userSettings', JSON.stringify(settings));
