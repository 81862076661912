export const labelToIconType = (label: string) => label.toLowerCase()
  .replace(/(evt)|\s|_/g, '');

export const labelToDisplayLabel = (label?: string) => label && label
  .substring(4)
  .replace(/[-_]/g, ' ')
  .toLowerCase()
  .replace(/opsnormal/g, 'Ops Normal')
  .replace(/oooi/g, 'OOOI')
  .replace(/([eE])ngine/g, 'engine ')
  .replace(/\soff/g, ' stop')
  .replace(/\son/g, ' start')
  .replace(/([Pp])oi/g, 'POI')
  .replace(/^(.)|\s+(.)/g, (c: string) => c.toUpperCase());

export const isSignificantEvent = (e: string) => !['EVT_STANDARD', 'EVT_INFLIGHT', 'EVT_SCHEDULED', 'EVT_INVGPS'].includes(e);
export const isSignificantReport = (report: Report) => report.events.some(isSignificantEvent);

export const isPriorityEvent = (e: string) => ['EVT_MANUAL', 'EVT_OPSNORMAL', 'EVT_POI', 'EVT_ALERT'].includes(e);
export const isPriorityReport = (report: Report) => report.events.some(isPriorityEvent);

export const eventDoesntMakeSense = (report: Report, assetCategory: string, deviceMake: string) => assetCategory === 'Helicopter'
    && ['EVT_TAKEOFF', 'EVT_LANDING'].includes(report.events[0])
    && report.speed > 30
    && deviceMake.toLowerCase() !== 'flightcell';
