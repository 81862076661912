import Smart from 'hocs/smart';
import DetailsView from './details-view';

export default Smart(
  DetailsView,
  state => ({
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // assetState: state.assetState[ownProps.asset.id],
    organisationId: state.session.organisationId,
  }),
  ac => ({
    selectItem: ac.app.selectItem
  })
);
