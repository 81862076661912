import React from 'react';
import { Alert } from '@mui/material';

const PageStaffAccessAlert = () => (
  <Alert severity="warning" variant="filled">
    The feature toggle which controls access to this page is not enabled.
    This page is accessible to TracPlus staff but not other users.
  </Alert>
);

export default PageStaffAccessAlert;
