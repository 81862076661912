import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslations } from 'use-intl';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Header from 'components/header';
import StatusBar from 'components/statusbar';
import Navigation from 'components/navigation';
import { ClassNameMap } from '@mui/styles';
import useStyles from './page-styles';

interface PageProps {
  children: React.ReactNode,
  title?: string,
}

const useTitle = (title?: string) => {
  const t = useTranslations('pageTitles');
  const location = useLocation();

  if (title !== undefined) return title;

  // TODO: remove this fallback code once all pages provide their title
  let key = location.pathname.substr(1).replaceAll('/', '-');
  if (location.pathname === '/') {
    key = 'map';
  } else if (location.pathname.startsWith('/staff/billing/plans/') && location.pathname !== '/staff/billing/plans/new') {
    key = 'edit-plan';
  } else if (location.pathname.startsWith('/staff/billing/addons/') && location.pathname !== '/staff/billing/addons/new') {
    key = 'edit-addon';
  }

  const fallbackTitle = t(key);
  if (fallbackTitle !== key) return fallbackTitle;

  return undefined;
};

const Page = ({
  children,
  title,
}: PageProps): JSX.Element => {
  const classes: ClassNameMap = useStyles();

  const titleText = useTitle(title);

  return (
    <>
      <Helmet>
        <title>{titleText ? `${titleText} - TracPlus Cloud` : 'TracPlus Cloud'}</title>
      </Helmet>
      <Grid className={classes.gridContainer} container spacing={0} direction="column">
        <Grid item xs={12} className={classes.headerBar}>
          <Header />
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Box className={classes.navWrapper}>
            <Navigation />
          </Box>

          <Box className={classes.content}>
            {children}
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.footerBar}>
          <StatusBar />
        </Grid>
      </Grid>
    </>
  );
};

export default Page;
