import { useEffect } from 'react';
import { getUnitSettings, updateLocalUnits } from 'slices/settings/unitSettings.slice';
import { useAppDispatch } from 'store/types';
import { useUser } from 'hooks/session/useUser';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useSelector } from 'react-redux';
import { getUiSettings, updateLocalUiSettings } from 'slices/settings/uiSettings.slice';
import { getLocaleSettings, localeLoaded } from 'slices/settings/localeSettings.slice';
import {
  getOmniboxSettings,
  OmniboxSettingsState,
  updateLocalOmniboxSettings
} from 'slices/settings/omniboxSettingsSlice';

const UserSettingsLoader = (): null => {
  const appDispatch = useAppDispatch();
  const userId = useUser()?.id;
  const isUsercodeLogin = useIsUsercodeLogin();
  const localUnitSettings = useSelector<ReduxState, ReduxState['settings']['units']>(state => state.settings.units);
  const localUiSettings = useSelector<ReduxState, ReduxState['settings']['ui']>(state => state.settings.ui);
  const localOmniboxSettings = {
    assetsGroupBy: useSelector<ReduxState, ReduxState['app']['groupBy']['assets']>(state => state.app.groupBy.assets),
    assetsSortBy: useSelector<ReduxState, ReduxState['app']['sortBy']['assets']>(state => state.app.sortBy.assets),
    schema: 0
  } as OmniboxSettingsState['omnibox'];

  useEffect(() => {
    if (!userId) return;
    if (isUsercodeLogin) {
      appDispatch(updateLocalUnits({ ...localUnitSettings, schema: 0 }));
    } else {
      appDispatch(getUnitSettings(userId));
    }
  }, [appDispatch, userId]);

  useEffect(() => {
    if (!userId) return;
    if (isUsercodeLogin) {
      appDispatch(updateLocalUiSettings({ ...localUiSettings, schema: 0 }));
    } else {
      appDispatch(getUiSettings(userId));
    }
  }, [appDispatch, userId]);

  useEffect(() => {
    if (!userId) return;
    if (isUsercodeLogin) {
      appDispatch(localeLoaded());
    } else {
      appDispatch(getLocaleSettings(userId));
    }
  }, [appDispatch, userId]);

  useEffect(() => {
    if (!userId) return;
    if (isUsercodeLogin) {
      appDispatch(updateLocalOmniboxSettings(localOmniboxSettings));
    } else {
      appDispatch(getOmniboxSettings(userId));
    }
  }, [appDispatch, userId]);
  return null;
};

export default UserSettingsLoader;
