import { HttpResponseError } from 'helpers/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { eventNotificationQueryKeys } from './queryKeys';
import * as types from './types';
import * as requests from './requests';
import { EventNotificationRuleWithGroupName } from './types';
import { getNotificationRulesByGeofenceId } from './requests';

export const useListEventNotificationGroups = () => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.all(organisationId);
  const query = useQuery<types.EventNotificationGroupIds[], HttpResponseError>(
    queryKeys,
    () => requests.listEventNotificationGroups(organisationId),
  );
  return { query, queryKeys };
};

export const useListNotificationRules = () => {
  const organisationId = useOrganisationId();
  const queryKey = eventNotificationQueryKeys.rules(organisationId);
  const allEventNotifications = useListEventNotificationGroups().query.data;
  return useQuery<types.EventNotificationRule[], HttpResponseError>(
    queryKey,
    async () => {
      const groups = allEventNotifications ?? await requests.listEventNotificationGroups(organisationId);
      return (await Promise.all(groups.map(g => requests.getEventNotification(organisationId, g.id)))).flatMap(g => g.notificationRules);
    },
  );
};

export const useGetEventNotificationGroup = (groupId: number) => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.group(organisationId, groupId);
  const query = useQuery<types.EventNotificationGroup, HttpResponseError>(
    queryKeys,
    () => requests.getEventNotification(organisationId, groupId),
  );
  return { query, queryKeys };
};

export const useGetNotificationRulesByContact = (contactGroupId: number) => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.byContactGroup(organisationId, contactGroupId);
  const query = useQuery<types.EventNotificationRule[], HttpResponseError>(
    queryKeys,
    () => requests.getNotificationRulesByContact(organisationId, contactGroupId),
  );
  return { query, queryKeys };
};

export const useGetNotificationRulesByGeofenceId = (geofenceId: number) => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.byGeofenceId(organisationId, geofenceId);
  const query = useQuery<types.EventNotificationRuleWithGroupName[], HttpResponseError>(
    queryKeys,
    () => requests.getNotificationRulesByGeofenceId(organisationId, geofenceId),
  );
  return { query, queryKeys };
}

export const useMutateCreateEventNotificationGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<types.EventNotificationGroup, HttpResponseError, Pick<types.EventNotificationGroupRequest, 'name'>>(
    value => requests.createEventNotification(organisationId, value.name),
    {
      mutationKey: ['createEventNotification'],
      onSuccess: () => queryClient.invalidateQueries(eventNotificationQueryKeys.all(organisationId)),
    }
  );
};

export const useMutateCreateEventNotificationRule = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<types.EventNotificationRule, HttpResponseError, types.NotificationRuleRequest>(
    value => requests.createEventNotificationRule(organisationId, value.notificationRule.notificationGroupId, value),
    {
      mutationKey: ['createEventNotificationRule'],
      onSuccess: data => queryClient.invalidateQueries(eventNotificationQueryKeys.all(organisationId)),
    }
  );
};

export const useMutateUpdateEventNotificationGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, types.NotificationGroupRequest>(
    value => requests.updateEventNotification(organisationId, value),
    {
      mutationKey: ['updateEventNotificationGroup'],
      onSuccess: () => queryClient.invalidateQueries(eventNotificationQueryKeys.all(organisationId))

    }
  );
};

export const useMutateUpdateEventNotificationRule = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, types.NotificationRuleRequest>(
    value => requests.updateEventNotificationRule(organisationId, value.notificationRule.notificationGroupId, value),
    {
      mutationKey: ['updateEventNotificationRule'],
      onSuccess: (data, request) => queryClient.invalidateQueries(eventNotificationQueryKeys.all(organisationId)),
    }
  );
};

export const useMutateUpdateAssetGroupsForEventNotificationGroup = (onSuccess: () => void) => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { notificationGroupId: number, assetGroupIds: number[] }>(
    value => requests.updateAssetGroupsForEventNotificationGroup(organisationId, value.notificationGroupId, value.assetGroupIds),
    {
      mutationKey: ['updateAssetGroupsForEventNotificationGroup'],
      onSuccess: (data, request) => {
        queryClient.invalidateQueries(eventNotificationQueryKeys.group(organisationId, request.notificationGroupId));
        onSuccess();
      },
    }
  );
};

export const useMutateUpdatePeopleGroupsForEventNotificationRule = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { notificationGroupId: number, ruleId: number, peopleGroupIds: number[] }>(
    value => requests.updatePeopleGroupsForEventNotificationRule(organisationId, value.notificationGroupId, value.ruleId, value.peopleGroupIds),
    {
      mutationKey: ['updatePeopleGroupsForEventNotificationRule'],
      onSuccess: (data, request) => queryClient.invalidateQueries(eventNotificationQueryKeys.group(organisationId, request.notificationGroupId)),
    }
  );
};

export const useMutateDeleteEventNotificationGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, types.EventNotificationGroupRequest>(
    value => requests.deleteEventNotificationGroup(organisationId, value.id, value.rowVersion),
    {
      mutationKey: ['deleteEventNotificationGroup'],
      onSuccess: () => queryClient.invalidateQueries(eventNotificationQueryKeys.all(organisationId)),
    }
  );
};

export const useMutateDeleteEventNotificationRule = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<types.EventNotificationRule, 'id' | 'notificationGroupId'>>(
    value => requests.deleteEventNotificationRule(organisationId, value.notificationGroupId, value.id),
    {
      mutationKey: ['deleteEventNotificationRule'],
      onSuccess: (data, value) => queryClient.invalidateQueries(eventNotificationQueryKeys.group(organisationId, value.notificationGroupId)),
    }
  );
};

export const useGetEventNotificationWarnings = (groupId: number) => {
  const organisationId = useOrganisationId();
  const queryKeys = eventNotificationQueryKeys.warnings(organisationId, groupId);
  const query = useQuery<types.EventNotificationWarnings, HttpResponseError>(
    queryKeys,
    () => requests.getEventNotificationWarnings(organisationId, groupId),
  );
  return { query, queryKeys };
};

export const useMutateDeletePeopleGroupForEventNotification = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, {contactGroupId: number, notificationGroupId: number}>(
    value => requests.deletePeopleGroupForEventNotificationRule(organisationId, value.contactGroupId, value.notificationGroupId),
    {
      mutationKey: ['deletePeopleGroupForEventNotificationRule'],
      onSuccess: (data, value) => queryClient.invalidateQueries(eventNotificationQueryKeys.byContactGroup(organisationId, value.contactGroupId)),
    }
  );
};
