import { ScatterplotLayer } from '@deck.gl/layers';
import { PickingInfo, GetPickingInfoParams } from '@deck.gl/core';
import { useCallback, useMemo } from 'react';
import { hexToRGBArray } from 'helpers/color';
import { mapToColors } from 'utils/colormap';
import { MapSettings } from 'reducers/map';
import { usePickingPriority } from 'hooks/usePickingPriority';
import { Model } from '@luma.gl/engine';
import ConstantSizeScatterplotShader from './shaders/constant-size-scatterplot-vertex.glsl';

const WHITE: Color = [255, 255, 255];

interface DataItem {
  position: [number, number, number]
  terrainElevation?: number
  color: ColorAlpha
  reportId: number
  assetId: number
  pickingKey: string
  event: string
}

interface ExtraProps {
  getPick?: (item: DataItem) => DataItem
}

interface DataPickingInfo extends PickingInfo {
  object?: DataItem
}

class ConstantSizeScatterplotLayer extends ScatterplotLayer<DataItem, ExtraProps> {
  static layerName = 'ConstantSizeScatterplotLayer';
  getShaders() {
    return { ...super.getShaders(), vs: ConstantSizeScatterplotShader };
  }

  getPickingInfo(params: GetPickingInfoParams): DataPickingInfo {
    const info = super.getPickingInfo(params);
    if (!this.props.getPick || !info.object) return info;

    const object = this.props.getPick(info.object as DataItem);
    return { ...info, object };
  }
}

const useReportDotLayers = (
  visible: boolean,
  assets: AssetBasic[],
  reports: Record<number, Report[]>,
  radius: number,
  coloring: MapSettings['assetTrailColouring'],
  selectedAssetId: number | undefined,
  zoom: number,
  use3d = false
) => {
  const fallbackColor = useCallback((r: Report) => hexToRGBArray(assets.find(a => a.id === r.assetId)?.colour ?? '#fff'), [assets]);

  const data: DataItem[] = useMemo(() => {
    const validReports = Object.values(reports).flat();
    const reportColors = selectedAssetId ? mapToColors(validReports, coloring, fallbackColor) : [];

    const newData: DataItem[] = validReports.map((report, index) => {
      const color = reportColors.at(index) ?? fallbackColor(report);
      const position = [report.longitude, report.latitude, use3d ? report.altitude : 0] as [number, number, number];
      return { reportId: report.id, assetId: report.assetId, position, color, event: report.events[0], pickingKey: position.join() };
    });

    return newData;
  }, [reports, coloring, fallbackColor, use3d]);

  const getPick = usePickingPriority(data);

  const triplicateData = useMemo(() => data.flatMap<DataItem>(item => [
    item,
    { ...item, position: [item.position[0] - 360, item.position[1], item.position[2]] },
    { ...item, position: [item.position[0] + 360, item.position[1], item.position[2]] },
  ]), [data]);

  return useMemo(() => [
    new ConstantSizeScatterplotLayer({
      id: 'report-dot-background-layer',
      data: triplicateData,
      pickable: true,
      billboard: true,
      filled: true,
      opacity: visible ? 1 : 0,
      getFillColor: d => [...WHITE, !selectedAssetId || d.assetId === selectedAssetId ? 255 : 0],
      radiusUnits: 'pixels',
      getRadius: radius + 1,
      parameters: { depthTest: use3d && zoom > 12 },
      getPick,
    }),
    new ConstantSizeScatterplotLayer({
      id: 'report-dot-layer',
      data: triplicateData,
      pickable: false,
      billboard: true,
      filled: true,
      visible,
      getFillColor: d => [d.color[0], d.color[1], d.color[2], !selectedAssetId || d.assetId === selectedAssetId ? 255 : 0],
      radiusUnits: 'pixels',
      getRadius: radius,
      parameters: { depthTest: use3d && zoom > 12 },
    })
  ] as const, [getPick, radius, selectedAssetId, triplicateData, use3d, visible, zoom]);
};

export default useReportDotLayers;
