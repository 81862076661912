import React, { useCallback } from 'react';
import {
  Typography, IconButton, Divider, Stack, FormControl, FormControlLabel, Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { useTranslations } from 'use-intl';
import CheckboxInput from 'components/shared/forms/inputs/bool';
import SliderInput from 'components/shared/forms/inputs/slider';
import SelectInput from 'components/shared/forms/inputs/muiSelect';
import { Feature } from 'components/shared/Feature';
import { useViewport } from 'contexts/viewport/useViewport';
import { useUserSettings } from 'contexts/userSettings/useUserSettings';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import { useSelector } from 'react-redux';
import { updateSetting } from 'actions/settings';
import { selectFirefightingMode, setFirefightingMode } from 'slices/settings/mapSettings.slice';
import { useAppDispatch } from 'store/types';
import { FeatureFlag } from 'components/shared/featureFlag';
import { updateLocalUiSettingsField, updateUiSettingField } from 'slices/settings/uiSettings.slice';
import { showLabInfo } from 'slices/labs.slice';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import BaseLayerPicker from './modules/baseLayerPicker';
import {
  MapSettingsDrawer, MapSettingsHeader,
  MapSettingsSection,
  MapSettingsTitle,
  MapSettingsWrapper,
} from './settingsDrawer-styles';
import KmlLoader from './modules/kmlLoader';
import { FirefightingOptions } from './modules/dropsOptionPicker/firefightingOptions';
import TrialPicker from './modules/trailPicker';
import LabsMapSettings from './modules/labs';

const SettingsDrawer = ({
  children,
  settingsDrawerOpen,
  handleSettingsDrawerClose,
  config,
  updateConfig,
  updateMapControlsContrast,
  highContrastControls,
  mapId,
  setKmlStatus,
  kmlStatus,
  setKmlOrder,
  kmlOrder,
}) => {
  const t = useTranslations('pages.map.settingsDialog');
  const trailT = useTranslations('components.TrailColouring');
  const uiSettings = useUiSettings();
  const dispatch = useAppDispatch();
  const viewport = useViewport(mapId);
  const [mapSettings, setMapSettings] = useUserSettings('map');
  const firefightingMode = useSelector(selectFirefightingMode);
  const isUsercodeUser = useIsUsercodeLogin();

  const geofencesFeature = useFeatureAssets('manage.geofencing');

  const handleAssetDetailChange = useCallback(value => {
    if (isUsercodeUser) {
      dispatch(updateSetting('ui', 'assetDetailSelected', value));
      dispatch(updateLocalUiSettingsField({ field: 'assetDetailSelected', value }));
    } else {
      dispatch(updateUiSettingField({ field: 'assetDetailSelected', value }));
    }
  }, [dispatch, isUsercodeUser]);

  return (
    <MapSettingsDrawer
      anchor="left"
      open={settingsDrawerOpen}
      onClose={handleSettingsDrawerClose}
    >
      <MapSettingsWrapper>
        <MapSettingsHeader component="header">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2" color="inherit">{t('title')}</Typography>
            {handleSettingsDrawerClose ? (
              <IconButton
                aria-label="close"
                onClick={handleSettingsDrawerClose}
                size="large"
                color="inherit"
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            ) : null}
          </Stack>
        </MapSettingsHeader>
        <Stack flex={1}>
          <BaseLayerPicker mapId={config.id} />
          <MapSettingsSection component={Stack} spacing={1}>
            <MapSettingsTitle>{t('customLayers')}</MapSettingsTitle>
            <KmlLoader mapId={config.id} setKmlOrder={setKmlOrder} setKmlStatus={setKmlStatus} kmlOrder={kmlOrder} kmlStatus={kmlStatus} t={t} />
            <Divider />
            <CheckboxInput
              label={t('kml.displayOverlay')}
              value={mapSettings.kml.displayOverlay}
              onChange={(id, value) => setMapSettings(s => { s.kml.displayOverlay = value; })}
            />
            <CheckboxInput
              label={t('kml.displayAdditionalData')}
              value={mapSettings.kml.displayAdditionalData}
              onChange={(id, value) => setMapSettings(s => { s.kml.displayAdditionalData = value; })}
              disabled={!mapSettings.kml.displayOverlay}
            />
          </MapSettingsSection>
          <MapSettingsSection>
            <MapSettingsTitle>{t('animations')}</MapSettingsTitle>
            <CheckboxInput
              label={t('animateTrails')}
              value={config.animateTrails}
              onChange={(id, value) => updateConfig(config.id, { animateTrails: value })}
            />
            <CheckboxInput
              label={t('animateSelectedTrailOnly')}
              value={config.animateSelectedTrailOnly}
              onChange={(id, value) => updateConfig(config.id, { animateSelectedTrailOnly: value })}
              disabled={!config.animateTrails}
            />
          </MapSettingsSection>
          <MapSettingsSection>
            <MapSettingsTitle>{t('trailOptions')}</MapSettingsTitle>
            <TrialPicker mapId={mapId} />
            <CheckboxInput
              label={t('reportDots')}
              value={config.reportDots}
              onChange={(id, value) => updateConfig(config.id, { reportDots: value })}
            />
            <SliderInput
              label={t('reportDotsZoom')}
              value={config.hideReportDotsAtZoom}
              onChange={(id, value) => updateConfig(config.id, { hideReportDotsAtZoom: value })}
              disabled={!config.reportDots}
              min={0}
              max={19}
              step={0.5}
              marks={[{ value: viewport?.zoom, label: t('currentZoom') }]}
            />
            <Feature
              id="map.droplines"
              enabled={(
                <>
                  <MapSettingsTitle>{t('firefightingOptions.title')}</MapSettingsTitle>
                  <FirefightingOptions mapId={config.id} />
                </>
              )}
            />
            <SelectInput
              label={t('selectedTrailColouring')}
              value={config.assetTrailColouring}
              onChange={(id, value) => updateConfig(config.id, { assetTrailColouring: value })}
              options={['none', 'speed', 'altitude', 'battery', 'latency', 'transport'].map(v => ({ id: v, label: trailT(v) }))}
            />
            <FeatureFlag
              feature="enablefirefightingmodesetting"
              enabled={(
                <CheckboxInput
                  label={t('highlightDrops')}
                  value={firefightingMode}
                  onChange={() => dispatch(setFirefightingMode(!firefightingMode))}
                />
            )} />
          </MapSettingsSection>
          <MapSettingsSection>
            <MapSettingsTitle>{t('labels')}</MapSettingsTitle>
            <CheckboxInput
              label={t('assetLabels')}
              value={config.permanentLabels}
              onChange={(id, value) => updateConfig(config.id, { permanentLabels: value })}
            />
            <CheckboxInput
              label={t('kmlLabels')}
              value={config.kmlLabels}
              onChange={(id, value) => updateConfig(config.id, { kmlLabels: value })}
            />
          </MapSettingsSection>
          <MapSettingsSection>
            <MapSettingsTitle>{t('assetDetails')}</MapSettingsTitle>
            <SelectInput
              value={uiSettings.assetDetailSelected}
              onChange={(_, value) => handleAssetDetailChange(value)}
              options={['bearingAtSpeed', 'altitudeAtSpeed', 'position', 'make', 'model', 'variant', 'owner', 'category'].map(v => ({ id: v, label: t(`assetDetail.${v}`) }))}
            />
          </MapSettingsSection>
          <MapSettingsSection flex={1}>
            <MapSettingsTitle>{t('mapControls')}</MapSettingsTitle>
            <CheckboxInput
              label={t('highContrastControls')}
              value={highContrastControls}
              onChange={() => updateMapControlsContrast(!highContrastControls)}
            />
            <p>{highContrastControls}</p>
          </MapSettingsSection>
          <FeatureFlag feature="featureModules" enabled={(
            <MapSettingsSection>
              <FormControl component="fieldset" fullWidth>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <MapSettingsTitle as="legend">{t('geofences.title')}</MapSettingsTitle>
                  <IconButton aria-label={t('geofences.help')} onClick={() => dispatch(showLabInfo('manage.geofencing'))}>
                    <HelpOutline />
                  </IconButton>
                </Stack>
                <FormControlLabel
                  label={t('geofences.enabled')}
                  control={(
                    <Checkbox
                      checked={geofencesFeature.some ? config.geofencesEnabled : false}
                      onChange={event => dispatch(updateConfig(config.id, { geofencesEnabled: event.target.checked }))}
                    />
                  )}
                  disabled={!geofencesFeature.some}
                />
              </FormControl>
            </MapSettingsSection>
          )} />
          <LabsMapSettings mapId={config.id} />
          {children}
        </Stack>
      </MapSettingsWrapper>
    </MapSettingsDrawer>
  );
};

export default SettingsDrawer;
