import React, { useState } from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import useTranslation from 'hooks/useTranslation';
import HashScroll from 'components/shared/hashScroll';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import OrganisationMembers from './members';
import UserInvitation from './userInvitation/index';
import OrganisationContactModal from './organisationContactModal';
import OrganisationContact from './organisationContact';
import OrganisationDetails from './organisationDetails';

const OrganisationSettings = ({
  user,
  organisation,
  permissions,
}) => {
  const t = useTranslation('pages.organisationSettings');

  const [editingPrimaryContact, setEditingPrimaryContact] = useState(false);
  const [editingBillingContact, setEditingBillingContact] = useState(false);

  return (
    <>
      <HashScroll />
      <SettingsSection id="orgDetails" title={t('tableDetails')} description={t('tableDetailsSubtext')}>
        <OrganisationDetails organisation={organisation} />

        <Grid container direction="row" spacing={3} alignItems="stretch">
          { /* # Primary Contact */ }
          <Grid item sm={6}>
            <DetailPanel p={3} height="100%">
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography variant="h5">{t('primaryOrgContactTitle')}</Typography>
                </Grid>
                <Grid item xs={2}>
                  {permissions.canEditOrganisation && (
                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                      <IconButton size="small" onClick={() => setEditingPrimaryContact(true)} aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      { editingPrimaryContact ? (
                        <OrganisationContactModal
                          departmentName={t('primaryOrgContactTitle')}
                          organisation={organisation}
                          mode="primary"
                          onClose={() => setEditingPrimaryContact(false)}
                        />
                      ) : undefined }
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <OrganisationContact organisation={organisation} mode="primary" />
                </Grid>
              </Grid>
            </DetailPanel>
          </Grid>

          { /* # Billing Contact */ }
          <Grid item sm={6}>
            <DetailPanel p={3} height="100%">
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography variant="h5">{t('billingOrgContactTitle')}</Typography>
                </Grid>
                {/* TODO: check git history for JSX to edit primary contact once we can trackstar */}
                <Grid item xs={2}>
                  {permissions.canEditOrganisation && organisation.billingId && (
                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                      <IconButton size="small" onClick={() => setEditingBillingContact(true)} aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      { editingBillingContact ? (
                        <OrganisationContactModal
                          departmentName={t('billingOrgContactTitle')}
                          organisation={organisation}
                          mode="billing"
                          onClose={() => setEditingBillingContact(false)}
                        />
                      ) : undefined }
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {organisation.billingId ? <OrganisationContact organisation={organisation} mode="billing" /> : <Typography>{t('billingOrgContactNone')}</Typography>}
                </Grid>
              </Grid>
            </DetailPanel>
          </Grid>
        </Grid>
      </SettingsSection>

      <SettingsSection id="orgUsers" title={t('users')} description={t('userListDescription')}>
        <OrganisationMembers organisation={organisation} user={user} permissions={permissions} />
      </SettingsSection>

      { /* # Invite User */ }
      {permissions.canEditOrganisation && (
        <SettingsSection id="inviteUser" title={t('newUserTitle')} description={t('newUserDescription')}>
          <UserInvitation orgId={organisation.id} />
        </SettingsSection>
      )}
    </>
  );
};

export default OrganisationSettings;
