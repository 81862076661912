/* eslint-disable no-underscore-dangle */
import React, { useCallback, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import useStyles from './results-styles';
import GroupedQueryResults from './groupedResults';
import useFeatureFlag from "hooks/useFeatureFlag";
import {useSelector} from "react-redux";
import {selectOmniboxSettings} from "slices/settings/omniboxSettingsSlice";
import {useAssetGroupBy} from "hooks/settings/useAssetGroupBy";
import {useAssetSortBy} from "hooks/settings/useAssetSortBy";

interface OmniboxResultsProps {
  organisationId: string,
  selectedMapId: string,
  assignItemToMap: (mapId: string, asset: AssetBasic) => void,
  selectItem: (asset: AssetBasic) => void
  data: { assets: AssetBasic[] }
  toggleAll?: boolean,
  toggleVisibilityText: (hideAll: boolean) => void,
}

const OmniboxResults = ({
  organisationId,
  selectedMapId,
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  assignItemToMap,
  selectItem,
  data,
  toggleAll,
  toggleVisibilityText,
}: OmniboxResultsProps): JSX.Element => {
  const classes = useStyles();
  const selectedSortBy = useAssetSortBy();
  const selectedGroupBy = useAssetGroupBy();

  const selectAsset = useCallback(asset => {
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // const selectedAssetState = assetState[asset.id];
    // const isAssetInDistress = tagHelper.isInDistress(selectedAssetState);
    selectItem(asset);
    // if (isAssetInDistress) fetchDistressIncident(asset.id);
    // and also track which map is selected
    assignItemToMap(selectedMapId, { ...asset, organisationId });
    // TODO: performance - this use effect is selecting assets when switching maps, this is also happening in mapContainer-view
  }, [assignItemToMap, organisationId, selectItem, selectedMapId]);

  const assets = useCallback(() => data.assets, [data])();

  // const latestPositionsByAsset = useLatestPositionsForAssets(assets);
  //
  // const sortedAssets = useMemo(() => data.assets.sort((a, b) => {
  //   const aTime = latestPositionsByAsset[a.id]?.received || 0;
  //   const bTime = latestPositionsByAsset[b.id]?.received || 0;
  //   return bTime - aTime;
  // }), [data.assets, latestPositionsByAsset]);

  return (
    <Box id="omniboxResults" className={classes.container}>
      <Box className={classes.listWrapper} data-test="assetContainer">
        {data.assets && (
          <GroupedQueryResults
            results={assets}
            groupBy={selectedGroupBy.assets}
            sortBy={selectedSortBy.assets}
            selectAsset={selectAsset}
            toggleAll={toggleAll}
            toggleVisibilityText={toggleVisibilityText}
          />
        )}
      </Box>
    </Box>
  );
};

export default OmniboxResults;
