import * as app from './app';
import * as asset from './asset';
import * as map from './map';
import * as settings from './settings';
import * as ui from './ui';
import * as reports from './reports';
import * as legs from './legs';

export default {
  app,
  asset,
  map,
  settings,
  ui,
  reports,
  legs,
};
