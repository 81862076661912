import React from 'react';
import { Outlet, Routes, Route } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { Box, Chip, ChipProps, Container, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import Page from 'components/pages/page';
import Loading from 'components/pages/loading/noHeaderNoFooterLoading-view';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import LinkAbove from 'components/shared/linkAbove';
import { Feature } from 'components/shared/Feature';
import useFeatureTrial from 'hooks/features/useFeatureTrial';
import PageStaffAccessAlert from '../../shared/pageStaffAccessAlert';

interface TrialBadgeProps extends Pick<ChipProps, 'size'> {
  featureKey: string
}

export const TrialBadge = ({ size, featureKey }: TrialBadgeProps) => {
  const t = useTranslations('pages.reporting');
  const trial = useFeatureTrial(featureKey);

  let label = t('beta');

  if (trial && trial.featureCanExpire && trial.expiryDate) {
    const diff = DateTime.fromISO(trial.expiryDate).diff(DateTime.now(), 'days');
    if (diff.days > 0) label = `${t('beta')} | ${t('nDaysRemain', { n: Math.ceil(diff.days) })}`;
  }

  return (
    <Chip
      label={label}
      size={size}
      color="warning"
      sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
    />
  );
};

const ReportingLayout = () => {
  const t = useTranslations('pages.reporting');

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0} sx={{ scrollbarGutter: 'stable' }}>
        <Container maxWidth={false}>
          <Box>
            <Routes>
              <Route path="trip" element={(
                <>
                  <LinkAbove />
                  <Typography variant="h1" gutterBottom>{t('tripReports.title')}</Typography>
                  <Typography paragraph>{t('tripReports.description')}</Typography>
                </>
              )} />
              <Route path="summary" element={(
                <>
                  <LinkAbove />
                  <Typography variant="h1" gutterBottom>{t('utilisation.title')}</Typography>
                  <Typography paragraph>{t('utilisation.description')}</Typography>
                  <Feature
                    id="reporting.activitySummaryReport"
                    disabled={<Box mb={3}><PageStaffAccessAlert /></Box>}
                  />
                </>
              )} />
              <Route path="trip-analysis" element={(
                <>
                  <LinkAbove />
                  <Stack direction="row" spacing={1}>
                    <Typography variant="h1" gutterBottom>{t('tripAnalysis.title')}</Typography>
                    <TrialBadge featureKey="reporting.newTripReports" />
                  </Stack>
                  <Typography paragraph>{t('tripAnalysis.descriptionLong')}</Typography>
                </>
              )} />
              <Route path="daily-flight-summary" element={(
                <>
                  <LinkAbove />
                  <Stack direction="row" spacing={1}>
                    <Typography variant="h1" gutterBottom>{t('dailyFlightSummary.title')}</Typography>
                    <TrialBadge featureKey="reporting.newTripReports" />
                  </Stack>
                  <Typography paragraph>{t('dailyFlightSummary.description')}</Typography>
                </>
              )} />
              <Route path="mission" element={(
                <>
                  <LinkAbove />
                  <Typography variant="h1" gutterBottom>{t('missionReports.title')}</Typography>
                  <Typography paragraph>{t('missionReports.description')}</Typography>
                </>
              )} />
              <Route path="firefighting" element={(
                <Feature
                  id="reporting.aerialFirefightingReport"
                  enabled={<Outlet />}
                  loading={<Loading />}
                  allowStaff
                />
              )}>
                <Route index element={(
                  <>
                    <LinkAbove />
                    <Typography variant="h1" gutterBottom>{t('firefighting.summaryTitle')}</Typography>
                    <Typography paragraph>{t('firefighting.description')}</Typography>
                  </>
                )} />
                <Route path="asset/:assetId" element={(
                  <>
                    <LinkAbove />
                    <Typography variant="h1" gutterBottom>{t('firefighting.assetTitle')}</Typography>
                    <Typography paragraph>{t('firefighting.description')}</Typography>
                  </>
                )} />
              </Route>
              <Route path="geofence-events" element={(
                <>
                  <LinkAbove />
                  <Typography variant="h1" gutterBottom>{t('geofenceEvents.title')}</Typography>
                  <Typography paragraph>{t('geofenceEvents.description')}</Typography>
                  <Feature
                    id="reporting.geofences"
                    disabled={<Box mb={3}><PageStaffAccessAlert /></Box>}
                  />
                </>
              )} />
            </Routes>
          </Box>

          <Outlet />
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default ReportingLayout;
