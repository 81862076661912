import useOrganisationId from 'hooks/session/useOrganisationId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import { inviteQueryKeys } from './queryKeys';
import {
  cancelInvite,
  getInvites,
  inviteUser
} from './requests';

export const useGetInvites = () => {
  const organisationId = useOrganisationId();
  const queryKey = inviteQueryKeys.list(organisationId);
  const query = useQuery<Invite[], HttpResponseError>(
    queryKey,
    () => getInvites(organisationId),
  );
  return { query, queryKey };
};

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation<Invite | null, HttpResponseError, Pick<Invite, 'email' | 'name' | 'role'>>(
    value => inviteUser(organisationId, value.name, value.email, value.role),
    {
      mutationKey: ['inviteUser'],
      onSuccess: () => queryClient.invalidateQueries(inviteQueryKeys.all(organisationId)),
    },
  );
};

export const useCancelInvite = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation<void, HttpResponseError, Pick<Invite, 'inviteId'>>(
    value => cancelInvite(value.inviteId),
    {
      mutationKey: ['cancelInvite'],
      onSuccess: () => queryClient.invalidateQueries(inviteQueryKeys.all(organisationId)),
    },
  );
};
