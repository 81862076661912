import Smart from 'hocs/smart';
import TripReports from './tripReports-view';

export default Smart(
  TripReports,
  (state: any) => ({}),
  (ac: any) => ({
    setAssetLegs: ac.legs.setAssetLegs,
    displaySnackbar: ac.app.displaySnackbar,
    updateSetting: ac.settings.updateSetting,
  })
);
