import React, { ReactNode } from 'react';
import { useTranslations } from 'use-intl';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

export enum SetDefaultContactGroupDialogStatus {
  Cancelled = 'cancelled',
  Saved = 'saved',
}

export interface SetDefaultContactGroupDialogProps {
  open: boolean
  onClose: (status: SetDefaultContactGroupDialogStatus, groupId: number) => void
  group: ContactGroup
  mutation: UseMutationResult<void, Error, Pick<ContactGroup, 'id' | 'name' | 'isDefault'>>
  valid: boolean
  ariaLabel: string
  title: ReactNode
  children: ReactNode
}

export const SetDefaultContactGroupDialog = ({
  open,
  onClose,
  group,
  mutation,
  valid,
  ariaLabel,
  title,
  children,
}: SetDefaultContactGroupDialogProps): JSX.Element | null => {
  const t = useTranslations('dialogs.contactGroups.setDefault');

  const handleClose = (status: SetDefaultContactGroupDialogStatus) => onClose(status, group.id);
  const handleCancel = () => handleClose(SetDefaultContactGroupDialogStatus.Cancelled);
  const handleConfirm = () => mutation.mutate(
    { id: group.id, name: group.name, isDefault: true },
    { onSuccess: () => handleClose(SetDefaultContactGroupDialogStatus.Saved) },
  );
  const onExited = () => mutation.reset();

  const canConfirm = valid && !mutation.isLoading;

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!mutation.isLoading) handleCancel();
      }}
      aria-labelledby={ariaLabel}
      fullWidth
      maxWidth="md"
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent sx={{ p: 3, pb: 0 }}>
        <Stack spacing={3} my={3}>
          {children}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey' }}>
        <Stack spacing={3} flex={1} direction="row" justifyContent="flex-end" height="4em">
          <Button
            variant="outlined"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={mutation.isLoading}
            onClick={handleCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={!canConfirm}
            onClick={handleConfirm}
          >
            {t('confirm')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
