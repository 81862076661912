import { styled } from '@mui/material/styles';
import { Box, Drawer, Stack, Typography } from '@mui/material';

export const MapSettingsSection = styled(Box)(({ theme }) => `
  padding: ${theme.spacing(3, 3, 2, 3)};
  border-top: ${theme.border.default};
`);

export const MapSettingsTitle = styled(Typography)(({ theme }) => `
  font-size: .8rem;
  font-weight: 500;
  margin-bottom: ${theme.spacing(1)};
  color: ${theme.palette.settingsDrawer.label};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`);

export const MapSettingsHeader = styled(Box)(({ theme }) => `
  position: relative;
  padding: ${theme.spacing(2, 3)};
  color: ${theme.palette.settingsDrawer.label};
`);

export const MapSettingsWrapper = styled(Stack)(({ theme }) => `
  width: var(--drawer-width);
  overflow-x: hidden;
  background-color: ${theme.palette.settingsDrawer.background};
  flex: 1;
`);

export const MapSettingsDrawer = styled(Drawer)(({ theme }) => `
  & .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0);
  }
  & .MuiDrawer-paper {
    top: var(--header-bar-height);
    bottom: var(--footer-bar-height);
    height: auto;
    box-shadow: none;
  }
  & * {
    scrollbar-color: ${theme.palette.common.scrollBar} ${theme.palette.common.scrollBarBackground};
  }
  & *::-webkit-scrollbar-track {
    background: ${theme.palette.common.scrollBarBackground};
  }
  & *::-webkit-scrollbar {
    background-color: ${theme.palette.common.scrollBarBackground};
  }
  & *::-webkit-scrollbar-thumb {
    background: ${theme.palette.common.scrollBar};
  }
`);
