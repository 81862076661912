/* eslint-disable @typescript-eslint/ban-types */
import React, {
  MutableRefObject, useCallback, useEffect, useRef
} from 'react';
import MaterialTable, { Column, MaterialTableProps } from '@material-table/core';
import { Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import { SxProps } from '@mui/system/styleFunctionSx';
import {updateLocalUiSettingsField, updateUiSettingField} from 'slices/settings/uiSettings.slice';
import { useAppDispatch } from 'store/types';
import { TableSettings } from 'reducers/settings';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import TableActions from '../materialTable/tableActions';

interface PersistentTableProps<RowData extends object> extends MaterialTableProps<RowData> {
  settingsCategory: string,
  updateSetting: (category: string, field: string, value: any) => void,
  onRender?: (state: { data?: RowData[] }) => void,
  sx?: SxProps<Theme>
}

const PersistentTable = <RowData extends object>({
  settingsCategory,
  updateSetting,
  options,
  columns,
  components,
  onRender,
  ...props
}: PersistentTableProps<RowData>): JSX.Element => {
  const tableRef: MutableRefObject<MaterialTable<RowData> | null> = useRef(null);
  const appDispatch = useAppDispatch();
  // @ts-ignore
  const tableSettings: TableSettings = useSelector(state => state.settings[settingsCategory]);
  const { rowsPerPage } = useUiSettings();
  const isUsercodeUser = useIsUsercodeLogin();

  useEffect(() => {
    // I searched for a long time for a less gross workaround to this, but it
    // is currently not implemented into material-table (either fork of it)
    // See https://github.com/mbrn/material-table/issues/1480
    if (tableRef.current) {
      // @ts-ignore
      tableRef.current.dataManager.changePageSize(rowsPerPage);
      tableRef.current.setState({ pageSize: rowsPerPage });
    }
  }, [rowsPerPage]);

  useEffect(() => {
    if (options?.filtering && tableRef.current) {
      // If table can hide columns, only filter on displayed columns
      // @ts-ignore
      tableSettings.filters?.filter(f => !tableSettings.columns || tableSettings.columns[columns[f.columnId].field] || (['tpSerial', 'manufacturerSerial', 'imei'].includes(columns[f.columnId].field) && tableSettings.columns.deviceSerialNumber))
        .forEach(f => tableRef.current.dataManager.changeFilterValue(f.columnId, f.value));
      // @ts-ignore
      tableRef.current.setState(tableRef.current.dataManager.getRenderState());
    }
  }, [tableSettings, columns, options]);

  // expose the current state after every render
  useEffect(() => {
    if (onRender) { // @ts-ignore
      onRender(tableRef.current?.state);
    }
  });

  const coalesceSerialFields = (field: string): string => {
    if (['tpSerial', 'manufacturerSerial', 'imei'].includes(field)) return 'serial';
    return field;
  };

  const sortedColumns = useCallback((): Column<RowData>[] => columns?.map(col => {
    if (coalesceSerialFields(col.field?.toString() || '') === tableSettings.sortOrder?.field) return { ...col, defaultSort: tableSettings.sortOrder.order };
    if (tableSettings.sortOrder) return { ...col, defaultSort: undefined };
    return col;
  }) ?? [], [columns, tableSettings.sortOrder])();

  const onSortChange = (orderBy: number, order: 'asc' | 'desc'): void => {
    // @ts-ignore
    const field = coalesceSerialFields(columns[orderBy].field);
    updateSetting(settingsCategory, 'sortOrder', { field, order });
  };

  const updateRowsPerPage = (pageSize: number) => {
    if (isUsercodeUser) {
      updateSetting('ui', 'rowsPerPage', pageSize);
      appDispatch(updateLocalUiSettingsField({ field: 'rowsPerPage', value: pageSize }));
    } else {
      appDispatch(updateUiSettingField({ field: 'rowsPerPage', value: pageSize }));
    }
  };

  // I'm pretty sure these issues are mainly surrounding MaterialTables typing
  return (
    <MaterialTable<RowData>
      tableRef={tableRef}
      onPageChange={(page, pageSize) => {
        updateRowsPerPage(pageSize);
        updateSetting(settingsCategory, 'selectedPage', page);
      }}
      onRowsPerPageChange={pageSize => updateRowsPerPage(pageSize)}
      onSearchChange={searchText => updateSetting(settingsCategory, 'searchQuery', searchText)}
      onFilterChange={filters => updateSetting(settingsCategory, 'filters', filters.map(f => ({
        columnId: f.column.tableData.id,
        value: f.value
      })))}
      onOrderChange={onSortChange}
      options={{
        ...options,
        searchText: tableSettings.searchQuery,
        searchFieldVariant: options?.searchFieldVariant ?? 'outlined',
        searchFieldStyle: {
          ...options?.searchFieldStyle,
          borderRadius: '4px',
          paddingLeft: '18px',
          paddingRight: '10px'
        },
        initialPage: tableSettings.selectedPage,
        pageSizeOptions: [10, 25, 50, 100],
        pageSize: rowsPerPage
      }}
      columns={sortedColumns}
      components={{ Actions: TableActions, ...components }}
      {...props}
    />
  );
};

export default PersistentTable;
