import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Box,
  FormControl,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { MTableAction, Action } from '@material-table/core';
import { useTranslations } from 'use-intl';
import tableIcons from 'components/shared/icons/tableIcons';
import {
  useGetFriendGroupsList,
  useGetFriendOrganisationsList,
  useMutateDeleteFriendGroup,
  useMutateDeleteFriendOrganisation,
  useMutateNewFriendOrganisation,
} from 'apis/rest/friends/hooks';
import { GroupFriend, OrganisationFriend } from 'apis/rest/friends/types';
import { useGetTemporalSharesFromList, useGetTemporalSharesToList } from 'apis/rest/temporalShares/hooks';
import insensitiveSort from 'utils/insensitiveSort';
import PersistentTable from 'components/shared/persistentTable';
import StickyPagination from 'components/shared/stickyPagination';
import mixpanel from 'mixpanel-browser';
import useStyles from './friends-styles';

type RowData = {
  id: string
  name: string
  sharesFrom: number
  sharesTo: number
} & ({ type: 'organisation', friend: OrganisationFriend } | { type: 'group', friend: GroupFriend });

interface FriendsTableParams {
  userPermissions: any;
  displaySnackbar: (Snack: Snack) => void;
  organisationId: string;
}

const FriendsTable = ({
  userPermissions,
  displaySnackbar,
  organisationId,
}:FriendsTableParams): JSX.Element => {
  const classes = useStyles();
  const t = useTranslations('pages.connections.friends');
  const { canEditFriends } = userPermissions;

  const [invitee, setInvitee] = useState<string>();
  const [addFriendDialogOpen, setAddFriendDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState<OrganisationFriend | GroupFriend>();

  const sharesToQuery = useGetTemporalSharesToList();
  const sharesFromQuery = useGetTemporalSharesFromList();
  if (sharesToQuery.isError || sharesFromQuery.isError) displaySnackbar({ id: 'getSharesFailedSnackbar', text: t('getSharesFailed'), type: 'error' });

  const friendOrganisationsQuery = useGetFriendOrganisationsList();
  const friendGroupsQuery = useGetFriendGroupsList();

  const hasFriendsError = friendOrganisationsQuery.isError || friendGroupsQuery.isError;
  useEffect(() => {
    if (hasFriendsError) displaySnackbar({ id: 'getFriendsFailedSnackbar', text: t('getFriendsFailed'), type: 'error' });
  }, [hasFriendsError, displaySnackbar, t]);

  const addFriendMutation = useMutateNewFriendOrganisation();
  const deleteFriendOrganisationMutation = useMutateDeleteFriendOrganisation();
  const deleteFriendGroupMutation = useMutateDeleteFriendGroup();

  //
  // Add Friend
  //
  const handleAddFriend = () => {
    if (!invitee) return;

    addFriendMutation.mutate(
      { pubKey: invitee },
      {
        onSuccess: () => {
          displaySnackbar({ id: 'friendAdded', text: t('friendAdded'), type: 'success' });
          mixpanel.track('Add Friend', { success: true });
          setAddFriendDialogOpen(false);
          setInvitee(undefined);
        },
        onError: () => {
          displaySnackbar({ id: 'addFriendError', text: t('addFriendError'), type: 'error' });
          mixpanel.track('Add Friend', { success: false });
        },
      },
    );
  };

  const handleAddFriendDialogClose = (): void => {
    setAddFriendDialogOpen(false);
  };

  //
  // Remove Friend
  //
  const handleDeleteConfirm = (): void => {
    if (!selectedFriend) return;

    let mutation = deleteFriendOrganisationMutation;
    if ('groupId' in selectedFriend) mutation = deleteFriendGroupMutation;

    mutation.mutate(
      selectedFriend,
      {
        onSuccess: () => {
          displaySnackbar({ id: 'friendRemoved', text: t('friendRemoved'), type: 'success' });
          mixpanel.track('Remove Friend', { success: true });
          setDeleteDialogOpen(false);
          setSelectedFriend(undefined);
        },
        onError: () => {
          displaySnackbar({ id: 'removeFriendError', text: t('removeFriendError'), type: 'error' });
          mixpanel.track('Remove Friend', { success: false });
        },
      },
    );
  };

  const deleteFriend = (friend: OrganisationFriend | GroupFriend): void => {
    setSelectedFriend(friend);
    setDeleteDialogOpen(true);
  };

  //
  // Actions
  //
  const deleteAction = (rowData: RowData): Action<RowData> => ({
    icon: () => <Delete />,
    tooltip: t('delete'),
    onClick: () => deleteFriend(rowData.friend),
  });
  const createAction: Action<RowData> = {
    icon: () => <Add className={classes.addButton} />,
    iconProps: { color: 'primary' },
    isFreeAction: true,
    onClick: () => setAddFriendDialogOpen(true),
  };

  const tableFriends = useMemo(
    () => [
      ...(friendOrganisationsQuery.data ?? []).filter(friend => friend.organisationId !== organisationId).map<RowData>(friend => ({
        type: 'organisation',
        id: friend.organisationId,
        name: friend.organisationName,
        friend,
        sharesTo: sharesToQuery.data?.shares?.filter(s => s.organisationId === friend.organisationId).length ?? 0,
        sharesFrom: sharesFromQuery.data?.shares.filter(s => s.organisationId === friend.organisationId).length ?? 0,
      })),
      ...(friendGroupsQuery.data ?? []).map<RowData>(friend => ({
        type: 'group',
        id: friend.groupId,
        name: friend.groupName,
        friend,
        sharesTo: sharesToQuery.data?.groupShares?.filter(s => s.groupId === friend.groupId).length ?? 0,
        sharesFrom: sharesFromQuery.data?.groupShares?.filter(s => s.groupId === friend.groupId).length ?? 0,
      })),
    ],
    [organisationId, friendOrganisationsQuery.data, friendGroupsQuery.data, sharesToQuery.data, sharesFromQuery.data],
  );

  const footerRef = React.useRef<HTMLElement>();
  const Pagination = React.useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);

  const selectedFriendName = selectedFriend ? ('organisationName' in selectedFriend ? selectedFriend.organisationName : selectedFriend.groupName) : '';

  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box
        className={classes.materialTable}
        bgcolor="common.white"
        borderRadius={1}
        border={1}
        borderColor="common.midGrey"
        mb={8}
      >
        <PersistentTable<RowData>
          settingsCategory="friendsTable"
          title={t('title')}
          icons={tableIcons}
          isLoading={friendOrganisationsQuery.isLoading || friendOrganisationsQuery.isLoading}
          columns={[
            {
              title: t('columns.name'),
              field: 'name',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a, b) => insensitiveSort(a.name, b.name)
            },
            {
              title: t('columns.type'),
              field: 'type',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              render: row => t(row.type),
            },
            {
              title: t('columns.sharesFrom'),
              field: 'sharesFrom',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a, b) => a.sharesFrom - b.sharesFrom,
            },
            {
              title: t('columns.sharesTo'),
              field: 'sharesTo',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              customSort: (a, b) => a.sharesTo - b.sharesTo,
            },
          ]}
          data={tableFriends}
          actions={canEditFriends ? [createAction, deleteAction] : []}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            paging: true,
            pageSizeOptions: [10, 25, 50, 100],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
          }}
          localization={{
            header: {
              actions: t('columns.actions')
            }
          }}
          components={{
            Container: Box,
            Pagination,
            Action: canEditFriends ? props => {
              const { action, data } = props;
              return ((action.position === 'toolbar')
                ? (
                  <Button
                    onClick={event => action.onClick(event, data)}
                    className={classes.addButton}
                    variant="contained"
                  >
                    <Add />
                    {t('addButton')}
                  </Button>
                ) : <MTableAction {...props} />
              );
            } : undefined,
          }}
        />
        <Box ref={footerRef} position="sticky" bottom={0} />
      </Box>

      <Dialog open={addFriendDialogOpen} onClose={handleAddFriendDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle>{t('addFriendDialogTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogDesc}>{t('befriendDescription')}</DialogContentText>
          <FormControl variant="standard" className={classes.inviteInputWrapper}>
            <TextField
              id="addFriend"
              value={invitee}
              onChange={e => setInvitee(e.target?.value)}
              variant="outlined"
              label={t('friendPublicKeyPrompt')}
              autoFocus
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="primary" className={classes.inputButton} onClick={handleAddFriendDialogClose}>{t('cancel')}</Button>
          <Button disabled={!invitee} variant="contained" className={classes.inputButton} onClick={handleAddFriend}>{t('inviteButton')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="confirmation-dialog-title">{t('confirmDeleteFriendTitle', { friend: selectedFriendName })}</DialogTitle>
        <DialogContent dividers>{t('confirmDeleteFriendMessage', { friend: selectedFriendName })}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDeleteDialogOpen(false)}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={handleDeleteConfirm} color="primary">
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
};

export default FriendsTable;
