import airac from 'utils/airac';

const cache = {};

export default language => {
  if (!cache[language]) {
    cache[language] = {
      version: 8,
      name: 'SkyVector IFR Lo Map',
      provider: 'SkyVector',
      sources: {
        'skyVector-ifr-lo': {
          type: 'raster',
          tiles: [
            `${import.meta.env.VITE_SERENITY_TILE_PROXY}/skyvector/lo/${airac}/{z}/{x}/{y}.jpg`
          ],
          maxzoom: 9,
          minzoom: 3,
          tileSize: 256
        }
      },
      layers: [{
        id: 'ifr-lo',
        type: 'raster',
        source: 'skyVector-ifr-lo'
      }],
      thumbnailUrl: '/img/base-layer-thumbnails/ifr-map-thumbnail.jpg'
    };
  }

  return cache[language];
};
