import Smart from 'hocs/smart';
import UserPocketView from './userPocket-view';

export default Smart(
  UserPocketView,
  state => ({
    loggedIn: state.session.loggedIn && !!state.session.user
  }),
  ac => ({
    resetEverything: ac.app.resetEverything
  })
);
