import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import { joinOrganisationAction, leaveOrganisationAction } from 'slices/session/thunks';
import { setOrganisationId } from 'slices/session/session.slice';
import AssetSearchView from './assetSearch-view';

export default Smart(
  AssetSearchView,
  (state: any) => ({
    user: state.session.user,
    organisationId: state.session.organisationId,
    permissions: permissionsForCurrentUserAndOrg(state),
    assetSearchQuery: state.settings.staffAssetSearch.assetSearchQuery,
  }),
  (ac: any) => ({
    joinOrganisation: joinOrganisationAction,
    leaveOrganisation: leaveOrganisationAction,
    setOrganisation: setOrganisationId,
  })
);
