import { useMutation, useQueries, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { zipObject } from 'lodash';
import { useCallback } from 'react';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import {
  updateTrips,
  getTripsForAssets,
  UserTransitionToAdd,
  TripDeletionRequest,
  deleteTripsBetween,
  updateSupplementaryDataForTrip,
  approveTrip,
  getTripSummaryForAssets,
  getSupplementaryData,
  getSupplementaryDataFields,
  getBasicTripsForAssets,
} from './requests';
import { tripQueryKeys } from './queryKeys';
import type { TripBasic } from './types';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetTripsForAssets = <T = Trip[]>(assets: number[], from?: number, until?: number, options: Options<Trip[], T> = {}) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.tripsForAssets(organisationId, assets, from, until);
  return useQuery<Trip[], HttpResponseError, T>(
    queryKey,
    async () => {
      if (!from || !until || assets.length === 0) { return []; }
      return getTripsForAssets(organisationId, assets, from, until);
    },
    {
      ...options,
      enabled: options.enabled !== false && !!(from && until),
    }
  );
};

export const useGetBasicTripsForAssets = <T = TripBasic[]>(assets: number[], from?: number, until?: number, options: Options<TripBasic[], T> = {}) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.tripsBasicForAssets(organisationId, assets, from, until);
  return useQuery<TripBasic[], HttpResponseError, T>(
    queryKey,
    async () => {
      if (!from || !until || assets.length === 0) { return []; }
      return getBasicTripsForAssets(organisationId, assets, from, until);
    },
    {
      ...options,
      enabled: options.enabled !== false && !!(from && until),
    }
  );
};

export const useGetTripSummariesForAssets = <T = TripSummary[]>(assets: number[], from?: number, until?: number, options: Options<TripSummary[], T> = {}) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.tripSummariesForAssets(organisationId, assets, from, until);
  return useQuery<TripSummary[], HttpResponseError, T>(
    queryKey,
    async () => {
      if (!from || !until || assets.length === 0) { return []; }
      return getTripSummaryForAssets(organisationId, assets, from, until);
    },
    {
      ...options,
      enabled: options.enabled !== false && !!(from && until),
    }
  );
};

export const useSubmitChangeRequest = () => {
  const organisationId = useOrganisationId();

  return useMutation<void, HttpResponseError, UserTransitionToAdd[]>(
    knownTransitions => updateTrips(organisationId, knownTransitions),
    {
      mutationKey: ['changeRequest'],
    },
  );
};

export const useDeleteTripsBetween = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, TripDeletionRequest>(
    deletionReq => deleteTripsBetween(organisationId, deletionReq),
    {
      mutationKey: ['changeRequest'],
      onSuccess: () => queryClient.invalidateQueries(tripQueryKeys.allKnownTransitions(organisationId)),
    },
  );
};

export const useSupplementaryDataForTrip = <T = SupplementaryData | null> (tripId: string, options: Options<SupplementaryData | null, T> = {}) => {
  const organisationId = useOrganisationId();

  return useQuery<SupplementaryData | null, HttpResponseError, T>(
    tripQueryKeys.supplementaryDataForTrip(organisationId, tripId),
    async () => getSupplementaryData(organisationId, tripId),
    options
  );
};

export const useSupplementaryDataForTrips = (tripIds: string[]) => {
  const organisationId = useOrganisationId();
  return useQueries(
    {
      queries: tripIds.map<UseQueryOptions<SupplementaryData | null, HttpResponseError>>(t => ({
        queryKey: tripQueryKeys.supplementaryDataForTrip(organisationId, t),
        queryFn: () => getSupplementaryData(organisationId, t)
      })),
    }
  );
};

export const useSupplementaryDataFields = <T = SupplementaryDataFieldType[]> (options: Options<SupplementaryDataFieldType[], T> = {}) => {
  const organisationId = useOrganisationId();
  const queryKey = tripQueryKeys.supplementaryDataFields(organisationId);

  return useQuery<SupplementaryDataFieldType[], HttpResponseError, T>(
    queryKey,
    async () => getSupplementaryDataFields(organisationId),
    options
  );
};

export const useUpdateSupplementaryData = (tripId: string) => {
  const organisationId = useOrganisationId();

  return useMutation<void, HttpResponseError, SupplementaryDataEvent[]>(
    updateReq => updateSupplementaryDataForTrip(organisationId, tripId, updateReq),
    {
      mutationKey: ['supplementaryData', tripId]
    },
  );
};

export const useApproveTrips = () => {
  const organisationId = useOrganisationId();

  return useMutation<void[], HttpResponseError, string[]>(
    tripIds => Promise.all(tripIds.map(t => approveTrip(organisationId, t))),
    {
      mutationKey: ['tripApproval'],
    }
  );
};

// NOTE: This is for one-off on-demand fetching of trips
export const useFetchTrips = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useCallback(async (assetIds: number[], from: number, until: number, staleTime = 0) => queryClient.fetchQuery({
    queryKey: tripQueryKeys.tripsForAssets(organisationId, assetIds, from, until),
    queryFn: () => getTripsForAssets(organisationId, assetIds, from, until),
    staleTime,
  }), [queryClient, organisationId]);
};

export const useFetchSupplementaryDataForTrips = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useCallback(async (tripIds: string[], staleTime = 0) => Promise.all(
    tripIds.map(tripId => queryClient.fetchQuery({
      queryKey: tripQueryKeys.supplementaryDataForTrip(organisationId, tripId),
      queryFn: () => getSupplementaryData(organisationId, tripId),
      staleTime,
    }))
  ).then(results => zipObject(tripIds, results)), [queryClient, organisationId]);
};
