import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { friendGroupsQueryKeys, friendOrganisationsQueryKeys } from './queryKeys';
import { GroupFriend, OrganisationFriend } from './types';
import {
  addFriendGroup,
  addFriendOrganisation,
  deleteFriendGroup,
  deleteFriendOrganisation,
  fetchFriendGroups,
  fetchFriendOrganisations,
} from './requests';
import { groupsQueryKeys } from '../groups/queryKeys';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetFriendOrganisationsList = <T = OrganisationFriend[]>(options?: Options<OrganisationFriend[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = friendOrganisationsQueryKeys.lists(organisationId);

  return useQuery<OrganisationFriend[], HttpResponseError, T>(
    queryKey,
    () => fetchFriendOrganisations(organisationId),
    options,
  );
};

export const useGetFriendGroupsList = <T = GroupFriend[]>(options?: Options<GroupFriend[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = friendGroupsQueryKeys.lists(organisationId);

  return useQuery<GroupFriend[], HttpResponseError, T>(
    queryKey,
    () => fetchFriendGroups(organisationId),
    options,
  );
};

export const useMutateNewFriendOrganisation = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<OrganisationFriend, 'pubKey'>>(
    value => addFriendOrganisation(organisationId, value),
    {
      onSettled: () => queryClient.invalidateQueries(friendOrganisationsQueryKeys.all(organisationId)),
      mutationKey: ['addFriendOrganisation'],
    }
  );
};

export const useMutateNewFriendGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<GroupFriend, 'pubKey'>>(
    value => addFriendGroup(organisationId, value),
    {
      onSettled: () => Promise.all([
        queryClient.invalidateQueries(friendGroupsQueryKeys.all(organisationId)),
        queryClient.invalidateQueries(groupsQueryKeys.all(organisationId)),
      ]),
      mutationKey: ['addFriendGroup'],
    }
  );
};

export const useMutateDeleteFriendOrganisation = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<OrganisationFriend, 'pubKey'>>(
    value => deleteFriendOrganisation(organisationId, value),
    {
      onSettled: () => queryClient.invalidateQueries(friendOrganisationsQueryKeys.all(organisationId)),
      mutationKey: ['deleteFriendOrganisation'],
    }
  );
};

export const useMutateDeleteFriendGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<OrganisationFriend, 'pubKey'>>(
    value => deleteFriendGroup(organisationId, value),
    {
      onSettled: () => Promise.all([
        queryClient.invalidateQueries(friendGroupsQueryKeys.all(organisationId)),
        queryClient.invalidateQueries(groupsQueryKeys.all(organisationId)),
      ]),
      mutationKey: ['deleteFriendGroup'],
    }
  );
};
