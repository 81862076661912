import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { createFirestoreUiSettings, updateFirestoreUiSettings } from 'apis/rest/settings/requests';
import { app } from '../../firebaseApp';
import { loggedOut } from '../session/session.slice';

export type SerialType = 'tpSerial' | 'imei' | 'manufacturerSerial';

export interface uiSettingsState {
  ui: {
    darkMode: boolean | null
    assetLabel: 'name' | 'tailNumber' | 'callSign'
    assetDetailSelected: string
    serialType: SerialType
    rowsPerPage: number
    schema: number
  }
  loaded: boolean
}

export const initialUiState: uiSettingsState = {
  ui: {
    darkMode: null,
    assetLabel: 'name',
    assetDetailSelected: 'altitudeAtSpeed',
    serialType: 'tpSerial',
    rowsPerPage: 10,
    schema: 0,
  },
  loaded: false,
};

export interface updateUiFieldRequestType<F extends keyof uiSettingsState['ui']>{
  field: F
  value: uiSettingsState['ui'][F]
}

// add request execution
const debouncedWriteUiSettings = debounce(async (request: uiSettingsState['ui']) => updateFirestoreUiSettings(request), 3000, { leading: true, trailing: true });

export const updateUiSettingField = createAsyncThunk(
  'updateUiSettingField',
  async (request: updateUiFieldRequestType<keyof uiSettingsState['ui']>, thunkAPI) => {
    const state = thunkAPI.getState() as ReduxState;
    const payload = { ...state.uiSettings.ui, [request.field]: request.value };
    debouncedWriteUiSettings(payload);
    return payload;
  }
);

export const getUiSettings = createAsyncThunk<uiSettingsState['ui'], string, {state: ReduxState}>(
  'getUiSettings',
  async (uid: string, { getState }) => {
    const firestore = getFirestore(app);
    const uiSettingsRef = doc(firestore, `settings/${uid}/userSettings/uiSettings`);
    const uiSettingsDoc = await getDoc(uiSettingsRef);
    if (uiSettingsDoc.exists()) {
      return uiSettingsDoc.data() as uiSettingsState['ui'];
    }

    const existingUiSettings = getState().settings.ui as uiSettingsState['ui'];
    await createFirestoreUiSettings(existingUiSettings);
    return existingUiSettings;
  }
);

export const manageUiSettingsSlice = createSlice({
  name: 'uiSettings',
  initialState: initialUiState,
  reducers: {
    updateLocalUiSettings: (state, action: PayloadAction<uiSettingsState['ui']>) => {
      state.ui = action.payload;
      state.loaded = true;
    },
    updateLocalUiSettingsField: (state, action: PayloadAction<updateUiFieldRequestType<keyof uiSettingsState['ui']>>) => {
      state.ui[action.payload.field] = action.payload.value;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loggedOut, () => initialUiState)
      .addCase(getUiSettings.fulfilled, (state, action: PayloadAction<uiSettingsState['ui']>) => {
        state.ui = action.payload;
        state.loaded = true;
      })
      .addCase(updateUiSettingField.fulfilled, (state, action: PayloadAction<uiSettingsState['ui']>) => {
        state.ui = action.payload;
      });
  },
  selectors: {
    selectUiSettings: state => state.ui,
  }
});

export const { selectUiSettings } = manageUiSettingsSlice.selectors;
export const { updateLocalUiSettings, updateLocalUiSettingsField } = manageUiSettingsSlice.actions;
export default manageUiSettingsSlice.reducer;
