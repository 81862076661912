import Smart from 'hocs/smart';
import SnackbarView from './snackbarWrapper-view';

/**
 * @deprecated
 */
export default Smart(
  SnackbarView,
  state => ({
    snackbars: Object.values(state.app.snackbars)
  }),
  ac => ({
    destroySnackbar: ac.app.destroySnackbar
  })
);
