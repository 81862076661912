import React, { useState } from 'react';
import Modal from 'react-modal';
import {
  Box,
  Typography,
  Grid,
  Button
} from '@mui/material';
import TextInput from 'components/shared/forms/inputs/text';
import useTranslation from 'hooks/useTranslation';
import { isValidEmail } from 'utils/strings';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveUsercode } from 'apis/trackstar/serenity';
import useStyles from './organisation-contact-modal-styles';

const OrganisationContactModal = ({
  departmentName,
  organisation,
  mode,
  onClose,
  permissions,
  displaySnackbar,
}) => {
  const t = useTranslation('pages.organisationsList');
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [companyName, setCompanyName] = useState(organisation.billingCompanyName);
  const [contactName, setContactName] = useState(mode === 'primary' ? organisation.contactName : organisation.billingContactName);
  const [phone, setPhone] = useState(mode === 'primary' ? organisation.contactNumberPrimary : organisation.billingNumberPrimary);
  const [phoneAlt, setPhoneAlt] = useState(mode === 'primary' ? organisation.contactNumberAlternate : organisation.billingNumberAlternate);
  const [email, setEmail] = useState(mode === 'primary' ? organisation.email : organisation.billingEmail);
  const [address, setAddress] = useState(mode === 'primary'
    ? [organisation.contactAddressLine1, organisation.contactAddressLine2, organisation.contactAddressLine3].filter(s => s !== '').join(', ')
    : [organisation.billingAddressLine1, organisation.billingAddressLine2, organisation.billingAddressLine3].filter(s => s !== '').join(', '));
  const [city, setCity] = useState(mode === 'primary' ? organisation.contactCity : organisation.billingCity);
  const [state, setState] = useState(mode === 'primary' ? organisation.contactState : organisation.billingState);
  const [postcode, setPostcode] = useState(mode === 'primary' ? organisation.contactZip : organisation.billingZip);
  const [country, setCountry] = useState(mode === 'primary' ? organisation.contactCountry : organisation.billingCountry);
  const queryClient = useQueryClient();

  const updateOrganisationDetails = useMutation(updatedOrg => saveUsercode(updatedOrg), {
    onError: () => {
      displaySnackbar({ id: 'saveOrgContactFailed', text: t('saveOrgContactFailed'), type: 'error' });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'saveOrgContactSuccess', text: t('saveOrgContactSuccess'), type: 'success' });
      onClose();
    },
    onSettled: () => {
      queryClient.invalidateQueries(['organisations']);
    },
    onMutate: newUsercode => newUsercode,
    mutationKey: ['updateOrganisationContact'],
  });

  const emailValid = (mode === 'primary' ? email === organisation.email : email === organisation.billingEmail) || isValidEmail(email);
  const isValid = phone && emailValid && address && city && postcode && country;
  const canSave = isValid && !isSaving;
  const save = () => {
    if (!permissions.canEditOrganisation) return;
    setIsSaving(true);
    const updatedOrg = mode === 'primary' ? {
      ...organisation,
      contactName,
      contactNumberPrimary: phone,
      contactNumberAlternate: phoneAlt,
      email,
      contactAddressLine1: address,
      // purposely blank out address line 1 & 2, this update merges all 3 lines into line1
      contactAddressLine2: '',
      contactAddressLine3: '',
      contactCity: city,
      contactState: state,
      contactZip: postcode,
      contactCountry: country
    } : {
      ...organisation,
      billingCompanyName: companyName,
      billingContactName: contactName,
      billingNumberPrimary: phone,
      billingNumberAlternate: phoneAlt,
      billingEmail: email,
      billingAddressLine1: address,
      // purposely blank out address line 1 & 2, this update merges all 3 lines into line1
      billingAddressLine2: '',
      billingAddressLine3: '',
      billingCity: city,
      billingState: state,
      billingZip: postcode,
      billingCountry: country
    };
    updateOrganisationDetails.mutate(updatedOrg);
  };

  // TODO: re-implement this 'use primary contact as billing content' feature?
  // See commit from 26/04/2022 for the other bits of this feature that were ripped out
  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      className={classes.container}
      overlayClassName={classes.modalOverlay}
    >

      <Box className={classes.header}>
        <Typography variant="h2">{departmentName}</Typography>
      </Box>

      <Box className={classes.modalContent}>
        <Grid container spacing={3}>
          {mode === 'billing' && (
            <Grid item xs={12}>
              <TextInput label={t('companyName')} onChange={(_, next) => setCompanyName(next)} value={companyName} />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextInput label={t('name')} onChange={(_, next) => setContactName(next)} value={contactName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput label={t('phone')} onChange={(_, next) => setPhone(next)} value={phone} hasError={!phone} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput label={t('phoneAlt')} onChange={(_, next) => setPhoneAlt(next)} value={phoneAlt} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput label={t('email')} onChange={(_, next) => setEmail(next)} value={email} hasError={!emailValid} />
          </Grid>
          <Grid item xs={12}>
            <TextInput label={t('createDialog.address')} onChange={(_, next) => setAddress(next)} value={address} multiline minRows={2} hasError={!address} />
          </Grid>
          <Grid item xs={12}>
            <TextInput label={t('city')} onChange={(_, next) => setCity(next)} value={city} hasError={!city} />
          </Grid>
          <Grid item xs={8}>
            <TextInput label={t('createDialog.state')} onChange={(_, next) => setState(next)} value={state} />
          </Grid>
          <Grid item xs={4}>
            <TextInput label={t('createDialog.postcode')} onChange={(_, next) => setPostcode(next)} value={postcode} hasError={!postcode} />
          </Grid>
          <Grid item xs={12}>
            <TextInput label={t('country')} onChange={(_, next) => setCountry(next)} value={country} hasError={!country} />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.footer}>
        <Button onClick={onClose} tabIndex={-1}>Cancel</Button>
        <Button
          color="primary"
          disabled={!canSave}
          onClick={save}
        >
          {isSaving ? 'Saving...' : 'Save'}

        </Button>
      </Box>
    </Modal>
  );
};

export default OrganisationContactModal;
