import React, { useMemo } from 'react';
import { useTranslations } from 'use-intl';
import { Chip, FormControl, FormHelperText, FormLabel, Stack, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DateTime } from 'luxon';
import { ShareRangeType } from 'apis/rest/temporalShares/types';
import moment from 'utils/moment';

interface DateRangePickerProps {
  tz: string
  rangeType: ShareRangeType
  range: { start: string | undefined, end: string | undefined }
  onChangeRangeType: (value: ShareRangeType) => void
  onChangeRange: (value: { start: string | null | undefined, end: string | null | undefined }) => void
  disabled?: boolean
}

const viewRenderers = {
  hours: renderTimeViewClock,
  minutes: renderTimeViewClock,
  seconds: renderTimeViewClock,
};

const ShareDateRangePicker = ({ tz, rangeType, range, onChangeRangeType, onChangeRange, disabled = false }: DateRangePickerProps) => {
  const t = useTranslations('pages.sharing.dateRangePicker');
  const startM = useMemo(() => {
    if (!range.start) return undefined;
    return moment(DateTime.fromISO(range.start, { setZone: true })
      .setZone(tz)
      .setZone('utc', { keepLocalTime: true })
      .toMillis());
  }, [range.start, tz]);
  const endM = useMemo(() => {
    if (!range.end) return undefined;
    return moment(DateTime.fromISO(range.end, { setZone: true })
      .setZone(tz)
      .setZone('utc', { keepLocalTime: true })
      .toMillis());
  }, [range.end, tz]);

  const onChangeOpenEnded = (m: moment.Moment | null) => {
    if (m === null) {
      onChangeRange({ start: null, end: undefined });
      return;
    }
    if (!m.isValid()) return;
    onChangeRange({
      start: DateTime.fromMillis(+m, { zone: 'utc' })
        .setZone(tz, { keepLocalTime: true })
        .toUTC()
        .toISO({ suppressMilliseconds: true }),
      end: undefined,
    });
  };

  const onChangeSpecificStart = (m: moment.Moment | null) => {
    if (m === null) {
      onChangeRange({ start: null, end: range.end });
      return;
    }
    if (!m.isValid()) return;
    onChangeRange({
      start: DateTime.fromMillis(+m, { zone: 'utc' })
        .setZone(tz, { keepLocalTime: true })
        .toUTC()
        .toISO({ suppressMilliseconds: true }),
      end: range.end,
    });
  };

  const onChangeSpecificEnd = (m: moment.Moment | null) => {
    if (m === null) {
      onChangeRange({ start: range.start, end: null });
      return;
    }
    if (!m.isValid()) return;

    let newEnd = DateTime.fromMillis(+m, { zone: 'utc' }).setZone(tz, { keepLocalTime: true });
    if (newEnd.second !== 59) {
      newEnd = newEnd.endOf('day');
    }
    onChangeRange({
      start: range.start,
      end: newEnd.toUTC().toISO({ suppressMilliseconds: true }),
    });
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend"><Typography variant="h5" mb={1}>{t('label')}</Typography></FormLabel>
      <Stack direction="row" spacing={1} mt={1}>
        <Chip
          label={t('options.all')}
          icon={rangeType === ShareRangeType.AllTime ? <Check /> : undefined}
          color={rangeType === ShareRangeType.AllTime ? 'primary' : 'default'}
          onClick={() => onChangeRangeType(ShareRangeType.AllTime)}
          disabled={disabled}
        />
        <Chip
          label={t('options.openEnded')}
          icon={rangeType === ShareRangeType.OpenEnded ? <Check /> : undefined}
          color={rangeType === ShareRangeType.OpenEnded ? 'primary' : 'default'}
          onClick={() => onChangeRangeType(ShareRangeType.OpenEnded)}
          disabled={disabled}
        />
        <Chip
          label={t('options.specificRange')}
          icon={rangeType === ShareRangeType.SpecificRange ? <Check /> : undefined}
          color={rangeType === ShareRangeType.SpecificRange ? 'primary' : 'default'}
          onClick={() => onChangeRangeType(ShareRangeType.SpecificRange)}
          disabled={disabled}
        />
      </Stack>

      {rangeType === ShareRangeType.OpenEnded && (
        <>
          <Stack direction="row" spacing={3} mt={3}>
            <DesktopDateTimePicker<moment.Moment>
              label={t('start')}
              value={startM ?? null}
              format="DD/MM/YYYY HH:mm"
              onChange={onChangeOpenEnded}
              disabled={disabled}
              viewRenderers={viewRenderers}
              ampm={false}
            />
          </Stack>
          {range.start && (
            <FormHelperText sx={{ ml: 0 }}>
              {t('effectiveFrom', {
                start: DateTime.fromISO(range.start, { setZone: true }).setZone(tz).toFormat('dd MMM yyyy HH:mm:ss ZZZ'),
              })}
            </FormHelperText>
          )}
        </>
      )}

      {rangeType === ShareRangeType.SpecificRange && (
        <>
          <Stack direction="row" spacing={3} mt={3}>
            <DesktopDateTimePicker<moment.Moment>
              label={t('start')}
              value={startM ?? null}
              format="DD/MM/YYYY HH:mm"
              onChange={onChangeSpecificStart}
              maxDateTime={endM}
              disabled={disabled}
              ampm={false}
              viewRenderers={viewRenderers}
            />
            <DesktopDateTimePicker<moment.Moment>
              label={t('end')}
              value={endM ?? null}
              format="DD/MM/YYYY HH:mm"
              onChange={onChangeSpecificEnd}
              minDateTime={startM}
              disabled={disabled}
              ampm={false}
              viewRenderers={viewRenderers}
            />
          </Stack>
          {range.start && range.end && (
            <FormHelperText sx={{ ml: 0 }}>
              {t('effectiveFromTo', {
                start: DateTime.fromISO(range.start, { setZone: true }).setZone(tz).toFormat('dd MMM yyyy HH:mm:ss ZZZ'),
                end: DateTime.fromISO(range.end, { setZone: true }).setZone(tz).toFormat('dd MMM yyyy HH:mm:ss ZZZ'),
              })}
            </FormHelperText>
          )}
        </>
      )}
    </FormControl>
  );
};

export default ShareDateRangePicker;
