import { DateTime } from 'luxon';
import { HttpResponseError, serenityFetch } from 'helpers/api';
import { EngineUsage } from './types';

interface ApiResult {
  totalEngineTimeMinutes: number
  engineUsage: {
    start: string
    end: string | null
  }[]
}

export const getEngineUsageForAsset = async (organisationId: string, assetId: number, from: number, until: number): Promise<EngineUsage[]> => {
  const params = new URLSearchParams({
    from: DateTime.fromMillis(from).toUTC().toISO(),
    until: DateTime.fromMillis(until).toUTC().toISO(),
  });
  const response = await serenityFetch('GET', `/organisations/${organisationId}/engine-usage/${assetId}?${params}`, null);
  HttpResponseError.detect(response);

  return (await response.json() as ApiResult).engineUsage.map(item => {
    const startTime = DateTime.fromISO(item.start, { zone: 'utc' }).toMillis();
    const endTime = item.end ? DateTime.fromISO(item.end, { zone: 'utc' }).toMillis() : undefined;
    return {
      assetId,
      startTime,
      endTime,
      duration: endTime === undefined ? undefined : endTime - startTime,
    };
  });
};
