import useFeatureFlag from 'hooks/useFeatureFlag';
import useFeature from 'hooks/features/useFeature';
import useFeatureAssets, { FeatureAssetsResult } from 'contexts/featureAssets/useFeatureAssets';
import { useMemo } from 'react';

export enum AssetsGeofenceStatus {
  All,
  Some,
  None,
  Never,
  Loading,
}

export const getStatus = (
  featureModulesFlag: boolean | undefined,
  featureAssets: FeatureAssetsResult,
  featureToggle: boolean | undefined,
  assetGroupIds: number[] | undefined
): AssetsGeofenceStatus => {
  if (featureModulesFlag && !featureAssets.isLoading) {
    if (featureAssets.all) return AssetsGeofenceStatus.All;
    if (!featureAssets.some) return AssetsGeofenceStatus.Never;
    const all = assetGroupIds?.every(id => featureAssets.completeAssetGroups.includes(id));
    if (all) return AssetsGeofenceStatus.All;

    const someOfGivenGroups = assetGroupIds?.some(id => (
      featureAssets.completeAssetGroups.includes(id)
      || featureAssets.partialAssetGroups.includes(id)
    )) ?? false;
    return someOfGivenGroups ? AssetsGeofenceStatus.Some : AssetsGeofenceStatus.None;
  }

  if (featureModulesFlag === false && featureToggle !== undefined) {
    return featureToggle ? AssetsGeofenceStatus.All : AssetsGeofenceStatus.Never;
  }

  return AssetsGeofenceStatus.Loading;
};

export const useAssetsGeofenceStatus = (assetGroupIds: number[] | undefined) => {
  const featureModulesFlag = useFeatureFlag('featureModules');
  const geofencesFeatureToggle = useFeature('manage.geofencing');
  const geofencesFeatureAssets = useFeatureAssets('manage.geofencing');

  return useMemo<AssetsGeofenceStatus>(() => (
    getStatus(featureModulesFlag, geofencesFeatureAssets, geofencesFeatureToggle, assetGroupIds)
  ), [featureModulesFlag, assetGroupIds, geofencesFeatureAssets, geofencesFeatureToggle]);
};
