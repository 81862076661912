import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslations } from 'use-intl';
import { Box, Paper, Switch, Typography } from '@mui/material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import sumBy from 'lodash/fp/sumBy';
import {getSuppressantColors} from './helpers';
import useVolume from 'hooks/units/useVolume';
import {useTheme} from "@mui/material/styles";

interface DropsBySuppressantScorecardProps extends PaperOwnProps {
  drops: Drop[]
  visibleSuppressants: Record<Suppressant, boolean>
  setVisibleSuppressants: Dispatch<SetStateAction<Record<Suppressant, boolean>>>
}

const DropsBySuppressantScorecard = ({ drops, sx, visibleSuppressants, setVisibleSuppressants, ...props }: DropsBySuppressantScorecardProps): JSX.Element | null => {
  const t = useTranslations('pages.reporting.firefighting');
  const volume = useVolume();
  const dropDrops = drops.filter(drop => drop.type === 'Drop');

  const theme = useTheme();
  const suppressantColor = getSuppressantColors(theme);

  const bySuppressant = dropDrops.reduce<Record<string, Drop[]>>((acc, drop) => {
    if (!acc[drop.suppressant]?.push(drop)) acc[drop.suppressant] = [drop];
    return acc;
  }, {});

  const allSameSuppressant = Object.keys(bySuppressant).length === 1;

  if (!drops.length) return null;

  return (
    <Paper elevation={0} {...props} sx={{ ...sx, p: 3, display: 'flex', alignItems: 'center' }}>
      <Box flex={1} display="grid" gridTemplateColumns="1fr max-content max-content max-content" columnGap={6} alignItems="center">
        {allSameSuppressant ? (
          <Typography fontSize="1.5rem" fontWeight="bold" sx={{ gridColumn: '1 / -1' }}>
            {t('allSuppressant', { name: t(`suppressant.${dropDrops[0].suppressant}`) })}
          </Typography>
        ) : (
          (Object.keys(visibleSuppressants) as Suppressant[]).filter(suppressant => bySuppressant[suppressant]).map(suppressant => {
            const visible = visibleSuppressants[suppressant];
            const color = visible ? suppressantColor[suppressant] : 'common.disabled';
            const suppressantDrops = bySuppressant[suppressant];
            return (
              <Fragment key={suppressant}>
                <Typography fontSize="1.5rem" fontWeight="bold" color={color} whiteSpace="nowrap">
                  {t(`suppressant.${suppressant}`)}
                </Typography>
                <Typography fontSize="1.5rem" fontWeight="bold" color={color} textAlign="right">
                  {t('nDrops', { n: suppressantDrops.length })}
                </Typography>
                <Typography fontSize="1.5rem" fontWeight="bold" color={color} textAlign="right">
                  {volume.create(sumBy('dropVolume', suppressantDrops)).format()}
                </Typography>
                <Switch
                  checked={visible}
                  onChange={event => setVisibleSuppressants(state => ({ ...state, [suppressant]: event.target.checked }))}
                  inputProps={{ 'aria-label': t('toggleSuppressantLabel', { name: t(`suppressant.${suppressant}`) }) }}
                />
              </Fragment>
            );
          })
        )}
      </Box>
    </Paper>
  );
};

export default DropsBySuppressantScorecard;
