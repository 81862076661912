import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import {
  addMessagingWhitelistContactGroup,
  deleteMessagingWhitelistContactGroup,
  getMessagingWhitelistContactGroups,
  updateMessagingWhitelistContactGroup,
  updateMessagingWhitelistContactGroupAssignDevices,
  updateMessagingWhitelistContactGroupAssignPeople,
} from './requests';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

type MessagingWhitelistContactGroupsQueryKey = ['messagingWhitelistContactGroups', { organisationId: string }];
const useMessagingWhitelistContactGroupsQueryKey = (organisationId: string): MessagingWhitelistContactGroupsQueryKey => useMemo(
  () => ['messagingWhitelistContactGroups', { organisationId }],
  [organisationId],
);

export const useGetMessagingWhitelistContactGroups = <T = ContactGroup[]>(options?: Options<ContactGroup[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = useMessagingWhitelistContactGroupsQueryKey(organisationId);
  const query = useQuery<ContactGroup[], HttpResponseError, T>(queryKey, () => getMessagingWhitelistContactGroups(organisationId), options);
  return { query, queryKey };
};

export const useMutateNewMessagingWhitelistContactGroup = () => {
  const organisationId = useOrganisationId();
  const queryKey = useMessagingWhitelistContactGroupsQueryKey(organisationId);
  const queryClient = useQueryClient();

  return useMutation<ContactGroup, HttpResponseError, Pick<ContactGroup, 'name'>, { previousGroups?: ContactGroup[] }>(
    value => addMessagingWhitelistContactGroup(organisationId, value),
    {
      onMutate: async value => {
        await queryClient.cancelQueries(queryKey);
        const previousGroups = queryClient.getQueryData<ContactGroup[]>(queryKey);
        if (previousGroups) {
          queryClient.setQueryData<ContactGroup[]>(queryKey, [
            ...previousGroups,
            {
              ...value,
              id: -1,
              isDefault: false,
              temporary: true,
              deviceAndAssetIds: [],
              peopleWithOrder: [],
              peopleVersion: -1,
              deviceVersion: -1,
            },
          ]);
        }
        return { previousGroups };
      },
      onError: (err, value, context) => {
        if (context?.previousGroups) {
          queryClient.setQueryData(queryKey, context.previousGroups);
        }
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
    }
  );
};

export const useMutateMessagingWhitelistContactGroup = () => {
  const organisationId = useOrganisationId();
  const queryKey = useMessagingWhitelistContactGroupsQueryKey(organisationId);
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<ContactGroup, 'id' | 'name' | 'isDefault'>, { previousGroups?: ContactGroup[] }>(
    value => updateMessagingWhitelistContactGroup(organisationId, value),
    {
      onMutate: async value => {
        await queryClient.cancelQueries(queryKey);
        const previousGroups = queryClient.getQueryData<ContactGroup[]>(queryKey);

        if (previousGroups?.some(g => g.id === value.id)) {
          queryClient.setQueryData<ContactGroup[]>(queryKey, () => previousGroups.map(g => {
            if (g.id === value.id) return { ...g, ...value, temporary: true };
            if (value.isDefault && g.isDefault) return { ...g, isDefault: false, temporary: true };
            return g;
          }));
        }
        return { previousGroups };
      },
      onError: (err, value, context) => {
        if (context?.previousGroups) {
          queryClient.setQueryData(queryKey, context.previousGroups);
        }
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
    }
  );
};

export const useMutateMessagingWhitelistContactGroupAssignDevices = () => {
  const organisationId = useOrganisationId();
  const queryKey = useMessagingWhitelistContactGroupsQueryKey(organisationId);
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<ContactGroup, 'id' | 'deviceAndAssetIds' | 'deviceVersion'>, { previousGroups?: ContactGroup[] }>(
    value => updateMessagingWhitelistContactGroupAssignDevices(organisationId, {
      id: value.id,
      deviceVersion: value.deviceVersion,
      deviceIds: value.deviceAndAssetIds.map(x => x.deviceId),
    }),
    {
      onMutate: async value => {
        await queryClient.cancelQueries(queryKey);
        const previousGroups = queryClient.getQueryData<ContactGroup[]>(queryKey);
        if (previousGroups?.some(g => g.id === value.id)) {
          queryClient.setQueryData<ContactGroup[]>(queryKey, () => previousGroups.map(g => (
            g.id === value.id ? { ...g, ...value, temporary: true } : g
          )));
        }
        return { previousGroups };
      },
      onError: (err, value, context) => {
        if (context?.previousGroups) {
          queryClient.setQueryData(queryKey, context.previousGroups);
        }
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
    }
  );
};

export const useMutateMessagingWhitelistContactGroupAssignPeople = () => {
  const organisationId = useOrganisationId();
  const queryKey = useMessagingWhitelistContactGroupsQueryKey(organisationId);
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<ContactGroup, 'id' | 'peopleWithOrder' | 'peopleVersion'>, { previousGroups?: ContactGroup[] }>(
    value => updateMessagingWhitelistContactGroupAssignPeople(organisationId, {
      id: value.id,
      peopleVersion: value.peopleVersion,
      peopleForGroup: value.peopleWithOrder,
    }),
    {
      onMutate: async value => {
        await queryClient.cancelQueries(queryKey);
        const previousGroups = queryClient.getQueryData<ContactGroup[]>(queryKey);
        if (previousGroups?.some(g => g.id === value.id)) {
          queryClient.setQueryData<ContactGroup[]>(queryKey, () => previousGroups.map(g => (
            g.id === value.id ? { ...g, ...value, temporary: true } : g
          )));
        }
        return { previousGroups };
      },
      onError: (err, value, context) => {
        if (context?.previousGroups) {
          queryClient.setQueryData(queryKey, context.previousGroups);
        }
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
    }
  );
};

export const useMutateDeleteMessagingWhitelistContactGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();
  const queryKey = useMessagingWhitelistContactGroupsQueryKey(organisationId);

  return useMutation<void, HttpResponseError, Pick<ContactGroup, 'id'>, { previousGroups?: ContactGroup[] }>(
    value => deleteMessagingWhitelistContactGroup(organisationId, value),
    {
      // No optimistic update for this because we want the UI to wait until settled
      onMutate: async value => {
        await queryClient.cancelQueries(queryKey);
        const previousGroups = queryClient.getQueryData<ContactGroup[]>(queryKey);
        if (previousGroups?.some(g => g.id === value.id)) {
          queryClient.setQueryData<ContactGroup[]>(queryKey, () => previousGroups.filter(g => g.id !== value.id));
        }
        return { previousGroups };
      },
      onError: (err, value, context) => {
        if (context?.previousGroups) {
          queryClient.setQueryData(queryKey, context.previousGroups);
        }
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
    }
  );
};
