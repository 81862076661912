import { XMLParser } from 'fast-xml-parser';
import { getRefreshedToken } from 'apis/auth';
import { objToXml } from './serenity';

const TRACSTAR_SOAP_URL = import.meta.env.VITE_TRACSTAR_SOAP_URL;

const getSoapHeaders = (endpoint: string): Headers => {
  const headers = new Headers();
  headers.append('SOAPAction', `http://pathfindertech.net/services/${endpoint}`);
  headers.append('Content-Type', 'text/xml');
  return headers;
};

const getXMLBody = async (api: string, extraXMLOptions?: object): Promise<string> => (
  `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://pathfindertech.net/services/">
     <soapenv:Header/>
     <soapenv:Body>
        <ser:${api}>
           <ser:userId>${localStorage.getItem('organisationId')}</ser:userId>
           <ser:user>${localStorage.getItem('organisationId')}</ser:user>
           <ser:password>${await getRefreshedToken()}</ser:password>
           ${objToXml(extraXMLOptions)}
        </ser:${api}>
     </soapenv:Body>
  </soapenv:Envelope>`
);

/**
 * This method parses the SOAP response from our API, and throws an error based on the error the server throws.
 *
 * @param xml Soap response text to be parsed
 * @returns The result of the request (4 levels down from root), or an error.
 */
export const parseSOAP = (xml: string): any => {
  // When a tag only has one entry, it can't decide whether it's an array or not
  // This rectifies this. Update this when a new entry is added that relies on an element
  // containing a list of other elements.
  const alwaysInArray = [
    'terminal',
    'report',
    'Friend',
    'Friends',
    'usercode',
    'Share',
    'conversation',
    'message',
    'groupMember',
    'contactDetails',
    'asset',
    'device',
    'pendingMember',
    'member',
    'role',
    'organisation',
    'messagingWhitelist',
    'capability',
  ];
  const parser = new XMLParser({
    isArray: name => alwaysInArray.includes(name)
  });
  const parsedXML = parser.parse(xml);
  const soapNamespace = 's';
  if (Object.keys(parsedXML).includes(`${soapNamespace}:Envelope`)) {
    const body = parsedXML[`${soapNamespace}:Envelope`][`${soapNamespace}:Body`];
    const endpoint = Object.keys(body)[0].replace('Response', '');
    const result = body[`${endpoint}Response`][`${endpoint}Result`];
    // TODO: remove getAssets exception once we can work out why it returns result.success=false even when successful
    if (result.success || endpoint === 'getAssets') {
      return result;
    }
    throw new Error(`${endpoint}: ${result.error}: ${result.description}`);
  }
  throw new Error('Server did not return anything.');
};

/**
 * Wraps a fetch and parsing call to tracstar.
 *
 * @param endpoint The SOAP api to call on /trackstar.asmx
 * @param extraXMLOptions Extra xml options to provide aside from usercode/password
 * @returns parsed SOAP result, 4 levels down from root
 */
const fetchTracstar = async (endpoint: string, extraXMLOptions?: object): Promise<any> => {
  const response = await fetch(`${TRACSTAR_SOAP_URL}?${endpoint}`, {
    method: 'POST',
    headers: getSoapHeaders(endpoint),
    body: await getXMLBody(endpoint, extraXMLOptions),
    redirect: 'follow',
    mode: 'cors',
  });
  if (!response.ok) throw new Error(`Failed to request tracstar/${endpoint}.`);
  return parseSOAP(await response.text());
};

/**
 * Set permissions creates, updates or deletes share permissions on the TracStar backend.
 *
 * @remarks `setPermissions` can only modify shares from the current user. The
 * backend does not support using this endpoint to delete shares to the user.
 *
 * `setPermissions` can be used directly. TrackStar uses the single endpoint for creation, edits, and deletion. The
 * continence methods `createShare`, `editShare` and `deleteShare` are recommended for readability.
 *
 * @param deviceId Device to modify the sharing for.
 * @param pubkey public key of Organisation to modify the share for.
 * @param accessCode Access to set on the share for `deviceId` to `orgId`.
 * @returns A promise to set the share permission.
  */
export const setPermissions = async (deviceId: string, pubkey: string, accessCode: string): Promise<void> => {
  const extraXMLOptions = {terminalId: deviceId, pubkey, newPermissions: accessCode};
  return fetchTracstar('SetPermissions', extraXMLOptions);
};

// #---------------------------#
// # CONVERSATIONS & MESSAGING #
// #---------------------------#
export const sendMessage = async (deviceId: number, message: string, transport: string): Promise<Result> => {
  // TODO: may want to allow choosing transport method (iridium vs cellular) in the future
  // hardcode to send text message capability and iridium SBD transport (always message over iridium for now)
  const extraXMLOptions = {
    terminalID: deviceId,
    capabilityCode: 'CP_SND_TEXT',
    transportCode: transport,
    args: {
      paramPair: { key: 'text', value: message }
    }
  };
  return fetchTracstar('sendMessage', extraXMLOptions);
};
