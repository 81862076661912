import React, { useCallback, useMemo, useState } from 'react';
import { Chip, Stack, TextField, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { useMutateNewContact } from 'apis/rest/people/hooks';
import { displaySnackbar } from 'actions/app';
import { useDispatch } from 'react-redux';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import { isValidEmail } from 'utils/strings';
import { ContactDetails } from './contactDetails';
import { EditContactProvider, InputComponent, Validator } from './editContactContext';

interface EmailContactProps {
  person?: Person
}

export const EmailInput: InputComponent = ({ label, value, existingValue, setValue, validation, allowMissing, ...rest }) => {
  const t = useTranslation('pages.manage.person.email');
  const missingAllowed = allowMissing && existingValue === undefined && validation.missing;

  return (
    <TextField
      variant="outlined"
      value={value}
      type="email"
      onChange={event => setValue(event.target.value)}
      error={!missingAllowed && !validation.valid}
      helperText={missingAllowed ? undefined : validation.helperText}
      label={label ?? t('contactType')}
      fullWidth
      {...rest}
    />
  );
};

export const useValidateEmail = (rows: { value: string }[]) => {
  const t = useTranslation('pages.manage.person.email');

  return useCallback((nextValue: string, value: string | null) => {
    if (!nextValue.trim()) {
      return {
        valid: false,
        missing: true,
        helperText: t('invalid'),
      };
    }
    if (!isValidEmail(nextValue)) {
      return {
        valid: false,
        helperText: t('invalid'),
      };
    }
    if (value !== null && nextValue.trim() === value.trim()) {
      return {
        valid: true,
        changed: false,
      };
    }
    if (rows.some(c => c.value.toLowerCase() === nextValue)) {
      return {
        valid: false,
        helperText: t('exists'),
      };
    }
    return { valid: true, changed: true };
  }, [t, rows]);
};

export const EmailContacts = ({ person }: EmailContactProps): JSX.Element => {
  const t = useTranslation('pages.manage.person.email');
  const dispatch = useDispatch();
  const [newEmail, setNewEmail] = useState<string>('');
  const addMutation = useMutateNewContact();

  const onAddContact = (): void => {
    if (!person) return;
    addMutation.mutate(
      {
        contactType: 'email',
        contactValue: newEmail,
        peopleId: person.id,
        isDefault: false,
      },
      {
        onSuccess: () => {
          displaySnackbar({
            id: 'personContactUpdated',
            text: t('snackbar.addContact.success', { value: newEmail }),
            type: 'success'
          })(dispatch);
        },
        onError: () => {
          displaySnackbar({
            id: 'personContactUpdatedError',
            text: t('snackbar.addContact.error'),
            type: 'error'
          })(dispatch);
        },
      },
    );
    setNewEmail('');
  };

  const rows = useMemo(
    () => person?.contacts.filter(c => c.contactType === 'email')
      .map(contact => ({ contact, value: contact.contactValue })) || [],
    [person?.contacts],
  );

  const validate: Validator = useValidateEmail(rows);

  const newValidation = validate(newEmail, null);

  return (
    <EditContactProvider validate={validate} Input={EmailInput}>
      <ContactDetails
        title={t('title')}
        description={t('description')}
        contactName={t('contactType')}
        rows={rows}
        onAdd={onAddContact}
        addText={
          addMutation.isLoading ? <LoadingIcon size={20} /> : t('add')
        }
        noItemsText={t('noContacts')}
        input={(
          <EmailInput
            value={newEmail}
            setValue={setNewEmail}
            validation={newValidation}
            disabled={addMutation.isLoading}
            allowMissing
          />
        )}
        renderContact={({ row }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{row.value}</Typography>
            {row.contact.isDefault && <Chip label={t('default')} color="primary" />}
          </Stack>
        )}
        addDisabled={!newValidation.valid || addMutation.isLoading}
        editTooltipTitle={t('tooltips.edit')}
        deleteTooltipTitle={t('tooltips.delete')}
        canDelete={() => true}
      />
    </EditContactProvider>
  );
};
