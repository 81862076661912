import colormap from 'colormap';
import { gatewayToTransport, Transport } from 'helpers/transport';
import { clamp, lerp } from '@math.gl/core';
import { hexToRGBArray } from 'helpers/color';
import { MapSettings } from 'reducers/map';

export const COLORMAP: ColorAlpha[] = colormap({
  colormap: 'viridis',
  nshades: 100,
  format: 'rgba'
}).map((c: ColorAlpha) => [c[0], c[1], c[2], 255]);

export const TransportColors: Record<Transport, ColorAlpha> = {
  AFF: hexToRGBArray('#CC78BC'),
  Unknown: hexToRGBArray('#CA9161'),
  Satellite: hexToRGBArray('#D55E00'),
  SMS: hexToRGBArray('#029E73'),
  API: hexToRGBArray('#ECE133'),
  Cellular: hexToRGBArray('#0173B2'),
  AFF2: hexToRGBArray('#FBAFE4'),
};

const filterConsecutive = (reports: Report[]): Report[] => reports
  .filter((r, idx, arr) => {
    if (idx === 0) { return true; }
    const prev = arr[idx - 1];
    return prev.latitude !== r.latitude || prev.longitude !== r.longitude;
  }).reverse();

type AssetTrailColoringOptions = MapSettings['assetTrailColouring'];
export type NumericAssetColouringOptions = Exclude<AssetTrailColoringOptions, 'transport' | 'none'>;

export const colorLookup = {
  battery: (r: Report) => r.battery,
  speed: (r: Report) => r.speed,
  altitude: (r: Report) => r.altitude,
  latency: (r: Report) => r.logged - r.received,
  transport: (r: Report) => gatewayToTransport(r.gateway)
} as const;

const numToColor = (inp: number, max: number) => COLORMAP[Math.floor(clamp((inp * 100) / max, 0, 99.999))];

type CategoricalSelection = (r: Report) => Transport
type NumericalSelection = (r: Report) => number

export const addColoursToSpline = <T extends PathFeature>(reports: Report[], paths: T[], colouring: AssetTrailColoringOptions, fallbackColor: ColorAlpha): T[] => {
  const cleanedReports = filterConsecutive(reports);

  if (cleanedReports.length === 0 || !colouring || colouring === 'none') {
    return paths;
  }

  const selector = colorLookup[colouring];
  const numerical = typeof selector(cleanedReports[0]) === 'number';

  const max = numerical ? Math.max(...reports.map(selector as NumericalSelection)) : undefined;

  let splineProgress = 0;
  let i = 0;
  let report = cleanedReports[0];
  let nextReport = cleanedReports[1] ?? report;

  return [...paths].reverse().map(path => ({
    ...path,
    properties: {
      ...path.properties,
      color: path.path.map((_, idx) => {
        if (idx !== 0 && path.path.length - 2 !== idx) {
          // 16 because there are 15 interpolated points
          splineProgress = (idx - 1) % 16;
          if (splineProgress === 0) {
            i += 1;
            report = cleanedReports[i];
            nextReport = cleanedReports[i + 1] ?? report;
          }
          splineProgress /= 16;
        }
        if (!report || max === 0) {
          return fallbackColor;
        }
        if (numerical && (max !== undefined)) {
          const val = (selector as NumericalSelection)(report);
          const nextVal = (selector as NumericalSelection)(nextReport);
          return numToColor(lerp(val, nextVal, splineProgress), max);
        }
        const val = (selector as CategoricalSelection)(report);
        return TransportColors[val];
      })
    }
  }));
};

export const mapToNumbers = (reports: Report[], colouring: NumericAssetColouringOptions) => reports.map(colorLookup[colouring]);

export const mapToColors = (reports: Report[], colouring: AssetTrailColoringOptions, fallbackColor: (r: Report) => ColorAlpha): ColorAlpha[] => {
  if (reports.length === 0 || !colouring || colouring === 'none') {
    return reports.map(fallbackColor);
  }

  const selector = colorLookup[colouring];

  if (typeof selector(reports[0]) === 'number') {
    const max = Math.max(...reports.map(selector as NumericalSelection));

    if (max === 0) {
      return reports.map(fallbackColor);
    }

    return reports
      .map(selector as NumericalSelection)
      .map(n => numToColor(n, max));
  }

  return reports
    .map(selector as CategoricalSelection)
    .map(t => TransportColors[t]);
};
