import React, { useCallback } from 'react';
import {
  Box,
  Typography,
  Stack,
  Grid,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import moment from 'moment';
import Altitude from 'components/shared/altitude';
import AssetLabel from 'components/shared/assetLabel';
import Coordinate from 'components/shared/coordinate';
import Course from 'components/shared/course';
import DateTime from 'components/shared/datetime';
import DirectionIcon from 'components/shared/icons/directionIcon';
import Icon from 'components/shared/icons/eventIcons';
import Quantity from 'components/shared/quantity';
import Speed from 'components/shared/speed';
import TransmissionIcon from 'components/shared/icons/transmissionIcon';
import { gatewayToTransport } from 'helpers/transport';
import { isSignificantEvent, labelToDisplayLabel } from 'helpers/events';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useReportsDataRepository } from 'repositories/reports/hooks';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import useStyles from './reportDetails-styles';
import { ScoreCard, DetailsGrid, OverlayWrapper, OverlayHeader } from '../common';

const Simple2dGrid = ({
  data,
}) => {
  const keys = Object.keys(data);

  return keys.map((fieldName) => (
    <ScoreCard
      key={fieldName}
      label={fieldName}
      value={data[fieldName]}
      wide={fieldName === 'Passengers'}
    />
  ));
};

const EventIcon = ({ label, className }) => (
  <Box className={className}>
    <Icon type={label} />
  </Box>
);

// Displays information common to all reports, eg: the Event name, icon, transport etc
const Header = ({
  classes,
  t,
  asset,
  report
}) => (
  <>
    <OverlayHeader>
      <Stack direction="row" alignItems="center">
        <Typography variant="h3"><AssetLabel asset={asset} /></Typography>

        <Box flex={1} />

        {report?.events && report.events.map(e => isSignificantEvent(e) && (
          <EventIcon key={`${report.id}-${e}`} label={e} className={classes.eventIcon} />)
        )}
        {report?.gateway && (
          <Box className={classes.eventIcon}>
            <TransmissionIcon transport={gatewayToTransport(report.gateway)} fillColor="rgba(0,0,0,0.5)" circle />
          </Box>
        )}
        <Box className={classes.iconWrapper}>
          <DirectionIcon style={{ transition: 'all 0.15s', transform: `rotate(${report?.course || '0'}deg)` }} fillColor="rgba(0,0,0,0.5)" circle />
        </Box>
      </Stack>
    </OverlayHeader>
    <DetailsGrid withoutTopBorder sx={{ pt: 0 }}>
      <ScoreCard label={t('transport')}>
        <Typography fontWeight={500}>{gatewayToTransport(report?.gateway) || '--'}</Typography>
      </ScoreCard>
      <ScoreCard label={t('eventType')}>
        <Typography fontWeight={500}>{labelToDisplayLabel(report?.events?.[0]) || t('standard')}</Typography>
      </ScoreCard>
    </DetailsGrid>
  </>
);

const PositionDetails = ({
  t,
  position,
  speedUnit,
}) => (
  <>
      <ScoreCard label={t('timeOfFix')} wide>
        <DateTime value={moment.unix(position.received)} />
      </ScoreCard>

      <ScoreCard label={t('coordinates')} wide>
        <Coordinate latitude={position.latitude} longitude={position.longitude} />
      </ScoreCard>

      <ScoreCard label={t('altitude')}>
        <Altitude altitudeInMetres={position.altitude} precision={0} />
      </ScoreCard>
      <ScoreCard label={t('latency')}>
        <Quantity value={position.logged - position.received} precision={0} units="s" />
      </ScoreCard>

      <ScoreCard label={t('speed')}>
        <Speed unit={speedUnit} speedInKmh={position.speed} />
      </ScoreCard>
      <ScoreCard label={t('course')}>
        <Course courseInDegreesTrue={position.course} timestamp={position.received * 1000} position={[position.longitude, position.latitude]} />
      </ScoreCard>
  </>
);

const ReportDetailsOverlay = ({
  selectedReport,
  highContrastControls,
}) => {
  const classes = useStyles();
  const t = useTranslations('pages.map.reportDetails');

  const { data: asset } = useGetAssetsList({
    select: useCallback(assets => assets.find(a => a.id === selectedReport.assetId), [selectedReport.assetId]),
  }).query;

  const speedUnit = useSpeedByAsset(asset);

  const reports = useReportsDataRepository();
  const report = useCallback(() => reports.getReport(selectedReport?.id), [reports, selectedReport])();

  if (!report || !report.isValid || !asset) return null;

  return (
    <OverlayWrapper highContrast={highContrastControls}>
      <Header classes={classes} t={t} asset={asset} report={report} />

      <DetailsGrid>
        <PositionDetails t={t} position={report} speedUnit={speedUnit} />
        {report?.metadata && (
          <Simple2dGrid
            data={report.metadata}
          />
        )}
      </DetailsGrid>
    </OverlayWrapper>
  );
};

export default ReportDetailsOverlay;
