import Smart from 'hocs/smart';
import AssetsList from './assetsList-view';

export default Smart(
  AssetsList,
  state => ({
    organisationId: state.session.organisationId,
  }),
  ac => ({
    deleteAsset: ac.asset.deleteAsset,
    displaySnackbar: ac.app.displaySnackbar,
  })
);
