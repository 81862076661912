import { useContext } from 'react';
import { FeaturesContext } from 'contexts/features/featuresContextValue';

const useFeature = (featureKey: string | undefined): boolean | undefined => {
  const { isLoading, data } = useContext(FeaturesContext);

  if (isLoading) return undefined;

  if (!data) return false;

  return data.some(value => value.featureKey === featureKey);
};

export default useFeature;
