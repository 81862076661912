import { connect } from 'react-redux';
import actionCreators from 'actions';
import SettingsDrawer from './settingsDrawer-view';

export default connect(
  state => ({
    mapId: state.map.selectedMapId,
    highContrastControls: state.settings.map.highContrastControls,
  }),
  {
    updateConfig: actionCreators.map.updateConfig,
    updateMapControlsContrast: actionCreators.settings.updateMapControlsContrast,
  },
)(SettingsDrawer);
