import Smart from 'hocs/smart';
import DatePickerView from './datePicker-view';

export default Smart(
  DatePickerView,
  state => ({
    selectedDay: state.app.selectedDay,
    selectedAsset: state.app.selectedItem,
    organisationId: state.session.organisationId
  }),
  ac => ({
    setDay: ac.app.setDay,
  })
);
