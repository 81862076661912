import { Localization, MaterialTableProps } from '@material-table/core';
import { Delete, Edit, InfoOutlined } from '@mui/icons-material';
import { Button, Skeleton, Stack, Typography, Tooltip, Chip } from '@mui/material';
import { Box } from '@mui/system';
import { EventNotificationRule } from 'apis/rest/eventNotifications/types';
import DetailPanel from 'components/shared/DetailPanel';
import PersistentTable from 'components/shared/persistentTable';
import StickyPagination from 'components/shared/stickyPagination';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslations } from 'use-intl';
import { useGetPeopleGroups } from 'apis/rest/peopleGroups/hooks';
import {
  useGetEventNotificationGroup,
  useMutateCreateEventNotificationRule,
  useMutateDeleteEventNotificationRule,
  useMutateUpdateEventNotificationRule
} from 'apis/rest/eventNotifications/hooks';
import useSnackbar from 'hooks/useSnackbar';
import { useGetEventCodeGroups, useGetEventCodes } from 'apis/rest/eventCodes/hooks';
import { DeleteDialog } from 'components/shared/deleteDialog';
import LimitedList from 'components/shared/LimitedList';
import * as FlagsHelper from 'helpers/flags';
import { useGetPeople } from 'apis/rest/people/hooks';
import { ConfirmationDialog } from 'components/dialogs/shared/confirmation-dialog';
import { useGetGeofences } from 'apis/rest/geofence/hooks';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useFeature from 'hooks/features/useFeature';
import SelectionCard from 'components/shared/selectionCard';
import * as Types from './types';
import { NotificationRulesDialog } from './rulesDialog';
import { AssetsGeofenceStatus, useAssetsGeofenceStatus } from './helpers';

export interface NotificationRulesTableProps {
  isLoading: boolean;
  isReadOnly: boolean;
  notificationGroupId: number,
}

export const NotificationRulesTable = ({ isLoading, isReadOnly, notificationGroupId }: NotificationRulesTableProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.edit.rulesSection');
  const { query: eventNotificationGroupQuery } = useGetEventNotificationGroup(notificationGroupId);
  const contactGroupsQuery = useGetPeopleGroups();
  const peopleQuery = useGetPeople();
  const createRuleMutation = useMutateCreateEventNotificationRule();
  const updateRuleMutation = useMutateUpdateEventNotificationRule();
  const deleteRuleMutation = useMutateDeleteEventNotificationRule();
  const eventCodes = useGetEventCodes();
  const eventCodeGroups = useGetEventCodeGroups();
  const footerRef = useRef<HTMLElement>();
  const Pagination = useCallback(p => <StickyPagination container={footerRef.current} {...p} />, []);
  const snackbar = useSnackbar();
  const [isEditRuleDialogOpen, setIsEditRuleDialogOpen] = useState<boolean>();
  const [ruleToEdit, setRuleToEdit] = useState<EventNotificationRule>();
  const [isDeleteRuleOpen, setIsDeleteRuleOpen] = useState<boolean>();
  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState<boolean>();
  const [ruleToDelete, setRuleToDelete] = useState<EventNotificationRule>();
  const localization: Localization = { header: { actions: '' } };

  const featureModules = useFeatureFlag('featureModules');
  const geofencesFeatureToggle = useFeature('manage.geofencing');
  const geofencesFeatureAssets = useFeatureAssets('manage.geofencing');

  let enableRuleTypeSelection = false;
  if (featureModules === false) {
    enableRuleTypeSelection = !!geofencesFeatureToggle;
  } else if (featureModules) {
    enableRuleTypeSelection = true;
  }

  const geofencesEnabled = featureModules ? geofencesFeatureAssets.some : !!geofencesFeatureToggle;
  const geofences = useGetGeofences({ enabled: geofencesEnabled });

  const contactTypeOptions: Record<string, string>[] = useMemo(() => [
    { key: 'email', value: t('rulesDialog.options.transport.email') },
    { key: 'sms', value: t('rulesDialog.options.transport.sms') },
    // { key: 'notification', value: t('rulesDialog.options.transport.notification') },
    // { key: 'mobile', value: t('rulesDialog.options.transport.mobile') }
  ], [t]);

  const daysOfWeekOptions: Record<string, string>[] = useMemo(() => [
    { key: 'MON', value: t('rulesDialog.options.dayOfWeek.mon') },
    { key: 'TUE', value: t('rulesDialog.options.dayOfWeek.tue') },
    { key: 'WED', value: t('rulesDialog.options.dayOfWeek.wed') },
    { key: 'THU', value: t('rulesDialog.options.dayOfWeek.thu') },
    { key: 'FRI', value: t('rulesDialog.options.dayOfWeek.fri') },
    { key: 'SAT', value: t('rulesDialog.options.dayOfWeek.sat') },
    { key: 'SUN', value: t('rulesDialog.options.dayOfWeek.sun') },
  ], [t]);

  const contactGroups: Types.ContactGroupWithPeople[] = useMemo(() => contactGroupsQuery.query.data?.map(
    c => ({ ...c, people: peopleQuery.query.data?.filter(p => c.peopleWithOrder.some(po => po.personId === p.id)) ?? [] })
  ) ?? [], [contactGroupsQuery.query.data, peopleQuery.query.data]);

  const handleNewEventRule = useCallback(() => {
    setRuleToEdit({
      id: 0,
      notificationGroupId,
      name: '',
      daysOfWeek: 'ALL',
      transport: 'email,sms',
      sourceId: '',
      sourceType: '',
      startTime: undefined,
      endTime: undefined,
      timeZone: '',
      rowVersion: 1,
      peopleGroupIds: []
    });
    setIsTypeSelectOpen(false);
    setIsEditRuleDialogOpen(true);
  }, [notificationGroupId]);

  const handleNewGeofenceRule = useCallback(() => {
    setRuleToEdit({
      id: 0,
      notificationGroupId,
      name: '',
      daysOfWeek: 'ALL',
      transport: 'email,sms',
      sourceId: '',
      sourceType: 'GEOFENCE',
      startTime: undefined,
      endTime: undefined,
      timeZone: '',
      rowVersion: 1,
      peopleGroupIds: []
    });
    setIsTypeSelectOpen(false);
    setIsEditRuleDialogOpen(true);
  }, [notificationGroupId]);

  const handleCreateNewRule = useCallback(() => {
    if (enableRuleTypeSelection) {
      setIsTypeSelectOpen(true);
    } else {
      handleNewEventRule();
    }
  }, [enableRuleTypeSelection, handleNewEventRule]);

  const onEditDialogClose = useCallback(() => setIsEditRuleDialogOpen(false), []);

  const onEditDialogSave = useCallback((rule: EventNotificationRule | undefined) => {
    if (!rule) { return; }
    if (rule.id) {
      updateRuleMutation.mutate({
        notificationRule: rule
      }, {
        onSuccess: () => {
          setIsEditRuleDialogOpen(false);
        },
        onError: () => {
          snackbar.display({
            id: `notificationRule.update.error.${rule.id}`,
            text: t('snackbar.updateError'),
            type: 'error'
          });
        },
      });
    } else {
      createRuleMutation.mutate({ notificationRule: rule }, {
        onSuccess: () => {
          setIsEditRuleDialogOpen(false);
        },
        onError: () => {
          snackbar.display({
            id: 'notificationRule.create.error',
            text: t('snackbar.createError'),
            type: 'error'
          });
        }
      });
    }
  }, [createRuleMutation, snackbar, t, updateRuleMutation]);

  const rules = eventNotificationGroupQuery.data?.notificationRules;
  const assetGroupIds = eventNotificationGroupQuery.data?.assetGroupIds;

  const onRowOpen = useCallback((id: number | undefined) => {
    const rule = rules?.find(r => r.id === id);
    if (rule) {
      setRuleToEdit(rule);
      setIsEditRuleDialogOpen(true);
    }
  }, [rules]);

  const handleDeleteCancel = useCallback(() => setIsDeleteRuleOpen(false), []);

  const handleDeleteConfirm = useCallback(() => {
    if (ruleToDelete) {
      deleteRuleMutation.mutate(
        { id: ruleToDelete.id, notificationGroupId },
        { onSuccess: () => setIsDeleteRuleOpen(false) }
      );
    }
  }, [ruleToDelete, deleteRuleMutation, notificationGroupId]);

  const getEventCode = useCallback((rule: EventNotificationRule) => {
    if (rule.sourceType === 'GEOFENCE') {
      return t('eventGeofence');
    }
    if (rule.sourceType !== 'GROUP') {
      return eventCodes.data?.find(ec => ec.event_id === rule.sourceId)?.name ?? '';
    }
    return eventCodeGroups.data?.find(ec => ec.event_group_code === rule.sourceId)?.name ?? '';
  }, [eventCodeGroups.data, eventCodes.data, t]);

  const geofenceStatus = useAssetsGeofenceStatus(assetGroupIds);

  const rowData: Types.NotificationRuleTableItem[] = useMemo(() => (rules ?? []).map(r => {
    const contactTypeArray = FlagsHelper.flagsToArray(r.transport ?? '');
    const item: Types.NotificationRuleTableItem = {
      id: r.id,
      name: r.name,
      isGeofenceType: r.sourceType === 'GEOFENCE',
      disableEdit: r.sourceType === 'GEOFENCE' && (
        geofenceStatus === AssetsGeofenceStatus.Never
        || geofenceStatus === AssetsGeofenceStatus.None
        || geofenceStatus === AssetsGeofenceStatus.Loading
      ),
      eventCode: getEventCode(r),
      contactTypes: contactTypeOptions.filter(o => contactTypeArray.includes(o.key)),
      peopleGroups: r.peopleGroupIds.map(p => {
        const record: Record<number, string> = { [p]: '' };
        return record;
      }),
      contactGroups: contactGroupsQuery.query.data?.filter(cg => r.peopleGroupIds.some(x => x === cg.id)) ?? [],
      activeTime: '',
    };
    return item;
  }), [rules, getEventCode, contactTypeOptions, contactGroupsQuery.query.data, geofenceStatus]);

  const columns = useMemo<MaterialTableProps<Types.NotificationRuleTableItem>['columns']>(() => ([
    {
      title: t('columns.name'),
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      render: row => (<Typography color={row.disableEdit ? 'common.disabled' : undefined}>{row.name}</Typography>)
    },
    {
      title: t('columns.eventCode'),
      field: 'sourceId',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      render: row => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color={row.disableEdit ? 'common.disabled' : undefined}>{row.eventCode}</Typography>
          {row.isGeofenceType && (geofenceStatus === AssetsGeofenceStatus.None || geofenceStatus === AssetsGeofenceStatus.Never) && (
            <Chip color="error" size="small" variant="outlined" label={t('columns.noGeofenceAssets')} />
          )}
          {row.isGeofenceType && geofenceStatus === AssetsGeofenceStatus.Some && (
            <Chip color="warning" size="small" variant="outlined" label={t('columns.someGeofenceAssets')} />
          )}
        </Stack>
      )
    },
    {
      title: t('columns.contactType'),
      field: 'contactType',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      render: row => (
        <Stack direction="row" spacing={1} alignItems="center" color={row.disableEdit ? 'common.disabled' : undefined}>
          <LimitedList<string>
            items={row.contactTypes.map(x => ` ${x.value}`)}
            limit={2}
            renderMore={({ items, children }) => (
              <Tooltip
                title={items.map((name, i) => <div key={`tooltip-${i}`}>{name}</div>)}
              >
                <Chip label={children} variant="outlined" />
              </Tooltip>
            )}
          />
        </Stack>
      )
    },
    {
      title: t('columns.peopleGroups'),
      field: 'peopleGroups',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      render: row => (
        <Stack direction="row" spacing={1} alignItems="center" color={row.disableEdit ? 'common.disabled' : undefined}>
          <LimitedList<string>
            items={row.contactGroups.map(cg => cg.name)}
            limit={1}
            renderMore={({ items, children }) => (
              <Tooltip
                title={items.map((name, i) => <div key={`tooltip-${i}`}>{name}</div>)}
              >
                <Chip label={children} variant="outlined" />
              </Tooltip>
            )}
          />
        </Stack>
      )
    },
  ]), [t, geofenceStatus]);

  const actions = useMemo<MaterialTableProps<Types.NotificationRuleTableItem>['actions']>(() => {
    const onRowDelete = (id: number) => {
      setRuleToDelete(rules?.find(r => r.id === id));
      setIsDeleteRuleOpen(true);
    };
    if (isReadOnly) {
      return [
        row => ({
          icon: () => <InfoOutlined sx={{ color: 'common.text' }} />,
          tooltip: t('tooltips.view'),
          onClick: () => onRowOpen(row.id),
        })
      ];
    }

    return [
      row => ({
        icon: () => <Edit sx={{ color: row.disableEdit ? 'common.disabled' : 'common.text' }} />,
        tooltip: row.disableEdit ? undefined : t('tooltips.edit'),
        onClick: () => {
          if (row.disableEdit) return;
          onRowOpen(row.id);
        },
      }),
      row => ({
        icon: () => <Delete sx={{ color: 'common.text' }} />,
        tooltip: t('tooltips.delete'),
        onClick: () => onRowDelete(row.id),
      }),
    ];
  }, [isReadOnly, t, rules, onRowOpen]);

  const ruleDialogTitle = useMemo(() => {
    if (isReadOnly) return t('rulesDialog.titleView');
    if (ruleToEdit?.id) return t('rulesDialog.titleEdit');
    return t('rulesDialog.titleCreate');
  }, [isReadOnly, ruleToEdit?.id, t]);

  if (contactGroupsQuery.query.isLoading || peopleQuery.query.isLoading || eventCodes.isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return (
    <>
      <DetailPanel spacing={3}>
        {!isReadOnly && (
          <Stack direction="row" spacing={3} px={3} justifyContent="space-between" height="4em">
            <Stack justifyContent="center">
              <Typography>{t('addText')}</Typography>
            </Stack>
            <Button variant="contained" size="large" color="primary" sx={{ minWidth: '10rem' }}
              onClick={handleCreateNewRule} disabled={isEditRuleDialogOpen}>
              {t('addButton')}
            </Button>
          </Stack>
        )}
        <PersistentTable<Types.NotificationRuleTableItem>
          settingsCategory="eventNotitficationGroupsTable"
          isLoading={isLoading}
          components={{
            Pagination, Container: Box, Toolbar: () => null
          }}
          data={rowData}
          columns={columns}
          actions={actions}
          localization={localization}
          onRowClick={(_, row) => {
            if (row?.disableEdit) return;
            onRowOpen(row?.id);
          }}
          options={{
            search: false, draggable: false, showTitle: false, actionsColumnIndex: -1, paging: true, emptyRowsWhenPaging: false, headerStyle: { position: 'sticky', top: 0 }
          }}
          sx={{ 'tbody tr:last-child td, tbody tr:last-child th': { border: 0 } }}
        />
        <Box ref={footerRef} bottom={0} sx={{
          '& .MuiToolbar-regular': { padding: 10 / 3 },
          margin: '0 !important'
        }} />

      </DetailPanel>

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        title={t('delete.dialogTitle')}
        open={isDeleteRuleOpen ?? false}
        confirmText={deleteRuleMutation.isLoading ? t('delete.loadingText') : t('delete.confirmText')}
        snackText="">
        {t('delete.dialogText', { name: ruleToDelete?.name })}
      </DeleteDialog>

      <NotificationRulesDialog
        isReadOnly={isReadOnly}
        open={isEditRuleDialogOpen ?? false}
        isSaving={updateRuleMutation.isLoading || createRuleMutation.isLoading}
        rule={ruleToEdit}
        title={<Typography>{ruleDialogTitle}</Typography>}
        ariaLabel="Manage event in notification group"
        peopleGroups={contactGroups}
        eventCodes={eventCodes.data?.filter(e => e.types.some(ty => ty === 'NOTIFICATION')) ?? []}
        eventGroups={eventCodeGroups.data ?? []}
        geofences={geofences.data ?? []}
        contactTypeOptions={contactTypeOptions}
        daysOfWeekOptions={daysOfWeekOptions}
        onClose={onEditDialogClose}
        onSave={onEditDialogSave}
      />

      <ConfirmationDialog title={t('typeDialog.title')} hideActions
        dialogProps={{ open: isTypeSelectOpen ?? false, maxWidth: true }}
        dialogContentProps={{ sx: { py: 4 } }}
        onCancel={() => setIsTypeSelectOpen(false)}
        cancelButtonProps={{ 'aria-label': t('typeDialog.closeLabel') }}>
        <Stack direction="row" spacing={4} sx={{ pt: 4 }}>
          <SelectionCard
            title={t('typeDialog.event.title')}
            subTitle={t('typeDialog.event.subtext')}
            handleClick={handleNewEventRule}
          />
          <SelectionCard
            title={t('typeDialog.geofence.title')}
            subTitle={t('typeDialog.geofence.subtext')}
            handleClick={handleNewGeofenceRule}
            disabled={!geofencesEnabled}
          />
        </Stack>
      </ConfirmationDialog>
    </>
  );
};
