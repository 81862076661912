import React, { useMemo } from 'react';
import { useTranslations } from 'use-intl';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import sumBy from 'lodash/fp/sumBy';
import useVolume, { useVolumePerPeriod } from 'hooks/units/useVolume';
import useDuration from 'hooks/units/useDuration';
import { calculateEfficiencyStats, calculateTotalFlightTime, useGetFilteredTrips } from './statistics';

interface DropsScorecardProps extends PaperOwnProps {
  drops: Drop[]
  trips: Trip[]
}

interface StatsDisplayProps {
  titleKey: 'averageDropsPerHour' | 'averageVolumePerHour' | 'averageDropsPerFlightHour' | 'averageVolumePerFlightHour';
  valueKey?: 'nDropsPerHour';
  value: number | string;
  hasTooltip?: boolean;
}

const StatsDisplay = ({ titleKey, valueKey, value, hasTooltip }: StatsDisplayProps) => {
  const t = useTranslations('pages.reporting.firefighting.stats');

  return (
    <Box>
      {
        hasTooltip ? (
          <Box display="flex">
            <Tooltip title={t(`tooltips.${titleKey}`)} placement="top">
              <Box display="flex" flexDirection="row" gap={1}>
                <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">
                  {t(titleKey)}
                </Typography>
                <HelpOutline sx={{ fontSize: '1rem' }} />
              </Box>
            </Tooltip>
          </Box>
        ) : (
          <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">
            {t(titleKey)}
          </Typography>
        )
      }
      <Typography fontSize="1.5rem" lineHeight={1.2} fontWeight="bold">
        {value ? (valueKey ? t(valueKey, { n: value }) : value) : '—'}
      </Typography>
    </Box>
  );
};

const DropsScorecard = ({ drops, trips, sx, ...props }: DropsScorecardProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const volume = useVolume();
  const volumePerPeriod = useVolumePerPeriod();
  const dropDrops = drops.filter(drop => drop.type === 'Drop');
  const duration = useDuration();

  const tripsFilteredFromStats = useGetFilteredTrips(trips);
  const stats = useMemo(() => calculateEfficiencyStats(tripsFilteredFromStats), [tripsFilteredFromStats]);
  const totalFlightTime = useMemo(() => calculateTotalFlightTime(trips), [trips]);
  const durationFormat = useMemo(() => {
    if (totalFlightTime > 60 * 60 * 1000) return 'h\'h\' m\'m\' s\'s\'';
    if (totalFlightTime > 60 * 1000) return 'm\'m\' s\'s\'';
    return 's\'s\'';
  }, [totalFlightTime]);

  return (
    <Paper elevation={0} {...props} sx={{ ...sx, p: 3 }}>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3} alignItems="center">
        <Typography fontSize="2.5rem" lineHeight={1} fontWeight="bold" gridColumn="span 2">
          {t('nDrops', { n: dropDrops.length })}
        </Typography>
        {drops.length > 0 && (
          <>
            <Box gridColumn="span 2">
              <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">{t('totalVolume')}</Typography>
              <Typography fontSize="2.5rem" lineHeight={1.2} fontWeight="bold">
                {volume.create(sumBy('dropVolume', dropDrops)).format()}
              </Typography>
            </Box>
            <Box gridColumn="span 2">
              <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">{t('totalFlightTime')}</Typography>
              <Typography fontSize="2.5rem" lineHeight={1.2} fontWeight="bold">
                {duration.fromMillis(totalFlightTime, undefined, durationFormat)}
              </Typography>
            </Box>
            <StatsDisplay
              titleKey="averageDropsPerHour"
              valueKey="nDropsPerHour"
              value={stats.dropsPerHour}
              hasTooltip
            />
            <StatsDisplay
              titleKey="averageVolumePerHour"
              value={volumePerPeriod.create(stats.volumePerHour, 'h').format()}
              hasTooltip
            />
            <StatsDisplay
              titleKey="averageDropsPerFlightHour"
              valueKey="nDropsPerHour"
              value={stats.dropsPerFlightHour}
              hasTooltip
            />
            <StatsDisplay
              titleKey="averageVolumePerFlightHour"
              value={volumePerPeriod.create(stats.volumePerFlightHour, 'h').format()}
              hasTooltip
            />
          </>
        )}
      </Box>
    </Paper>
  );
};

export default DropsScorecard;
