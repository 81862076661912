import React, { useEffect, useRef } from 'react';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon, Warning } from '@mui/icons-material';
import useStyles from './snackbarWrapper-styles';

const Snackbar = ({
  snackbar,
  enqueueSnackbar,
  closeSnackbar,
  destroySnackbar
}) => {
  const idRef = useRef();

  useEffect(() => {
    const {
      id,
      type: variant,
      toDelete,
      persist,
      text
    } = snackbar;

    // Destroy any snackbars pending destruction
    if (toDelete) {
      closeSnackbar(id);
      destroySnackbar(id);
    }

    // Display any snackbars pending creation
    if (idRef.current !== id && !snackbar.deleted) {
      // only show the notification once
      idRef.current = id;

      enqueueSnackbar(text, {
        id,
        key: id,
        persist,
        autoHideDuration: persist && 10000,
        variant,

        /* Note that this only filters on duplicate keys, not duplicate text.  We could remove the
         * keys and then it would filter on text, but then we could not add close buttons to the
         * snackbars.  To avoid duplicate notifications, we use a 'hardcoded' key.
        */
        preventDuplicate: true,

        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },

        action: sbKey => (
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => {
              closeSnackbar(sbKey);
              destroySnackbar(sbKey);
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )
      });
    }
  }, [snackbar]);

  return null;
};

const SnackbarRenderer = withSnackbar(({
  snackbars,
  children,
  enqueueSnackbar,
  closeSnackbar,
  destroySnackbar
}) => (
  <>
    {children}
    {snackbars?.map(sb => (
      <Snackbar
        key={sb.id}
        snackbar={sb}
        enqueueSnackbar={enqueueSnackbar}
        closeSnackbar={closeSnackbar}
        destroySnackbar={destroySnackbar}
      />
    ))}
  </>
));

const SnackbarWrapper = ({
  snackbars,
  children,
  destroySnackbar
}) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        root: classes.root,
        variantInfo: classes.infoSnackbar
      }}
      // hideIconVariant
      iconVariant={{
        error: <Warning sx={{ mr: 1 }} />,
        warning: <Warning sx={{ mr: 1 }} />,
      }}
    >
      <SnackbarRenderer snackbars={snackbars} destroySnackbar={destroySnackbar}>
        {children}
      </SnackbarRenderer>
    </SnackbarProvider>
  );
};

export default SnackbarWrapper;
