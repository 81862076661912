import React, { useState, useEffect, useCallback } from 'react';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import {
  useGetCapabilitiesForDevice,
  useGetLatestDeviceConfiguration,
  useRequestConfig,
  useUpdateCustomConfig
} from 'apis/rest/devices/hooks';
import { tryGetTrackingRates } from 'helpers/config';
import useSnackbar from 'hooks/useSnackbar';
import { useCheckDeviceAccess } from 'hooks/device/useDeviceAccess';
import usePermissions from 'hooks/session/usePermissions';

const validCapabilityCodes = ['CP_CHG_CEL_RT', 'CP_CHG_SAT_RT', 'CP_CHG_DS_RT', 'CP_CHG_STD_RT'];
type ValidCapabilityCode = 'CP_CHG_CEL_RT' | 'CP_CHG_SAT_RT' | 'CP_CHG_DS_RT' | 'CP_CHG_STD_RT';
type ChangeHandler = (val?: number) => void;
interface ValidCapability extends Capability {
  capability: ValidCapabilityCode
}

const extractValues = /values=(\[.+])/;

const normalizeSeconds = (seconds: number) => {
  if (seconds < 60) return { seconds };
  const minutes = Math.floor(seconds / 60);
  const newSeconds = seconds - (minutes * 60);
  if (minutes < 60) return { minutes, seconds: newSeconds > 0 ? newSeconds : undefined };
  const hours = Math.floor(minutes / 60);
  const newMinutes = minutes - (hours * 60);
  return {
    hours,
    minutes: newMinutes > 0 ? newMinutes : undefined,
    seconds: newSeconds > 0 ? newSeconds : undefined
  };
};

const parseTrackingRates = (ratestr: string | null) => {
  if (!ratestr) return undefined;
  const [rate, unit] = ratestr.split(' ');
  const rateNum = parseInt(rate, 10);
  if (Number.isNaN(rateNum)) return undefined;
  if (unit.includes('minute')) return rateNum * 60;
  if (unit.includes('hour')) return rateNum * 60 * 60;
  return rateNum;
};

export const CapabilityView = ({ capability, value, onChange }: { capability: ValidCapability, value: number | undefined, onChange: ChangeHandler }) => {
  const t = useTranslation(`pages.deviceView.rates.${capability.capability}`);
  const unitT = useTranslation('pages.deviceView.rates.units');

  const values = capability.constraints.match(extractValues)?.[1];
  if (values) {
    const options = JSON.parse(values).map((v: string) => parseInt(v, 10)) as number[];

    return (
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h3">{t('title')}</Typography>
          <Typography>{t('description')}</Typography>
        </Stack>
        <FormControl>
          <InputLabel id={`${capability.capability}-rate-label`}>{unitT('rate')}</InputLabel>
          <Select
            labelId={`${capability.capability}-rate-label`}
            id={`${capability.capability}-rate`}
            label={unitT('rate')}
            value={value?.toString() || ''}
            onChange={e => {
              const num = parseInt(e.target.value, 10);
              if (!Number.isNaN(num)) {
                onChange(num);
                return;
              }
              onChange(undefined);
            }}
            sx={{ minWidth: '10rem' }}
          >
            {options.map(n => {
              const normalized = normalizeSeconds(n);
              const str = `${normalized.hours ? unitT('hours', { value: normalized.hours }) : ''} ${normalized.minutes ? unitT('minutes', { value: normalized.minutes }) : ''} ${normalized.seconds ? unitT('seconds', { value: normalized.seconds }) : ''}`;
              return <MenuItem value={n} key={n}>{str}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Stack>
    );
  }

  return null;
};

export const DeviceRates = ({ deviceId }: { deviceId: number }) => {
  const t = useTranslation('pages.deviceView.rates');
  const [configReq, setConfigReq] = useState<ConfigRequest>({});
  const [currentConfig, setCurrentConfig] = useState<ConfigRequest>({});

  const capabilitiesQuery = useGetCapabilitiesForDevice(deviceId);
  const configQuery = useGetLatestDeviceConfiguration(deviceId).query;
  const updateConfig = useUpdateCustomConfig();
  const requestConfig = useRequestConfig();
  const snackbar = useSnackbar();
  const deviceConfig = useCheckDeviceAccess()?.call(null, deviceId);
  const permissions = usePermissions();

  const shouldRender = useCallback(
    () => !!capabilitiesQuery.data && !!deviceConfig && deviceConfig.canSendConfiguration && permissions.canEditDevices,
    [capabilitiesQuery.data, deviceConfig, permissions.canEditDevices]
  );


  useEffect(() => {
    if (configQuery.data) {
      const rates = tryGetTrackingRates(configQuery.data);
      if (rates) {
        const existingConfig = {
          satelliteTrackingRate: parseTrackingRates(rates.satellite),
          cellularTrackingRate: parseTrackingRates(rates.cellular),
          distressTrackingRate: parseTrackingRates(rates.distress),
          standardTrackingRate: parseTrackingRates(rates.satellite)
        };
        setCurrentConfig(existingConfig);
        setConfigReq(existingConfig);
      }
    }
  }, [configQuery.data, setConfigReq, setCurrentConfig]);

  const valuesUnchanged = currentConfig.satelliteTrackingRate === configReq.satelliteTrackingRate
    && currentConfig.cellularTrackingRate === configReq.cellularTrackingRate
    && currentConfig.distressTrackingRate === configReq.distressTrackingRate
    && currentConfig.standardTrackingRate === configReq.standardTrackingRate;

  const capabilities = capabilitiesQuery.data
    ?.filter((c): c is ValidCapability => validCapabilityCodes.includes(c.capability))
    // filter out CHG_STD capability if there is a CHG_SAT capability
    .filter((v, i, a) => !a.some(c => c.capability === 'CP_CHG_SAT_RT') || v.capability !== 'CP_CHG_STD_RT')
    ?? [];

  if (capabilities.length === 0) {
    return null;
  }

  const changeHandlers: Record<ValidCapabilityCode, ChangeHandler> = {
    CP_CHG_CEL_RT: val => setConfigReq({ ...configReq, cellularTrackingRate: val }),
    CP_CHG_SAT_RT: val => setConfigReq({ ...configReq, satelliteTrackingRate: val }),
    CP_CHG_DS_RT: val => setConfigReq({ ...configReq, distressTrackingRate: val }),
    CP_CHG_STD_RT: val => setConfigReq({ ...configReq, standardTrackingRate: val })
  };

  const values: Record<ValidCapabilityCode, number | undefined> = {
    CP_CHG_CEL_RT: configReq.cellularTrackingRate,
    CP_CHG_SAT_RT: configReq.satelliteTrackingRate,
    CP_CHG_DS_RT: configReq.distressTrackingRate,
    CP_CHG_STD_RT: configReq.standardTrackingRate
  };

  const updateRates = () => {
    const newConfig = { ...configReq };
    if (newConfig.cellularTrackingRate === currentConfig.cellularTrackingRate) {
      newConfig.cellularTrackingRate = undefined;
    }
    if (newConfig.satelliteTrackingRate === currentConfig.satelliteTrackingRate) {
      newConfig.satelliteTrackingRate = undefined;
    }
    if (newConfig.distressTrackingRate === currentConfig.distressTrackingRate) {
      newConfig.distressTrackingRate = undefined;
    }
    if (newConfig.standardTrackingRate === currentConfig.standardTrackingRate) {
      newConfig.standardTrackingRate = undefined;
    }
    updateConfig.mutate({ deviceId, config: newConfig }, {
      onSuccess: () => {
        snackbar.display({ id: 'updateRates', type: 'success', text: t('updateRatesSuccess') });
        requestConfig.mutate({ deviceId }, {
          onError: () => snackbar.display({ id: 'requestConfigFailed', type: 'error', text: t('requestConfigFailed') }),
        });
      },
      onError: () => snackbar.display({ id: 'updateRatesFailed', type: 'error', text: t('updateRatesFailed') }),
    });
  };

  return shouldRender()
    && (
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel p={3} spacing={3}>
          {capabilities.map(c => <CapabilityView capability={c} value={values[c.capability]} onChange={changeHandlers[c.capability]} key={c.capability} />)}
          <Stack direction="row" height="4rem" spacing={3} justifyContent="right">
            <Button variant="outlined" sx={{ minWidth: '10rem' }} onClick={() => setConfigReq(currentConfig)} disabled={valuesUnchanged}>
              {t('cancel')}
            </Button>
            <Button variant="contained" sx={{ minWidth: '10rem' }} onClick={() => updateRates()} color="primary" disabled={valuesUnchanged}>
              {updateConfig.isLoading ? <CircularProgress size="2rem" color="secondary" /> : t('updateRates')}
            </Button>
          </Stack>
        </DetailPanel>
      </SettingsSection>
    );
};
