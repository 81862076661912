/* eslint-disable react/no-unstable-nested-components */
import React, { ComponentProps, useCallback, useRef, useState } from 'react';
import { MTableToolbar } from '@material-table/core';
import {
  Box,
  Button,
  Container,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AirplanemodeActive,
  BuildCircle,
  EditNote,
  Map,
  NotificationImportant,
  PermDataSetting,
  PersonAdd,
  PersonAddDisabled,
  PlayCircle,
  Router,
  Settings,
  VideogameAssetOff,
  WifiFind,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import insensitiveSort from 'utils/insensitiveSort';
import {
  useGetDeviceSearch,
  updateGPRSEndpoint,
  DeviceSearchResult,
  requestGprsEndpoints,
  setIncidentHandlingMethod,
  setStatus,
} from 'apis/rest/staff';
import AssetIcon from 'components/shared/icons/assetIcons';
import LinkAbove from 'components/shared/linkAbove';
import Page from 'components/pages/page/page-view';
import PersistentTable from 'components/shared/persistentTable';
import SelectSerialType from 'components/shared/selectSerialType';
import StickyPagination from 'components/shared/stickyPagination';
import tableIcons from 'components/shared/icons/tableIcons';
import useSnackbar from 'hooks/useSnackbar';
import { JoinOrganisationParams, LeaveOrganisationParams } from 'slices/session/types';
import { Settings as SettingsReducer } from 'reducers/settings';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import { serenityApiClient } from 'apis/client/serenityApiClient';
import { updateSetting } from 'actions/settings';
import { useGetAllOrganisations } from 'apis/rest/memberships/hook';
import { useGetLatestFirmwareVersions } from 'apis/rest/devices/hooks';
import useTimezone from 'hooks/session/useTimezone';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import useStyles from './assetSearch-styles';
import { Rock7ConfigDialog } from './components/rock7Config/Rock7ConfigDialog';
import { SelectDialog } from './components/SelectDialog';
import { ConfirmDialog } from './components/ConfirmDialog';
import { IridiumLbsDialog } from './components/IridiumLbsDialog';

interface AssetSearchPageProps {
  organisationId: string,
  user: NonNullable<ReduxState['session']['user']>,
  joinOrganisation: (organisation: JoinOrganisationParams) => void,
  leaveOrganisation: (organisation: LeaveOrganisationParams) => void,
  setOrganisation: (organisationId: string) => void,
  timezone: string,
  serialType: 'tpSerial' | 'imei' | 'manufacturerSerial',
  assetSearchQuery: string,
  updateSetting: (category: string, field: string, value: string) => void,
}

type ActionType =
  'activateIridiumForDevice'
  | 'updateGprsEndpoints'
  | 'requestGprsEndpoints'
  | 'rock7Config'
  | 'incidentHandlingMethod'
  | 'deviceStatus'
  | 'iridiumExtremeActivation';

const MUTATION_TEXT: Record<ActionType, string> = {
  activateIridiumForDevice: 'Activate Device Provisioning',
  updateGprsEndpoints: 'GPRS Endpoint Update',
  requestGprsEndpoints: 'GPRS Endpoint Request',
  rock7Config: '', // UNUSED
  iridiumExtremeActivation: 'Iridium Extreme Activation',
  incidentHandlingMethod: 'Update Incident Handling Method',
  deviceStatus: 'Update Device Status',
};

const Toolbar = (props: ComponentProps<typeof MTableToolbar>): JSX.Element => {
  const classes = useStyles();
  const assetSearchQuery = useSelector<ReduxState, string>(state => state.settings.staffAssetSearch.assetSearchQuery ?? '');
  const dispatch = useDispatch();

  return (
    <Stack direction="row" justifyContent="space-between" m={3} height="4em">
      <TextField
        defaultValue={assetSearchQuery}
        label="Search for Asset or Device"
        variant="outlined"
        onChange={event => dispatch(updateSetting('staffAssetSearch', 'assetSearchQuery', event.target.value))}
        sx={{ width: '30ch' }}
      />
      <Stack direction="row" spacing={3}>
        <SelectSerialType InputProps={{ sx: { width: '30ch' } }} />
        <MTableToolbar {...props} className={classes.toolbar} />
      </Stack>
    </Stack>
  );
};

const AssetSearchPage = ({
  organisationId,
  user,
  joinOrganisation,
  leaveOrganisation,
  setOrganisation,
  assetSearchQuery,
}: AssetSearchPageProps) => {
  const timezone = useTimezone();
  const classes = useStyles();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const devicesQuery = useGetDeviceSearch(assetSearchQuery);
  const [openDialog, setOpenDialog] = useState<ActionType | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<DeviceSearchResult>();
  const tableSettings = useSelector<ReduxState, SettingsReducer['staffAssetSearch']>(state => state.settings.staffAssetSearch);
  const dispatch = useDispatch();
  const { serialType } = useUiSettings();

  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);

  const organisationsQuery = useGetAllOrganisations().query;

  const footerRef = useRef<HTMLElement>();
  const Pagination = useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);

  const viewOnMap = (data: DeviceSearchResult) => {
    const isMember = user.memberOf.some(org => org.id === data.deviceOwner);
    if (isMember) {
      if (data.deviceOwner) {
        setOrganisation(data.deviceOwner);
        navigate(`/search?q=${data.assetName}`);
      } else {
        navigate('/missing-organisation');
      }
    }
  };

  const manageAsset = (data: DeviceSearchResult) => {
    const isMember = user.memberOf.some(org => org.id === data.assetOwner);
    if (isMember) {
      if (data.assetOwner) {
        setOrganisation(data.assetOwner);
        navigate(`/manage/assets/${data.assetId}`);
      } else {
        navigate('/missing-organisation');
      }
    }
  };

  const viewDevice = (data: DeviceSearchResult) => {
    const isMember = user.memberOf.some(org => org.id === data.deviceOwner);
    if (isMember) {
      if (data.deviceOwner) {
        setOrganisation(data.deviceOwner);
        navigate(`/manage/devices/${data.id}`);
      } else {
        navigate('/missing-organisation');
      }
    }
  };

  const handleOpenMenu = (target: React.SetStateAction<SVGSVGElement | null>, rowData: DeviceSearchResult) => {
    setSelectedDevice(rowData);
    setAnchorEl(target);
  };

  const handleOpenDialog = (dialog: ActionType) => {
    setAnchorEl(null);
    setOpenDialog(dialog);
  };

  const handleCloseDialog = () => {
    setOpenDialog(undefined);
  };

  const onError = (action: ActionType) => {
    snackbar.display({
      id: action,
      text: `${MUTATION_TEXT[action]} Failed`,
      type: 'error',
    });
  };

  const onSuccess = (action: ActionType) => {
    snackbar.display({
      id: action,
      text: `${MUTATION_TEXT[action]} Successful`,
      type: 'success',
    });
  };

  const onSettled = () => {
    handleCloseDialog();
  };

  const activateIridiumMutation = useMutation<
    void,
    unknown,
    DeviceSearchResult
  >(async value => {
    await serenityApiClient.provisioningActivate(value.id);
  }, {
    onError: () => onError('activateIridiumForDevice'),
    onSuccess: () => onSuccess('activateIridiumForDevice'),
    onSettled,
    mutationKey: ['activateIridiumForDevice'],
  });

  const updateGPRSEndpointMutation = useMutation<
    void,
    unknown,
    DeviceSearchResult
  >(value => updateGPRSEndpoint(value.deviceOwner, value.id), {
    onError: () => onError('updateGprsEndpoints'),
    onSuccess: () => onSuccess('updateGprsEndpoints'),
    onSettled,
    mutationKey: ['updateGprsEndpoint'],
  });

  const requestGprsEndpointsMutation = useMutation<
    void,
    unknown,
    DeviceSearchResult
  >(value => requestGprsEndpoints(value.deviceOwner, value.id), {
    onError: () => onError('requestGprsEndpoints'),
    onSuccess: () => onSuccess('requestGprsEndpoints'),
    onSettled,
    mutationKey: ['requestGprsEndpoints'],
  });

  const setIncidentHandlingMethodMutation = useMutation<
    void,
    unknown,
    [DeviceSearchResult, string]
  >(value => setIncidentHandlingMethod(value[0].deviceOwner, value[0].id, value[1]), {
    onError: () => onError('incidentHandlingMethod'),
    onSuccess: () => {
      onSuccess('incidentHandlingMethod');
      return queryClient.invalidateQueries(['deviceSearchResults']);
    },
    onSettled,
    mutationKey: ['incidentHandlingMethod'],
  });

  const setStatusMutation = useMutation<
    void,
    unknown,
    [DeviceSearchResult, string]
  >(value => setStatus(value[0].deviceOwner, value[0].id, value[1]), {
    onError: () => onError('deviceStatus'),
    onSuccess: () => {
      onSuccess('deviceStatus');
      return queryClient.invalidateQueries(['deviceSearchResults']);
    },
    onSettled,
    mutationKey: ['deviceStatus'],
  });

  const resetFilters = () => dispatch(updateSetting('staffAssetSearch', 'filters', []));

  const latestFirmwareVersions = useGetLatestFirmwareVersions({
    enabled: !!devicesQuery.data
  });

  return (
    <Page>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <LinkAbove />
          <Box>
            <Typography variant="h1" gutterBottom>Asset & Device Search</Typography>
            <Typography variant="body1" paragraph>Enter an asset or device name or ID to search all devices
              globally
            </Typography>
          </Box>
          <Container className={classes.tableContainer} maxWidth={false}>
            <Box
              className={classes.materialTable}
              bgcolor="common.white"
              borderRadius={1}
              border={1}
              borderColor="common.midGrey"
              mb={8}
            >
              <Button
                variant="outlined"
                size="large"
                sx={{ minWidth: '10rem' }}
                className={classes.resetFiltersButton}
                onClick={() => resetFilters()}
                disabled={(tableSettings.filters?.length === 0)}
              >
                Reset Filters
              </Button>
              <PersistentTable
                settingsCategory="staffAssetSearch"
                isLoading={devicesQuery.isLoading}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Asset/Device ID',
                    field: 'id',
                    headerStyle: { textAlign: 'left' },
                    cellStyle: { textAlign: 'left' },
                    filterPlaceholder: 'filter',
                    width: '10%',
                    customSort: (a, b): number => insensitiveSort(a.id, b.id),
                    render: row => (
                      <Stack>
                        <Box>A: {row.assetId}</Box>
                        <Box>D: {row.id}</Box>
                      </Stack>
                    ),
                  },
                  {
                    title: 'Asset',
                    field: 'assetName',
                    headerStyle: { textAlign: 'left' },
                    cellStyle: { textAlign: 'left' },
                    defaultSort: 'asc',
                    filterPlaceholder: 'filter',
                    customSort: (a, b): number => insensitiveSort(a.assetName, b.assetName),
                    customFilterAndSearch: (filter, a) => (
                      a.assetName.toLowerCase()
                        .includes(filter.toLowerCase())
                      || `${a.assetMake} ${a.assetModel} ${a.assetVariant}`.trim()
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                    ),
                    render: row => (
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box className={classes.assetIcon}>
                          <AssetIcon type={row.assetIconId} />
                        </Box>
                        <Stack>
                          <Typography>{row.assetName}</Typography>
                          <Typography variant="body3">{row.assetMake} {row.assetModel} {row.assetVariant}</Typography>
                        </Stack>
                      </Stack>
                    ),
                  },
                  {
                    title: 'Status',
                    field: 'status',
                    headerStyle: { textAlign: 'left' },
                    cellStyle: { textAlign: 'left' },
                    filterPlaceholder: 'filter',
                    width: '10%',
                    customSort: (a, b): number => insensitiveSort(a.status, b.status),
                    render: row => (
                      <Tooltip title={row.errorCode} placement="bottom">
                        <Box>{row.status}</Box>
                      </Tooltip>
                    ),
                  },
                  {
                    title: 'Owner',
                    field: 'deviceOwner',
                    headerStyle: { textAlign: 'left' },
                    cellStyle: { textAlign: 'left' },
                    defaultSort: 'asc',
                    filterPlaceholder: 'filter',
                    customSort: (a, b): number => insensitiveSort(a.deviceOwner, b.deviceOwner),
                    render: row => (
                      <Stack>
                        <Typography>{organisationsQuery.isSuccess && organisationsQuery.data.find(o => o.id.toLowerCase() === row.deviceOwner.toLowerCase())?.name}</Typography>
                        <Typography variant="body3">{row.deviceOwner}</Typography>
                      </Stack>
                    ),
                  },
                  {
                    title: 'Device',
                    field: 'deviceMake',
                    headerStyle: { textAlign: 'left' },
                    cellStyle: { textAlign: 'left' },
                    defaultSort: 'asc',
                    filterPlaceholder: 'filter',
                    customSort: (a, b) => insensitiveSort(a.deviceMake, b.deviceMake),
                    customFilterAndSearch: (filter, row) => ((
                      row[serialType]?.toLowerCase()
                        .includes(filter.toLowerCase()))
                      || `${row.deviceMake} ${row.deviceModel}`.trim()
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                    ),
                    render: row => (
                      <Stack>
                        <Typography>{row.deviceMake} {row.deviceModel}</Typography>
                        <Typography variant="body3">{row[serialType]}</Typography>
                      </Stack>
                    ),
                  },
                  {
                    title: 'Last Active',
                    field: 'lastActive',
                    headerStyle: { textAlign: 'left' },
                    cellStyle: { textAlign: 'left' },
                    defaultSort: 'desc',
                    filterPlaceholder: 'filter',
                    customSort: (a, b): number => insensitiveSort(a.lastActive, b.lastActive),
                    render: row => (
                      row.lastActive ? DateTime.fromISO(row.lastActive)
                        .setZone(timezone)
                        .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) : 'Never'
                    ),
                  },
                  {
                    title: 'Firmware Version',
                    field: ['config', 'VersionInfoElement', 'SoftwareVersion'],
                    headerStyle: { textAlign: 'left' },
                    cellStyle: { textAlign: 'left' },
                    defaultSort: 'asc',
                    filterPlaceholder: 'filter',
                    customSort: (a, b) => insensitiveSort(a.config?.VersionInfoElement?.SoftwareVersion, b.config?.VersionInfoElement?.SoftwareVersion),
                    render: row => {
                      const firmwareVersion = row.config?.VersionInfoElement?.SoftwareVersion;
                      const latest = latestFirmwareVersions.data?.find(fw => fw.make === row.deviceMake && fw.model === row.deviceModel)?.version;
                      const firmwareOutdated = firmwareVersion !== undefined && latest !== undefined && firmwareVersion !== latest;
                      return (firmwareOutdated ? (
                        <Stack direction="row" spacing={1}>
                          <Box>
                            {firmwareVersion}
                          </Box>
                          <Tooltip title="Firmware Outdated">
                            <BuildCircle sx={{ color: 'primary.orange' }} />
                          </Tooltip>
                        </Stack>
                      ) : firmwareVersion);
                    },
                  },
                  {
                    title: 'GPRS Endpoint',
                    field: ['config', 'Properties', 'EndpointAddress1'],
                    headerStyle: { textAlign: 'left' },
                    cellStyle: { textAlign: 'left' },
                    defaultSort: 'asc',
                    filterPlaceholder: 'filter',
                    customSort: (a, b) => {
                      // @ts-ignore
                      const endpointA = a.config?.Properties?.EndpointAddress1;
                      // @ts-ignore
                      const endpointB = b.config?.Properties?.EndpointAddress1;
                      return insensitiveSort(endpointA, endpointB);
                    },
                  },
                  {
                    title: 'Incident Handling Method',
                    field: 'incidentHandling',
                    headerStyle: { textAlign: 'left' },
                    cellStyle: { textAlign: 'left' },
                    filterPlaceholder: 'filter',
                    customSort: (a, b): number => insensitiveSort(a.incidentHandling, b.incidentHandling),
                    render: row => (
                      row.incidentHandling === 'TakeNoAction' ? 'Take No Action'
                        : row.incidentHandling === 'NotifyOnly' ? 'Notify Only'
                          : row.incidentHandling === 'NotifyAndCall' ? 'Notify and Call'
                            : ''
                    ),
                  },
                ]}
                data={devicesQuery.data ?? []}
                actions={[
                  rowData => {
                    const isMember = user.memberOf?.some(org => org.id.toLowerCase() === rowData.deviceOwner.toLowerCase());
                    let tooltip: string | undefined = 'Join Org';
                    if (isMember) {
                      if (rowData.deviceOwner.toLowerCase() !== organisationId.toLowerCase()) {
                        tooltip = 'Leave Org';
                      } else {
                        tooltip = undefined;
                      }
                    }

                    const deviceOrg = organisationsQuery.isSuccess
                      ? organisationsQuery.data.find(o => o.id.toLowerCase() === rowData.deviceOwner.toLowerCase())
                      : undefined;

                    const isCurrentOrg = rowData.deviceOwner === organisationId;

                    return {
                      icon: () => {
                        if (isCurrentOrg) return null;
                        return (
                          isMember
                            ? <PersonAddDisabled className={classes.actionButtonColor} />
                            : <PersonAdd className={classes.actionButtonColor} />
                        );
                      },
                      tooltip,
                      disabled: isCurrentOrg || !deviceOrg,
                      onClick: () => {
                        if (isCurrentOrg || !deviceOrg) return;
                        if (isMember) {
                          leaveOrganisation({
                            organisationId: deviceOrg.id,
                            userId: user.id,
                          });
                        } else {
                          joinOrganisation({
                            organisationId: deviceOrg.id,
                            organisationName: deviceOrg.name,
                            userId: user.id,
                            publicKey: deviceOrg.publicKey,
                            membershipRole: 'Administrator',
                          });
                        }
                      },
                    };
                  },
                  rowData => ({
                    icon: () => (
                      <Tooltip title="Edit Asset" placement="bottom">
                        <AirplanemodeActive className={clsx(classes.actionButtonColor)} />
                      </Tooltip>
                    ),
                    onClick: () => manageAsset(rowData),
                    hidden: !user.memberOf?.find(org => org.id.toLowerCase() === rowData.deviceOwner.toLowerCase()),
                  }),
                  rowData => ({
                    icon: () => (
                      <Tooltip title="View Device" placement="bottom">
                        <Router className={clsx(classes.actionButtonColor)} />
                      </Tooltip>
                    ),
                    onClick: () => viewDevice(rowData),
                    hidden: !user.memberOf?.find(org => org.id.toLowerCase() === rowData.deviceOwner.toLowerCase()),
                  }),
                  rowData => ({
                    icon: () => (
                      <Tooltip title="View on Map" placement="bottom">
                        <Map className={clsx(classes.actionButtonColor)} />
                      </Tooltip>
                    ),
                    onClick: () => viewOnMap(rowData),
                    hidden: !user.memberOf?.find(org => org.id.toLowerCase() === rowData.deviceOwner.toLowerCase()),
                  }),
                  rowData => ({
                    icon: () => (
                      <div>
                        <Settings
                          className={clsx(classes.actionButtonColor)}
                          id="menu-button"
                          aria-controls={Boolean(anchorEl) && rowData.id === selectedDevice?.id ? 'menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={Boolean(anchorEl) && rowData.id === selectedDevice?.id ? 'true' : undefined}
                          onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => handleOpenMenu(event.currentTarget, rowData)}
                        />
                        <Menu
                          id="menu"
                          MenuListProps={{
                            'aria-labelledby': 'menu-button',
                          }}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && rowData.id === selectedDevice?.id}
                          onClose={() => setAnchorEl(null)}
                        >
                          <ListSubheader>Provisioning</ListSubheader>
                          <MenuItem onClick={() => handleOpenDialog('activateIridiumForDevice')}>
                            <ListItemIcon>
                              <PlayCircle fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Activate Iridium for Device</ListItemText>
                          </MenuItem>
                          {rowData.deviceMake === 'Iridium' && (
                            <MenuItem onClick={() => handleOpenDialog('iridiumExtremeActivation')}>
                              <ListItemIcon>
                                <PlayCircle fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Activation code for Iridium Extreme</ListItemText>
                            </MenuItem>
                          )}
                          {rowData.deviceMake === 'Rock7' && (
                            <Box>
                              <MenuItem onClick={() => handleOpenDialog('updateGprsEndpoints')}>
                                <ListItemIcon>
                                  <PermDataSetting fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Point at GCP (instead of Techdata)</ListItemText>
                              </MenuItem>
                              <MenuItem onClick={() => requestGprsEndpointsMutation.mutate(rowData)}>
                                <ListItemIcon>
                                  <WifiFind fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Request GPRS endpoint</ListItemText>
                              </MenuItem>
                              <ListSubheader>Configuration</ListSubheader>
                              <MenuItem onClick={() => handleOpenDialog('rock7Config')}>
                                <ListItemIcon>
                                  <EditNote fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Update Configuration</ListItemText>
                              </MenuItem>
                            </Box>
                          )}
                          <ListSubheader>Device Status</ListSubheader>
                          <MenuItem onClick={() => handleOpenDialog('incidentHandlingMethod')}>
                            <ListItemIcon>
                              <NotificationImportant fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Set Incident Handling Method</ListItemText>
                          </MenuItem>
                          <MenuItem onClick={() => handleOpenDialog('deviceStatus')}>
                            <ListItemIcon>
                              <VideogameAssetOff fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Set Status</ListItemText>
                          </MenuItem>
                        </Menu>
                      </div>
                    ),
                    onClick: () => null,
                  }),
                ]}
                options={{
                  draggable: false,
                  showTitle: false,
                  search: true,
                  sorting: true,
                  paging: true,
                  filtering: true,
                  pageSize: 100,
                  pageSizeOptions: [50, 100, 500],
                  actionsColumnIndex: -1,
                  searchFieldVariant: 'outlined',
                  thirdSortClick: false,
                  emptyRowsWhenPaging: false,
                  headerStyle: {
                    position: 'sticky',
                    top: 0,
                  },
                }}
                localization={{
                  header: {
                    actions: '',
                  },
                  body: {
                    emptyDataSourceMessage: (assetSearchQuery ?? '').length < 3 ? 'Please search for an asset or device to query results' : 'No results found',
                  },
                  toolbar: {
                    searchPlaceholder: 'Filter',
                  },
                }}
                components={{
                  Container: Box,
                  Pagination,
                  Toolbar,
                }}
              />
              <Box ref={footerRef} position="sticky" bottom={0} />
            </Box>
          </Container>
        </Container>

        {selectedDevice && (
          <>
            <ConfirmDialog
              open={openDialog === 'activateIridiumForDevice'}
              onClose={handleCloseDialog}
              onConfirm={() => activateIridiumMutation.mutate(selectedDevice)}
              title="Activate Iridium for Device"
              description="This will advance the provisioning progress for this device (if device is waiting activation). This includes activating the iridium contract."
              disabled={activateIridiumMutation.isLoading}
            />

            <IridiumLbsDialog
              open={openDialog === 'iridiumExtremeActivation'}
              onClose={handleCloseDialog}
              imei={selectedDevice.imei}
              assetName={selectedDevice.assetName}
            />

            <ConfirmDialog
              open={openDialog === 'updateGprsEndpoints'}
              onClose={handleCloseDialog}
              onConfirm={() => updateGPRSEndpointMutation.mutate(selectedDevice)}
              title="Change GPRS Endpoints to GCP?"
              description="This will change the cellular reporting destination to the new GCP endpoint instead of the old TechData one. Are you sure?"
              disabled={updateGPRSEndpointMutation.isLoading}
            />

            <ConfirmDialog
              open={openDialog === 'requestGprsEndpoints'}
              onClose={handleCloseDialog}
              onConfirm={() => updateGPRSEndpointMutation.mutate(selectedDevice)}
              title="Change GPRS Endpoints to GCP?"
              description="This will change the cellular reporting destination to the new GCP endpoint instead of the old TechData one. Are you sure?"
              disabled={updateGPRSEndpointMutation.isLoading}
            />

            <Rock7ConfigDialog
              open={openDialog === 'rock7Config'}
              onClose={handleCloseDialog}
              device={selectedDevice}
            />

            <SelectDialog
              open={openDialog === 'incidentHandlingMethod'}
              onClose={handleCloseDialog}
              onConfirm={method => setIncidentHandlingMethodMutation.mutate([selectedDevice, method])}
              title="Set Incident Handling Method"
              description="This will set the incident handling method for the device. Are you sure?"
              options={[{
                label: 'Take No Action',
                value: 'no-action',
              }, {
                label: 'Notify Only',
                value: 'notify',
              }, {
                label: 'Notify and Call',
                value: 'notify-call',
              }]}
              disabled={setIncidentHandlingMethodMutation.isLoading}
            />

            <SelectDialog
              open={openDialog === 'deviceStatus'}
              onClose={handleCloseDialog}
              onConfirm={status => setStatusMutation.mutate([selectedDevice, status])}
              title="Set Status"
              description="This will set the status for the device. Are you sure?"
              options={[{
                label: 'Activated',
                value: 'activated',
              }, {
                label: 'Deactivated',
                value: 'deactivated',
              }, {
                label: 'Suspended',
                value: 'suspended',
              }, {
                label: 'Archived',
                value: 'archived',
              }]}
              disabled={setStatusMutation.isLoading}
            />
          </>
        )}
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default AssetSearchPage;
