import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { AssetGroup } from 'apis/rest/assetGroups/types';
import LimitedList from 'components/shared/LimitedList';
import React from 'react';
import { useTranslations } from 'use-intl';

interface AssetGroupLabelProps {
  assetGroup: AssetGroup
}

export const AssetGroupLabel = ({ assetGroup }: AssetGroupLabelProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.edit.assetGroupsDialog');
  return (
    <Stack direction="row" flex={1} alignItems="center">
      <Stack direction="row" spacing={2} flex={1} alignItems="center">
        <Stack>
          <Typography>{assetGroup?.name}</Typography>
          {(!!(assetGroup?.assets.length) && (
            <Typography variant="body3">
              <LimitedList<string>
                items={assetGroup.assets.map(x => x.name)}
                limit={2}
                renderMore={({ items, children }) => (
                  <Tooltip title={items.map((name, i) => <div key={`tooltip-${i}`}>{name}</div>)}>
                    <Chip label={children} variant="outlined" />
                  </Tooltip>
                )}
              />
            </Typography>
          )) || (<Typography variant="body3">{t('countLabel', { count: assetGroup.assets.length })}</Typography>)}
        </Stack>
      </Stack>
    </Stack>
  );
};
