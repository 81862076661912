import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CalendarDay, useDatePicker } from '@rehookify/datepicker';
import { DateTime } from 'luxon';
import {
  Box,
  Tooltip,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { chunk } from 'lodash';
import {
  DayButton,
  DayVariant,
  Header,
  Title,
} from './dateRangePicker-styles';

interface DateRange {
  start: string
  end: string
}
interface IncompleteDateRange {
  start: string
  end: undefined
}

interface ActivityIndicator {
  id: number
  color: string
  name: string
}

interface DateRangePickerProps {
  open: boolean
  setOpen: (value: boolean) => void
  value: DateRange
  allowedRange: Partial<DateRange>
  onChange: (range: DateRange) => void
  maxRangeLength: number
  renderTitle: (props: { pendingValue: DateRange | IncompleteDateRange }) => React.ReactNode
  getActivityIndicators?: (day: string) => ActivityIndicator[]
  onMonthChange?: (month: string) => void
}

const getDayVariant = (days: CalendarDay[], day: CalendarDay, selectedDate: string | undefined, disabled: boolean): DayVariant => {
  if (disabled) return DayVariant.Disabled;

  const prevDay = days[days.indexOf(day) - 1];
  const nextDay = days[days.indexOf(day) + 1];
  if (day.selected && !prevDay?.range && !nextDay?.range) return DayVariant.AloneSelected;

  if (!day.range) return DayVariant.None;

  if (day.range === 'range-start') return DayVariant.LeftSelected;
  if (day.range === 'range-end') return DayVariant.RightSelected;
  if (day.range === 'in-range') return DayVariant.MiddleSelected;
  if (day.range === 'will-be-in-range') return DayVariant.Middle;

  const selected = selectedDate ? DateTime.fromISO(selectedDate).toMillis() === +day.$date : false;
  if (day.range === 'will-be-range-start') return selected ? DayVariant.LeftSelected : DayVariant.Left;
  if (day.range === 'will-be-range-end') return selected ? DayVariant.RightSelected : DayVariant.Right;

  return DayVariant.None;
};

const DateRangePicker = ({
  open,
  setOpen,
  value,
  onChange,
  allowedRange,
  maxRangeLength,
  renderTitle,
  getActivityIndicators = () => [],
  onMonthChange = () => undefined,
}: DateRangePickerProps) => {
  const [pendingValue, setPendingValue] = useState<DateRange | IncompleteDateRange>(value);

  const onCancel = useCallback(() => {
    setOpen(false);
    setPendingValue(value);
  }, [setOpen, setPendingValue, value]);

  const onOkay = useCallback(() => {
    if (pendingValue.start) {
      setOpen(false);
      onChange(pendingValue.end ? pendingValue : { start: pendingValue.start, end: pendingValue.start });
    }
  }, [setOpen, pendingValue, onChange]);

  const { data, propGetters } = useDatePicker({
    selectedDates: ([
      DateTime.fromISO(pendingValue.start).toJSDate(),
      pendingValue.end ? DateTime.fromISO(pendingValue.end).toJSDate() : undefined
    ].filter(d => d) as Date[]),
    onDatesChange: range => setPendingValue({
      start: DateTime.fromJSDate(range[0]).toISODate(),
      end: DateTime.fromJSDate(range[1]).toISODate(),
    }),
    dates: {
      toggle: false,
      selectSameDate: true,
      mode: 'range',
      minDate: allowedRange.start ? DateTime.fromISO(allowedRange.start).toJSDate() : undefined,
      maxDate: allowedRange.end ? DateTime.fromISO(allowedRange.end).toJSDate() : undefined,
    },
    calendar: {
      mode: 'fluid',
      startDay: 1
    }
  });

  const [{ month, year, days }] = data.calendars;

  const allowedSelectionRange: DateRange | undefined = useMemo(() => {
    if (pendingValue.start && !pendingValue.end) {
      const start = DateTime.fromISO(pendingValue.start);
      const duration = { days: maxRangeLength - 1 };
      return {
        start: start.minus(duration).toISODate(),
        end: start.plus(duration).toISODate(),
      };
    }
    return undefined;
  }, [pendingValue, maxRangeLength]);

  const inAllowedSelectionRange = (date: string) => {
    if (!allowedSelectionRange) return true;
    return date >= allowedSelectionRange.start && date <= allowedSelectionRange.end;
  };

  const weeks = chunk(days, 7);

  useEffect(() => {
    const selectedMonth = data.months.find(d => d.active);
    if (selectedMonth) {
      onMonthChange(DateTime.fromJSDate(selectedMonth.$date).toISODate());
    }
  }, [data.months, onMonthChange]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="lg"
    >
      <DialogContent sx={{ padding: 0 }}>
        <Box height={440}>
          <Header
            height={100}
            container
            direction="column"
            justifyContent="center"
          >
            <Grid item>
              <Title>{renderTitle({ pendingValue })}</Title>
            </Grid>
          </Header>
          <Grid container sx={theme => ({ padding: theme.spacing(2, 4, 4) })} alignItems="center">
            <Grid item xs>
              <Typography>{`${month} ${year}`}</Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton {...propGetters.previousMonthButton()}><ChevronLeft /></IconButton>
              <IconButton {...propGetters.nextMonthButton()}><ChevronRight /></IconButton>
            </Grid>
          </Grid>
          <Grid container direction="column" sx={theme => ({ padding: theme.spacing(0, 4) })}>
            <Grid item container columns={7} sx={theme => ({ marginBottom: theme.spacing(2) })}>
              {data.weekDays.map(day => (
                <Grid item xs={1} key={`${month}-${day}`}>
                  <Typography sx={{ fontSize: '0.75rem' }} align="center">{day}</Typography>
                </Grid>
              ))}
            </Grid>
            {weeks.map(week => (
              <Grid item xs container columns={7} key={week[0].$date.toString()}>
                {week.map(day => {
                  const dateString = DateTime.fromJSDate(day.$date).toISODate();
                  const disabled = !inAllowedSelectionRange(dateString);
                  const dayProps = propGetters.dayButton(day, { disabled });
                  const variant = getDayVariant(days, day, pendingValue.start, !!dayProps.disabled);
                  const activityIndicators = getActivityIndicators(dateString);
                  const tooManyActivityIndicators = activityIndicators.length > 3;

                  return (
                    <Grid xs={1} item key={day.$date.toString()}>
                      {day.inCurrentMonth && (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {disabled ? (
                            <DayButton {...dayProps} variant={variant}>{day.$date.getDate()}</DayButton>
                          ) : (
                            <Tooltip title={activityIndicators.length ? (
                              <>{activityIndicators.map((a, i) => (<p key={i}>{a.name}</p>))}</>
                            ) : null}>
                              <DayButton
                                {...dayProps}
                                disabled={false}
                                variant={variant}
                              >
                                <Typography>
                                  {day.$date.getDate()}
                                </Typography>
                                {activityIndicators.length > 0
                                  && (
                                    <Stack
                                      direction="row"
                                      spacing="3px"
                                      sx={{
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translate(-50%)',
                                      }}>
                                      {tooManyActivityIndicators
                                        ? (
                                          <Box sx={theme => ({
                                            width: '20px',
                                            height: '3px',
                                            backgroundColor: theme.palette.primary.activeGreen,
                                          })} />
                                        ) : activityIndicators.slice(0, 3)
                                          .map(a => (
                                            <div
                                              style={{
                                                width: 5,
                                                height: 5,
                                                backgroundColor: a.color,
                                                borderRadius: '50%',
                                              }}
                                            />
                                          ))}
                                    </Stack>
                                  )}
                              </DayButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onOkay} disabled={!pendingValue.start}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangePicker;
