import React, { useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useTranslations } from 'use-intl';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useOutletContext } from 'react-router';
import useVolume from 'hooks/units/useVolume';
import { Close, KeyboardArrowDown } from '@mui/icons-material';
import { alpha, useTheme } from '@mui/material/styles';
import useTimezone from 'hooks/session/useTimezone';
import { useGetGeocodedLocationByReportId } from 'apis/rest/geocoding/hooks';
import { getSuppressantColors } from '../helpers';
import { OutletContextValue } from '../layout';
import DropDetailSummary from './dropDetailSummary';
import { DropGroup, calculateDropGroups } from './dropGroups';
import { useIsFiltered } from '../statistics';

interface DropRowProps {
  isSelectedDrop: boolean;
  onOpen: () => void;
  group: DropGroup;
  dropReports: Record<number, TripSlimReport[]>;
  lastDropTime?: number;
  nextFillTime?: number;
  location?: string;
  trip?: Trip;
  setHoveredDropGroup: (id: string) => void
}

const DropRow = ({
  isSelectedDrop,
  onOpen,
  group,
  dropReports,
  lastDropTime,
  nextFillTime,
  location,
  trip,
  setHoveredDropGroup
}: DropRowProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const tz = useTimezone();
  const volume = useVolume();

  const theme = useTheme();
  const suppressantColor = getSuppressantColors(theme);
  const selectedBgColor = alpha(theme.palette.primary.main, 0.05);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const drop = group.drops.find(d => d.id === group.lastDropId)!;
  const splitDropCount = group.drops.filter(d => d.type === 'Drop').length;

  const isLastInTrip = useMemo(() => {
    const last = trip?.drops.slice(0).reverse().find(d => d.type === 'Drop');
    return last && last.id === group.lastDropId;
  }, [trip, group]);
  const isFiltered = useIsFiltered(trip);

  return (
    <>
      <TableRow
        onClick={onOpen}
        sx={{
          cursor: 'pointer',
          bgcolor: isSelectedDrop ? selectedBgColor : undefined,
          ':hover': { bgcolor: selectedBgColor },
          '& > .MuiTableCell-root': { border: 0 },
        }}
        onMouseEnter={() => setHoveredDropGroup(group.id)}
      >
        <TableCell>
          <Stack direction="column" justifyContent="center">
            {!location
              ? <Skeleton width="50ch" sx={{ display: 'inline-block' }} />
              : <Typography>{location}</Typography>}
            <Typography>
              {DateTime.fromMillis(drop.startTime)
                .setZone(tz)
                .toFormat('dd MMM yyyy, HH:mm:ss ZZZ')}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box width="1rem" height="1rem" borderRadius="50%" bgcolor={suppressantColor[drop.suppressant]} />
            <Box>
              <Box>{t(`suppressant.${drop.suppressant}`)}</Box>
              <Box>{t('nFills', { n: group.drops.filter(d => d.type === 'Fill').length })}</Box>
            </Box>
          </Stack>
        </TableCell>
        <TableCell>
          {drop.dropVolume === undefined ? '—' : volume.create(drop.dropVolume).format()}
          {drop.splitDrop && (<Typography>{t('nSplitDrops', { n: splitDropCount })}</Typography>)}
        </TableCell>
        <TableCell>{isLastInTrip ? !isFiltered ? t('yes') : t('no') : '—'}</TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {isSelectedDrop ? <Close sx={{ width: '2rem', height: '2rem' }} /> : <KeyboardArrowDown sx={{ width: '2rem', height: '2rem' }} />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ bgcolor: selectedBgColor }}>
        <TableCell style={{
          paddingBottom: 0,
          paddingTop: 0,
        }} colSpan={5}>
          <Collapse in={isSelectedDrop} timeout="auto" unmountOnExit>
            <DropDetailSummary
              group={group}
              dropReports={dropReports}
              lastDropTime={lastDropTime}
              nextFillTime={nextFillTime}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const AssetDropsDetail = () => {
  const {
    visibleSuppressants,
    drops,
    trips,
    setHoveredDropGroup,
    setSelectedDropGroup
  } = useOutletContext<OutletContextValue>();
  const t = useTranslations('pages.reporting.firefighting');

  const dropGroups = useMemo(() => calculateDropGroups(trips), [trips]);

  const reportsByGroupId = useMemo(() => dropGroups.reduce<Record<number, Record<number, TripSlimReport[]>>>((acc, group) => {
    acc[group.lastDropId] = group.drops.reduce<Record<number, TripSlimReport[]>>((dropAcc, drop) => {
      const trip = trips.find(tr => tr.drops.some(d => d.id === drop.id));
      if (!trip) return dropAcc;

      const dropStart = trip.reports.find(r => drop.startReportId === r.id);
      if (dropStart && !dropAcc[drop.id]?.push(dropStart)) dropAcc[drop.id] = [dropStart];
      const dropStop = trip.reports.find(r => drop.endReportId === r.id);
      if (dropStop && !dropAcc[drop.id]?.push(dropStop)) dropAcc[drop.id] = [dropStop];

      return dropAcc;
    }, {});
    return acc;
  }, {}), [dropGroups, trips]);

  const coordsToName = useMemo(() => dropGroups.reduce<Pick<Report, 'id' | 'latitude' | 'longitude'>[]>((acc, group) => {
    const reports = reportsByGroupId[group.lastDropId];
    if (!reports) return acc;
    const report = reports[group.lastDropId]?.at(0);
    if (report) {
      acc.push({
        id: report.id,
        longitude: report.coords[0],
        latitude: report.coords[1],
      });
    }
    return acc;
  }, []), [dropGroups, reportsByGroupId]);
  const locationsQuery = useGetGeocodedLocationByReportId(drops.at(0)?.assetId, coordsToName).query;
  const locationByGroupId = useMemo(() => dropGroups.reduce<Record<number, string | undefined>>((acc, group) => {
    acc[group.lastDropId] = locationsQuery.isLoading ? undefined : t('unknownLocation');

    const reports = reportsByGroupId[group.lastDropId];
    if (!reports) return acc;

    const reportId = reports[group.lastDropId]?.at(0)?.id;
    if (!reportId) return acc;

    if (locationsQuery.data) acc[group.lastDropId] = locationsQuery.data[reportId] ?? t('unknownLocation');
    return acc;
  }, {}), [dropGroups, reportsByGroupId, locationsQuery.isLoading, locationsQuery.data, t]);

  const lastDropById = useMemo(() => drops.reduce<Record<number, number | undefined>>((acc, drop) => {
    if (drop.type !== 'Drop') return acc;
    acc[drop.id] = drops.slice(0)
      .reverse()
      .filter(d => visibleSuppressants[d.suppressant])
      .find(d => d.type === 'Drop' && d.startTime < drop.startTime)?.startTime;
    return acc;
  }, {}), [drops, visibleSuppressants]);

  const nextFillById = useMemo(() => drops.reduce<Record<number, number | undefined>>((acc, drop) => {
    if (drop.type !== 'Drop') return acc;
    acc[drop.id] = drops
      .filter(d => visibleSuppressants[d.suppressant])
      .find(d => d.type === 'Fill' && d.startTime > drop.startTime)?.startTime;
    return acc;
  }, {}), [drops, visibleSuppressants]);

  const [openDrop, setOpenDrop] = React.useState<string | undefined>();

  useEffect(() => {
    setSelectedDropGroup(openDrop);
  }, [openDrop, setSelectedDropGroup]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {drops.length > 0 && (
        <Paper sx={{ gridColumn: '1/-1' }} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('columns.time')}</TableCell>
                <TableCell>{t('columns.suppressant')}</TableCell>
                <TableCell>{t('columns.dropVolume')}</TableCell>
                <TableCell>{t('columns.isAllowedInStats')}</TableCell>
                <TableCell sx={{ width: 0 }} />
              </TableRow>
            </TableHead>
            <TableBody onMouseLeave={() => setHoveredDropGroup(undefined)}>
              {dropGroups.map(group => {
                if (group.drops.some(d => d.type === 'Drop' && !visibleSuppressants[d.suppressant])) return null;

                const dropReports = reportsByGroupId[group.lastDropId];
                const lastDropTime = lastDropById[group.lastDropId];
                const nextFillTime = nextFillById[group.lastDropId];
                const location = locationByGroupId[group.lastDropId];

                return (
                  <DropRow
                    key={group.id}
                    isSelectedDrop={openDrop === group.id}
                    onOpen={() => (openDrop === group.id ? setOpenDrop(undefined) : setOpenDrop(group.id))}
                    group={group}
                    dropReports={dropReports}
                    lastDropTime={lastDropTime}
                    nextFillTime={nextFillTime}
                    location={location}
                    trip={group.trip}
                    setHoveredDropGroup={setHoveredDropGroup}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
    </>
  );
};

export default AssetDropsDetail;
