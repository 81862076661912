import { DateTime } from 'luxon';
import { HttpResponseError, serenityFetch } from 'helpers/api';
import { DropGroup } from './types';

export const getDropsForAssets = async (organisationId: string, assetIds: number[], from: number, until: number): Promise<DropGroup[]> => {
  const params = [
    ['from', DateTime.fromMillis(from).toUTC().toISO()],
    ['until', DateTime.fromMillis(until).toUTC().toISO()],
    ['assets', assetIds.map(a => a.toString(10)).join(',')]
  ];
  const response = await serenityFetch('GET', `/organisations/${organisationId}/firefighting/groups?${new URLSearchParams(params)}`, null);
  HttpResponseError.detect(response);

  const data = await response.json() as { dropGroups: DropGroup[] };

  return data.dropGroups;
};
