import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useSnackbar from 'hooks/useSnackbar';
import SelectSerialType from 'components/shared/selectSerialType';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';
import AssetsTable from './assetsTable-view';

export enum AssignAssetsSingleGroupDialogStatus {
  Cancelled = 'cancelled',
  Assigned = 'assigned',
}

export interface AssignAssetsSingleGroupDialogProps {
  open: boolean
  onClose: (status: AssignAssetsSingleGroupDialogStatus, groupId: number) => void
  group: ContactGroup
  mutation: UseMutationResult<void, HttpResponseError, Pick<ContactGroup, 'id' | 'deviceAndAssetIds' | 'deviceVersion'>>
  title: ReactNode
  ariaLabel: string
  type: string
}

const hasChange = (current: {deviceId: number, assetId: number}[], next: {deviceId: number, assetId: number}[] | undefined) => {
  if (!next) return false;
  // NOTE: we only actually care about device IDs
  return current.map(x => x.deviceId).sort().join(',') !== next.map(x => x.deviceId).sort().join(',');
};

export const AssignAssetsSingleGroupDialog = ({
  open,
  onClose,
  group,
  mutation,
  title,
  ariaLabel,
  type,
}: AssignAssetsSingleGroupDialogProps): JSX.Element => {
  const t = useTranslations('dialogs.contactGroups.assignAssetsSingleGroup');
  const snackbar = useSnackbar();
  const [selectedItems, setSelectedItems] = useState<{deviceId: number, assetId: number}[]>();

  const handleClose = (status: AssignAssetsSingleGroupDialogStatus) => onClose(status, group.id);
  const handleCancel = () => handleClose(AssignAssetsSingleGroupDialogStatus.Cancelled);
  const handleSave = () => {
    if (!selectedItems) return;
    mutation.mutate(
      { ...group, deviceAndAssetIds: selectedItems },
      {
        onSuccess: () => {
          snackbar.display({
            id: `groupAssetsAssigned.${group.id}`,
            text: t('snackbar.success', { group: group.name }),
            type: 'success',
          });
          handleClose(AssignAssetsSingleGroupDialogStatus.Assigned);
        },
      },
    );
  };

  const onExited = () => {
    setSelectedItems(undefined);
    mutation.reset();
  };

  const canSave = hasChange(group.deviceAndAssetIds, selectedItems) && !mutation.isLoading;

  // clear existing error in selection change
  useEffect(() => {
    if (mutation.error && mutation.error.response.status !== 409) mutation.reset();
  }, [selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  // reset selections on 409 (conflict) error
  useEffect(() => {
    if (mutation.error?.response.status === 409) setSelectedItems(undefined);
  }, [mutation.error, setSelectedItems]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!mutation.isLoading) handleCancel();
      }}
      aria-labelledby={ariaLabel}
      fullWidth
      maxWidth="lg"
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {title}
          <SelectSerialType size="small" InputProps={{ sx: { width: '15rem' } }} />
        </Stack>
      </TPDialogTitle>
      <DialogContent sx={{ p: 0, mb: '-1px' }}>
        <AssetsTable
          selectedItems={selectedItems ?? group.deviceAndAssetIds}
          setSelectedItems={setSelectedItems}
          disabled={mutation.isLoading}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
        <Stack spacing={3} flex={1}>
          <Alert severity="warning">
            {t('assignmentWarning', { type })}
          </Alert>
          <Collapse in={mutation.isError} unmountOnExit>
            <Alert severity="error">{t(mutation.error?.response.status === 409 ? 'error409' : 'error')}</Alert>
          </Collapse>
          <Stack my={1} spacing={3} direction="row" height="4em" alignSelf="flex-end">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              disabled={mutation.isLoading}
              onClick={handleCancel}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ minWidth: '10rem' }}
              color="primary"
              disabled={!canSave}
              onClick={handleSave}
            >
              {t(mutation.isLoading ? 'saving' : 'save')}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
