import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { tryGetFirmwareVersion } from 'helpers/config';
import {
  assignDeviceToAsset,
  ConfigurationProfile,
  fetchLatestFirmwareVersions,
  getCapabilitiesList,
  getConfigChanges,
  getDevice,
  getDevicesList,
  getLatestConfigurations,
  removeDeviceFromAsset,
  requestConfig,
  sendCustomConfig,
  setConfigurationProfile,
} from './requests';
import { assetsQueryKeys } from '../assets/queryKeys';
import { deviceQueryKeys } from './queryKeys';
import { FirmwareVersion, Rock7ConfigurationChange } from './types';

type Options<QueryData, ResultData> = Omit<UseQueryOptions<QueryData, HttpResponseError, ResultData>, 'queryKey' | 'queryFn'>;

export const useGetDevicesList = <T = DeviceBasic[]>(options?: Options<DeviceBasic[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.lists(organisationId);
  const query = useQuery<DeviceBasic[], HttpResponseError, T>(
    queryKey,
    () => getDevicesList(organisationId),
    options,
  );
  return { query, queryKey };
};

export const useGetDeviceBasic = (deviceId: number, enabled = true) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => deviceQueryKeys.detail(organisationId, deviceId), [organisationId, deviceId]);
  const query = useQuery<DeviceBasic, HttpResponseError>(
    queryKey,
    () => getDevice(organisationId, deviceId),
    { enabled },
  );
  return { query, queryKey };
};

export const useGetLatestDeviceConfigurations = <T = DeviceConfiguration[]>(options: Options<DeviceConfiguration[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.listsConfigurations(organisationId);
  const query = useQuery<DeviceConfiguration[], HttpResponseError, T>(
    queryKey,
    () => getLatestConfigurations(organisationId),
    options,
  );
  return { query, queryKey };
};

export const useGetLatestDeviceConfiguration = (deviceId: number) => useGetLatestDeviceConfigurations({ select: data => data.find(c => c.deviceId === deviceId) });

export const useDeviceConfigChanges = <T = Rock7ConfigurationChange[]>(deviceId: number) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.configChanges(organisationId, deviceId);
  return useQuery<Rock7ConfigurationChange[], HttpResponseError, T>(
    queryKey,
    () => getConfigChanges(organisationId, deviceId)
  );
};

export const useSetDeviceConfigurationProfile = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { deviceId: number, profile: ConfigurationProfile }>(
    value => setConfigurationProfile(organisationId, value.deviceId, value.profile),
    {
      mutationKey: ['setDeviceConfigurationProfile'],
      onSuccess: (data, value) => queryClient.invalidateQueries(deviceQueryKeys.listsConfigurations(organisationId)),
    },
  );
};

export const useGetCapabilitiesList = <T = Capability[]>(options?: UseQueryOptions<Capability[], HttpResponseError, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.listsCapabilities(organisationId);
  const query = useQuery<Capability[], HttpResponseError, T>(
    queryKey,
    () => getCapabilitiesList(organisationId),
    options
  );
  return { query, queryKey };
};

export const useGetCapabilitiesForDevice = (deviceId: number) => {
  const deviceQuery = useGetDeviceBasic(deviceId).query;
  return useGetCapabilitiesList({
    enabled: !!deviceQuery.data?.scriptId,
    select: c => c.filter(cap => cap.scriptId === deviceQuery.data?.scriptId)
  }).query;
};

export const useGetLatestFirmwareVersions = <T = FirmwareVersion[]>(options?: UseQueryOptions<FirmwareVersion[], HttpResponseError, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.firmwareVersions(organisationId);
  return useQuery<FirmwareVersion[], HttpResponseError, T>(
    queryKey,
    () => fetchLatestFirmwareVersions(organisationId),
    options,
  );
};

export const useLatestFirmwareVersionForDevice = (device: DeviceBasic | undefined) => {
  const select = useCallback(
    versions => versions.find((v: FirmwareVersion) => v.make === device?.make && v.model === device?.model)?.version,
    [device]
  );

  const config = useGetLatestDeviceConfiguration(device?.id ?? -1).query;
  const latestFirmwareVersion = useGetLatestFirmwareVersions({
    enabled: !!device && !!config.data,
    select,
  }).data;

  return useMemo(
    () => {
      const firmwareVersion = config.data && tryGetFirmwareVersion(config.data);

      return ({
        outdated: !firmwareVersion && !latestFirmwareVersion && latestFirmwareVersion !== firmwareVersion,
        latest: latestFirmwareVersion,
      });
    },
    [config.data, latestFirmwareVersion],
  );
};

export const useAssignAssetToDevice = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { assetId: number, deviceId: number }>(
    value => assignDeviceToAsset(organisationId, value.assetId, value.deviceId),
    {
      mutationKey: ['assignDeviceToAsset'],
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries(deviceQueryKeys.all(organisationId)),
        queryClient.invalidateQueries(assetsQueryKeys.all(organisationId)),
      ]),
    },
  );
};

export const useUnassignAssetFromDevice = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { assetId: number, deviceId: number }>(
    value => removeDeviceFromAsset(organisationId, value.assetId, value.deviceId),
    {
      mutationKey: ['unassignDeviceToAsset'],
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries(deviceQueryKeys.all(organisationId)),
        queryClient.invalidateQueries(assetsQueryKeys.all(organisationId)),
      ]),
    },
  );
};

export const useUpdateCustomConfig = () => {
  const organisationId = useOrganisationId();

  return useMutation<void, HttpResponseError, { deviceId: number, config: ConfigRequest}>(
    value => sendCustomConfig(organisationId, value.deviceId, value.config),
    {
      mutationKey: ['updateConfig'],
    },
  );
};

export const useRequestConfig = () => {
  const organisationId = useOrganisationId();

  return useMutation<void, HttpResponseError, { deviceId: number }>(
    value => requestConfig(organisationId, value.deviceId),
    {
      mutationKey: ['updateConfig'],
    },
  );
};
