import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useTranslations } from 'use-intl';
import { Container, Box, Typography, Alert } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { debounce } from 'lodash';
import Page from 'components/pages/page';
import LinkAbove from 'components/shared/linkAbove';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import { useAssetLabel } from 'components/shared/assetLabel';
import { getAssetImage } from 'apis/assetImages';
import { updateOrganisationAssetProfile } from 'apis/rest/assets/requests';
import { useUpdateAsset } from 'apis/rest/assets/hooks';
import AssetImage from './assetImage-view';
import AssetDetails from './assetDetails-view';
import AssetDevice from './assetDevices-view';
import { AssetAms, AssetIncidentSuppression } from './escalation';
import useStyles from './asset-styles';
import AssetTrailColour from './assetTrailColour-view';
import AssetIceContactGroup from './assetIceContactGroup-view';
import AssetMessagingWhitelistContactGroup from './assetMessagingWhitelistContactGroup-view';
import AssetSharing from './assetSharing-view';

interface AssetPageParams {
  categories: Category[];
  asset: AssetBasic;
  device: DeviceBasic;
  hasDevice: boolean;
  organisationId: string;
  displaySnackbar: (params: Snack) => void;
  serialType: string;
  permissions: { canEditAssets: boolean };
}

const AssetPage = ({
  categories,
  asset,
  device,
  hasDevice,
  organisationId,
  displaySnackbar,
  serialType,
  permissions,
}: AssetPageParams): JSX.Element => {
  const navigate = useNavigate();
  const t = useTranslations('pages.assetView');
  const classes = useStyles();
  const readOnly = !(asset.ownerId.toLowerCase() === organisationId.toLowerCase())
    || !permissions.canEditAssets
    || asset.category === 'System';
  const canEditOrganisationProfile = permissions.canEditAssets && asset.category !== 'System';
  const queryClient = useQueryClient();
  const assetLabel = useAssetLabel();
  const [localAsset, setAsset] = useState<AssetBasic>({ ...asset });
  useEffect(() => setAsset({ ...asset }), [asset]);

  if (!asset) {
    navigate('/404');
  }

  const updateAssetMutation = useUpdateAsset();

  const saveAssetDetails = (assetDetails: any): void => {
    if (readOnly) return;
    setAsset({
      ...localAsset,
      category: assetDetails.category?.label,
      name: assetDetails.name,
      make: assetDetails.make,
      model: assetDetails.model,
      variant: assetDetails.variant,
      messagingHandle: assetDetails.messagingHandle,
      watchlistGroup: assetDetails.watchlistGroup,
      tailNumber: assetDetails.tailNumber,
      callSign: assetDetails.callSign,
    });
    updateAssetMutation.mutate(
      {
        id: localAsset.id,
        name: assetDetails.name,
        category: assetDetails.category?.label,
        tailNumber: assetDetails.tailNumber || null,
        callSign: assetDetails.callSign || null,
        make: assetDetails.make || null,
        model: assetDetails.model || null,
        variant: assetDetails.variant || null,
        messagingHandle: assetDetails.messagingHandle || null,
        watchlistGroup: assetDetails.watchlistGroup || null,
      },
      {
        onError: error => {
          if (error.response.status === 400) {
            // NOTE: for now we assume that a 400 failure is due to a non-unique messaging handle
            displaySnackbar({
              id: 'assetDetailsUniqueMessagingHandle',
              text: t('assetDetailsUniqueMessagingHandle'),
              type: 'error',
            });
            mixpanel.track('Update Asset', {
              success: false,
              error: 'uniqueMessagingHandle',
              asset,
              organisationId,
            });
          } else {
            displaySnackbar({
              id: 'assetDetailsSaveFailed',
              text: t('assetDetailsSaveFailed'),
              type: 'error',
            });
            mixpanel.track('Update Asset', {
              success: false,
              asset,
              organisationId,
            });
          }
        },
        onSuccess: () => {
          displaySnackbar({
            id: 'assetDetailsSaved',
            text: t('assetDetailsSaved'),
            type: 'success',
          });
          mixpanel.track('Update Asset', {
            success: true,
            asset,
            organisationId,
          });
        },
      }
    );
  };

  const doUpdateProfile = useMutation(
    oap => updateOrganisationAssetProfile(organisationId, asset?.id, oap),
    {
      onError: (err: Error) => {
        displaySnackbar({
          id: 'assetDetailsSaveFailed',
          text: t('assetDetailsSaveFailed'),
          type: 'error',
        });
        mixpanel.track('Update Asset', {
          success: false,
          asset,
          organisationId,
        });
      },
      onSuccess: () => {
        displaySnackbar({
          id: 'assetDetailsSaved',
          text: t('assetDetailsSaved'),
          type: 'success',
        });
        mixpanel.track('Update Asset', {
          success: true,
          asset,
          organisationId,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['asset', organisationId, asset.id]);
        queryClient.invalidateQueries(['assetBasic', organisationId, asset.id]);
        queryClient.invalidateQueries(['assets', organisationId, null]);
      },
      onMutate: (newOap: any) => newOap,
      mutationKey: ['updateAssetProfile'],
    }
  );

  const saveAssetTrailColourInner = (colour: string): void => {
    if (!canEditOrganisationProfile || localAsset.colour === colour) return;
    setAsset({ ...localAsset, colour });
    doUpdateProfile.mutate({ colour });
  };
  const saveAssetTrailColour = debounce(saveAssetTrailColourInner, 400);

  const assetImageQuery = useQuery(
    ['assetImage', asset.id],
    () => getAssetImage(asset.id),
    // TODO: aggressive caching since the client currently doesn't know if this asset has a custom profile image
    // This can be removed (and the placeholder loaded without waiting for the failed custom image query) if we
    // store an indication that there is a custom image against the asset table in db (e.g. in the image_url field)
    { staleTime: Infinity, cacheTime: Infinity }
  );

  const onImageUpload = (success: boolean): void => {
    if (!success) {
      displaySnackbar({
        id: 'assetImageSaveFailed',
        text: t('assetImageSaveFailed'),
        type: 'error',
      });
      mixpanel.track('Upload Asset Image', {
        success: false,
        asset,
        organisationId,
      });
      return;
    }
    assetImageQuery.refetch();
    displaySnackbar({
      id: 'assetImageSaved',
      text: t('assetImageSaved'),
      type: 'success',
    });
    mixpanel.track('Upload Asset Image', {
      success: true,
      asset,
      organisationId,
    });
  };

  const onImageRemove = async (success: boolean): Promise<void> => {
    if (!success) {
      displaySnackbar({
        id: 'assetImageRemoveFailed',
        text: t('assetImageRemoveFailed'),
        type: 'error',
      });
      mixpanel.track('Remove Asset Image', {
        success: false,
        asset,
        organisationId,
      });
      return;
    }
    await assetImageQuery.refetch();
    displaySnackbar({
      id: 'assetImageRemoved',
      text: t('assetImageRemoved'),
      type: 'success',
    });
    mixpanel.track('Remove Asset Image', {
      success: true,
      asset,
      organisationId,
    });
  };

  const title = assetLabel(localAsset) || t('titleNoName');
  const isOwnAsset = localAsset.ownerId.toLowerCase() === organisationId.toLowerCase();
  const isSystemAsset = localAsset.category === 'System';

  return (
    <Page title={title}>
      <SettingsMenuPageWrapper>
        <Container maxWidth="md">
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>
              {title}{' '}
              {asset.archived && (
                <Typography variant="overline" fontSize="1rem">
                  {t('archived')}
                </Typography>
              )}
            </Typography>
            {!isOwnAsset && (
              <Typography variant="body1" gutterBottom>
                {t('ownedBy', { ownerName: localAsset.ownerName })}
              </Typography>
            )}
            {isSystemAsset && (
              <Alert severity="info">
                {t('systemAsset', { make: asset.deviceMake, model: asset.deviceModel, serial: asset.deviceTracPlusSerial })}
              </Alert>
            )}
            {!readOnly && (
              <AssetImage
                classes={classes}
                t={t}
                imageUrl={assetImageQuery.data}
                isLoading={assetImageQuery.isLoading}
                assetId={asset.id}
                onUpload={onImageUpload}
                canEditAsset={!readOnly}
                onRemove={onImageRemove}
              />
            )}
            {!isSystemAsset && (
              <AssetDetails
                localAsset={localAsset}
                readOnly={readOnly}
                categories={categories}
                saveAssetDetails={saveAssetDetails}
                isSaving={updateAssetMutation.isLoading}
              />
            )}
            <AssetDevice
              asset={asset}
              hasDevice={hasDevice}
              device={device}
              organisationId={organisationId}
              displaySnackbar={displaySnackbar}
              serialType={serialType}
              readOnly={readOnly}
            />
            {!isSystemAsset && <AssetSharing device={device} />}
            {!isSystemAsset && (
              <AssetTrailColour
                classes={classes}
                t={t}
                localAsset={localAsset}
                saveAssetTrailColour={saveAssetTrailColour}
                readOnly={!canEditOrganisationProfile}
              />
            )}
            {hasDevice && isOwnAsset && (
              <>
                <AssetIceContactGroup
                  asset={asset as AssetWithDevice}
                  readOnly={readOnly}
                />
                <AssetMessagingWhitelistContactGroup
                  asset={asset as AssetWithDevice}
                  readOnly={readOnly}
                />
              </>
            )}
            {!readOnly && (
              <>
                <AssetAms assetId={asset.id} />
                <AssetIncidentSuppression assetId={asset.id} />
              </>
            )}
          </Box>
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default AssetPage;
