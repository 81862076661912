import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { Rock7ConfigSpec, UpdateRock7ConfigRequest } from './types';
import { fetchRock7ConfigSpec, updateRock7Config } from './requests';
import { rock7ConfigQueryKeys } from './queryKeys';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;
type MutationOptions<MutationData, Variables> = Omit<UseMutationOptions<MutationData, HttpResponseError, Variables, void>, 'queryKey' | 'queryFn'>;

export const useRock7ConfigSpec = <T = Rock7ConfigSpec>(options?: Options<Rock7ConfigSpec, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = rock7ConfigQueryKeys.spec(organisationId);
  return useQuery<Rock7ConfigSpec, HttpResponseError, T>(
    queryKey,
    () => fetchRock7ConfigSpec(organisationId),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    },
  );
};

export const useUpdateRock7Config = (options?: MutationOptions<void, UpdateRock7ConfigRequest>) => {
  const organisationId = useOrganisationId();

  const mutationKey = ['updateRock7Config'];
  return useMutation<void, HttpResponseError, UpdateRock7ConfigRequest, void>(
    mutationKey,
    ({
      deviceId,
      config,
    }) => updateRock7Config(organisationId, deviceId, config),
    options ?? {},
  );
};
