import React, { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import ColorInput from 'components/shared/forms/inputs/color';
import MarkerIcon, { MarkerIconType } from 'components/shared/icons/markerIcons/markerIcon';
import { Marker } from 'apis/rest/markers/types';
import { SUGGESTED_ASSET_COLORS } from 'constants/colors';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { GeographicCoordinates } from 'components/pages/manage/markers/types';
import { LatitudeInput, LongitudeInput } from 'components/shared/CoordinatesInput';
import validateLatLong from 'helpers/validation/validateLatLong';
import { useCoordFormat } from 'components/pages/manage/markers/hooks/useCoordFormat';

interface EditDialogProps {
  open: boolean
  title: string
  latLng?: GeographicCoordinates
  marker?: Marker
  onClose: () => void
  onSubmit: (marker: Marker) => void
  isLoading: boolean
}

const EditDialog = ({
  open,
  title,
  latLng,
  marker,
  onClose,
  onSubmit,
  isLoading
}: EditDialogProps) => {
  const t = useTranslations('dialogs.markers');

  const [name, setName] = useState<string | undefined>(marker?.name);
  const [icon, setIcon] = useState<MarkerIconType>(marker?.icon ?? 'generic');
  const [colour, setColour] = useState<string>(marker?.colour ?? '#000');
  const [pendingMarkerPosition, setPendingMarkerPosition] = useState<GeographicCoordinates>({ latitude: undefined, longitude: undefined });

  const coordinateValidation = useMemo(
    () => validateLatLong(pendingMarkerPosition),
    [pendingMarkerPosition]
  );

  const handleSubmit = () => {
    if (!name || !colour) {
      setName('');
      return;
    }

    // return if coordinates are not valid
    if (!coordinateValidation.valid) {
      return;
    }

    onSubmit({
      id: 0,
      type: 'POI',
      altitude: 0,
      ...marker,
      latitude: pendingMarkerPosition?.latitude ?? latLng?.latitude ?? 0,
      longitude: pendingMarkerPosition?.longitude ?? latLng?.longitude ?? 0,
      name,
      icon,
      colour,
    });
  };

  const onEnter = () => {
    setName(marker?.name);
    setIcon(marker?.icon ?? 'generic');
    setColour(marker?.colour ?? '#000');
    setPendingMarkerPosition({ latitude: latLng?.latitude, longitude: latLng?.longitude });
  };

  const onExited = () => {
    setName(undefined);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onEnter,
        onExited,
      }}
    >
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent>
        <Stack spacing={3} pt={3}>
          <TextField
            label={t('common.fields.name.title')}
            value={name}
            onChange={event => setName(event.target.value)}
            error={name !== undefined && !name}
            helperText={(name !== undefined && !name) ? t('common.fields.name.error') : undefined}
            fullWidth
          />
          <Stack direction="row" spacing={3} justifyContent="flex-start" alignItems="top">
            <LatitudeInput
              label={t('common.fields.latitude.title')}
              value={pendingMarkerPosition?.latitude}
              onChangeValue={value => setPendingMarkerPosition({ ...pendingMarkerPosition, latitude: value })}
              error={pendingMarkerPosition?.latitude === undefined}
              helperText={pendingMarkerPosition?.latitude === undefined ? t('common.fields.latitude.error') : ''}
              displayFormat={useCoordFormat()}
              fullWidth
            />
            <LongitudeInput
              label={t('common.fields.longitude.title')}
              value={pendingMarkerPosition?.longitude}
              onChangeValue={value => setPendingMarkerPosition({ ...pendingMarkerPosition, longitude: value })}
              error={pendingMarkerPosition?.longitude === undefined}
              helperText={pendingMarkerPosition?.longitude === undefined ? t('common.fields.longitude.error') : ''}
              displayFormat={useCoordFormat()}
              fullWidth
            />
          </Stack>
          <TextField
            select
            label={t('common.fields.icon.title')}
            value={icon}
            onChange={event => setIcon(event.target.value as MarkerIconType)}
            fullWidth
          >
            {['generic', 'airport', 'fire', 'fuel', 'water'].map(option => (
              <MenuItem key={option} value={option}>{t(`common.fields.icon.options.${option as MarkerIconType}`)}</MenuItem>
            ))}
          </TextField>
          <ColorInput
            palette={SUGGESTED_ASSET_COLORS}
            value={colour}
            onChange={setColour}
            preview={(
              <MarkerIcon type={icon} fill={colour} />
            )}
            readOnly={false}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>{t('common.actions.cancel')}</Button>
        <Button onClick={handleSubmit} disabled={isLoading}>{t('common.actions.save')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
