import React from 'react';
import { useTranslations } from 'use-intl';
import { Badge, Box } from '@mui/material';
import {
  Announcement,
  Chat,
  ContactPhone,
  Flight,
  FlightTakeoff,
  NotificationsActive,
  PinDrop,
  VpnLock,
} from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import Page from 'components/pages/page';
import {
  SettingsMenuContainer,
  SettingsMenuPanelGrid,
  SettingsMenuPanelItem,
  SettingsMenuPageWrapper,
  SettingsMenuSvgIcon,
} from 'components/shared/settingsMenu';
import { useGetIceContactGroupsHealth } from 'apis/rest/iceContactGroups/hooks';
import LabsSettingsMenuContainer from 'components/shared/labs/SettingsMenuContainer';
import useFeature from 'hooks/features/useFeature';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useStaff } from 'hooks/session/useStaff';
import usePermissions from 'hooks/session/usePermissions';
import TracPlusIcon from 'components/shared/icons/tracplusIcon';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';

const DevicesIcon = () => (
  <SettingsMenuSvgIcon width="16" height="16" viewBox="0 0 16 16">
    <path d="M14.17,8.05H1.83C0.82,8.05,0,8.87,0,9.88l0,0c0,1.01,0.82,1.83,1.83,1.83h12.34c1.01,0,1.83-0.82,1.83-1.83l0,0C16,8.87,15.18,8.05,14.17,8.05z M2.58,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98S3.12,10.86,2.58,10.86z M5.02,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98S5.57,10.86,5.02,10.86zM10.98,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98C11.97,10.42,11.52,10.86,10.98,10.86zM13.42,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98S13.97,10.86,13.42,10.86z" />
    <path d="M1.83,7.78h12.34c0.3,0,0.57,0.07,0.82,0.17L11.82,4.8C11.49,4.47,11.06,4.3,10.6,4.3H5.41c-0.46,0-0.9,0.18-1.22,0.51L1,7.96C1.25,7.84,1.53,7.78,1.83,7.78z" />
  </SettingsMenuSvgIcon>
);

const ManagePage = () => {
  const t = useTranslations('pages.manage');
  const iceHealth = useGetIceContactGroupsHealth().query.data;
  const isStaff = useStaff();
  const permissions = usePermissions();

  const featureModules = useFeatureFlag('featureModules');

  const assetGroupsFeatureEnabled = useFeature('manage.assetGroups') || isStaff;
  const eventNotificationFeatureEnabled = useFeature('manage.notifications') || isStaff;
  const markersFeatureEnabled = useFeature('map.markers');

  const geofencesFeatureToggle = useFeature('manage.geofencing');
  const geofencesLabsEnabled = featureModules === false && !!geofencesFeatureToggle;

  const featureAssets = useFeatureAssets('manage.geofencing');
  const geofencesEnabled = !!featureModules && featureAssets.some;

  const showLocationsSection = !!featureModules;
  const showLabsSection = geofencesLabsEnabled || markersFeatureEnabled;

  const showIceHeathError = iceHealth?.defaultIceGroupExists === false || iceHealth?.defaultIceGroupIsHealthy === false;

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper>
        <SettingsMenuContainer title={t('sections.tracking')}>
          <SettingsMenuPanelGrid>
            <SettingsMenuPanelItem
              to="/manage/assets"
              icon={<Flight sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('assets.title')}
              description={t('assets.description')}
            />
            <SettingsMenuPanelItem
              to="/manage/devices"
              icon={<DevicesIcon />}
              title={t('devices.title')}
              description={t('devices.description')}
            />
            {permissions.canAdministerBeacon && (
              <SettingsMenuPanelItem
                to="/manage/beacon"
                icon={(
                  <Box sx={{ '& svg': { '& g': { '& circle, & path': { fill: 'currentColor' } } } }}>
                    <TracPlusIcon
                      overrideStyle={{
                        width: '1.8rem',
                        height: '1.8rem',
                        display: 'block',
                      }}
                    />
                  </Box>
                )}
                title={t('beacon.title')}
                description={t('beacon.description')}
              />
            )}
          </SettingsMenuPanelGrid>
        </SettingsMenuContainer>

        <SettingsMenuContainer title={t('sections.contacts')}>
          <SettingsMenuPanelGrid>
            <SettingsMenuPanelItem
              to="/manage/people"
              icon={<ContactPhone sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('people.title')}
              description={t('people.description')}
            />
            <SettingsMenuPanelItem
              to="/manage/ice/groups"
              icon={(
                <Badge variant="dot" color="error" invisible={!showIceHeathError}>
                  <Announcement sx={{ fontSize: '1.8rem', display: 'block' }} />
                </Badge>
              )}
              title={t('ice.title')}
              description={t('ice.description')}
            />
            <SettingsMenuPanelItem
              to="/manage/device-messaging/groups"
              icon={<Chat sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('messagingWhitelists.title')}
              description={t('messagingWhitelists.description')}
            />
            {eventNotificationFeatureEnabled && (
              <SettingsMenuPanelItem
                to="/manage/people-groups"
                icon={<GroupsIcon sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('peopleGroups.title')}
                description={t('peopleGroups.description')}
              />
            )}
          </SettingsMenuPanelGrid>
        </SettingsMenuContainer>
        {(assetGroupsFeatureEnabled || eventNotificationFeatureEnabled) && (
          <SettingsMenuContainer title={(t('sections.groups'))}>
            <SettingsMenuPanelGrid>
              {assetGroupsFeatureEnabled && (
                <SettingsMenuPanelItem
                  to="/manage/asset-groups"
                  icon={<FlightTakeoff sx={{ fontSize: '1.8rem', display: 'block' }} />}
                  title={t('assetGroups.title')}
                  description={t('assetGroups.description')}
                />
              )}
              {eventNotificationFeatureEnabled && (
                <SettingsMenuPanelItem
                  to="/manage/event-notifications"
                  icon={<NotificationsActive sx={{ fontSize: '1.8rem', display: 'block' }} />}
                  title={t('eventNotifications.title')}
                  description={t('eventNotifications.description')} />
              )}
            </SettingsMenuPanelGrid>
          </SettingsMenuContainer>
        )}
        {showLocationsSection && (
          <SettingsMenuContainer title={(t('sections.locations'))}>
            <SettingsMenuPanelGrid>
              <SettingsMenuPanelItem
                to="/manage/geofences"
                icon={<VpnLock sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('geofencing.title')}
                description={t('geofencing.description')}
                disabled={!geofencesEnabled}
              />
            </SettingsMenuPanelGrid>
          </SettingsMenuContainer>
        )}
        {showLabsSection && (
          <LabsSettingsMenuContainer description={t('sections.labsDescription')}>
            <SettingsMenuPanelGrid>
              {geofencesLabsEnabled && (
                <SettingsMenuPanelItem
                  to="/manage/geofences"
                  icon={<VpnLock sx={{ fontSize: '1.8rem', display: 'block' }} />}
                  title={t('geofencing.title')}
                  description={t('geofencing.description')}
                />
              )}
              {markersFeatureEnabled && (
                <SettingsMenuPanelItem
                  to="/manage/markers"
                  icon={<PinDrop sx={{ fontSize: '1.8rem', display: 'block' }} />}
                  title={t('markers.title')}
                  description={t('markers.description')}
                />
              )}
            </SettingsMenuPanelGrid>
          </LabsSettingsMenuContainer>
        )}
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default ManagePage;
