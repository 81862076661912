import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import { setAutoFreeze } from 'immer';
import { thunk } from 'redux-thunk';
import { getAuth } from 'firebase/auth';
// eslint-disable-next-line import/no-cycle
import createReducers from 'reducers';
import { configureStore } from '@reduxjs/toolkit';
import { loggedOut } from 'slices/session/session.slice';

const isProduction = process.env.NODE_ENV === 'production';

const userTiming = () => next => action => {
  if (performance.mark === undefined) return next(action);
  performance.mark(`${action.type}_start`);
  const result = next(action);
  performance.mark(`${action.type}_end`);
  performance.measure(
    `${action.type}`,
    `${action.type}_start`,
    `${action.type}_end`
  );
  return result;
};

const initialState = {};
const middleware = [thunk];

// Records Redux events in performance profiler:
if (!isProduction) {
  middleware.push(userTiming);
}

const rootReducer = createReducers();

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: defaultMiddleware => defaultMiddleware(
    {
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    },
  ).concat(middleware),
});

// If immer auto-freezes reducers, redux-persist fails (it attempts
// to add an '_persist' key to the returned state)
// https://github.com/rt2zz/redux-persist/issues/747
setAutoFreeze(false);

// Callback here is to synchronously, after store persisted (loaded whether user is logged in), then wait for auth to be initialised,
const persistor = persistStore(store, null, () => getAuth().onAuthStateChanged(user => {
  if (!user) store.dispatch(loggedOut());
}));

export { store, persistor };

window.store = store;
window.purge = () => persistor.purge();
