import React from 'react';
import {
  Stack,
  Box,
  Grid,
  Divider,
  Typography,
  IconButton
} from '@mui/material';
import { PlayArrow, Add, Remove, Stop, FlightTakeoff, FlightLand } from '@mui/icons-material';
import { useTranslations } from 'use-intl';

import clsx from 'clsx';
import moment from 'moment';
import DateTime from 'components/shared/datetime';
import TimeSpan from 'components/shared/timespan';

import useStyles from './leg-styles';
import LegInner from './legInner-view';

export interface ACBActivityLegProps {
  leg: Leg,
  reports: Report[],
  reportFilter: { blacklist: string[] },
  setTrailHighlight: (leg: TrailHighlight | null) => void,
  selectedLeg: Leg,
  selectLeg: (leg?: Leg | null) => void,
}

const LocationTypography = ({ name }: { name?: string }) => {
  if (!name) { return null; }
  // check aerodrome format
  const dashSplitName = name.split(' - ');

  // first element is entirely uppercase
  if (dashSplitName.length > 1 && dashSplitName.at(0)?.toUpperCase() === dashSplitName.at(0)) {
    return <Typography><strong>{dashSplitName[0]}</strong> - {dashSplitName[1]}</Typography>;
  }

  // check for region added on independently - put underneath
  const splitName = name.split(', ');
  if (splitName.length > 1) {
    return (
      <Stack>
        <Typography>{splitName.slice(0, -1).join(', ')}</Typography>
        <Typography>{splitName.at(-1)}</Typography>
      </Stack>
    );
  }
  return <Typography>{name}</Typography>;
};

const AssetContextBoxActivityLeg = ({
  leg,
  reports,
  reportFilter,
  setTrailHighlight,
  selectedLeg,
  selectLeg,
}: ACBActivityLegProps) => {
  const classes = useStyles();
  const t = useTranslations('contextbox.asset.latestActivity');
  const expanded = selectedLeg?.id === leg?.id;
  const tookoffDateTime = leg.takeoff ? moment.unix(leg.takeoff) : null;
  const landedDateTime = leg.landing ? moment.unix(leg.landing) : null;

  const startDateTime = tookoffDateTime ?? moment.unix(leg.start);
  const endDateTime = landedDateTime ?? moment.unix(leg.end);

  const doSetTrailHighlight = () => {
    if (reports.length < 1) {
      setTrailHighlight(null);
      return;
    }
    const { assetId } = reports[0];
    setTrailHighlight({
      assetId,
      reports
    });
  };

  const handleToggleLeg = () => {
    if (selectedLeg === leg) {
      selectLeg(null);
    } else {
      selectLeg(leg);
    }
  };

  return (
    <Box
      className={clsx(classes.legWrapper, { [classes.selectedLeg]: expanded })}
      onMouseEnter={doSetTrailHighlight}
      onMouseLeave={() => setTrailHighlight(null)}
      onClick={() => handleToggleLeg()}
    >
      { leg && (
        <>
          <Grid container spacing={0}>
            <Grid item xs={10} sm={10} className={classes.locationsData}>
              <Box display="grid" gridTemplateColumns="max-content 1fr" gap={1}>
                {leg.takeoff ? <FlightTakeoff fontSize="small" /> : <PlayArrow fontSize="small" />}
                <LocationTypography name={leg.from} />
                {leg.complete && (leg.landing ? <FlightLand fontSize="small" /> : <Stop fontSize="small" />)}
                {leg.to && !leg.complete && <span />}
                <LocationTypography name={leg.to} />
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} className={classes.openAnalysisButton}>
              { leg?.id && (
              <IconButton size="small">
                {expanded ? (
                  <Remove className={clsx(classes.icon, { [classes.activeIcon]: expanded })} />
                ) : (
                  <Add className={clsx(classes.icon, { [classes.activeIcon]: expanded })} />
                )}
              </IconButton>
              )}
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} className={classes.borderRight}>
              <Box className={classes.legDataWrapper}>
                <Typography className={classes.label}>{leg.takeoff ? t('takeoff') : t('departure')}</Typography>
                <Typography className={classes.data}>
                  <DateTime value={startDateTime} timeOnly tooltip />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.borderRight}>
              <Box className={classes.legDataWrapper}>
                <Typography className={classes.label}>{leg.landing ? t('landing') : t('arrival')}</Typography>
                <Typography className={classes.data}>
                  {leg.complete ? <DateTime value={endDateTime} timeOnly tooltip /> : <Typography>—</Typography>}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className={classes.legDataWrapper}>
                <Typography className={classes.label}>{t('elapsed')}</Typography>
                <Typography className={classes.data}>
                  <TimeSpan startDateTime={startDateTime} endDateTime={endDateTime} hideLargeZeros />
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </>
      )}
      <Box className={clsx(classes.legReportsWrapper, { [classes.legCollapsed]: !expanded })}>
        <LegInner reports={reports} reportFilter={reportFilter} />
      </Box>
    </Box>
  );
};

export default AssetContextBoxActivityLeg;
