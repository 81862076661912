import Smart from 'hocs/smart';
import AppView from './app-view';

export default Smart(
  AppView,
  state => ({
    selectedDay: state.app.selectedDay,
    loggedIn: state.session.loggedIn && !!state.session.user,
  }),
  ac => ({
    setFullscreen: ac.ui.setFullscreen
  })
);
