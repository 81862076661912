import React, { useEffect, useRef } from 'react';
import { Box, Theme } from '@mui/material';
import { useSize } from 'hooks/useSize';
import { scaleTime, select, Selection } from 'd3';
import { useTheme } from '@mui/material/styles';

interface AssetTimelineGraphProps {
  legsByAsset: Record<number, Leg[]>
  assets: AssetWithDevice[]
  height: number
  startTime: number
  setWidth: (w: number) => void
  onLegHover: (l?: Leg | null, e?: SVGSVGElement) => void
  onLegClick: (l: Leg) => void
  selectedLeg: Leg | null
  selectedReport: Report | null
}

export const MARGIN_TOP = 30;
const VERT_PADDING = 6;

class TimelineGraph {
  private scaleX = scaleTime();
  private root: Selection<SVGSVGElement, unknown, null, undefined>;
  public theme?: Theme;
  public startTime: number;
  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-empty-function
  public onLegHover: (l?: Leg, e?: SVGSVGElement) => void = () => {};
  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-empty-function
  public onLegClick: (l: Leg) => void = () => {};
  public selectedLeg: Leg | null = null;

  constructor(svg: SVGSVGElement, startTime: number) {
    this.root = select(svg);
    this.startTime = startTime;
  }

  draw(height: number, width: number, assets: AssetWithDevice[], legsByAsset: Record<number, Leg[]>, startTime: number) {
    const axisHeight = height;
    const assetHeight = axisHeight / assets.length;
    const assetIds = assets.map(a => a.id);
    const legsWithAssetIndex = Object.values(legsByAsset).flat().map(l => ({
      ...l,
      assetIndex: assetIds.indexOf(l.assetId),
      assetColor: assets.find(a => a.id === l.assetId)?.colour ?? '#000'
    }));

    this.startTime = startTime;

    this.root
      .attr('viewBox', [0, 0, width, height]);

    this.scaleX
      .domain([new Date(startTime), new Date(startTime + 86400 * 1000)])
      .range([0, width]);

    this.root.select('.legs')
      .selectAll<SVGRectElement, Leg>('.leg')
      .data(legsWithAssetIndex)
      .join('rect')
      .classed('leg', true)
      .attr('fill', d => d.assetColor)
      .attr('x', d => this.scaleX(d.start * 1000))
      .attr('y', d => d.assetIndex * assetHeight + VERT_PADDING / 2)
      .attr('height', assetHeight - VERT_PADDING)
      .attr('rx', 2)
      .attr('opacity', d => (d.id === this.selectedLeg?.id ? 0.6 : 1))
      .attr('width', d => Math.max(
        2, (
          d.end
            ? this.scaleX(new Date(d.end * 1000)) - this.scaleX(new Date(d.start * 1000))
            : this.scaleX(new Date()) - this.scaleX(new Date(d.start * 1000))
        )
      ))
      .on('mouseenter', (e, d) => {
        this.onLegHover(d, e.target);
        select(e.target).transition().attr('opacity', 0.6);
      })
      .on('mouseleave', (e, d) => {
        this.onLegHover(undefined, e.target);
        if (d.id !== this.selectedLeg?.id) {
          select(e.target).transition().attr('opacity', 1);
        }
      })
      .on('click', (e, d) => {
        this.onLegClick(d);
        this.onLegHover(undefined, e.target);
      })
      .style('cursor', 'pointer');

    this.root.selectAll('.vertLines')
      .attr('y1', 0)
      .attr('y2', height);

    this.root.select('#invisibleZone')
      .attr('height', axisHeight);
    this.updateAxis(height, width, assetIds);
    this.updateNowLine();
  }

  public updateAxis(height: number, width: number, assetIds: number[]) {
    this.root.select('.grids').selectAll('.x-ticks').remove();
    this.root.select('.grids').selectAll('.y-ticks').remove();
    this.root.select('.grids').selectAll('.hour-areas').remove();

    this.root.select('.grids').append('g')
      .attr('class', 'hour-areas')
      .selectAll<SVGRectElement, number>('.hour-area')
      .data([0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22])
      .join('rect')
      .classed('hour-area', true)
      .attr('x', d => this.scaleX(this.startTime + d * 60 * 1000 * 60))
      .attr('width', d => this.scaleX(this.startTime + (d + 1) * 60 * 1000 * 60) - this.scaleX(this.startTime + d * 60 * 1000 * 60))
      .attr('y', 0)
      .attr('height', height)
      .attr('fill', this.theme ? this.theme.palette.common.midGrey : 'rgba(0,0,0,0.1)')
      .attr('opacity', 0.3);

    this.root.select('.grids').append('g')
      .attr('class', 'x-ticks')
      .selectAll<SVGRectElement, number>('.x-tick')
      .data([0, 3, 6, 9, 12, 15, 18, 21, 24])
      .join('line')
      .classed('y-tick', true)
      .attr('x1', d => this.scaleX(this.startTime + d * 60 * 1000 * 60))
      .attr('x2', d => this.scaleX(this.startTime + d * 60 * 1000 * 60))
      .attr('y1', 0)
      .attr('y2', height)
      .attr('stroke-width', 1)
      .attr('stroke', this.theme ? this.theme.palette.common.midGrey : 'rgba(0,0,0,0.1)');

    this.root.select('.grids').append('g')
      .attr('class', 'y-ticks')
      .selectAll<SVGRectElement, number>('.y-tick')
      .data(assetIds.map((_, i) => Math.round(i * (height / assetIds.length)) - 0.5))
      .join('line')
      .classed('y-tick', true)
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', d => d)
      .attr('y2', d => d)
      .attr('stroke-width', 1)
      .attr('stroke', this.theme ? this.theme.palette.common.midGrey : 'rgba(0,0,0,0.1)');
  }

  public updateNowLine() {
    const now = new Date();
    if (now > new Date(this.startTime + 86400 * 1000)) {
      this.root.select('#nowLine')
        .attr('visibility', 'hidden');
      this.root.select('#invisibleZone')
        .attr('visibility', 'hidden');
      return;
    }

    this.root.select('#nowLine')
      .attr('visibility', 'visible')
      .attr('x1', this.scaleX(now))
      .attr('x2', this.scaleX(now));

    this.root.select('#invisibleZone')
      .attr('visibility', 'visible')
      .attr('x', this.scaleX(now))
      .attr('width', this.scaleX(new Date(this.startTime + 86400 * 1000)) - this.scaleX(now))
      .attr('y', 0);
  }

  public updateReportLine(reportTime?: number) {
    if (!reportTime) {
      this.root.select('#reportLine')
        .attr('visibility', 'hidden');
      return;
    }
    this.root.select('#reportLine')
      .attr('visibility', 'visible')
      .attr('x1', this.scaleX(new Date(reportTime * 1000)))
      .attr('x2', this.scaleX(new Date(reportTime * 1000)));
  }
}

export const AssetTimelineGraph = ({
  legsByAsset,
  assets,
  height,
  startTime,
  setWidth,
  onLegHover,
  onLegClick,
  selectedLeg,
  selectedReport
}: AssetTimelineGraphProps) => {
  const d3container = useRef<HTMLDivElement>(null);
  const d3root = useRef<SVGSVGElement>(null);
  const chart = useRef<TimelineGraph>();
  const size = useSize(d3container);
  const theme = useTheme();

  useEffect(() => {
    if (!d3root.current) return;
    chart.current = new TimelineGraph(
      d3root.current,
      startTime
    );
  }, [startTime]);

  useEffect(() => {
    if (chart.current) {
      chart.current?.updateNowLine();
    }

    const h = setInterval(() => {
      if (chart.current) {
        chart.current?.updateNowLine();
      }
    }, 10000);

    // clean interval upon dismount
    return () => { clearInterval(h); };
  }, [startTime]);

  useEffect(() => {
    if (chart.current) {
      chart.current?.updateReportLine(selectedReport?.received);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (!chart.current) {
      return;
    }

    chart.current.theme = theme;
    chart.current.onLegHover = onLegHover;
    chart.current.onLegClick = onLegClick;
    chart.current.selectedLeg = selectedLeg;

    chart.current.draw(
      size.height,
      size.width,
      assets,
      legsByAsset,
      startTime
    );

    // return () => {
    //   chart.current?.reset();
    // };
  }, [size, startTime, legsByAsset, assets, theme, onLegHover, onLegClick, selectedLeg]);

  useEffect(() => {
    setWidth(size.width);
  }, [setWidth, size.width]);

  return (
    <Box sx={{ height, width: '100%', overflow: 'hidden' }} ref={d3container}>
      <svg
        style={{ height, width: '100%' }}
        ref={d3root}
        preserveAspectRatio="none"
        overflow="visible"
      >
        <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
          <path d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2" stroke={theme.palette.common.black} strokeWidth={1} />
        </pattern>
        <g className="grids" />
        <g className="legs" />
        <line className="vertLines" id="nowLine" stroke={theme.palette.common.black} />
        <line className="vertLines" id="reportLine" stroke={theme.palette.common.black} opacity={0.5} />
        <rect id="invisibleZone" fill="url(#diagonalHatch)" opacity={0.3} />
      </svg>
    </Box>
  );
};
