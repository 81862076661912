import { loggedOut, resetEverything } from "slices/session/session.slice";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case loggedOut.type:
    case resetEverything.type: return initialState;

    case 'FETCH_INCIDENT_SUCCESS': {
      const {
        assetId,
        incident
      } = action.payload;

      return {
        ...state,
        [assetId]: incident
      };
    }

    case 'FETCH_INCIDENT_FAILURE': {
      return { ...state, error: action.payload };
    }

    default:
      return state;
  }
};
