import { useEffect, useRef } from 'react';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import { ReportsDataRepository } from 'repositories/reports';

const calcBounds = (reports: Pick<Report, 'longitude' | 'latitude'>[] | undefined) => {
  if (!reports || reports.length === 0) return null;
  const coords = reports.map<[number, number]>(r => [r.longitude, r.latitude]);
  return coords.reduce(
    (b, coord) => b.extend(coord),
    new mapboxgl.LngLatBounds(coords[0], coords[0])
  );
};

type LatestReport = Pick<Report, 'isValid' | 'assetId' | 'latitude' | 'longitude'>;

export interface MoveMapOptions {
  setView: (bounds: LngLatBounds | null, report: LatestReport | undefined) => void
  reportsRepository: ReportsDataRepository
  selectedAssetId?: number
  selectedLeg?: Leg
  latestReport?: LatestReport
}

const useMoveMapToSelection = ({ setView, selectedAssetId, selectedLeg, latestReport, reportsRepository }: MoveMapOptions) => {
  const selectedAssetIdRef = useRef<number>();
  const selectedLegRef = useRef<Leg>();

  useEffect(() => {
    const previousSelectedItemId = selectedAssetIdRef.current;
    const previousSelectedLeg = selectedLegRef.current;
    selectedAssetIdRef.current = selectedAssetId;
    if (selectedAssetId === undefined && selectedLeg?.assetId === undefined) return;

    selectedLegRef.current = selectedLeg;

    if (selectedAssetId === previousSelectedItemId && (!selectedLeg || selectedLeg === previousSelectedLeg)) return;

    // this ensures that the zoom and pan are retained when the user changes between map splits with different assets
    // selected so zoom to bounds should only happen when the user selects a new asset on this view
    const bounds = calcBounds(reportsRepository.getReportsForAsset(selectedLeg?.assetId ?? selectedAssetId, true, selectedLeg));
    setView(bounds, latestReport);

    // We don't want run effect on change of setView or latestReport
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId, selectedLeg, reportsRepository]);
};

export default useMoveMapToSelection;
