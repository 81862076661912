import React, { useMemo, ComponentProps, useEffect, useCallback, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';
import {
  Box,
  Button,
  FormControlLabel,
  Grow,
  Skeleton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Column, MTableToolbar } from '@material-table/core';
import { Edit } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link, useNavigate } from 'react-router-dom';
import insensitiveSort from 'utils/insensitiveSort';
import tableIcons from 'components/shared/icons/tableIcons';
import PersistentTable from 'components/shared/persistentTable';
import { NoHeaderNoFooterLoadingPage } from 'components/pages/loading';
import { SUGGESTED_ASSET_COLORS } from 'constants/colors';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetIceContactGroups } from 'apis/rest/iceContactGroups/hooks';
import { useGetMessagingWhitelistContactGroups } from 'apis/rest/messagingWhitelistContactGroups/hooks';
import AssetColourMarker from 'components/shared/assetColourMarker';
import StickyPagination from 'components/shared/stickyPagination';
import SelectSerialType from 'components/shared/selectSerialType';
import TableColumnsPicker from 'components/shared/tableColumnsPicker';
import usePermissions from 'hooks/session/usePermissions';
import { Settings } from 'reducers/settings';
import { updateSetting } from 'actions/settings';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import useStyles from './assetsList-styles';

interface AssetListPageParams {
  navigate: (url: string) => void,
  organisationId: string,
  serialType: 'tpSerial' | 'imei' | 'manufacturerSerial',
  displaySnackbar: (Snack: Snack) => void,
}
interface AssetListAsset {
  owner: string;
  makeModelVariant: string;
  ownerId: string;
  ownedByUser: boolean;
  manufacturerSerial: string;
  colour: string;
  name: string;
  imei: string;
  tpSerial: string;
  id: number;
  category: string;
  device: string;
  watchlistGroup: string | null;
  messagingHandle: string | null;
  callSign: string | null;
  tailNumber: string | null;
  iceContactGroup: string | undefined;
  messagingWhitelist: string | undefined;
  archived: boolean;
}

const Toolbar = (props: ComponentProps<typeof MTableToolbar>): JSX.Element => {
  const classes = useStyles();
  const t = useTranslations('pages.assets');
  const permissions = usePermissions();
  const tableSettings = useSelector<ReduxState, Settings['assetsTable']>(state => state.settings.assetsTable);
  const dispatch = useDispatch();
  const setShowArchived = (value: boolean) => dispatch(updateSetting('assetsTable', 'showArchived', value));

  const columns = [
    { id: 'name', label: t('assetsTable.name'), always: true },
    { id: 'tailNumber', label: t('assetsTable.tailNumber') },
    { id: 'callSign', label: t('assetsTable.callSign') },
    { id: 'category', label: t('assetsTable.category') },
    { id: 'makeModelVariant', label: t('assetsTable.makeModelVariant') },
    { id: 'device', label: t('assetsTable.installation') },
    { id: 'deviceSerialNumber', label: t('assetsTable.deviceSerialNumber') },
    { id: 'messagingHandle', label: t('assetsTable.messagingHandle') },
    { id: 'watchlistGroup', label: t('assetsTable.watchlistGroup') },
    { id: 'owner', label: t('assetsTable.owner') },
    { id: 'iceContactGroup', label: t('assetsTable.iceContactGroup') },
    { id: 'messagingWhitelist', label: t('assetsTable.messagingWhitelist') },
  ];

  return (
    <Stack direction="row" justifyContent="space-between" m={3} height="4em">
      <Stack direction="row" spacing={3}>
        <TableColumnsPicker settingsCategory="assetsTable" columns={columns} />
        <Grow in={tableSettings.columns?.deviceSerialNumber} unmountOnExit>
          <Box>
            <SelectSerialType InputProps={{ sx: { width: '22ch' } }} />
          </Box>
        </Grow>
        <FormControlLabel
          control={<Switch checked={tableSettings.showArchived} onChange={event => setShowArchived(event.target.checked)} />}
          label={t('showArchived')}
          labelPlacement="start"
          sx={theme => ({ margin: theme.spacing(0, 1, 0, 0) })}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        <MTableToolbar {...props} className={classes.toolbar} />
        {permissions.canEditAssets && (
          <Button component={Link} to="/manage/assets/new" size="large" variant="contained">
            {t('addNew')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

interface ContactGroupsSelection {
  defaultGroup?: ContactGroup
  groupsByAssetId: Record<number, ContactGroup>
}

const ContactGroupCell = ({ isLoading, defaultGroup, children }: { isLoading: boolean, defaultGroup: ContactGroup | undefined, children: ReactNode }): JSX.Element => {
  const t = useTranslations('pages.assets.assetsTable');
  if (isLoading) return <Skeleton width="10ch" />;
  if (children) return <Typography>{children}</Typography>;
  if (defaultGroup) {
    return (
      <Tooltip title={t('defaultGroupTooltip', { name: defaultGroup.name })}>
        <Typography variant="body3" fontStyle="italic">{t('noGroupAssignedWithDefault')}</Typography>
      </Tooltip>
    );
  }
  return <Typography variant="body3" fontStyle="italic" color="error">{t('noGroupAssigned')}</Typography>;
};

const AssetListPage = ({
  organisationId,
  displaySnackbar,
}: AssetListPageParams): JSX.Element => {
  const classes = useStyles();
  const t = useTranslations('pages.assets');
  const { serialType } = useUiSettings();
  const tableSettings = useSelector<ReduxState, Settings['assetsTable']>(state => state.settings.assetsTable);
  const getAssets = useGetAssetsList({
    select: useCallback((data: AssetBasic[]) => data.filter(a => tableSettings.showArchived || !a.archived), [tableSettings.showArchived]),
  }).query;
  const permissions = usePermissions();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectContactGroups = useCallback((groups: ContactGroup[]) => groups.reduce<ContactGroupsSelection>((acc, group) => {
    if (group.isDefault) acc.defaultGroup = group;
    group.deviceAndAssetIds.forEach(item => {
      acc.groupsByAssetId[item.assetId] = group;
    });
    return acc;
  }, { groupsByAssetId: {} }), []);

  const iceContactGroupsQuery = useGetIceContactGroups<ContactGroupsSelection>({
    enabled: tableSettings.columns?.iceContactGroup,
    select: selectContactGroups,
  }).query;
  const messagingWhitelistsQuery = useGetMessagingWhitelistContactGroups<ContactGroupsSelection>({
    enabled: tableSettings.columns?.messagingWhitelist,
    select: selectContactGroups,
  }).query;

  const columns = useMemo<Column<AssetListAsset>[]>(() => ([
    {
      title: t('assetsTable.name'),
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a, b) => insensitiveSort(a.name, b.name),
      render: asset => (
        <Stack direction="row" spacing={1} alignItems="center">
          <AssetColourMarker assetId={asset.id} />
          <Typography fontStyle={asset.archived ? 'italic' : 'normal'}>{asset.name}</Typography>
        </Stack>
      )
    },
    {
      title: t('assetsTable.tailNumber'),
      field: 'tailNumber',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.tailNumber, b.tailNumber),
      hidden: !tableSettings.columns?.tailNumber,
    },
    {
      title: t('assetsTable.callSign'),
      field: 'callSign',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.callSign, b.callSign),
      hidden: !tableSettings.columns?.callSign,
    },
    {
      title: t('assetsTable.category'),
      field: 'category',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.category, b.category),
      hidden: !tableSettings.columns?.category,
    },
    {
      title: t('assetsTable.makeModelVariant'),
      field: 'makeModelVariant',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.makeModelVariant, b.makeModelVariant),
      hidden: !tableSettings.columns?.makeModelVariant,
    },
    {
      title: t('assetsTable.installation'),
      field: 'device',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a, b) => insensitiveSort(a.device, b.device),
      hidden: !tableSettings.columns?.device,
    },
    {
      title: t(`assetsTable.${serialType}`),
      field: serialType,
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a, b) => insensitiveSort(a[serialType]?.toString(), b[serialType]?.toString()),
      hidden: !tableSettings.columns?.deviceSerialNumber,
    },
    {
      title: t('assetsTable.messagingHandle'),
      field: 'messagingHandle',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.messagingHandle, b.messagingHandle),
      render: a => (a.messagingHandle ? `${a.messagingHandle}@msg.tracplus.com` : null),
      hidden: !tableSettings.columns?.messagingHandle,
    },
    {
      title: t('assetsTable.watchlistGroup'),
      field: 'watchlistGroup',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.watchlistGroup, b.watchlistGroup),
      hidden: !tableSettings.columns?.watchlistGroup,
    },
    {
      title: t('assetsTable.owner'),
      field: 'owner',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.owner, b.owner),
      hidden: !tableSettings.columns?.owner,
    },
    {
      title: t('assetsTable.iceContactGroup'),
      field: 'iceContactGroup',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.iceContactGroup, b.iceContactGroup),
      render: a => (
        <ContactGroupCell
          isLoading={iceContactGroupsQuery.isLoading}
          defaultGroup={iceContactGroupsQuery.data?.defaultGroup}
        >
          {a.iceContactGroup}
        </ContactGroupCell>
      ),
      hidden: !tableSettings.columns?.iceContactGroup,
    },
    {
      title: t('assetsTable.messagingWhitelist'),
      field: 'messagingWhitelist',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.messagingWhitelist, b.messagingWhitelist),
      render: a => (
        <ContactGroupCell
          isLoading={messagingWhitelistsQuery.isLoading}
          defaultGroup={messagingWhitelistsQuery.data?.defaultGroup}
        >
          {a.messagingWhitelist}
        </ContactGroupCell>
      ),
      hidden: !tableSettings.columns?.messagingWhitelist,
    },
  ]), [serialType, t, tableSettings.columns, iceContactGroupsQuery, messagingWhitelistsQuery]);

  useEffect(() => {
    if (getAssets.isError) {
      displaySnackbar({
        id: 'assetLoadError',
        text: t('loadAssetsListFailed'),
        type: 'error',
      });
    }
  }, [getAssets.isError, displaySnackbar, t]);

  const cleanedAssets: AssetListAsset[] = getAssets.data ? getAssets.data.filter(a => a.category !== 'System').map(a => {
    const makeModel = [a.deviceMake, a.deviceModel].filter(Boolean).join(', ');
    return {
      name: a.name,
      category: a.category,
      makeModelVariant: [a.make, a.model, a.variant].filter(Boolean).join(' '),
      device: makeModel,
      owner: a.ownerName,
      ownerId: a.ownerId,
      ownedByUser: a.ownerId.toLowerCase() === organisationId.toLowerCase(),
      id: a.id,
      imei: a.deviceImei ?? '-',
      tpSerial: a.deviceTracPlusSerial ?? '-',
      manufacturerSerial: a.deviceManufacturerSerial ?? '-',
      watchlistGroup: a.watchlistGroup,
      messagingHandle: a.messagingHandle,
      callSign: a.callSign,
      tailNumber: a.tailNumber,
      iceContactGroup: iceContactGroupsQuery.data?.groupsByAssetId[a.id]?.name,
      messagingWhitelist: messagingWhitelistsQuery.data?.groupsByAssetId[a.id]?.name,
      colour: a.colour ?? SUGGESTED_ASSET_COLORS[a.id % 16],
      archived: a.archived
    };
  }) : [];

  const editAsset = (rowData?: AssetListAsset): void => {
    if (!rowData) { return; }
    const link = `/manage/assets/${rowData.id}`;
    navigate(link);
  };

  const footerRef = React.useRef<HTMLElement>();
  const Pagination = React.useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);

  if (getAssets.isLoading) return <NoHeaderNoFooterLoadingPage />;

  const resetFilters = () => dispatch(updateSetting('assetsTable', 'filters', []));

  return (
    <Box
      className={classes.materialTable}
      bgcolor="common.white"
      borderRadius={1}
      border={1}
      borderColor="common.midGrey"
      mb={8}
      position="relative"
    >
      <Button variant="outlined" size="large" sx={{ minWidth: '10rem' }} className={classes.resetFiltersButton} onClick={() => resetFilters()} disabled={tableSettings.filters?.length === 0}>
        Reset Filters
      </Button>
      <PersistentTable
        settingsCategory="assetsTable"
        title={t('title')}
        icons={tableIcons}
        onRowClick={(e, rowData) => editAsset(rowData)}
        columns={columns}
        data={cleanedAssets}
        actions={[
          (rowData: AssetListAsset) => ({
            icon: () => (rowData?.ownedByUser && permissions.canEditAssets ? (<Edit className={classes.actionButton} />) : (<InfoOutlinedIcon className={classes.actionButton} />)),
            tooltip: rowData?.ownedByUser && permissions.canEditAssets ? t('assetsTable.editAsset') : t('assetsTable.viewAsset'),
            onClick: (e, row) => { if (!Array.isArray(row)) editAsset(row); },
          }),
        ]}
        options={{
          filtering: true,
          draggable: false,
          showTitle: false,
          search: true,
          actionsColumnIndex: -1,
          searchFieldVariant: 'outlined',
          paging: true,
          emptyRowsWhenPaging: false,
          thirdSortClick: false,
          headerStyle: { position: 'sticky', top: 0 },
        }}
        localization={{
          header: {
            actions: '',
          },
          toolbar: {
            searchPlaceholder: t('assetsTable.search'),
          },
        }}
        components={{
          Container: Box,
          Pagination,
          Toolbar,
        }}
      />
      <Box ref={footerRef} position="sticky" bottom={0} />
    </Box>
  );
};

export default AssetListPage;
