import React from 'react';
import { useOutletContext } from 'react-router';
import DropsByAssetScorecard from './dropsByAssetScorecard';
import { OutletContextValue } from './layout';

const FirefightingSummary = (): JSX.Element | null => {
  const {
    visibleSuppressants,
    drops,
    trips,
    assetIds,
  } = useOutletContext<OutletContextValue>();

  if (!assetIds.length) return null;

  return (
    <DropsByAssetScorecard
      assetIds={assetIds}
      drops={drops}
      trips={trips}
      sx={{ gridColumn: '1/-1' }}
      visibleSuppressants={visibleSuppressants}
    />
  );
};

export default FirefightingSummary;
