import React, { useCallback, useEffect, useState } from 'react';
import Page from 'components/pages/page';
import { useNavigate } from 'react-router';
import { useTranslations } from 'use-intl';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import { Box, Container, Typography } from '@mui/material';
import LinkAbove from 'components/shared/linkAbove';
import DetailPanel from 'components/shared/DetailPanel';
import { useCreateAssetGroup, useDeleteAssetGroup, useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import useFeature from 'hooks/features/useFeature';
import usePermissions from 'hooks/session/usePermissions';
import useSnackbar from 'hooks/useSnackbar';
import { DeleteDialog } from 'components/shared/deleteDialog';
import PageStaffAccessAlert from 'components/shared/pageStaffAccessAlert';
import useValidateName from 'hooks/assetGroup/useValidateName';
import { AssetGroup } from 'apis/rest/assetGroups/types';
import { AssetGroupTable } from '../components/assetGroupTable';
import { CreateButton } from '../components/createButton';
import { AddAssetGroupDialog } from '../components/createButton/addAssetGroup-dialog';

const AssetGroupView = (): JSX.Element => {
  const t = useTranslations('pages.manage.assetGroups');
  const assetGroups = useGetAssetGroupsForOrganisation();
  const deleteMutation = useDeleteAssetGroup();
  const createMutation = useCreateAssetGroup();
  const permissions = usePermissions();
  const [addGroup, setAddGroup] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState<number | null>(null);
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const validateName = useValidateName(assetGroups);
  const featureEnabled = useFeature('manage.assetGroups');

  const handleSuccess = (group: AssetGroup) => {
    setAddGroup(false);
    snackbar.display({
      id: `assetGroupCreated.${group}`,
      text: t('snackbar.success', { name: group.name }),
      type: 'success',
    });
  };

  const handleDeleteClick = (assetGroupId: number) => {
    setDeleteGroup(assetGroupId);
  };

  const handleDeleteConfirm = useCallback(() => {
    if (deleteGroup) {
      deleteMutation.mutate({ id: deleteGroup });
    }
  }, [deleteGroup, deleteMutation]);

  const handleDeleteCancel = () => setDeleteGroup(null);

  const getAssetGroupName = (assetGroupId: number | null): string => {
    if (assetGroupId === undefined) return '';
    const group = assetGroups.data?.find(({ id }) => id === assetGroupId);
    return group?.name ?? '';
  };

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      setDeleteGroup(null);
    }
  }, [deleteMutation.isSuccess]);

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Box mb={3}>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>{t('title')}</Typography>
            <Typography paragraph>{t('description')}</Typography>
            {featureEnabled === false && <PageStaffAccessAlert />}
          </Box>
          <DetailPanel pb={0} mb={8}>
            {permissions.canEditAssetGroups && (
              <CreateButton
                onClick={() => setAddGroup(true)}
                buttonText={t('createGroup.createButtonText')}
              />
            )}
            <AddAssetGroupDialog
              ariaLabel="testing"
              cancel={() => setAddGroup(false)}
              created={handleSuccess}
              mutation={createMutation}
              open={addGroup}
              title={t('createGroup.createDialogTitle')}
              validateName={name => validateName(createMutation.data?.id, name)}
            />
            <AssetGroupTable
              onRowDelete={handleDeleteClick}
              readOnly={!permissions.canEditAssetGroups}
              data={assetGroups.data}
              navigateToAssetGroup={assetGroupId => navigate(`/manage/asset-groups/${assetGroupId}`)}
              isLoading={assetGroups.isLoading}
            />

          </DetailPanel>
        </Container>
      </SettingsMenuPageWrapper>

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        snackText={t('deleteGroup.deleteSnackText', { groupName: getAssetGroupName(deleteGroup) })}
        open={!!deleteGroup}
        title={t('deleteGroup.deleteDialogTitle')}
        confirmText={deleteMutation.isLoading ? t('deleteGroup.deletingButtonText') : t('deleteGroup.deleteButtonText')}
      >
        {t('deleteGroup.deleteDialogText', { groupName: getAssetGroupName(deleteGroup) })}
      </DeleteDialog>
    </Page>
  );
};

export default AssetGroupView;
