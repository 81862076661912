import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { serenityApiClient } from 'apis/client/serenityApiClient';
import { beaconQueryKeys } from './queryKeys';
import { BeaconUser, UpdateAssignedSeatsParams } from './types';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;
type MutationOptions<MutationData, Variables> = Omit<UseMutationOptions<MutationData, HttpResponseError, Variables, void>, 'queryKey' | 'queryFn'>;

export const useGetNumberOfSeats = <T = number>(options?: Options<number, T>) => {
  const orgId = useOrganisationId();
  const queryKey = beaconQueryKeys.numSeats(orgId);
  return useQuery<number, HttpResponseError, T>(
    queryKey,
    async () => serenityApiClient.organisationsBeaconSeatsGet(orgId),
    options,
  );
};

export const useGetAssignedSeats = <T = BeaconUser[]>(options?: Options<BeaconUser[], T>) => {
  const orgId = useOrganisationId();
  const queryKey = beaconQueryKeys.assignedSeats(orgId);
  return useQuery<BeaconUser[], HttpResponseError, T>(
    queryKey,
    // @ts-ignore
    async () => serenityApiClient.organisationsBeaconSeatsAssignedGet(orgId),
    options,
  );
};

export const useUpdateNumberOfSeats = (options?: MutationOptions<void, number>) => {
  const orgId = useOrganisationId();
  return useMutation<void, HttpResponseError, number, void>(
    async numSeats => {
      await serenityApiClient.organisationsBeaconSeatsPut(orgId, numSeats);
    },
    options,
  );
};

export const useUpdateAssignedSeats = (options?: MutationOptions<void, UpdateAssignedSeatsParams>) => {
  const orgId = useOrganisationId();
  return useMutation<void, HttpResponseError, UpdateAssignedSeatsParams, void>(
    async ({
      toAdd,
      toDelete,
    }) => {
      await serenityApiClient.organisationsBeaconSeatsAssignedDelete(orgId, toDelete);
      await serenityApiClient.organisationsBeaconSeatsAssignedPost(orgId, toAdd);
    },
    options,
  );
};
