import useOrganisationId from 'hooks/session/useOrganisationId';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import { membershipQueryKeys } from './queryKeys';
import {
  getAllOrganisations,
  getMembership,
  getMemberships,
  joinOrganisationOrUpdateRole,
  leaveOrganisation
} from './requests';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetAllOrganisations = () => {
  const queryKey = membershipQueryKeys.allOrganisations();
  const query = useQuery<Organisation[], HttpResponseError>(
    queryKey,
    () => getAllOrganisations(),
  );
  return { query, queryKey };
};

export const useGetMemberships = (options?: Options<Membership[], Membership[]>) => {
  const organisationId = useOrganisationId();
  const queryKey = membershipQueryKeys.list(organisationId);
  const query = useQuery<Membership[], HttpResponseError>(
    queryKey,
    () => getMemberships(organisationId),
    options,
  );
  return { query, queryKey };
};

export const useGetMembership = (userId: string) => {
  const organisationId = useOrganisationId();
  const queryKey = membershipQueryKeys.detail(organisationId, userId);
  const query = useQuery<Membership, HttpResponseError>(
    queryKey,
    () => getMembership(organisationId, userId),
  );
  return { query, queryKey };
};

export const useLeaveOrganisation = () => {
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<Membership, 'userId' | 'organisationId'>, { previousMemberships?: Membership[] }>(
    value => leaveOrganisation(value.organisationId, value.userId),
    {
      onMutate: async value => {
        const listsQueryKey = membershipQueryKeys.list(value.organisationId);
        await queryClient.cancelQueries(listsQueryKey);
        const previousMemberships = queryClient.getQueryData<Membership[]>(listsQueryKey);
        if (previousMemberships?.some(g => g.userId === value.userId)) {
          queryClient.setQueryData<Membership[]>(listsQueryKey, () => previousMemberships.filter(g => (
            g.userId !== value.userId
          )));
        }

        return { previousMemberships };
      },
      onError: (err, value, context) => {
        if (context?.previousMemberships) {
          queryClient.setQueryData(membershipQueryKeys.list(value.organisationId), context.previousMemberships);
        }
      },
      onSettled: (data, error, variables) => Promise.all([
        queryClient.invalidateQueries(membershipQueryKeys.all(variables.organisationId)),
      ]),
    },
  );
};

export const useJoinOrganisationOrUpdateRole = () => {
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<Membership, 'userId' | 'role' | 'organisationId'>, { previousMemberships?: Membership[] }>(
    value => joinOrganisationOrUpdateRole(value.organisationId, value.userId, value.role),
    {
      onMutate: async value => {
        const listsQueryKey = membershipQueryKeys.list(value.organisationId);
        await queryClient.cancelQueries(listsQueryKey);
        const previousMemberships = queryClient.getQueryData<Membership[]>(listsQueryKey);
        if (previousMemberships?.some(g => g.userId === value.userId)) {
          queryClient.setQueryData<Membership[]>(listsQueryKey, () => previousMemberships.map(g => (
            g.userId === value.userId ? { ...g, ...value } : g
          )));
        } else if (previousMemberships) {
          queryClient.setQueryData<Membership[]>(listsQueryKey, [
            ...previousMemberships,
            { ...value, email: '', name: '', organisationId: value.organisationId, organisationName: '', permissions: [], isStaff: false },
          ]);
        }

        return { previousMemberships };
      },
      onError: (err, value, context) => {
        if (context?.previousMemberships) {
          queryClient.setQueryData(membershipQueryKeys.list(value.organisationId), context.previousMemberships);
        }
      },
      onSettled: (data, error, variables) => Promise.all([
        queryClient.invalidateQueries(membershipQueryKeys.all(variables.organisationId)),
      ]),
    },
  );
};
