import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { destroySnackbar, displaySnackbar } from 'actions/app';

export interface SnackbarSettings {
  id: string
  text: string
  type: 'info' | 'error' | 'warning' | 'success'
  toDelete?: boolean
  persist?: boolean
}

const useSnackbar = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    display: (settings: SnackbarSettings) => displaySnackbar(settings)(dispatch),
    destroy: (id: string) => dispatch(destroySnackbar(id)),
  }), [dispatch]);
};

export default useSnackbar;
