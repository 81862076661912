import Smart from 'hocs/smart';
import AssetContextBox from './assetContextBox-view';

export default Smart(
  AssetContextBox,
  state => ({
    organisationId: state.session.organisationId,
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // assetState: state.assetState[ownProps.selectedAsset?.id],
  }),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {}
);
