import React from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import { useTranslations } from 'use-intl';
import { ValidateNameResult } from 'helpers/validation/validateName';

interface BasicDetailsPanelProps {
  group: ContactGroup
  mutation: UseMutationResult<void, Error, Pick<ContactGroup, 'id' | 'name' | 'isDefault'>>
  validateName: (groupId: number | undefined, name: string | undefined) => ValidateNameResult | undefined
}

export const BasicDetails = ({ group, mutation, validateName }: BasicDetailsPanelProps) => {
  const t = useTranslations('pages.manage.contactGroups.common.contactGroupEdit.basicDetails');
  const [name, setName] = React.useState<string>();
  const nameValidation = validateName(group.id, name);
  const canSave = nameValidation && nameValidation.valid && nameValidation.changed;

  const onCancel = () => {
    setName(undefined);
  };

  const onSave = () => {
    if (!group || !name) return;
    mutation.mutate({ ...group, name });
  };

  return (
    <Stack spacing={3} px={3}>
      <Stack spacing={3} direction="row" height="4em">
        <TextField
          label={t('name')}
          value={name ?? group.name}
          onChange={event => setName(event.target.value)}
          error={nameValidation?.valid === false}
          helperText={nameValidation?.helperText && t(`validation.${nameValidation.helperText}`)}
          fullWidth
        />
      </Stack>
      <Stack spacing={3} direction="row" justifyContent="flex-end" height="4em">
        <Button
          size="large"
          variant="outlined"
          sx={{ minWidth: '10rem' }}
          disabled={!nameValidation?.changed}
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          size="large"
          variant="contained"
          sx={{ minWidth: '10rem' }}
          disabled={!canSave}
          onClick={onSave}
        >
          {t(mutation.isLoading ? 'saving' : 'save')}
        </Button>
      </Stack>
    </Stack>
  );
};
