import React from 'react';
import {
  Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select
} from '@mui/material';
import useStyle from './multiSelectFilter-styles';

interface MultiSelectFilterProps {
  options: any[];
  selectedOptions: any[];
  setSelectedOptions: (option: any) => void;
  label: string;
  summaryText: string;
}

const MultiSelectFilter = ({
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  summaryText
}: MultiSelectFilterProps): JSX.Element => {
  const classes = useStyle();
  const uniqueId = `${label.replace(/\\s+/g, '')}-multiSelectFilterLabel`;

  const handleChange = (option: any): void => {
    setSelectedOptions(option.target.value);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor={uniqueId}>{label}</InputLabel>
      <Select
        variant="outlined"
        className={classes.select}
        MenuProps={{ variant: 'menu' }}
        labelId={uniqueId}
        id={uniqueId}
        multiple
        value={selectedOptions}
        onChange={handleChange}
        label={label}
        renderValue={() => `${summaryText} (${selectedOptions.length})`}
        inputProps={{
          name: label,
          id: uniqueId,
        }}
      >
        {options?.length && options.map(option => (
          <MenuItem className={classes.menuItem} key={option} value={option}>
            <Checkbox checked={selectedOptions.includes(option)} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectFilter;
