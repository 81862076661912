import React, { useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';
import { useIntercom } from 'react-use-intercom';
import { Feature } from 'components/shared/Feature';
import { useSetOrganisationFeatureExpiry } from 'apis/rest/features/hooks';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useStaff } from 'hooks/session/useStaff';
import TripAnalysisReport from './report';

const featureKey = 'reporting.newTripReports';

const TripAnalysisTrial = () => {
  const t = useTranslations('pages.reporting.tripAnalysis.trial');
  const isStaff = useStaff();
  const [bypass, setBypass] = useState(false);

  const organisationId = useOrganisationId();
  const mutation = useSetOrganisationFeatureExpiry();
  const intercom = useIntercom();

  if (isStaff) {
    if (bypass) return <TripAnalysisReport />;
    return (
      <Paper elevation={0} sx={{ p: 6 }}>
        <Typography>(TracPlus staff only)</Typography>
        <Feature
          id={featureKey}
          preTrial={<Typography>The customer has not yet started their trial period and does not have access to this report.</Typography>}
          expired={<Typography>The trial period for this report has expired so that customer does not have access to this report.</Typography>}
        />
        <Button size="large" variant="contained" sx={{ minHeight: '4rem', minWidth: '40ch', mt: 3 }} onClick={() => setBypass(true)}>View report</Button>
      </Paper>
    );
  }

  const begin = () => {
    mutation.mutate({ organisationId, featureKey }, {
      onSuccess: () => {
        intercom.trackEvent('beginTripAnalysisTrial', {
          organisation_id: organisationId,
          time_at: Date.now(),
        });
      },
    });
  };

  return (
    <Paper elevation={0} sx={{ p: 6 }}>
      <Feature
        id={featureKey}
        preTrial={(
          <>
            <Typography>{t('blurb')}</Typography>
            <Button
              size="large"
              variant="contained"
              sx={{ minHeight: '4rem', minWidth: '40ch', mt: 3 }}
              onClick={begin}
              disabled={mutation.isLoading}
            >
              {t('begin')}
            </Button>
          </>
        )}
        expired={<Typography>{t('expired')}</Typography>}
      />
    </Paper>
  );
};

export default TripAnalysisTrial;
