import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import moment from 'utils/moment';
import { Moment } from 'moment';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { MobileDatePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import {
  CalendarToday as CalendarTodayIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { getReportsSummaryForAsset } from 'apis/rest/reports/requests';
import AssetLabel from 'components/shared/assetLabel';
import DismissButton from 'components/shared/snackbar/dismissButton';
import useStyles from './datePicker-styles';
import './datePicker.css';
import useTimezone from "hooks/session/useTimezone";

const DateFormat = 'ddd, MMM DD, YYYY';

interface DateSelectorProps {
  selectedDay: string | null,
  selectedAsset?: AssetBasic,
  setDay: (day: string | null) => void,
  organisationId: string
}

const sameDayInZone = (m: Moment, timezone: string) => moment.tz(m.format('YYYY-MM-DD'), timezone);

const DateSelector = ({
  selectedDay,
  selectedAsset,
  setDay,
  organisationId,
}:DateSelectorProps): JSX.Element => {
  const timezone = useTimezone();
  const classes = useStyles();
  const t = useTranslations('omnibox.modules.datePicker');

  const selectedM = useMemo(() => (selectedDay ? moment.tz(selectedDay, timezone) : null), [selectedDay, timezone]);

  const [month, setMonth] = useState<Moment>(moment().tz(timezone).startOf('month'));

  const reportsSummary = useQuery(
    ['reportSummary', selectedAsset?.id, month?.clone().format('YYYY-MM')],
    () => (month && selectedAsset?.id ? getReportsSummaryForAsset({ organisationId, assetId: selectedAsset?.id, from: month.valueOf(), until: month.clone().endOf('month').valueOf() }) : []),
  );

  const deviceWasActive = useCallback((day: Moment): boolean => {
    if (!reportsSummary.data) { return false; }
    const dayInTz = sameDayInZone(day, timezone);
    const start = dayInTz.clone().startOf('day').unix();
    const end = dayInTz.clone().endOf('day').unix();
    return reportsSummary.data.some(summary => (summary.day <= start && summary.last >= start) || (summary.day <= end && summary.day >= start && summary.first <= end));
  }, [reportsSummary.data, timezone]);

  const Day = useCallback((props: PickersDayProps<Moment>) => {
    const wasActive = !props.outsideCurrentMonth && deviceWasActive(props.day);

    return (
      <PickersDay {...props}>
        <Tooltip title={wasActive && <AssetLabel asset={selectedAsset} />}>
          <span>{props.day.format('D')}</span>
        </Tooltip>
        <div style={{ width: wasActive ? '50%' : '0%' }} className={classes.activeDay} />
      </PickersDay>
    );
  }, [deviceWasActive, classes.activeDay, selectedAsset]);

  // Datetime dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pendingDate, setPendingDate] = React.useState<Moment>();
  const closeDialog = (): void => {
    setMonth((selectedM?.clone() ?? moment().tz(timezone)).startOf('month'));
    setPendingDate(undefined);
    setIsDialogOpen(false);
  };

  const jumpToNow: MouseEventHandler = e => {
    e.stopPropagation(); // prevent a click on the "now" button from opening the calendar
    setMonth(moment().tz(timezone).startOf('month'));
    setDay(null);
  };

  const setHistoricalDateTime = (m: Moment): void => {
    const newDate = sameDayInZone(m, timezone).endOf('day');
    if (newDate.isSame(moment.tz(), 'day')) {
      if (selectedM) setDay(null);
    } else {
      setDay(newDate.format('YYYY-MM-DD'));
    }
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    const snackbarKey = 'historicSnackbarMessage';
    if (selectedM) {
      enqueueSnackbar(t('historicSnackBar'), {
        key: snackbarKey,
        persist: true,
        variant: 'info',
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        action: <DismissButton snackbarKey={snackbarKey} />,
      });
      return;
    }
    closeSnackbar(snackbarKey);
  }, [selectedM, enqueueSnackbar, closeSnackbar, t]);

  const momentTz = useMemo(() => moment().tz(timezone), [timezone]);
  const value = sameDayInZone(pendingDate ?? selectedM ?? momentTz, timezone);

  const onAccept = (date: moment.Moment | null) => {
    const m = date && sameDayInZone(date, timezone);
    if (m) setHistoricalDateTime(m);
  };

  const onChange = (date: moment.Moment | null) => {
    const m = date ? moment(date) : undefined;
    setPendingDate(m);
  };

  return (
    <Box id="historicalModeButton" className={classes.historyWrapper}>
      {!selectedM && (
        <Tooltip title={t('viewHistoricalData')}>
          <Box
            role="button"
            className={classes.historyButton}
            onClick={() => setIsDialogOpen(true)}
          >
            <CalendarTodayIcon className={classes.calIcon} />
            {t('viewHistoricalData')}
          </Box>
        </Tooltip>
      )}
      <MobileDatePicker<Moment>
        open={isDialogOpen}
        onOpen={() => setIsDialogOpen(true)}
        onClose={closeDialog}
        onAccept={onAccept}
        onChange={onChange}
        value={value}
        slots={{
          textField: selectedM ? TextField : () => null,
          day: Day,
        }}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <Tooltip title={t('resetToLive')} placement="right">
                  <IconButton size="small" onClick={jumpToNow}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              ),
            },
          },
        }}
        format={DateFormat}
        onMonthChange={m => setMonth(m.startOf('month'))}
        className={classes.datePicker}
        dayOfWeekFormatter={day => day.format('ddd')}
        shouldDisableDate={date => {
          const days = sameDayInZone(date, timezone).diff(momentTz.clone().startOf('day'), 'day');
          return Math.floor(days) > 0;
        }}
        localeText={{
          toolbarTitle: value.format('YYYY'),
        }}
      />
    </Box>
  );
};

export default DateSelector;
