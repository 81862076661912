import React, { useRef, useEffect, useMemo } from 'react';
import * as d3 from 'd3';
import { speed, distance } from 'helpers/unitsOfMeasure';
import { MapSettings } from 'reducers/map';
import { useAssetPositions } from 'repositories/reports/hooks';
import { useSelector } from 'react-redux';
import { Stack, Box, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { colorLookup, COLORMAP, mapToNumbers, NumericAssetColouringOptions, TransportColors } from 'utils/colormap';
import { Transport } from 'helpers/transport';
import { uniq } from 'lodash';
import { useSpeedByAssetId } from 'hooks/units/useSpeed';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import { OverlayWrapper } from './common';

interface ColorbarProps {
  config: MapSettings
  highContrastControls: boolean
}

const rgbaToCss = (c: ColorAlpha) => `rgba(${c.slice(0, 3).join(',')}, 1)`;
const backgroundGradient = `linear-gradient(to right, ${COLORMAP.map(rgbaToCss).join(',')})`;

export const AssetTrailColouringLegend = ({ config, highContrastControls }: ColorbarProps) => {
  const selectedAssetId = useSelector<ReduxState, number>(state => state.map.selectedAssets[config.id]?.id ?? -1);
  const selectedLeg = useSelector<ReduxState, Leg | null>(state => state.map.selectedLegs[config.id]);
  const selectedReport = useSelector<ReduxState, Report | null>(state => state.reports.selectedReportPerMap[config.id]);
  const { altitude: altitudeUnit } = useUnitSettings();
  const speedUnit = useSpeedByAssetId(selectedAssetId);

  const t = useTranslations('components.TrailColouring');
  const categoricalColoring = useMemo(() => (config.assetTrailColouring === 'transport'), [config.assetTrailColouring]);

  const mapNumber = useMemo(
    () => ({
      altitude: (i: number) => distance.fromSI(i, altitudeUnit),
      speed: (i: number) => speed.fromKmh(i, speedUnit),
      battery: (i: number) => i,
      transport: (i: number) => i,
      latency: (i: number) => i,
      none: (i: number) => i
    }[config.assetTrailColouring]),
    [altitudeUnit, speedUnit, config]
  );

  const unitLabel = useMemo(() => ({
    altitude: distance.label(altitudeUnit),
    speed: speed.label(speedUnit),
    battery: '%',
    transport: '',
    latency: 's',
    none: ''
  }[config.assetTrailColouring]), [altitudeUnit, speedUnit, config]);

  const reports = useAssetPositions(selectedAssetId, selectedLeg ?? undefined);
  const transports: Transport[] = useMemo(() => uniq(reports.map(colorLookup.transport)), [reports]);
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) { return; }
    const numeric = config.assetTrailColouring !== 'none' && config.assetTrailColouring !== 'transport';
    if (numeric) {
      const mappedReports = mapToNumbers(reports, config.assetTrailColouring as NumericAssetColouringOptions);
      const siScale = d3.scaleLinear().domain([0, Math.max(...mappedReports)]).range([10, svgRef.current.clientWidth - 10]);
      const transformedScale = siScale.copy().domain(siScale.domain().map(mapNumber));
      const root = d3.select(svgRef.current);
      root.selectAll('g').remove();
      const axis = root.append('g').attr('class', 'ticks');
      axis.call(d3.axisBottom(transformedScale).ticks(5));

      const selectedNumber = selectedReport ? mapToNumbers([selectedReport], config.assetTrailColouring as NumericAssetColouringOptions)[0] : undefined;
      root.selectAll('.selectedReportLine')
        .data(selectedNumber ? [selectedNumber] : [])
        .join(
          enter => enter.append('line')
            .attr('transform', d => `translate(${siScale(d)}, 0)`),
          update => update
            .transition()
            .ease(d3.easeCubicInOut)
            .duration(200)
            .attr('transform', d => `translate(${siScale(d)}, 0)`)
        )
        .attr('id', 'selectedReportLineColourbar')
        .attr('y2', -svgRef.current.clientHeight)
        .attr('class', 'selectedReportLine')
        .attr('stroke', '#fff');
    }
  }, [config.assetTrailColouring, mapNumber, reports, selectedReport, unitLabel]);

  if (!config.assetTrailColouring || config.assetTrailColouring === 'none' || selectedAssetId < 0 || (categoricalColoring && transports.length === 0)) {
    return null;
  }

  return (
    <OverlayWrapper highContrast={highContrastControls}>
      <Stack p={2} spacing={2} color="white">
        <Typography variant="h5" textAlign="center">{t(config.assetTrailColouring)}{categoricalColoring ? '' : ` (${unitLabel})`}</Typography>
        {categoricalColoring ? transports.map(transport => (
          <Stack key={transport} direction="row" spacing={2}>
            <Box sx={theme => ({ width: theme.spacing(3), height: theme.spacing(3), borderRadius: 1, backgroundColor: rgbaToCss(TransportColors[transport]) })} />
            <Typography>{transport}</Typography>
          </Stack>
        ))
          : (
            <div>
              <Box sx={{ height: '2rem', mx: '10px', width: 'calc(100% - 19px)', background: backgroundGradient }} />
              <svg ref={svgRef} width="100%" height="2rem" style={{ overflow: 'visible' }} />
            </div>
          )}
      </Stack>
    </OverlayWrapper>
  );
};
