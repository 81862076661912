import React from 'react';
import { useTranslations } from 'use-intl';
import { useDropsOption } from 'hooks/settings/map/useDropsOption';
import { useSetDropsOption } from 'hooks/settings/map/useSetDropsOption';
import CirclePicker from 'components/shared/circlePicker';
import dropTrailsUrl from './showDropTrails.svg';
import dropIconsUrl from './showDropIcons.svg';

interface FirefightingOptionsProps {
  mapId: string
}

export const FirefightingOptions = ({ mapId }: FirefightingOptionsProps) => {
  const t = useTranslations('pages.map.settingsDialog.firefightingOptions');
  const dropsOption = useDropsOption(mapId);
  const setDropsOption = useSetDropsOption(mapId);

  return (
    <CirclePicker
      value={dropsOption}
      onChange={value => setDropsOption(value)}
      options={[
        {
          value: 'showDropTrails',
          label: t('showDropTrails'),
          image: (
            <img
              src={dropTrailsUrl}
              width={48}
              height={48}
              alt={t('showDropTrails')}
            />
          ),
        },
        {
          value: 'showDropIcons',
          label: t('showDropIcons'),
          image: (
            <img
              src={dropIconsUrl}
              width={48}
              height={48}
              alt={t('showDropIcons')}
            />
          ),
        },
      ]}
    />
  );
};
