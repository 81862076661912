export const tripQueryKeys = {
  trips: (org: string) => [org, 'trips'] as const,
  tripSummaries: (org: string) => [org, 'tripsSummaries'] as const,
  tripsBasic: (org: string) => [org, 'tripsBasic'] as const,
  tripsForAssets: (org: string, assets: number[], from?: number, until?: number) => [...tripQueryKeys.trips(org), { from, until, assets: [...assets].sort().join(',') }] as const,
  tripsBasicForAssets: (org: string, assets: number[], from?: number, until?: number) => [...tripQueryKeys.tripsBasic(org), { from, until, assets: [...assets].sort().join(',') }] as const,
  tripSummariesForAssets: (org: string, assets: number[], from?: number, until?: number) => [...tripQueryKeys.tripSummaries(org), { from, until, assets: [...assets].sort().join(',') }] as const,
  allKnownTransitions: (org: string) => [org, 'transitions'] as const,
  supplementaryData: (org: string) => [org, 'supplementaryData'] as const,
  supplementaryDataForTrip: (org: string, tripId: string) => [...tripQueryKeys.supplementaryData(org), tripId] as const,
  supplementaryDataFields: (org: string) => [org, 'supplementaryDataFields'] as const,
};
