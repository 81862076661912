import { distance } from 'helpers/unitsOfMeasure';

// converts from meters to specified destinationUnits
export const convertAltitudeFromSI = (input: number, destinationUnits: 'metres' | 'feet'): number => {
  return distance.fromSI(input, destinationUnits);
};

// converts from sourceUnits to meters
export const convertAltitudeToSI = (input: number, sourceUnits: 'metres' | 'feet'): number => {
  return distance.toSI(input, sourceUnits);
};

export const convertUnitToLabel = (inputUnit: 'metres' | 'feet'): string => distance.label(inputUnit);
