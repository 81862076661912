import { useDispatch } from 'react-redux';
import { TRootState } from 'reducers';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { store } from 'store';

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, TRootState, unknown, Action<string>>

export const useAppDispatch = () => useDispatch<AppDispatch>();
