import { useQueryClient } from '@tanstack/react-query';
import { geonameQueryKeys } from 'apis/rest/geocoding/queryKeys';
import { GeocodedLocation, reverseGeocodeBatch } from 'apis/rest/geocoding/requests';
import { Options as CSVOptions, Input } from 'csv-stringify';
import useDuration from 'hooks/units/useDuration';
import useVolume, { useVolumePerPeriod } from 'hooks/units/useVolume';
import sumBy from 'lodash/fp/sumBy';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAssetsNoDrops,
  selectStatsFilterEnabled,
  selectStatsFilterExceptions,
  selectStatsFilterInclusions,
} from 'slices/statsFilter.slice';
import { useTranslations } from 'use-intl';
import useDistance from 'hooks/units/useDistance';
import { distance, speed } from 'helpers/unitsOfMeasure';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import { calculateDropGroups } from './assetDropsDetail/dropGroups';

export interface EfficiencyStats {
  dropsPerHour: number;
  volumePerHour: number;
  dropsPerFlightHour: number;
  volumePerFlightHour: number;
}

type PartialStats = {
  [key in keyof EfficiencyStats]?: number;
};

type StatsAccumulator = {
  [key in keyof EfficiencyStats]: { sum: number, count: number };
};

const firstFillStartTime = (drops: Drop[]) => {
  if (drops.length === 0) {
    return undefined;
  }

  const first = drops[0];
  const firstFill = drops.find(drop => drop.type === 'Fill');

  if (firstFill === undefined) {
    return first.startTime;
  }

  if (first === firstFill) {
    return first.startTime;
  }

  // if the first fill is 24 hours after the start of the first drop, then use the first drop start time
  if (Math.abs(first.startTime - firstFill.startTime) > 86400000) {
    return first.startTime;
  }

  return firstFill?.startTime;
};

const hoursBetweenFillAndDrops = (drops: Drop[]) => {
  const startTime = firstFillStartTime(drops);
  if (startTime === undefined) {
    return 0;
  }

  const validEndTimes = drops
    .filter(drop => drop.type === 'Drop' && (drop.endTime - drop.startTime) < 864000000)
    .map(drop => drop.endTime);

  if (validEndTimes.length === 0) {
    return 0;
  }

  const lastDropTime = validEndTimes[validEndTimes.length - 1];
  return Math.abs(lastDropTime - startTime) / 3.6e6;
};

const calculateEfficiencyStatsForTrip = (trip: Trip, filter?: (drop: Drop) => boolean): PartialStats | null => {
  const stats: PartialStats = {};

  const drops = trip.drops.filter(filter ?? (() => true));
  const dropDrops = drops.filter(drop => drop.type === 'Drop');

  if (dropDrops.length === 0) {
    return null;
  }

  const startTime = firstFillStartTime(drops);
  const hours = hoursBetweenFillAndDrops(drops);
  if (startTime !== undefined && hours > 0) {
    const dropsAfterStartTime = dropDrops.filter(drop => drop.startTime >= startTime);
    const count = dropsAfterStartTime.length;
    const volume = dropsAfterStartTime.reduce((a, c) => a + (c.dropVolume ?? 0), 0);
    stats.dropsPerHour = count / hours;
    stats.volumePerHour = volume / hours;
  }

  const flightHours = trip.endTime ? (trip.endTime - trip.startTime) / 3.6e6 : 0;
  if (flightHours > 0) {
    const count = dropDrops.length;
    const volume = dropDrops.reduce((a, c) => a + (c.dropVolume ?? 0), 0);
    stats.dropsPerFlightHour = count / flightHours;
    stats.volumePerFlightHour = volume / flightHours;
  }

  return stats;
};

export const calculateEfficiencyStats = (trips: Trip[], filter?: (drop: Drop) => boolean): EfficiencyStats => {
  const stats: PartialStats[] = trips.map(t => calculateEfficiencyStatsForTrip(t, filter))
    .filter((s): s is PartialStats => s !== null);

  const statsAcc = stats.reduce<StatsAccumulator>((acc, stat) => Object.entries(stat)
    .reduce((innerAcc, [k, v]) => {
      const key = k as keyof EfficiencyStats;
      innerAcc[key] = {
        sum: acc[key].sum + v,
        count: acc[key].count + 1,
      };
      return innerAcc;
    }, acc), {
    dropsPerHour: {
      sum: 0,
      count: 0,
    },
    volumePerHour: {
      sum: 0,
      count: 0,
    },
    dropsPerFlightHour: {
      sum: 0,
      count: 0,
    },
    volumePerFlightHour: {
      sum: 0,
      count: 0,
    },
  });

  return Object.keys(statsAcc)
    .reduce<EfficiencyStats>((acc, k) => {
      const key = k as keyof EfficiencyStats;
      acc[key] = statsAcc[key].count > 0 ? statsAcc[key].sum / statsAcc[key].count : 0;
      return acc;
    }, {
      dropsPerHour: 0,
      volumePerHour: 0,
      dropsPerFlightHour: 0,
      volumePerFlightHour: 0,
    });
};

// `true` when the number of hours is less than 1 min per drop
export const tripTooShort = (drops: Drop[]) => hoursBetweenFillAndDrops(drops) * 60 < drops.length;

export const useGetFilteredTrips = (trips: Trip[], dropFilter?: (drop: Drop) => boolean): Trip[] => {
  const enabled = useSelector(selectStatsFilterEnabled);
  const exceptions = useSelector(selectStatsFilterExceptions);
  const inclusions = useSelector(selectStatsFilterInclusions);

  return useMemo(() => trips.filter(trip => {
    if (!enabled) return true;

    if (exceptions.includes(trip.id)) return true;
    if (inclusions.includes(trip.id)) return false;

    return !tripTooShort(trip.drops.filter(dropFilter ?? (() => true)));
  }), [trips, dropFilter, enabled, exceptions, inclusions]);
};

export const useIsFiltered = (trip?: Trip, dropFilter?: (drop: Drop) => boolean): boolean => {
  const trips = useGetFilteredTrips(trip ? [trip] : [], dropFilter);
  return useMemo(() => (trip ? trips.length === 0 : false), [trip, trips]);
};

// Total flight time in milliseconds
export const calculateTotalFlightTime = (trips?: Trip[]) => {
  if (!trips) {
    return 0;
  }
  return trips.reduce<number>((acc, trip) => acc + (trip.endTime ? trip.endTime - trip.startTime : 0), 0);
};

export const useCSVFirefightingFormatter = (): {
  createCSVSummaryData: (assets: AssetWithDevice[], selectedDeviceIds: number[], drops: Drop[], trips: Trip[], visibleSuppressants: Record<Suppressant, boolean>) => [Input, CSVOptions],
  createCSVAssetData: (asset: AssetWithDevice, trips: Trip[], timezone: string, visibleSuppressants: Record<Suppressant, boolean>) => Promise<[Input, CSVOptions]>
} => {
  const units = useUnitSettings();
  const volume = useVolume();
  const volumePerPeriod = useVolumePerPeriod();
  const duration = useDuration();
  const distanceUnits = useDistance();
  const speedUnits = units.speedAir;
  const shortDistanceUnits = distanceUnits.unit === 'kilometres' ? 'metres' : 'feet';
  const statsFilterEnabled = useSelector(selectStatsFilterEnabled);
  const assetNoDropsEnabled = useSelector(selectAssetsNoDrops);
  const t = useTranslations('pages.reporting.firefighting');
  const assetsT = useTranslations('pages.assets');
  const queryClient = useQueryClient();

  const durationFormat = (val: number) => {
    if (val > 60 * 60 * 1000) return 'h\'h\' m\'m\' s\'s\'';
    if (val > 60 * 1000) return 'm\'m\' s\'s\'';
    return 's\'s\'';
  };

  const createCSVSummaryData = (assets: AssetWithDevice[], selectedDeviceIds: number[], drops: Drop[], trips: Trip[], visibleSuppressants: Record<Suppressant, boolean>): [Input, CSVOptions] => {
    const selectedAssets = assets.filter(a => selectedDeviceIds.includes(a.deviceId) && (!assetNoDropsEnabled || drops.some(d => d.assetId === a.id && visibleSuppressants[d.suppressant])));
    const records: Input = selectedAssets.map(asset => {
      const relevantDrops = drops.filter(d => d.assetId === asset.id && d.type === 'Drop' && visibleSuppressants[d.suppressant]);
      const relevantTrips = trips.filter(trip => trip.assetId === asset.id && (!statsFilterEnabled || !tripTooShort(trip.drops)));
      const stats = calculateEfficiencyStats(relevantTrips);
      const totalFlightTime = calculateTotalFlightTime(relevantTrips);

      const relevantFreshWaterDrops = relevantDrops.filter(d => d.suppressant === 'FreshWater');
      const freshWaterVolume = volume.create(sumBy('dropVolume', relevantFreshWaterDrops)).format();
      const relevantSaltWaterDrops = relevantDrops.filter(d => d.suppressant === 'SaltWater');
      const saltWaterVolume = volume.create(sumBy('dropVolume', relevantSaltWaterDrops)).format();
      const relevantRetardantDrops = relevantDrops.filter(d => d.suppressant === 'Retardant');
      const retardantVolume = volume.create(sumBy('dropVolume', relevantRetardantDrops)).format();
      const relevantFoamDrops = relevantDrops.filter(d => d.suppressant === 'Foam');
      const foamVolume = volume.create(sumBy('dropVolume', relevantFoamDrops)).format();
      const relevantUnknownSuppressantDrops = relevantDrops.filter(d => d.suppressant === 'Unknown');
      const unknownSuppressantVolume = volume.create(sumBy('dropVolume', relevantUnknownSuppressantDrops)).format();

      return {
        organisation: asset.ownerName,
        name: asset.name,
        tailNumber: asset.tailNumber,
        callsign: asset.callSign,
        category: asset.category,
        makeModelVariant: [asset.make, asset.model, asset.variant].filter(x => x).join(' '),
        totalDrops: relevantDrops.length,
        totalVolume: volume.create(sumBy('dropVolume', relevantDrops)).format(),
        totalFlightTime: duration.fromMillis(totalFlightTime, undefined, durationFormat(totalFlightTime)),
        averageDropsPerHour: stats.dropsPerHour.toFixed(2),
        averageVolumePerHour: volumePerPeriod.create(stats.volumePerHour, 'h').format(),
        averageDropsPerFlightHour: stats.dropsPerFlightHour.toFixed(2),
        averageVolumePerFlightHour: volumePerPeriod.create(stats.volumePerFlightHour, 'h').format(),
        freshWaterDrops: relevantFreshWaterDrops.length,
        freshWaterVolume,
        saltWaterDrops: relevantSaltWaterDrops.length,
        saltWaterVolume,
        retardantDrops: relevantRetardantDrops.length,
        retardantVolume,
        foamDrops: relevantFoamDrops.length,
        foamVolume,
        unknownSuppressantDrops: relevantUnknownSuppressantDrops.length,
        unknownSuppressantVolume
      };
    });

    let baseColumns = [
      { key: 'organisation', header: assetsT('assetsTable.owner') },
      { key: 'name', header: assetsT('assetsTable.name') },
      { key: 'tailNumber', header: assetsT('assetsTable.tailNumber') },
      { key: 'callsign', header: assetsT('assetsTable.callSign') },
      { key: 'category', header: assetsT('assetsTable.category') },
      { key: 'makeModelVariant', header: assetsT('assetsTable.makeModelVariant') },
      { key: 'totalDrops', header: t('dropCount') },
      { key: 'totalVolume', header: t('totalVolume') },
      { key: 'totalFlightTime', header: t('totalFlightTime') },
      { key: 'averageDropsPerHour', header: t('stats.averageDropsPerHour') },
      { key: 'averageVolumePerHour', header: t('stats.averageVolumePerHour') },
      { key: 'averageDropsPerFlightHour', header: t('stats.averageDropsPerFlightHour') },
      { key: 'averageVolumePerFlightHour', header: t('stats.averageVolumePerFlightHour') },
    ];

    if (visibleSuppressants.FreshWater && records.some(r => r.freshWaterDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'freshWaterDrops', header: `${t('suppressant.FreshWater')} ${t('dropCount')}` },
        { key: 'freshWaterVolume', header: `${t('suppressant.FreshWater')} ${t('totalVolume')}` },
      ];
    }

    if (visibleSuppressants.SaltWater && records.some(r => r.saltWaterDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'saltWaterDrops', header: `${t('suppressant.SaltWater')} ${t('dropCount')}` },
        { key: 'saltWaterVolume', header: `${t('suppressant.SaltWater')} ${t('totalVolume')}` },
      ];
    }

    if (visibleSuppressants.Retardant && records.some(r => r.retardantDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'retardantDrops', header: `${t('suppressant.Retardant')} ${t('dropCount')}` },
        { key: 'retardantVolume', header: `${t('suppressant.Retardant')} ${t('totalVolume')}` },
      ];
    }

    if (visibleSuppressants.Foam && records.some(r => r.foamDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'foamDrops', header: `${t('suppressant.Foam')} ${t('dropCount')}` },
        { key: 'foamVolume', header: `${t('suppressant.Foam')} ${t('totalVolume')}` },
      ];
    }

    if (visibleSuppressants.Unknown && records.some(r => r.unknownSuppressantDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'unknownSuppressantDrops', header: `${t('suppressant.Unknown')} ${t('dropCount')}` },
        { key: 'unknownSuppressantVolume', header: `${t('suppressant.Unknown')} ${t('totalVolume')}` },
      ];
    }

    const headers: CSVOptions = {
      header: true,
      columns: baseColumns
    };

    return [records, headers];
  };

  const createCSVAssetData = async (asset: AssetWithDevice, trips: Trip[], timezone: string, visibleSuppressants: Record<Suppressant, boolean>): Promise<[Input, CSVOptions]> => {
    const relevantTrips = trips.filter(trip => trip.assetId === asset.id && (!statsFilterEnabled || !tripTooShort(trip.drops)));
    const dropGroups = calculateDropGroups(relevantTrips).filter(g => g.drops.some(d => d.type === 'Drop' && visibleSuppressants[d.suppressant]));
    const reportsByGroupId = dropGroups.reduce<Record<number, Record<number, TripSlimReport[]>>>((acc, group) => {
      acc[group.lastDropId] = group.drops.reduce<Record<number, TripSlimReport[]>>((dropAcc, drop) => {
        const trip = trips.find(tr => tr.drops.some(d => d.id === drop.id));
        if (!trip) return dropAcc;

        const dropStart = trip.reports.find(r => drop.startReportId === r.id);
        if (dropStart && !dropAcc[drop.id]?.push(dropStart)) dropAcc[drop.id] = [dropStart];
        const dropStop = trip.reports.find(r => drop.endReportId === r.id);
        if (dropStop && !dropAcc[drop.id]?.push(dropStop)) dropAcc[drop.id] = [dropStop];

        return dropAcc;
      }, {});
      return acc;
    }, {});

    const fillReports = dropGroups.filter(f => f.drops.some(d => d.type === 'Fill')).flatMap(f => {
      const tripReports = f.trip?.reports ?? [];
      const fillReportId = f.drops.find(d => d.type === 'Fill')?.startReportId ?? 0;
      const fillReport = tripReports?.find(r => r.id === fillReportId);
      return {
        id: fillReportId,
        longitude: fillReport?.coords[0] ?? 0,
        latitude: fillReport?.coords[1] ?? 0,
      };
    });

    const coordsToName = fillReports.concat(dropGroups.reduce<Pick<Report, 'id' | 'latitude' | 'longitude'>[]>((acc, group) => {
      const dropReports = reportsByGroupId[group.lastDropId];
      if (!dropReports) return acc;
      const dropReport = dropReports[group.lastDropId]?.at(0);
      if (dropReport) {
        acc.push({
          id: dropReport.id,
          longitude: dropReport.coords[0],
          latitude: dropReport.coords[1],
        });
      }
      return acc;
    }, []));

    const locationQueryKeys = geonameQueryKeys.locationsByReportId(coordsToName.map(r => r.id));
    const locationsQuery = await queryClient.fetchQuery(locationQueryKeys, async () => {
      const category = asset?.category ?? 'Unknown';
      const geocodingCache: GeocodedLocation[] = sessionStorage?.geocodingCache ? JSON.parse(sessionStorage?.geocodingCache)
        .filter((g: GeocodedLocation) => g.category) : [];
      const remainingCoords = coordsToName.filter(c => !geocodingCache.some(g => g.lat === c.latitude && g.lon === c.longitude));

      const geocodedLocations = remainingCoords.length > 0 ? await reverseGeocodeBatch(category, remainingCoords).catch(err => {
        console.error('Failed to reverse geocode locations:', err);
        return [];
      }) : [];
      const updatedCache = geocodingCache.concat(geocodedLocations);
      sessionStorage.setItem('geocodingCache', JSON.stringify(updatedCache));

      return updatedCache.reduce<Record<number, string>>((acc, loc) => {
        const reportId = coordsToName.find(r => r.latitude === loc.lat && r.longitude === loc.lon)?.id;
        if (reportId) acc[reportId] = loc.location;
        return acc;
      }, {});
    }, {
      staleTime: Infinity,
    });
    const locationByGroupId = dropGroups.reduce<Record<number, string | undefined>>((acc, group) => {
      acc[group.lastDropId] = t('unknownLocation');

      const reports = reportsByGroupId[group.lastDropId];
      if (!reports) return acc;

      const reportId = reports[group.lastDropId]?.at(0)?.id;
      if (!reportId) return acc;

      if (locationsQuery) acc[group.lastDropId] = locationsQuery[reportId] ?? t('unknownLocation');
      return acc;
    }, {});

    const records: Input = dropGroups.map(dropGroup => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const firstFill = dropGroup.drops.find(d => d.type === 'Fill')!;
      const firstDropIndex = dropGroup.drops.findIndex(d => d.type === 'Drop');

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const lastDrop = dropGroup.drops.find(d => d.id === dropGroup.lastDropId)!;
      const flightTimeMillis = firstFill && lastDrop ? (lastDrop.endTime ?? lastDrop.startTime) - firstFill.startTime : 0;
      const fillLocation = firstFill ? locationsQuery[firstFill.startReportId] ?? t('unknownLocation') : '—';
      const dropLocation = locationByGroupId[dropGroup.lastDropId] ?? t('unknownLocation');
      const totalDropVolume = dropGroup.drops.reduce<number>((acc, drop) => acc + (drop.dropVolume ?? 0), 0);
      const dropDuration = dropGroup.drops.filter(d => d.type === 'Drop').reduce<number>((acc, drop) => acc + drop.duration, 0);
      const averageDropRate = dropDuration > 0 ? totalDropVolume / (dropDuration / 1000) : 0;

      const dropReports = reportsByGroupId[dropGroup.lastDropId];
      const firstDropReports = dropReports[dropGroup.drops[firstDropIndex].id];
      const firstFillReports = dropReports[firstFill?.id];

      const [start, stop] = dropReports[dropGroup.lastDropId] ?? [undefined, undefined];

      const speedStart = start && start.speed > 0 ? start.speed : undefined;
      const speedStop = stop && stop.speed > 0 ? stop.speed : undefined;
      const avgSpeed = speedStart && speedStop ? (speedStart + speedStop) / 2 : (speedStart ?? speedStop);

      const distanceToDrop = firstFill && firstFillReports && firstFillReports.length > 0 ? distance.distanceTo(
        firstFillReports[0].coords[1],
        firstFillReports[0].coords[0],
        firstDropReports[0].coords[1],
        firstDropReports[0].coords[0]
      ) : null;

      return {
        fillDate: firstFill?.startTime ? DateTime.fromMillis(firstFill.startTime)
          .setZone(timezone)
          .toFormat('dd MMM yyyy, HH:mm:ss ZZZ') : '—',
        fillLocation: fillLocation ?? t('unknownLocation'),
        distanceToDrop: distanceToDrop ? distance.withUnits(distance.fromSI(distanceToDrop, distanceUnits.unit), distanceUnits.unit, 1) : '—',
        flightDuration: duration.fromMillis(flightTimeMillis, undefined, durationFormat(flightTimeMillis)),
        dropDate: DateTime.fromMillis(lastDrop.startTime)
          .setZone(timezone)
          .toFormat('dd MMM yyyy, HH:mm:ss ZZZ'),
        dropLocation,
        totalDropVolume: volume.create(totalDropVolume).format(),
        dropDuration: duration.fromMillis(lastDrop.duration, undefined, durationFormat(lastDrop.duration)),
        dropRate: volumePerPeriod.create(averageDropRate, 's').format(),
        dropSpeed: avgSpeed ? speed.withUnits(speed.fromKmh(avgSpeed, speedUnits), speedUnits) : '—',
        dropDistance: stop?.distance ? distance.withUnits(distance.fromSI(stop.distance, shortDistanceUnits), shortDistanceUnits) : '—'
      };
    });

    const headers: CSVOptions = {
      header: true,
      columns: [
        { key: 'fillDate', header: t('detailSummary.fillDate') },
        { key: 'fillLocation', header: t('detailSummary.fillLocation') },
        { key: 'distanceToDrop', header: t('detailSummary.directDistance') },
        { key: 'flightDuration', header: t('totalFlightTime') },
        { key: 'dropDate', header: t('detailSummary.dropDate') },
        { key: 'dropLocation', header: t('detailSummary.dropLocation') },
        { key: 'totalDropVolume', header: t('detailSummary.totalDropVolume') },
        { key: 'dropDuration', header: t('detailSummary.duration') },
        { key: 'dropRate', header: t('detailSummary.rate') },
        { key: 'dropSpeed', header: t('detailSummary.speed') },
        { key: 'dropDistance', header: t('detailSummary.distance') }
      ],
    };

    return [records, headers];
  };

  return { createCSVSummaryData, createCSVAssetData };
};
