export const deviceQueryKeys = {
  all: (org: string) => [org, 'devices'] as const,
  lists: (org: string) => [...deviceQueryKeys.all(org), 'list'] as const,
  listsCapabilities: (org: string) => [...deviceQueryKeys.all(org), 'capabilities'] as const,
  firmwareVersions: (org: string) => [...deviceQueryKeys.all(org), 'firmware-versions'] as const,
  details: (org: string) => [...deviceQueryKeys.all(org), 'detail'] as const,
  detail: (org: string, id: number) => [...deviceQueryKeys.details(org), id] as const,
  asset: (org: string, id: number) => [...deviceQueryKeys.detail(org, id), 'asset'] as const,
  listsConfigurations: (org: string) => [...deviceQueryKeys.all(org), 'configurations'] as const,
  configChanges: (org: string, id: number) => [...deviceQueryKeys.detail(org, id), 'config-changes'] as const,
};
