import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import ReactMapGl, {
  MapProvider,
  AttributionControl,
  useControl,
  useMap,
  ViewStateChangeEvent,
  ViewState,
} from 'react-map-gl';
import { Map } from 'mapbox-gl';
import clsx from 'clsx';
import { Box, Dialog, DialogContent, Stack } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import useMapTemplate from 'hooks/settings/map/useMapConfig';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox';
import { points } from '@turf/helpers';
import { ArcLayer, PathLayer, ScatterplotLayer } from '@deck.gl/layers';
import WebMercatorViewport from '@math.gl/web-mercator';
import ControlButton from 'components/map/modules/controls/control-button';
import { hexToRGBArray } from 'helpers/color';
import { safeBounds } from 'helpers/geo';
import { useSize } from 'hooks/useSize';
import { useSuppressantColors } from 'components/pages/reporting/firefighting/helpers';
import type { DropGroup } from 'apis/rest/firefighting/types';

type PartialViewState = Pick<ViewState, 'longitude' | 'latitude' | 'zoom'>;

const disablePitch = (event: { target?: Map }) => {
  event.target?.dragRotate.disable();
  event.target?.touchZoomRotate.disableRotation();
};

interface DropArc {
  id: string;
  from: [number, number];
  to: [number, number];
  color: ColorAlpha;
  dropVol: number;
}

interface DropLine {
  path: [number, number][];
  color: ColorAlpha;
}

interface DropFillHeatmapScorecardProps {
  dropGroups: DropGroup[];
  hoveredDropGroup?: string;
  selectedDropGroup?: string;
  visibleSuppressants: Record<Suppressant, boolean>;
}

interface ExtDropFillHeatmapScorecardProps extends Pick<DropFillHeatmapScorecardProps, 'hoveredDropGroup' | 'selectedDropGroup'> {
  dropArcs: DropArc[];
  dropLines: DropLine[];
  dropGroups: DropGroup[];
  initialViewport?: WebMercatorViewport;
  onMove: (event: ViewStateChangeEvent) => void;
  setFullscreen: (isFullscreen: boolean) => void;
  isFullscreen: boolean;
  viewState: PartialViewState | undefined;
  canFitBounds?: boolean;
}

const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
const WHITE: Color = [255, 255, 255];

const DeckGLOverlay = (props: MapboxOverlayProps) => {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

const DropFillHeatmap = ({
  selectedDropGroup,
  hoveredDropGroup,
  dropArcs,
  dropGroups,
  dropLines,
  initialViewport,
  onMove,
  setFullscreen,
  isFullscreen,
  viewState,
  canFitBounds,
}: ExtDropFillHeatmapScorecardProps) => {
  const mapTemplate = useMapTemplate();
  const ref = useRef<HTMLDivElement>(null);
  const { default: map } = useMap();

  const suppressantColor = useSuppressantColors();

  const {
    width,
    height,
  } = useSize(ref);

  useEffect(() => {
    if (canFitBounds) {
      const paddingAmount = Math.min(width, height, 20);
      const padding = { top: paddingAmount, bottom: paddingAmount, left: paddingAmount, right: paddingAmount };
      if (selectedDropGroup) {
        const selectedArc = dropArcs.find(d => d.id === selectedDropGroup);
        if (selectedArc) {
          map?.fitBounds(safeBounds(points([selectedArc.from, selectedArc.to])), { padding });
        }
      } else if (dropGroups.length > 0) {
        map?.fitBounds(safeBounds(
          points(dropGroups.flatMap(
            dg => [...dg.fills, ...dg.drops].flatMap(f => [f.start, f.end])
          ))
        ), { padding });
      }
    }
  }, [canFitBounds, width, height, dropGroups, selectedDropGroup, dropArcs, map]);

  const arcLayer = useMemo(() => new ArcLayer<DropArc>({
    id: 'drop-arcs',
    data: dropArcs,
    getWidth: d => d.dropVol / 200,
    widthUnits: 'meters',
    widthMaxPixels: 4,
    widthMinPixels: 1,
    getSourcePosition: d => d.from,
    getTargetPosition: d => d.to,
    getSourceColor: d => [...d.color.slice(0, 3), d.id === hoveredDropGroup ? 255 : 80] as ColorAlpha,
    getTargetColor: d => [255, 0, 0, d.id === hoveredDropGroup ? 255 : 20],
    opacity: 1,
    updateTriggers: {
      getSourceColor: [hoveredDropGroup],
      getTargetColor: [hoveredDropGroup],
    },
  }), [dropArcs, hoveredDropGroup]);

  const dropDetailLayers = useMemo(() => [
    new ScatterplotLayer<[number, number]>({
      id: 'drop-heatmap-dots-outline',
      data: dropGroups.flatMap(dg => [dg.locations.maxFill?.coords, ...dg.drops.flatMap(d => [d.start, d.end])]),
      getRadius: 18,
      radiusUnits: 'meters',
      radiusMinPixels: 5,
      opacity: 0.5,
      getFillColor: WHITE,
      getPosition: d => d,
    }),
    new ScatterplotLayer<DropArc>({
      id: 'fill-heatmap-outline',
      data: dropArcs,
      getRadius: 15,
      radiusUnits: 'meters',
      radiusMinPixels: 5,
      getLineWidth: 10,
      lineWidthUnits: 'meters',
      lineWidthMinPixels: 4,
      opacity: 0.5,
      stroked: true,
      filled: false,
      getLineColor: WHITE,
      getPosition: d => d.from,
    }),
    new ScatterplotLayer<DropArc>({
      id: 'fill-heatmap',
      data: dropArcs,
      getRadius: 15,
      radiusUnits: 'meters',
      radiusMinPixels: 4,
      getLineWidth: 5,
      lineWidthUnits: 'meters',
      lineWidthMinPixels: 2,
      opacity: 0.2,
      stroked: true,
      filled: false,
      getLineColor: d => d.color,
      getPosition: d => d.from,
    }),
    new ScatterplotLayer<{ coords: [number, number], suppressant: Suppressant }>({
      id: 'drop-heatmap-dots',
      data: dropGroups.flatMap(dg => dg.drops.flatMap(d => [d.start, d.end].map(c => ({ coords: c, suppressant: dg.suppressant })))),
      getRadius: 15,
      radiusUnits: 'meters',
      radiusMinPixels: 4,
      opacity: 0.2,
      getFillColor: d => hexToRGBArray(suppressantColor[d.suppressant]),
      getPosition: d => d.coords,
    }),
    new PathLayer<DropLine>({
      id: 'drop-heatmap-lines',
      data: dropLines,
      getWidth: 10,
      widthUnits: 'meters',
      widthMinPixels: 4,
      capRounded: true,
      opacity: 0.2,
      getColor: d => d.color,
      getPath: d => d.path,
    }),
  ], [suppressantColor, dropArcs, dropLines, dropGroups]);

  const layers = useMemo(() => [...dropDetailLayers, arcLayer], [dropDetailLayers, arcLayer]);

  if (dropGroups.length === 0) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={{
        minHeight: '200px',
        minWidth: '50px',
        position: 'sticky',
      }}
    >
      <ReactMapGl
        reuseMaps
        mapStyle={mapTemplate.template}
        mapboxAccessToken={mapboxToken}
        initialViewState={initialViewport}
        {...viewState} // @ts-ignore onLoad isn't typed
        onLoad={disablePitch}
        onMove={onMove}
        projection={{ name: 'mercator' }}
        attributionControl={mapTemplate.provider === 'Mapbox'}
      >
        {mapTemplate.provider !== 'Mapbox' && <AttributionControl customAttribution={`Maps provided by ${mapTemplate.provider}`} />}
        <DeckGLOverlay layers={layers} />
      </ReactMapGl>
      <Stack direction="row" spacing={0} sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        '& button': { borderRadius: '8px' },
      }}>
        <ControlButton
          tooltipPlacement="bottom"
          text={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
          onClick={() => setFullscreen(!isFullscreen)}
          className={clsx({ highContrastClass: true })}
          id="fullscreenToggle"
        >
          {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
        </ControlButton>
      </Stack>
    </Box>
  );
};

const DropFillHeatmapWithProvider = (props: ExtDropFillHeatmapScorecardProps) => <MapProvider><DropFillHeatmap {...props} /></MapProvider>;

export const DropFillHeatmapScorecard = ({
  dropGroups,
  visibleSuppressants,
  hoveredDropGroup,
  selectedDropGroup,
}: DropFillHeatmapScorecardProps) => {
  const [isFullscreen, setFullscreen] = useState<boolean>(false);
  const [viewState, setViewState] = useState<PartialViewState>();

  const onMove = useCallback((event: ViewStateChangeEvent) => setViewState({
    longitude: event.viewState.longitude,
    latitude: event.viewState.latitude,
    zoom: event.viewState.zoom,
  }), [setViewState]);

  const boxRef = useRef<HTMLDivElement>(null);
  const suppressantColor = useSuppressantColors();
  const { width, height } = useSize(boxRef);

  const shownDropGroups = useMemo(
    () => dropGroups.filter(dg => visibleSuppressants[dg.suppressant]),
    [visibleSuppressants, dropGroups]
  );
  const dropArcs: DropArc[] = useMemo(() => shownDropGroups.flatMap(dg => {
    if (!dg.locations.maxFill) {
      return [];
    }
    return [{
      id: dg.id,
      from: dg.locations.maxFill.coords,
      to: dg.locations.averageDrop.coords,
      color: hexToRGBArray(suppressantColor[dg.suppressant]),
      dropVol: dg.volumeDroppedLitres,
    }];
  }), [suppressantColor, shownDropGroups]);

  const dropLines: DropLine[] = useMemo(() => shownDropGroups.flatMap(dg => dg.drops.map(d => ({ path: [d.start, d.end], color: hexToRGBArray(suppressantColor[dg.suppressant]) }))), [shownDropGroups, suppressantColor]);

  const viewport = useMemo(
    () => {
      if (shownDropGroups.length > 0) {
        return (new WebMercatorViewport({
          width,
          height,
          ...viewState,
        }).fitBounds(safeBounds(points(shownDropGroups.flatMap(dg => [...dg.fills, ...dg.drops].flatMap(f => [f.start, f.end])))), { padding: Math.min(width, height, 20) }));
      }
      return undefined;
    },
    // we don't want it to update with every viewstate change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, height, shownDropGroups],
  );

  return (
    <>
      <Box ref={boxRef} sx={{ display: 'grid' }}>
        <DropFillHeatmapWithProvider
          hoveredDropGroup={hoveredDropGroup}
          selectedDropGroup={selectedDropGroup}
          dropGroups={shownDropGroups}
          dropArcs={dropArcs}
          dropLines={dropLines}
          initialViewport={viewport}
          onMove={onMove}
          setFullscreen={setFullscreen}
          isFullscreen={isFullscreen}
          viewState={viewState}
          canFitBounds
        />
      </Box>
      <Dialog
        open={isFullscreen}
        fullWidth
        maxWidth={false}
        onClose={() => setFullscreen(false)}
        PaperProps={{ sx: { minHeight: '90vh' } }}
        keepMounted
      >
        <DialogContent sx={{ display: 'grid', p: 0 }}>
          <DropFillHeatmapWithProvider
            dropArcs={dropArcs}
            dropLines={dropLines}
            dropGroups={shownDropGroups}
            initialViewport={viewport}
            onMove={onMove}
            setFullscreen={setFullscreen}
            isFullscreen={isFullscreen}
            viewState={viewState}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
