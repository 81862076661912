import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactMapGl, {
  MapProvider,
  AttributionControl,
  useControl,
  useMap,
  ViewStateChangeEvent,
  ViewState,
} from 'react-map-gl';
import clsx from 'clsx';
import { Box, Dialog, DialogContent, Stack } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import useMapTemplate from 'hooks/settings/map/useMapConfig';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox';
import { points } from '@turf/helpers';
import { geoCentroid } from 'd3';
import { ArcLayer, PathLayer, ScatterplotLayer } from '@deck.gl/layers';
import WebMercatorViewport from '@math.gl/web-mercator';
import ControlButton from 'components/map/modules/controls/control-button';
import { hexToRGBArray } from 'helpers/color';
import { safeBounds } from 'helpers/geo';
import { useSize } from 'hooks/useSize';
import { getSuppressantColors } from 'components/pages/reporting/firefighting/helpers';
import { useTheme } from '@mui/material/styles';
import { calculateDropGroups } from './assetDropsDetail/dropGroups';

type PartialViewState = Pick<ViewState, 'longitude' | 'latitude' | 'zoom'>;

interface DropArc {
  id: string;
  from: [number, number];
  to: [number, number];
  color: ColorAlpha;
  dropVol: number;
}

interface DropLine {
  path: [number, number][];
  color: ColorAlpha;
  type: DropType;
}

interface ReportWithSuppressantAndType extends TripSlimReport {
  suppressant: Suppressant;
  dropType: DropType;
  volume?: number;
}

interface DropFillHeatmapScorecardProps {
  trips: Trip[];
  hoveredDropGroup?: string;
  selectedDropGroup?: string;
  visibleSuppressants: Record<Suppressant, boolean>;
}

interface ExtDropFillHeatmapScorecardProps extends Pick<DropFillHeatmapScorecardProps, 'trips' | 'hoveredDropGroup' | 'selectedDropGroup'> {
  dropArcs: DropArc[];
  dropReports: ReportWithSuppressantAndType[];
  dropLines: DropLine[];
  initialViewport?: WebMercatorViewport;
  onMove: (event: ViewStateChangeEvent) => void;
  setFullscreen: (isFullscreen: boolean) => void;
  isFullscreen: boolean;
  viewState: PartialViewState | undefined;
  canFitBounds?: boolean;
}

const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
const WHITE: Color = [255, 255, 255];

const DeckGLOverlay = (props: MapboxOverlayProps) => {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

const getDropReportsFromDropsAndTrips = (drops: Drop[], trip: Trip, visibleSuppressants: Record<Suppressant, boolean>): ReportWithSuppressantAndType[] => drops.flatMap(d => {
  if (!visibleSuppressants[d.suppressant]) {
    return [];
  }
  return [d.startReportId, d.endReportId]
    .flatMap(r => {
      const report = trip.reports.find(sr => sr.id === r);
      if (!report) {
        return [] as ReportWithSuppressantAndType[];
      }
      return [{
        ...report,
        suppressant: d.suppressant,
        dropType: d.type,
        volume: d.type === 'Drop' ? d.dropVolume : d.endVolume,
      }];
    });
});

const getDropLinesFromDropsAndTrips = (drops: Drop[], trip: Trip, visibleSuppressants: Record<Suppressant, boolean>, suppressantColor: Record<Suppressant, string>) => drops.flatMap(d => {
  const reports = getDropReportsFromDropsAndTrips([d], trip, visibleSuppressants);
  if (reports.length < 2) {
    return [];
  }
  return [{
    path: [reports[0].coords, reports[1].coords],
    color: hexToRGBArray(suppressantColor[d.suppressant]),
    type: d.type,
  }];
});

const DropFillHeatmap = ({
  trips,
  selectedDropGroup,
  hoveredDropGroup,
  dropArcs,
  dropReports,
  dropLines,
  initialViewport,
  onMove,
  setFullscreen,
  isFullscreen,
  viewState,
  canFitBounds,
}: ExtDropFillHeatmapScorecardProps) => {
  const mapTemplate = useMapTemplate();
  const ref = useRef<HTMLDivElement>(null);
  const { default: map } = useMap();

  const theme = useTheme();
  const suppressantColor = useMemo(() => getSuppressantColors(theme), [theme]);

  const {
    width,
    height,
  } = useSize(ref);

  useEffect(() => {
    if (canFitBounds) {
      if (selectedDropGroup) {
        const selectedArc = dropArcs.find(d => d.id === selectedDropGroup);
        if (selectedArc) {
          map?.fitBounds(safeBounds(points([selectedArc.from, selectedArc.to])), { padding: Math.min(width, height, 20) });
        }
      } else if (dropReports.length > 0) {
        map?.fitBounds(safeBounds(points(dropReports.map(d => d.coords))), { padding: Math.min(width, height, 20) });
      }
    }
  }, [canFitBounds, width, height, dropReports, selectedDropGroup, dropArcs, map]);

  const arcLayer = useMemo(() => new ArcLayer<DropArc>({
    id: 'drop-arcs',
    data: dropArcs,
    getWidth: d => d.dropVol / 200,
    widthUnits: 'meters',
    widthMaxPixels: 4,
    widthMinPixels: 1,
    getSourcePosition: d => d.from,
    getTargetPosition: d => d.to,
    getSourceColor: d => [...d.color.slice(0, 3), d.id === hoveredDropGroup ? 255 : 80] as ColorAlpha,
    getTargetColor: d => [255, 0, 0, d.id === hoveredDropGroup ? 255 : 20],
    opacity: 1,
    updateTriggers: {
      getSourceColor: [hoveredDropGroup],
      getTargetColor: [hoveredDropGroup],
    },
  }), [dropArcs, hoveredDropGroup]);

  const dropDetailLayers = useMemo(() => [
    new ScatterplotLayer<ReportWithSuppressantAndType>({
      id: 'drop-heatmap-dots-outline',
      data: dropReports.filter(d => d.dropType === 'Drop'),
      getRadius: 18,
      radiusUnits: 'meters',
      radiusMinPixels: 5,
      opacity: 0.5,
      getFillColor: WHITE,
      getPosition: d => d.coords,
    }),
    new ScatterplotLayer<DropArc>({
      id: 'fill-heatmap-outline',
      data: dropArcs,
      getRadius: 15,
      radiusUnits: 'meters',
      radiusMinPixels: 5,
      getLineWidth: 10,
      lineWidthUnits: 'meters',
      lineWidthMinPixels: 4,
      opacity: 0.5,
      stroked: true,
      filled: false,
      getLineColor: WHITE,
      getPosition: d => d.from,
    }),
    new ScatterplotLayer<DropArc>({
      id: 'fill-heatmap',
      data: dropArcs,
      getRadius: 15,
      radiusUnits: 'meters',
      radiusMinPixels: 4,
      getLineWidth: 5,
      lineWidthUnits: 'meters',
      lineWidthMinPixels: 2,
      opacity: 0.2,
      stroked: true,
      filled: false,
      getLineColor: d => d.color,
      getPosition: d => d.from,
    }),
    new ScatterplotLayer<ReportWithSuppressantAndType>({
      id: 'drop-heatmap-dots',
      data: dropReports.filter(d => d.dropType === 'Drop'),
      getRadius: 15,
      radiusUnits: 'meters',
      radiusMinPixels: 4,
      opacity: 0.2,
      getFillColor: d => hexToRGBArray(suppressantColor[d.suppressant]),
      getPosition: d => d.coords,
    }),
    new PathLayer({
      id: 'drop-heatmap-lines',
      data: dropLines.filter(d => d.type === 'Drop'),
      getWidth: 10,
      widthUnits: 'meters',
      widthMinPixels: 4,
      capRounded: true,
      opacity: 0.2,
      getColor: (d: DropArc) => d.color,
      getPath: d => d.path,
    }),
  ], [suppressantColor, dropArcs, dropLines, dropReports]);

  const layers = useMemo(() => [...dropDetailLayers, arcLayer], [dropDetailLayers, arcLayer]);

  if (trips.reduce((p, d) => p + d.drops.length, 0) === 0) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={{
        minHeight: '200px',
        minWidth: '50px',
        position: 'sticky',
      }}
    >
      <ReactMapGl
        reuseMaps
        mapStyle={mapTemplate.template}
        mapboxAccessToken={mapboxToken}
        initialViewState={initialViewport}
        {...viewState}
        onLoad={event => {
          event.target.dragRotate.disable();
          event.target.touchZoomRotate.disableRotation();
        }}
        onMove={onMove}
        projection={{ name: 'mercator' }}
        attributionControl={mapTemplate.provider === 'Mapbox'}
      >
        {mapTemplate.provider !== 'Mapbox' && <AttributionControl customAttribution={`Maps provided by ${mapTemplate.provider}`} />}
        <DeckGLOverlay layers={layers} />
      </ReactMapGl>
      <Stack direction="row" spacing={0} sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        '& button': { borderRadius: '8px' },
      }}>
        <ControlButton
          tooltipPlacement="bottom"
          text={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
          onClick={() => setFullscreen(!isFullscreen)}
          className={clsx({ highContrastClass: true })}
          id="fullscreenToggle"
        >
          {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
        </ControlButton>
      </Stack>
    </Box>
  );
};

const DropFillHeatmapWithProvider = (props: ExtDropFillHeatmapScorecardProps) => <MapProvider><DropFillHeatmap {...props} /></MapProvider>;

export const DropFillHeatmapScorecard = ({
  trips,
  visibleSuppressants,
  hoveredDropGroup,
  selectedDropGroup,
}: DropFillHeatmapScorecardProps) => {
  const [isFullscreen, setFullscreen] = useState<boolean>(false);
  const [viewState, setViewState] = useState<PartialViewState>();

  const onMove = (event: ViewStateChangeEvent) => setViewState({
    longitude: event.viewState.longitude,
    latitude: event.viewState.latitude,
    zoom: event.viewState.zoom,
  });

  const boxRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const suppressantColor = useMemo(() => getSuppressantColors(theme), [theme]);
  const { width, height } = useSize(boxRef);

  const dropGroups = useMemo(() => calculateDropGroups(trips), [trips]);
  const dropArcs: DropArc[] = useMemo(() => dropGroups.flatMap(dg => {
    const { trip } = dg;
    if (!trip) {
      return [];
    }
    const dgFills = getDropReportsFromDropsAndTrips(dg.drops.filter(d => d.type !== 'Drop'), trip, visibleSuppressants);
    const dgDrops = getDropReportsFromDropsAndTrips(dg.drops.filter(d => d.type === 'Drop'), trip, visibleSuppressants);

    const suppressantType = dg.drops.filter(d => d.type === 'Drop')[0].suppressant;
    if (!visibleSuppressants[suppressantType]) {
      return [];
    }
    const averageFill = dgFills.at(0)?.coords;
    if (!averageFill || dgDrops.length === 0) {
      return [];
    }

    const averageDrop = geoCentroid(points(dgDrops.map(r => r.coords)));
    const vol = dgDrops.reduce((p, d) => (d.volume ? p + d.volume : p), 0);
    return [{
      id: dg.id,
      from: averageFill,
      to: averageDrop,
      color: hexToRGBArray(suppressantColor[suppressantType]),
      dropVol: vol,
    }];
  }), [suppressantColor, dropGroups, visibleSuppressants]);

  const dropReports = useMemo(() => trips.flatMap(t => getDropReportsFromDropsAndTrips(t.drops, t, visibleSuppressants)), [trips, visibleSuppressants]);
  const dropLines: DropLine[] = useMemo(() => trips.flatMap(t => getDropLinesFromDropsAndTrips(t.drops, t, visibleSuppressants, suppressantColor)), [suppressantColor, trips, visibleSuppressants]);

  const viewport = useMemo(
    () => {
      if (dropReports.length > 0) {
        return (new WebMercatorViewport({
          width,
          height,
          ...viewState,
        }).fitBounds(safeBounds(points(dropReports.map(d => d.coords))), { padding: Math.min(width, height, 20) }));
      }
      return undefined;
    },
    [width, height, viewState, dropReports],
  );

  return (
    <>
      <Box ref={boxRef} sx={{ display: 'grid' }}>
        <DropFillHeatmapWithProvider
          trips={trips}
          hoveredDropGroup={hoveredDropGroup}
          selectedDropGroup={selectedDropGroup}
          dropArcs={dropArcs}
          dropReports={dropReports}
          dropLines={dropLines}
          initialViewport={viewport}
          onMove={onMove}
          setFullscreen={setFullscreen}
          isFullscreen={isFullscreen}
          viewState={viewState}
          canFitBounds
        />
      </Box>
      <Dialog
        open={isFullscreen}
        fullWidth
        maxWidth={false}
        onClose={() => setFullscreen(false)}
        PaperProps={{ sx: { minHeight: '90vh' } }}
        keepMounted
      >
        <DialogContent sx={{ display: 'grid', p: 0 }}>
          <DropFillHeatmapWithProvider
            trips={trips}
            dropArcs={dropArcs}
            dropReports={dropReports}
            dropLines={dropLines}
            initialViewport={viewport}
            onMove={onMove}
            setFullscreen={setFullscreen}
            isFullscreen={isFullscreen}
            viewState={viewState}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
