import React, { JSXElementConstructor, ReactNode, useMemo } from 'react';
import useTimezone from 'hooks/session/useTimezone';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import useDateTime from 'hooks/settings/useDateTime';
import { Tooltip } from '@mui/material';
import TimeSinceView from './timeSince-view';

export interface TimeSinceProps {
  time?: number
  className?: string
  tooltip: boolean
  // Moment format
  tooltipFormat?: string
  Component: string | JSXElementConstructor<{ children: ReactNode, className?: string }>
  fallbackString?: string
}

const TimeSince: React.FC<TimeSinceProps> = ({ time, Component = 'span', className, tooltip, tooltipFormat, fallbackString }) => {
  const timezone = useTimezone();
  const selectedDay = useSelector<ReduxState, string | undefined>(state => state.app.selectedDay ?? undefined);
  const relativeTo = useMemo(
    () => (selectedDay ? DateTime.fromFormat(selectedDay, 'yyyy-MM-dd', { zone: timezone }) : undefined),
    [selectedDay, timezone],
  );
  const dt = time == null ? undefined : DateTime.fromMillis(time, { zone: timezone });

  const tooltipStr = useDateTime(dt?.toMillis(), tooltipFormat);

  const content = (
    <Component className={className}>
      <TimeSinceView time={dt} relativeTo={relativeTo} fallbackString={fallbackString} />
    </Component>
  );

  if (!tooltip) return content;

  return (
    <Tooltip title={tooltipStr} placement="bottom" arrow>
      {content}
    </Tooltip>
  );
};

export default TimeSince;
