import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useFeature from 'hooks/features/useFeature';
import { useStaff } from 'hooks/session/useStaff';
import { assetGroupsQueryKeys } from './queryKeys';
import { AssetGroup } from './types';
import {
  createAssetGroup,
  deleteAssetGroup,
  getAssetGroupsForOrganisation,
  updateAssetGroup,
  updateAssetGroupAssets
} from './requests';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetAssetGroupsForOrganisation = <T = AssetGroup[]>(options?: Options<AssetGroup[], T>) => {
  const isStaff = useStaff();
  const enabled = !!useFeature('manage.assetGroups') || isStaff;
  const organisationId = useOrganisationId();
  const queryKey = assetGroupsQueryKeys.all(organisationId);
  const query = useQuery<AssetGroup[], HttpResponseError, T>(
    queryKey,
    () => getAssetGroupsForOrganisation(organisationId),
    { ...options, enabled: enabled && options?.enabled },
  );
  return query;
};

export const useCreateAssetGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<AssetGroup, HttpResponseError, Pick<AssetGroup, 'name'>>(
    value => createAssetGroup(organisationId, value.name),
    {
      mutationKey: ['createAssetGroup'],
      onSuccess: () => queryClient.invalidateQueries(assetGroupsQueryKeys.all(organisationId)),
    }
  );
};

export const useUpdateAssetGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { assetGroupId: number, assetGroupName: string }>(
    value => updateAssetGroup(organisationId, value.assetGroupId, value.assetGroupName),
    {
      mutationKey: ['updateAssetGroup'],
      onSuccess: () => queryClient.invalidateQueries(assetGroupsQueryKeys.all(organisationId)),
    }
  );
};

export const useUpdateAssetGroupAssets = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { assetGroupId: number, assetIds: number[] }>(
    value => updateAssetGroupAssets(organisationId, value.assetGroupId, value.assetIds),
    {
      mutationKey: ['updateAssetGroupAssets'],
      onSuccess: () => queryClient.invalidateQueries(assetGroupsQueryKeys.all(organisationId)),
    }
  );
};

export const useDeleteAssetGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<AssetGroup, 'id'>>(
    value => deleteAssetGroup(organisationId, value.id),
    {
      mutationKey: ['deleteAssetGroup'],
      onSuccess: () => queryClient.invalidateQueries(assetGroupsQueryKeys.all(organisationId)),
    }
  );
};
