import React from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { fetchOrganisationJobs } from 'apis/rest/jobs';
import { useQuery } from '@tanstack/react-query';
import { useAssetPositions, useAssetReports } from 'repositories/reports/hooks';
import useFeature from 'hooks/features/useFeature';
import Details from './details';
import Activity from './activity';
import Jobs from './jobs';
import CollapsePanel from '../modules/collapsePanel';
import useStyles from '../contextbox-styles';
import useTimezone from "hooks/session/useTimezone";

const MODULES = [{
  id: 'jobs',
  Component: Jobs,
  shouldInclude: ({ jobs }) => jobs && jobs.length > 0,
  getProps: ({
    selectedAsset, jobs, timezone, now
  }) => ({
    selectedAsset, jobs, timezone, now
  })
}, {
  id: 'activity',
  Component: Activity,
  shouldInclude: () => true,
  getProps: ({ selectedAsset, reports, orderedLegs }) => ({ selectedAsset, reports, orderedLegs })
}];

const AssetContextBox = ({
  organisationId,
  selectedAsset,
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  contextboxOpen,
  now,
}) => {
  const timezone = useTimezone();
  const classes = useStyles();
  const reports = useAssetReports(selectedAsset?.id);
  const positions = useAssetPositions(selectedAsset?.id);

  const jobsEnabled = !!useFeature('assets.jobs');
  const getOrganisationJobs = useQuery(['jobs', organisationId, now], () => fetchOrganisationJobs(organisationId, now), {
    select: data => data?.filter(job => job.assetIds.includes(selectedAsset?.id?.toString())),
    enabled: jobsEnabled
  });

  if (!selectedAsset) return null;

  const modules = MODULES
    .filter(mod => mod.shouldInclude({
      selectedAsset,
      reports,
      positions,
      jobs: getOrganisationJobs.isSuccess ? getOrganisationJobs.data : [],
      now,
    }))
    .map(mod => {
      const { Component } = mod;
      const props = mod.getProps({
        selectedAsset,
        reports,
        positions,
        jobs: getOrganisationJobs.isSuccess ? getOrganisationJobs.data : [],
        now,
        timezone,
      });
      return (
        <Component key={mod.id} {...props} />
      );
    });

  return (
    <Box className={clsx(classes.contextboxWrapper, { [classes.collapsed]: contextboxOpen })}>
      <Box className={classes.contextbox}>
        <Box className={classes.contentWrapper}>
          <Details asset={selectedAsset} />
          {modules}
        </Box>
      </Box>

      <CollapsePanel />
    </Box>
  );
};

export default AssetContextBox;
