import React, { useCallback } from 'react';
import type { FeatureCollection } from 'geojson';
import { useDispatch } from 'react-redux';
import { manageGeofencesSlice as slice } from 'slices/manageGeofences.slice';
import { getValidFeatureCollection } from '../helpers';

type OnImport = (name: string, featureCollection: FeatureCollection, replace: boolean) => void

interface GeofenceImportProps {
  children: (onImport: OnImport) => React.ReactElement
  onComplete: (name: string, success: boolean) => void
}

const GeofenceImport: React.FC<GeofenceImportProps> = ({ children, onComplete }) => {
  const dispatch = useDispatch();
  const onImport: OnImport = useCallback((name, featureCollection, replace) => {
    if (replace) dispatch(slice.actions.clearFeatures());

    const validFeatureCollection = getValidFeatureCollection(featureCollection);

    if (validFeatureCollection.features.length) {
      dispatch(slice.actions.updateFeature(validFeatureCollection.features));
      onComplete(name, true);
    } else {
      onComplete(name, false);
    }
  }, [dispatch, onComplete]);

  return children(onImport);
};

export default GeofenceImport;
