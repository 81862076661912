import { connect } from 'react-redux';
import actionCreators from 'actions';
import BaseLayerPicker from './baseLayerPicker-view';

export default connect(
  () => ({}),
  {
    setBaseLayer: actionCreators.map.setBaseLayer
  },
)(BaseLayerPicker);
