import React, { ReactNode } from 'react';
import { Box, Stack, StackProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { excludeForwardProps } from 'utils/style';

const ImageWrapper = styled(Box, {
  shouldForwardProp: excludeForwardProps(['active', 'imageSize']),
})<{ active: boolean, imageSize: string | undefined }>(({ theme, active, imageSize = '50px' }) => `
  box-sizing: content-box;
  width: ${imageSize};
  height: ${imageSize};
  cursor: pointer;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  border-color: ${active ? theme.palette.primary.main : '#00000000'};
  transition: all 0.25s;
  overflow: hidden;
  
  &:hover {
    border-color: ${active ? theme.palette.primary.main : theme.palette.common.blueConstant};
  }

  & svg,img {
    display: block;
    width: 100%;
    height: auto;
  }
`);

export interface CirclePickerOption<T> {
  value: T
  image: ReactNode
  label: string
  tooltip?: ReactNode
}

interface CirclePickerProps<T> {
  value: T | undefined
  options: CirclePickerOption<T>[]
  onChange: (value: T) => void
  imageSize?: string
  stackProps?: StackProps
}

const CirclePicker = <T extends string | number>({ value, options, onChange, imageSize, stackProps }: CirclePickerProps<T>) => (
  <Stack direction="row" role="radiogroup" spacing={1} py={1} {...stackProps}>
    {options.map(option => (
      <Box key={option.value} role="radio" aria-checked={option.value === value} aria-label={option.label} tabIndex={0} onClick={() => onChange(option.value)}>
        <Tooltip title={option.tooltip ?? option.label}>
          <ImageWrapper active={option.value === value} imageSize={imageSize}>
            {option.image}
          </ImageWrapper>
        </Tooltip>
      </Box>
    ))}
  </Stack>
);

export default CirclePicker;
