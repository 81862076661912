import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {Navigate, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import type { MultiPolygon, Polygon } from 'geojson';
import { useTranslations } from 'use-intl';
import { Stack } from '@mui/material';
import { useDeleteGeofence, useGetGeofences, useUpdateGeofence } from 'apis/rest/geofence/hooks';
import { manageGeofencesSlice as slice } from 'slices/manageGeofences.slice';
import usePermissions from 'hooks/session/usePermissions';
import useSnackbar from 'hooks/useSnackbar';
import { useFitMapToFeatures } from '../map/hooks';
import GeofencePropertiesTable from '../create/GeofenceProperties';
import GeofenceImportModalView from '../import/GeofenceImportModal.view';
import type { GeofenceCreateTableDetails } from '../types';
import { getId, getValidMultiPolygon } from '../helpers';
import GeofenceBlocker from '../create/GeofenceBlocker';
import GeofenceDeleteView from './GeofenceDelete.view';
import { GeofenceNotificationsTable } from './GeofenceNotificationsTable';
import GeofenceImport from '../import/GeofenceImport';

const GeofenceEdit = () => {
  const t = useTranslations('pages.manage.geofencing');
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const params = useParams<{ geofenceId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const displayImportModal = searchParams.has('import');

  const geofenceQuery = useGetGeofences();
  const geofence = useMemo(() => {
    if (!params.geofenceId) return undefined;
    const id = parseInt(params.geofenceId, 10);
    return geofenceQuery.data?.find(g => g.id === id);
  }, [params.geofenceId, geofenceQuery.data]);

  const closeImportModal = useCallback(() => {
    setSearchParams(p => {
      p.delete('import');
      return p;
    });
  }, [setSearchParams]);

  const fitMap = useFitMapToFeatures();
  const fitAfterImport = useRef<boolean>(false);

  const onCompleteImport = useCallback((name: string, success: boolean) => {
    if (success) {
      fitAfterImport.current = true;
    } else {
      snackbar.display({
        id: `geofence.import.error.${getId()}`,
        type: 'error',
        text: t('snackbar.fileImportError', { name })
      });
    }
    closeImportModal();
  }, [closeImportModal, snackbar, t]);

  const { features } = useSelector(slice.selectors.selectMapDraw);

  useEffect(() => {
    if (!fitAfterImport.current) return;
    fitAfterImport.current = false;
    fitMap(features);
  }, [features, fitMap]);

  useEffect(() => {
    if (!geofence) return;
    dispatch(slice.actions.editGeofence(geofence));
    fitMap([{ type: 'Feature', properties: {}, geometry: geofence.geometry }]);
  }, [dispatch, geofence, fitMap]);

  const updateMutation = useUpdateGeofence();
  const deleteMutation = useDeleteGeofence();

  const onSave = (data: GeofenceCreateTableDetails) => {
    if (features.length === 0 || data.id === undefined || !geofence) return;

    const coordinates = features.flatMap(f => (
      f.geometry.type === 'MultiPolygon' ? (f.geometry as MultiPolygon).coordinates : [(f.geometry as Polygon).coordinates]
    ));

    updateMutation.mutate({
      id: geofence.id,
      name: data.name,
      description: data.description,
      category: data.category,
      altitudeRestriction: data.altitudeRestriction,
      geometry: getValidMultiPolygon({ type: 'MultiPolygon', coordinates }),
      organisationId: geofence.organisationId,
    }, {
      onSuccess: () => {
        snackbar.display({
          id: `geofence.update.${getId()}`,
          type: 'success',
          text: t('snackbar.updateSuccess', { name: data.name }),
        });
      },
      onError: () => {
        snackbar.display({
          id: `geofence.update.error.${getId()}`,
          type: 'error',
          text: t('snackbar.updateError', { name: data.name }),
        });
      },
    });
  };

  const onDelete = () => {
    if (!geofence) return;
    const { id, name } = geofence;

    deleteMutation.mutate(id, {
      onSuccess: () => {
        snackbar.display({
          id: `geofence.delete.${getId()}`,
          type: 'success',
          text: t('snackbar.deleteSuccess', { name }),
        });
      },
      onError: () => {
        snackbar.display({
          id: `geofence.delete.${getId()}`,
          type: 'error',
          text: t('snackbar.deleteError', { name }),
        });
      }
    });
  };

  const hasChanges = useSelector(slice.selectors.selectHasChanges);

  const onDiscard = () => {
    if (geofence) dispatch(slice.actions.reset());
  };

  if (updateMutation.isSuccess || deleteMutation.isSuccess) return <Navigate to=".." />;

  return (
    <Stack spacing={3}>
      <GeofencePropertiesTable
        onSave={onSave}
        onDiscard={onDiscard}
        isViewOnly={!permissions.canEditGeofences}
        enableCancel={hasChanges}
        isSaving={updateMutation.isLoading}
      />

      {permissions.canEditGeofences && <GeofenceDeleteView onDelete={onDelete} isLoading={deleteMutation.isLoading} />}

      {geofence && (
        <GeofenceNotificationsTable
          selectedGeofence={geofence}
          onRowOpen={id => navigate(`/manage/event-notifications/${id}`)}
        />
      )}

      <GeofenceImport onComplete={onCompleteImport}>
        {onImport => (
          <GeofenceImportModalView
            open={displayImportModal}
            onCancel={closeImportModal}
            onConfirm={onImport}
            allowReplace
          />
        )}
      </GeofenceImport>

      <GeofenceBlocker enabled={hasChanges} />
    </Stack>
  );
};

export default GeofenceEdit;
