import React, { useEffect, useState } from 'react';
import {
  Button, Box, Container,
  Dialog, DialogTitle, DialogContent,
  DialogActions, DialogContentText,
  List, ListItem, ListItemText
} from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import useTranslation from 'hooks/useTranslation';
import { MTableAction } from '@material-table/core';
import { Add } from '@mui/icons-material';
import tableIcons from 'components/shared/icons/tableIcons';
import insensitiveSort from 'utils/insensitiveSort';
import { useGetGroupsList } from 'apis/rest/groups/hooks';
import { Group } from 'apis/rest/groups/types';
import { useMutateDeleteFriendGroup, useMutateNewFriendGroup } from 'apis/rest/friends/hooks';
import PersistentTable from 'components/shared/persistentTable';
import StickyPagination from 'components/shared/stickyPagination';
import mixpanel from 'mixpanel-browser';
import useStyles from '../friends/friends-styles';

const permissionKeys = [
  'canViewCurrent',
  'canViewHistory',
  'canViewForms',
  'canSendTextMessages',
  'canSendConfiguration',
  'canEditCallSign',
] as const;

interface GroupsTableParams {
  userPermissions: any;
  displaySnackbar: (Snack: Snack) => void;
  organisationId: string;
}

const GroupsTable = ({
  userPermissions,
  displaySnackbar,
  organisationId,
}:GroupsTableParams): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('pages.connections.groups');
  const { canEditFriends } = userPermissions;
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group>();

  const groupsQuery = useGetGroupsList();
  useEffect(() => {
    if (groupsQuery.isError) displaySnackbar({ id: 'getGroupsFailedSnackbar', text: t('getGroupsFailed'), type: 'error' });
  }, [groupsQuery.isError, displaySnackbar, t]);

  //
  // Unfriend Group
  //
  const deleteMutation = useMutateDeleteFriendGroup();
  const handleRemoveGroup = (): void => {
    if (!selectedGroup) return;

    deleteMutation.mutate(selectedGroup, {
      onSuccess: () => {
        displaySnackbar({ id: 'groupRemoved', text: t('groupRemoved'), type: 'success' });
        mixpanel.track('Leave Group', { success: true });
        setGroupDialogOpen(false);
      },
      onError: () => {
        displaySnackbar({ id: 'removeGroupError', text: t('removeGroupError'), type: 'error' });
        mixpanel.track('Leave Group', { success: false });
      },
    });
  };

  //
  // Befriend Group
  //
  const addMutation = useMutateNewFriendGroup();
  const handleAddGroup = async (): Promise<any> => {
    if (!selectedGroup) return;

    addMutation.mutate(selectedGroup, {
      onSuccess: () => {
        displaySnackbar({ id: 'groupAdded', text: t('groupAdded'), type: 'success' });
        mixpanel.track('Join Group', { success: true });
        setGroupDialogOpen(false);
      },
      onError: () => {
        displaySnackbar({ id: 'addGroupError', text: t('addGroupError'), type: 'error' });
        mixpanel.track('Join Group', { success: false });
      },
    });
  };

  //
  // Actions
  //
  const handleRowClick = (e: React.MouseEvent | undefined, group: Group | undefined): void => {
    if (!group) return;
    setSelectedGroup(group);
    setGroupDialogOpen(true);
  };

  const footerRef = React.useRef<HTMLElement>();
  const Pagination = React.useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);

  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box
        className={classes.materialTable}
        bgcolor="common.white"
        borderRadius={1}
        border={1}
        borderColor="common.midGrey"
        mb={8}
      >
        <PersistentTable<Group>
          settingsCategory="groupsTable"
          title={t('title')}
          onRowClick={handleRowClick}
          icons={tableIcons}
          isLoading={groupsQuery.isLoading}
          columns={[
            {
              title: t('columns.name'),
              field: 'groupName',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              width: '32%',
              defaultSort: 'asc',
              customSort: (a, b) => insensitiveSort(a.groupName, b.groupName)
            },
            {
              title: 'Description',
              field: 'subtext',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              width: '40%',
            },
            {
              title: 'Region',
              field: 'country',
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              render: rowData => [rowData.state, rowData.country].join(' '),
            },
            {
              title: 'Friends',
              field: 'joined',
              headerStyle: { textAlign: 'center' },
              cellStyle: { textAlign: 'center' },
              render: rowData => rowData.groupFriends.some(gm => gm.organisationId === organisationId) && (<CheckCircle className={classes.groupFriendCheck} />),
              sorting: false,
              width: 0,
            }
          ]}
          data={groupsQuery.isSuccess ? groupsQuery.data : []}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            paging: true,
            pageSizeOptions: [10, 25, 50, 100],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
          }}
          localization={{
            header: {
              actions: t('columns.actions')
            }
          }}
          components={{
            Container: Box,
            Pagination,
            Action: canEditFriends ? (props: any) => {
              const { action, data } = props;
              return ((action.position === 'toolbar')
                ? (
                  <Button
                    onClick={event => action.onClick(event, data)}
                    className={classes.addButton}
                    variant="contained"
                  >
                    <Add />
                    {t('addButton')}
                  </Button>
                ) : <MTableAction {...props} />
              );
            } : undefined,
          }}
        />
        <Box ref={footerRef} position="sticky" bottom={0} />
      </Box>

      <Dialog open={groupDialogOpen} onClose={() => setGroupDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>{selectedGroup?.groupName}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogSubtext}>{selectedGroup?.subtext}</DialogContentText>
          <DialogContentText className={classes.dialogDesc}>{selectedGroup?.description}</DialogContentText>
          <DialogContentText className={classes.dialogHeading}>Group Members</DialogContentText>
          <List style={{
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
          }}>
            {selectedGroup?.groupMembers.map(gm => (
              <ListItem style={{ padding: '0 0 0 20px' }} key={gm.organisationId}><ListItemText>{gm.organisationName}</ListItemText></ListItem>
            ))}
          </List>
          <DialogContentText className={classes.dialogHeading}>Minimum Permissions Required</DialogContentText>
          <List>
            {selectedGroup && permissionKeys.filter(p => selectedGroup[p]).map(p => (
              <ListItem style={{ padding: '0 0 0 20px' }} key={p}>
                <ListItemText>{t(`permission.${p}`)}</ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" className={classes.inputButton} onClick={() => setGroupDialogOpen(false)}>{t('cancel')}</Button>
          {groupsQuery.isSuccess && selectedGroup && selectedGroup.groupFriends.some(gm => gm.organisationId === organisationId)
            ? <Button disabled={!selectedGroup} variant="contained" className={classes.inputButton} onClick={handleRemoveGroup}>{t('unfriendGroup')}</Button>
            : <Button disabled={!selectedGroup} variant="contained" className={classes.inputButton} onClick={handleAddGroup}>{t('befriendGroup')}</Button>}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GroupsTable;
