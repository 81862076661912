import { useContext, useMemo } from 'react';
import { FeatureAssetsContext, type FeatureAssetsContextValue } from './featureAssetsContext';

export interface FeatureAssetsResult {
  isLoading: boolean
  all: boolean
  some: boolean
  assetIds: number[]
  completeAssetGroups: number[]
  partialAssetGroups: number[]
}

const LOADING: FeatureAssetsResult = {
  isLoading: true,
  all: false,
  some: false,
  assetIds: [],
  completeAssetGroups: [],
  partialAssetGroups: []
};

const ALL: FeatureAssetsResult = {
  isLoading: false,
  all: true,
  some: true,
  assetIds: [],
  completeAssetGroups: [],
  partialAssetGroups: []
};

export function getFeatureAssets(value: FeatureAssetsContextValue, feature: string) {
  if (value.isLoading) return LOADING;

  const modules = value.data.filter(module => module.features.includes(feature));

  const all = modules.some(module => module.allowAllAssets);
  if (all) return ALL;

  const some = !!modules.length;

  const ids = modules.reduce<{ assets: Set<number>, completeAssetGroups: Set<number>, partialAssetGroups: Set<number> }>((acc, module) => {
    module.assetIds?.forEach(id => acc.assets.add(id));
    module.assetGroups?.forEach(g => {
      if (acc.completeAssetGroups.has(g.id)) return;
      if (g.complete) {
        acc.completeAssetGroups.add(g.id);
        acc.partialAssetGroups.delete(g.id);
      } else {
        acc.partialAssetGroups.add(g.id);
      }
    });
    return acc;
  }, { assets: new Set<number>(), completeAssetGroups: new Set<number>(), partialAssetGroups: new Set<number>() });

  return {
    isLoading: false,
    all,
    some,
    assetIds: [...ids.assets],
    completeAssetGroups: [...ids.completeAssetGroups],
    partialAssetGroups: [...ids.partialAssetGroups],
  };
}

function useFeatureAssets(feature: string): FeatureAssetsResult {
  const contextValue = useContext(FeatureAssetsContext);
  return useMemo<FeatureAssetsResult>(() => getFeatureAssets(contextValue, feature), [contextValue, feature]);
}

export default useFeatureAssets;
