import React from 'react';
import { Box, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { GeneralSettingsDialog } from 'components/statusbar/generalSettings/generalSettingsDialog-view';
import SystemStatusIcon from './systemStatus/systemStatus-view';
import CursorPosition from './cursorPosition';
import Clock from './clock';

const Bar = styled(Toolbar)(({ theme }) => `
  align-items: stretch;
  background-color: ${theme.palette.common.white};
  color: ${theme.palette.common.text};
  border-top: ${theme.border.default};
  min-height: var(--footer-bar-height);
  max-height: var(--footer-bar-height);
  z-index: 4;
  
  // Overrides default breakpoint min-height
  ${theme.breakpoints.up('xs')} {
    min-height: var(--footer-bar-height);
  }
`);

const PositionWrapper = styled(Box)(({ theme }) => `
  flex: 1;
  align-self: center;
  text-align: left;
  margin: ${theme.spacing(0, 1)};
`);

const StatusBar = () => (
  <Bar disableGutters>
    <SystemStatusIcon />
    <PositionWrapper>
      <CursorPosition />
    </PositionWrapper>
    <GeneralSettingsDialog />
    <Clock />
  </Bar>
);

export default StatusBar;
