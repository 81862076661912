import React, { useCallback, useMemo, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import WebMercatorViewport from '@math.gl/web-mercator';
import useSupercluster from 'use-supercluster';
import { BBox, Feature, Point } from 'geojson';
import { TextLayer } from '@deck.gl/layers';
import { memoize } from 'lodash';
import { useTranslations } from 'use-intl';
import MarkerIcon, { MarkerIconType } from 'components/shared/icons/markerIcons/markerIcon';
import { Marker } from 'apis/rest/markers/types';
import { hexToRGBArray } from 'helpers/color';
import { PathStyleExtension, PathStyleExtensionProps } from '@deck.gl/extensions';
import { useLatestPosition } from 'repositories/reports/hooks';
import { Coord } from 'repositories/reports/spline';
import OutlinedIconLayer from './outlinedIconLayer';
import AnimatedDashPathLayer from './animatedPathLayer';

const ICON_SIZE = 128;
const LABEL_COLOR: Color = [255, 255, 255];
const LABEL_BACKGROUND: ColorAlpha = [0, 0, 0, 128];
const LABEL_PADDING: [number, number, number, number] = [3, 1, 3, 1];

interface LabelData {
  text: string,
  position: [number, number, number],
}

const useLabelLayer = (
  markers: Marker[],
  visible: boolean,
  use3d: boolean,
) => {
  const data: LabelData[] = useMemo(() => markers.map(marker => ({
    text: marker.name,
    position: [marker.longitude, marker.latitude, use3d ? marker.altitude : 0],
  })), [markers, use3d]);

  return useMemo(() => new TextLayer<LabelData>({
    id: `marker-label-layer-${use3d ? '3d' : '2d'}`,
    data,
    getText: d => d.text,
    fontWeight: 600,
    getPosition: d => d.position,
    getSize: 12,
    getColor: LABEL_COLOR,
    getAngle: 0,
    getTextAnchor: 'middle',
    getAlignmentBaseline: 'top',
    getPixelOffset: [0, 32],
    visible,
    parameters: { depthTest: false },
    fontSettings: {
      sdf: true,
      fontSize: 30,
      radius: 12,
    },
    fontFamily: 'objektiv-mk2,sans-serif',
    background: true,
    getBackgroundColor: LABEL_BACKGROUND,
    backgroundPadding: LABEL_PADDING,
  }), [data, use3d, visible]);
};

interface IconData {
  marker: Marker;
  size: number;
  colour: string;
  icon: {
    id: string
    url: string
    height: number
    width: number
    anchorX: number
    anchorY: number
    mask: boolean
  };
  position: [number, number, number];
}

const getIcon = memoize((icon: MarkerIconType) => ({
  id: icon,
  // NOTE: We cannot pass in the correct asset color to the SVG because the way deck.gl renders it makes it black.
  //       It is passed into the OutlinedFragmentShader, which maps the white outline/black fill to white outline/<specified color> fill
  url: `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<MarkerIcon type={icon} />))}`,
  height: ICON_SIZE,
  width: ICON_SIZE,
  anchorX: ICON_SIZE / 2,
  anchorY: ICON_SIZE / 2,
  mask: true,
}));

type ClusteredMarker = Marker & { clusterId: string };

const useIconLayer = (
  markers: ClusteredMarker[],
  visible: boolean,
  use3d: boolean,
) => {
  const iconCache = useRef<Record<string, [IconData, IconData, IconData] | undefined>>({});
  const iconCache3D = useRef<Record<string, [IconData, IconData, IconData] | undefined>>({});

  const data = useMemo<IconData[]>(() => markers.flatMap(marker => {
    if (!marker) return [];

    const colour = marker.colour ?? '#ff69b4';

    let items = use3d ? iconCache3D.current[marker.clusterId] : iconCache.current[marker.clusterId];

    if (!items || items[0].colour !== colour || items[0].marker.longitude !== marker.longitude || items[0].marker.latitude!== marker.latitude) {
      const item: IconData = {
        marker,
        size: 5,
        colour,
        icon: getIcon(marker.icon),
        position: [marker.longitude, marker.latitude, use3d ? marker.altitude : 0],
      };

      items = [
        item,
        {
          ...item,
          position: [marker.longitude - 360, marker.latitude, use3d ? marker.altitude : 0],
        },
        {
          ...item,
          position: [marker.longitude + 360, marker.latitude, use3d ? marker.altitude : 0],
        },
      ];

      if (use3d) {
        iconCache3D.current[marker.clusterId] = items;
      } else {
        iconCache.current[marker.clusterId] = items;
      }
    }

    return items;
  }), [markers, use3d]);

  return useMemo(() => new OutlinedIconLayer<IconData>({
    id: 'marker-icon-layer',
    data,
    billboard: true,
    pickable: true,
    autoHighlight: true,
    getIcon: d => d.icon,
    sizeScale: 1,
    getPosition: d => d.position,
    getSize: 40,
    getAngle: 0,
    getColor: d => hexToRGBArray(d.colour),
    outlineColor: LABEL_COLOR,
    parameters: { depthTest: false },
    visible,
  }), [data, visible]);
};

const WHITE: ColorAlpha = [255, 255, 255, 255];
const BLACK: ColorAlpha = [0, 0, 0, 255];

interface LineDataItem {
  path: Coord[],
  color: ColorAlpha,
  width: number
}

const useMeasurementLineLayer = (
  marker: Marker | undefined,
  selectedAssetId: number | undefined,
  visible: boolean,
  use3d = false,
) => {
  const report = useLatestPosition(selectedAssetId);

  const lineData = useMemo<LineDataItem[]>(() => {
    const path: Coord[] = [];
    if (report && marker) {
      path.push(
        [report.longitude, report.latitude, use3d ? report.altitude : 0],
        [marker.longitude, marker.latitude, use3d ? marker.altitude : 0],
      );
    }

    return [
      { path, color: BLACK, width: 4 },
      { path, color: WHITE, width: 2 },
    ];
  }, [report, marker, use3d]);

  return useMemo(() => new AnimatedDashPathLayer<LineDataItem, PathStyleExtensionProps<LineDataItem>>({
    id: `markers-measure-line-layer-${use3d ? '3d' : '2d'}`,
    visible,
    data: lineData,
    pickable: false,
    getColor: d => d.color,
    getWidth: d => d.width,
    capRounded: true,
    widthUnits: 'pixels',
    getDashArray: d => [30 / d.width, 18 / d.width],
    extensions: [new PathStyleExtension({ dash: true })],
    parameters: { depthTest: false },
  }), [use3d, visible, lineData]);
};

export const useSingleMarkerIconLayer = (
  marker: Marker,
  visible: boolean,
) => {
  const data = useMemo<IconData[]>(() => [{
    marker,
    size: 5,
    colour: marker.colour,
    icon: getIcon(marker.icon),
    position: [marker.longitude, marker.latitude, 0],
  }], [marker]);

  return useMemo(() => new OutlinedIconLayer<IconData>({
    id: 'single-marker-icon-layer',
    data,
    billboard: true,
    pickable: false,
    autoHighlight: true,
    getIcon: d => d.icon,
    sizeScale: 1,
    getPosition: d => d.position,
    getSize: 40,
    getAngle: 0,
    getColor: d => hexToRGBArray(d.colour),
    outlineColor: LABEL_COLOR,
    parameters: { depthTest: false },
    visible: visible && marker.latitude !== 0 && marker.longitude !== 0,
  }), [data, visible, marker]);
};

const options = {
  map: (props: { marker: Marker }) => ({
    markers: [props.marker],
  }),
  reduce: (acc: { markers: Marker[] }, props: { markers: Marker[] }) => {
    acc.markers = acc.markers.concat(props.markers);
  },
};

const useMarkerIconLayers = (
  markers: Marker[] | undefined,
  viewport: WebMercatorViewport,
  hoveredMarker: Marker | undefined,
  selectedAssetId: number | undefined,
  visible: boolean,
  use3d = false,
) => {
  const t = useTranslations('pages.map.markers');

  const getClusterName = useCallback((clusterMarkers: Marker[]) => {
    const maxMarkersShown = 2;
    if (clusterMarkers.length > maxMarkersShown) {
      return `${clusterMarkers.slice(0, maxMarkersShown)
        .map(m => m.name)
        .join('\n')
      }\n${t('overflow', { n: clusterMarkers.length - maxMarkersShown })}`;
    }
    return clusterMarkers.map(m => m.name)
      .join('\n');
  }, [t]);

  const points: Feature<Point, {
    cluster: false,
    marker: Marker
  }>[] = useMemo(() => markers?.map(marker => ({
    type: 'Feature',
    properties: {
      cluster: false,
      marker,
    },
    geometry: {
      type: 'Point',
      coordinates: [marker.longitude, marker.latitude],
    },
  })) ?? [], [markers]);

  const bounds = useMemo(() => viewport.getBounds()
    .flat(), [viewport]) as BBox;

  const { clusters } = useSupercluster<{ cluster: false, marker: Marker }, { markers: Marker[] }>({
    points,
    bounds,
    zoom: viewport.zoom,
    options,
    disableRefresh: !markers,
  });

  const clusterMarkers = useMemo(() => clusters.reduce<ClusteredMarker[]>((acc, cluster) => {
    if (cluster.properties.cluster) {
      acc.push({
        id: 0,
        name: getClusterName(cluster.properties.markers),
        type: 'POI',
        colour: '#000',
        icon: 'generic',
        longitude: cluster.geometry.coordinates[0],
        latitude: cluster.geometry.coordinates[1],
        altitude: cluster.geometry.coordinates[2] ?? 0,
        clusterId: `C${cluster.id}`,
      });
    } else {
      const { marker } = cluster.properties;
      acc.push({
        ...marker,
        clusterId: `M${marker.id}`,
      });
    }
    return acc;
  }, []), [clusters, getClusterName]);

  const iconLayer = useIconLayer(clusterMarkers, visible, use3d);
  const labelLayer = useLabelLayer(clusterMarkers, visible, use3d);
  const dashedLineLayer = useMeasurementLineLayer(hoveredMarker, selectedAssetId, visible, use3d);

  return useMemo(() => [
    dashedLineLayer,
    iconLayer,
    labelLayer,
  ], [iconLayer, labelLayer, dashedLineLayer]);
};

export default useMarkerIconLayers;
