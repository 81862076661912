import {
  SetPreviousColorsAction,
  Settings,
  UpdateMapControlsContrastAction,
  UpdateSettingAction,
} from 'reducers/settings';

export const updateSetting = <C extends keyof Settings, F extends keyof Settings[C]>(
  category: C,
  field: F,
  value: Settings[C][F],
): UpdateSettingAction<C, F> => ({ type: 'UPDATE_SETTING', payload: { category, field, value } });

export const updateMapControlsContrast = (value: boolean): UpdateMapControlsContrastAction => ({
  type: 'TOGGLE_CONTROL_CONTRAST',
  payload: value
});

export const setPreviousColors = (color: string): SetPreviousColorsAction => ({
  type: 'SET_PREVIOUS_COLORS',
  payload: color
});
