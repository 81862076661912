import React, { useCallback, useRef, useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import useTranslation from 'hooks/useTranslation';
import useSnackbar from 'hooks/useSnackbar';
import useLastDefined from 'hooks/useLastDefined';
import { useCheckDeviceAccess } from 'hooks/device/useDeviceAccess';
import { useSetDeviceConfigurationProfile } from 'apis/rest/devices/hooks';
import { ConfigurationProfile } from 'apis/rest/devices/requests';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

interface DeviceSetConfigurationProps {
  deviceId: number
}

const configurationProfiles: ConfigurationProfile[] = Object.values(ConfigurationProfile);

interface ConfirmDialogProps {
  profile: ConfigurationProfile | undefined
  deviceId: number
  onClose: (confirmed: boolean) => void
}

const ConfirmDialog = ({ profile: givenProfile, deviceId, onClose }: ConfirmDialogProps) => {
  const t = useTranslation('pages.deviceView.sendConfiguration');
  const mutation = useSetDeviceConfigurationProfile();
  const snackbar = useSnackbar();

  const profile = useLastDefined(givenProfile, []);

  const setConfiguration = useCallback(() => {
    if (!profile) return;
    mutation.mutate({ deviceId, profile }, {
      onSuccess: () => {
        snackbar.display({
          id: 'sendConfiguration.success',
          type: 'success',
          text: t('snackbar.success', { profile }),
        });
        onClose(true);
      },
    });
  }, [profile, deviceId, mutation, t, snackbar, onClose]);

  return (
    <Dialog
      open={!!givenProfile}
      onClose={() => onClose(false)}
      aria-labelledby={t('dialog.title')}
      fullWidth
      maxWidth="md"
      TransitionProps={{ onExited: () => mutation.reset() }}
    >
      <TPDialogTitle>{t('dialog.title')}</TPDialogTitle>
      <DialogContent sx={{ p: 3, pb: 0 }}>
        <Stack spacing={3} my={3}>
          <Typography>
            {t.rich('dialog.message', { profile, strong: chunks => <strong>{chunks}</strong> })}
          </Typography>
          {mutation.isError && <Alert severity="error">{t('dialog.error')}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey' }}>
        <Stack spacing={3} flex={1} direction="row" justifyContent="flex-end" height="4em">
          <Button
            variant="outlined"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={mutation.isLoading || mutation.isSuccess}
            onClick={() => onClose(false)}
          >
            {t('dialog.cancel')}
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={mutation.isLoading || mutation.isSuccess}
            onClick={() => setConfiguration()}
          >
            {(mutation.isLoading || mutation.isSuccess) ? <CircularProgress size="2rem" /> : t('dialog.confirm')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const DeviceSetConfiguration = ({ deviceId }: DeviceSetConfigurationProps): JSX.Element | null => {
  const t = useTranslation('pages.deviceView.sendConfiguration');
  const profileButtonRef = useRef<HTMLButtonElement>(null);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [dialogProfile, setDialogProfile] = useState<ConfigurationProfile>();

  // render nothing if device share does not include configuration permission
  const config = useCheckDeviceAccess().call(null, deviceId);
  if (!config?.canSendConfiguration) return null;

  return (
    <Stack direction="row" justifyContent="flex-end" height="4rem" mb="-4rem">
      <Button
        id="setProfileButton"
        variant="contained"
        size="large"
        onClick={() => setProfileMenuOpen(true)}
        sx={{ minWidth: '15rem' }}
        endIcon={<KeyboardArrowDown />}
        ref={profileButtonRef}
        disableElevation
        aria-controls={profileMenuOpen ? 'setProfileMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={profileMenuOpen ? 'true' : undefined}
      >
        {t('buttonTitle')}
      </Button>
      <Menu
        id="setProfileMenu"
        MenuListProps={{
          'aria-labelledby': 'setProfileButton',
        }}
        open={profileMenuOpen}
        anchorEl={profileButtonRef.current}
        onClose={() => setProfileMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 1 }}
      >
        {configurationProfiles.map(profile => (
          <MenuItem
            onClick={() => {
              setProfileMenuOpen(false);
              setDialogProfile(profile);
            }}
            sx={{ minWidth: '15rem' }}
          >
            {t(`profile.${profile}`)}
          </MenuItem>
        ))}
      </Menu>
      <ConfirmDialog profile={dialogProfile} deviceId={deviceId} onClose={() => setDialogProfile(undefined)} />
    </Stack>
  );
};

export default DeviceSetConfiguration;
