import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { filter } from 'rxjs';
import { EventType, ReportEvent, ReportsDataRepository } from '.';
import { Polyline } from './spline';
import { Segment, SegmentEvent } from './reports';
import { ReportsDataRepositoryContext } from './context';

export const isNewReportEvent = (event: ReportEvent): boolean => [EventType.NEW_ASSET, EventType.NEW_REPORT, EventType.NEW_REPORTS, EventType.SET_ALL].includes(event.type);

const updateAllEvents = [EventType.SET_ALL];

export const eventRelatesToAsset = (event: ReportEvent, assetId: number): boolean => {
  if (updateAllEvents.includes(event.type)) { return true; }
  if (event.type === EventType.NEW_ASSET || event.type === EventType.NEW_REPORT) {
    return event.assetId === assetId;
  }
  if (event.type === EventType.NEW_REPORTS) {
    return event.assetIds?.includes(assetId) || false;
  }
  return false;
};

const NO_REPORTS: Report[] = [];

export const useReportsDataRepository = (): ReportsDataRepository => {
  const repo = useContext(ReportsDataRepositoryContext);
  if (repo === undefined) throw new Error('useReportsRepository must be used within a ReportsDataRepositoryContext.Provider');
  return repo;
};

export const useAssetReports = (assetId: number | undefined, leg?: Leg): Report[] => {
  const reports = useReportsDataRepository();
  const [assetReports, setAssetReports] = useState<Report[]>();

  useEffect(() => {
    if (assetId === undefined) {
      setAssetReports(undefined);
      return () => undefined;
    }

    setAssetReports(reports.getReportsForAsset(assetId, false, leg));

    const subscription = reports.subject
      .pipe(filter<ReportEvent>(e => eventRelatesToAsset(e, assetId)))
      .subscribe(() => setAssetReports(reports.getReportsForAsset(assetId, false, leg)));

    return () => subscription.unsubscribe();
  }, [reports, leg, assetId]);

  if (assetId === undefined) return NO_REPORTS;
  return assetReports ?? NO_REPORTS;
};

// Exclude the most recent report for if the device was inactive (before cutoff, unix timestamp in seconds)
export const useAssetReportsNoInactive = (assetId: number, cutoff: number, leg?: Leg): Report[] => {
  const reports = useReportsDataRepository();
  const [assetReports, setAssetReports] = useState<Report[]>([]);

  useEffect(() => {
    const tmpReports = reports.getReportsForAsset(assetId, false, leg);
    if (tmpReports.length === 1) {
      if (tmpReports[0].received > cutoff) {
        setAssetReports(tmpReports);
      } else {
        setAssetReports([]);
      }
    } else {
      setAssetReports(tmpReports);
    }

    const subscription = reports.subject
      .pipe(filter<ReportEvent>(e => eventRelatesToAsset(e, assetId)))
      .subscribe(() => {
        const tmpReports2 = reports.getReportsForAsset(assetId, false, leg);
        if (tmpReports2.length === 1) {
          if (tmpReports2[0].received > cutoff) {
            setAssetReports(tmpReports2);
          } else {
            setAssetReports([]);
          }
        } else {
          setAssetReports(tmpReports2);
        }
      });

    return () => subscription.unsubscribe();
  }, [reports, assetId, cutoff, leg]);

  return assetReports;
};

export const useAssetPositions = (assetId: number, leg?: Leg): Report[] => {
  const reports = useReportsDataRepository();
  const [assetPositions, setAssetPositions] = useState<Report[]>([]);

  useEffect(() => {
    setAssetPositions(reports.getReportsForAsset(assetId, true, leg));

    const subscription = reports.subject
      .pipe(filter<ReportEvent>(e => eventRelatesToAsset(e, assetId)))
      .subscribe(() => setAssetPositions(reports.getReportsForAsset(assetId, true, leg)));

    return () => subscription.unsubscribe();
  }, [reports, assetId, leg]);

  return assetPositions;
};

export const useAssetsPositions = (assets: { id: number }[], leg?: Leg): Record<number, Report[]> => {
  const reports = useReportsDataRepository();
  const [assetsPositions, setAssetsPositions] = useState<Record<number, Report[]>>({});

  useEffect(() => {
    setAssetsPositions(reports.getReportsForAssets(assets.map(a => a.id), true, leg));

    const subscription = reports.subject
      .pipe(filter(isNewReportEvent), filter<ReportEvent>(e => assets.some(a => eventRelatesToAsset(e, a.id))))
      .subscribe(() => setAssetsPositions(reports.getReportsForAssets(assets.map(a => a.id), true, leg)));

    return () => subscription.unsubscribe();
  }, [reports, assets, leg]);

  return assetsPositions;
};

export const useAssetsReports = (assets: { id: number }[], leg?: Leg): Record<number, Report[]> => {
  const reports = useReportsDataRepository();
  const [assetsReports, setAssetsReports] = useState<Record<number, Report[]>>({});

  useEffect(() => {
    setAssetsReports(reports.getReportsForAssets(assets.map(a => a.id), false, leg));

    const subscription = reports.subject
      .pipe(filter(isNewReportEvent), filter<ReportEvent>(e => assets.some(a => eventRelatesToAsset(e, a.id))))
      .subscribe(() => setAssetsReports(reports.getReportsForAssets(assets.map(a => a.id), false, leg)));

    return () => subscription.unsubscribe();
  }, [reports, assets, leg]);

  return assetsReports;
};

export const useAssetSpline = (assetId: number): Polyline[] => {
  const reports = useReportsDataRepository();
  const [assetSpline, setAssetSpline] = useState<Polyline[]>([]);

  useEffect(() => {
    setAssetSpline(reports.getSplineForAsset(assetId));

    const subscription = reports.subject
      .pipe(filter<ReportEvent>(e => eventRelatesToAsset(e, assetId)))
      .subscribe(() => setAssetSpline(reports.getSplineForAsset(assetId)));

    return () => subscription.unsubscribe();
  }, [reports, assetId]);

  return assetSpline;
};

export const useAssetSplines = (assets: { id: number }[], leg?: Leg): Record<number, Polyline[]> => {
  const reports = useReportsDataRepository();
  const [assetSplines, setAssetSplines] = useState<Record<number, Polyline[]>>({});

  useEffect(() => {
    setAssetSplines(reports.getSplinesForAssets(assets.map(a => a.id), leg));

    const subscription = reports.subject
      .pipe(filter(isNewReportEvent), filter<ReportEvent>(e => assets.some(a => eventRelatesToAsset(e, a.id))))
      .subscribe(() => {
        setAssetSplines(reports.getSplinesForAssets(assets.map(a => a.id), leg));
      });

    return () => subscription.unsubscribe();
  }, [reports, assets, leg]);

  return assetSplines || {};
};

export const useAssetSegments = (assets: { id: number }[], event: SegmentEvent, leg?: Leg): Record<number, Segment<object>[]> => {
  const reports = useReportsDataRepository();
  const [splines, setSplines] = useState<Record<number, Segment<object>[]>>({});

  useEffect(() => {
    setSplines(reports.getSegmentsForAssets(assets.map(a => a.id), event, leg));

    const subscription = reports.subject
      .pipe(filter(isNewReportEvent), filter<ReportEvent>(e => assets.some(a => eventRelatesToAsset(e, a.id))))
      .subscribe(() => {
        setSplines(reports.getSegmentsForAssets(assets.map(a => a.id), event, leg));
      });

    return () => subscription.unsubscribe();
  }, [reports, assets, leg, event]);

  return splines || {};
};

export const useLatestPosition = (assetId: number | undefined, leg?: Leg): Report | undefined => {
  const reports = useReportsDataRepository();
  const [latestPosition, setLatestPosition] = useState<Report>();

  useEffect(() => {
    if (assetId === undefined) {
      setLatestPosition(undefined);
      return () => undefined;
    }

    setLatestPosition(reports.getLatestPosition(assetId, assetId === leg?.assetId ? leg : undefined));

    const subscription = reports.subject
      .pipe(filter<ReportEvent>(e => eventRelatesToAsset(e, assetId)))
      .subscribe(() => setLatestPosition(reports.getLatestPosition(assetId, assetId === leg?.assetId ? leg : undefined)));

    return () => subscription.unsubscribe();
  }, [reports, leg, assetId]);

  return assetId === undefined ? undefined : latestPosition;
};

export const useClosestReport = (
  { latitude, longitude }: { latitude: number | undefined, longitude: number | undefined },
  assetIds: number[],
  leg?: Leg,
): Report | undefined => {
  const reports = useReportsDataRepository();
  return useMemo(() => reports.getClosestReport(latitude, longitude, assetIds, leg), [reports, latitude, longitude, assetIds, leg]);
};

interface LatestPositionsForAssets {
  [assetId: number]: Report | undefined
}

export const useLatestPositionsForAssets = (assets: { id: number }[], leg?: Leg): LatestPositionsForAssets => {
  const reports = useReportsDataRepository();
  const [latestPositions, setLatestPositions] = useState<LatestPositionsForAssets>();

  const updateAllLatestPositions = useCallback(() => {
    // @ts-ignore
    setLatestPositions(assets.reduce((prev: LatestPositionsForAssets, asset) => {
      prev[asset.id] = reports.getLatestPosition(asset.id, asset.id === leg?.assetId ? leg : undefined);
      return prev;
    }, {}));
  }, [reports, leg, assets]);

  useEffect(() => {
    updateAllLatestPositions();

    const subscription = reports.subject
      .pipe(filter(isNewReportEvent), filter<ReportEvent>(e => assets.some(a => eventRelatesToAsset(e, a.id))))
      .subscribe(() => updateAllLatestPositions());

    return () => subscription.unsubscribe();
  }, [reports, assets, updateAllLatestPositions]);

  return latestPositions || {};
};

export const useHaveReportData = (): boolean => {
  const reports = useReportsDataRepository();
  const [hasData, setHasData] = useState(false);

  const subscription = useCallback(() => reports.subject.subscribe(event => {
    if (event.type === EventType.CLEAR) setHasData(false);
    else if (!hasData) setHasData(true); // avoid unnecessary rerenders
  }), [reports, hasData])();

  if (hasData) {
    subscription?.unsubscribe();
  }

  return hasData;
};
