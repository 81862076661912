import {
  Box,
} from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import ReactMapGl, { AttributionControl, MapRef, useControl } from 'react-map-gl';
import { GeometryCollection, LineString, Point, Position } from '@turf/helpers';
import WebMercatorViewport from '@math.gl/web-mercator';
import { useSize } from 'hooks/useSize';
import { PathLayer } from '@deck.gl/layers';
import { hexToRGBArray } from 'helpers/color';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox';
import useHighlightedReportLayers from 'components/maps/reactmapgl/layers/useHighlightedReportLayers';
import useMapTemplate from 'hooks/settings/map/useMapConfig';
import { safeBounds } from 'helpers/geo';

const DeckGLOverlay = (props: MapboxOverlayProps) => {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

export const FlightMap = ({ asset, trips, selectedReport }: { asset: AssetWithDevice, trips: Trip[], selectedReport?: TripSlimReport }) => {
  const ref = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);

  const tripGeoms = useMemo(() => trips.map(t => t.path ?? '{"geometries": []}'), [trips]);

  const { width, height } = useSize(ref);

  const path = useMemo(() => ({
    type: 'GeometryCollection' as const,
    geometries: tripGeoms.flatMap(s => (JSON.parse(s) as GeometryCollection).geometries),
  } as GeometryCollection), [tripGeoms]);

  const viewport = useMemo(
    () => ({
      ...new WebMercatorViewport({ width, height })
        .fitBounds(safeBounds(path), { padding: Math.min(width, height, 20) })
    }),
    [width, height, path]
  );

  useEffect(() => {
    if (path.geometries.length > 0) {
      mapRef.current?.getMap()
        .fitBounds(safeBounds(path), { padding: Math.min(width, height, 20) });
    }
  }, [width, height, path]);

  const coordinates = useMemo(() => (path.geometries as (Point | LineString)[])
    .map(geom => (Array.isArray(geom.coordinates[0]) ? geom.coordinates as Position[] : [geom.coordinates as Position]))
    .flatMap(coords => ([{ path: coords }, { path: coords.map(c => [c[0] + 360, c[1]]) }, { path: coords.map(c => [c[0] - 360, c[1]]) }])), [path]);

  const layers = useMemo(() => ([
    new PathLayer({
      id: `path-outline-${asset.id}`,
      data: coordinates,
      getWidth: 4,
      getColor: [255, 255, 255, 255],
      capRounded: true,
      jointRounded: true,
      widthUnits: 'pixels'
    }),
    new PathLayer({
      id: `path-trip-${asset.id}`,
      data: coordinates,
      getWidth: 2,
      getColor: hexToRGBArray(asset.colour ?? '#000'),
      capRounded: true,
      jointRounded: true,
      widthUnits: 'pixels'
    }),
  ]), [coordinates, asset]);

  const highlightedReportLayers = useHighlightedReportLayers(
    selectedReport && {
      latitude: selectedReport.coords[1],
      longitude: selectedReport.coords[0],
      altitude: selectedReport.altitude,
      course: selectedReport.track
    },
    11,
    asset.colour ?? undefined,
  );

  const allLayers = useMemo(() => [...layers, ...highlightedReportLayers], [layers, highlightedReportLayers]);
  const mapTemplate = useMapTemplate();

  // noinspection JSSuspiciousNameCombination
  return (
    <Box
      ref={ref}
      sx={theme => ({
        height: width,
        minHeight: 50,
        minWidth: 50,
        borderLeft: theme.border.default,
        position: 'sticky',
        top: -50 + theme.spacingNumber(3)
      })}
    >
      <ReactMapGl
        ref={mapRef}
        mapStyle={mapTemplate.template}
        mapboxAccessToken={mapboxToken}
        initialViewState={viewport}
        onLoad={event => {
          event.target.dragRotate.disable();
          event.target.touchZoomRotate.disableRotation();
        }}
        projection={{ name: 'mercator' }}
        attributionControl={mapTemplate.provider === 'Mapbox'}
      >
        {mapTemplate.provider !== 'Mapbox' && <AttributionControl customAttribution={`Maps provided by ${mapTemplate.provider}`} />}
        <DeckGLOverlay layers={allLayers} />
      </ReactMapGl>
    </Box>
  );
};
