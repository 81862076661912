import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
// import { serenityApiClient } from 'apis/client/serenityApiClient';
// import { MarketSector } from '@tracplus/serenity-client';
import {
  createMarketSector,
  deleteMarketSector,
  getAllMarketSectorOrganisations,
  getMarketSector,
  getMarketSectors,
  getMarketSectorsForOrganisation,
  updateMarketSector,
  updateMarketSectorsForOrganisation,
} from './requests';
import * as types from './types';
import { marketSectorQueryKeys } from './queryKeys';

export const useGetAllMarketSectors = () => {
  const queryKeys = marketSectorQueryKeys.all();
  const query = useQuery<types.MarketSector[], HttpResponseError>(
    queryKeys,
    () => getMarketSectors()
  );
  return query;
};

export const useGetMarketSector = (id: string) => {
  const queryKeys = marketSectorQueryKeys.forMarketSector(id);
  const query = useQuery<types.MarketSector, HttpResponseError>(
    queryKeys,
    () => getMarketSector(id)
  );
  return query;
};

export const useGetAllMarketSectorOrganisations = () => {
  const queryKeys = marketSectorQueryKeys.allOrgs();
  const query = useQuery<types.MarketSectorOrganisation[], HttpResponseError>(
    queryKeys,
    () => getAllMarketSectorOrganisations()
  );
  return query;
};

export const useGetMarketSectorsForOrganisation = () => {
  const organisationId = useOrganisationId();
  const queryKeys = marketSectorQueryKeys.forOrg(organisationId);
  const query = useQuery<types.OrganisationMarketSector, HttpResponseError>(
    queryKeys,
    () => getMarketSectorsForOrganisation(organisationId)
  );
  return query;
};

export const useMutateUpdateMarketSectorsForOrganisation = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, types.OrganisationMarketSector>(
    value => updateMarketSectorsForOrganisation(organisationId, value),
    {
      mutationKey: ['updateMarketSectorsForOrganisation'],
      onSuccess: data => queryClient.invalidateQueries(marketSectorQueryKeys.forOrg(organisationId)),
    }
  );
};

export const useMutateDeleteMarketSector = () => {
  const queryClient = useQueryClient();
  return useMutation<void, HttpResponseError, string>(
    id => deleteMarketSector(id),
    {
      mutationKey: ['deleteMarketSectors'],
      onSuccess: () => queryClient.invalidateQueries(marketSectorQueryKeys.all()),
    }
  );
};

export const useMutateCreateMarketSector = () => {
  const queryClient = useQueryClient();
  return useMutation<types.MarketSector, HttpResponseError, types.MarketSector>(
    body => createMarketSector(body),
    {
      mutationKey: ['createMarketSectors'],
      onSuccess: () => queryClient.invalidateQueries(marketSectorQueryKeys.all()),
    }
  );
};

export const useMutateUpdateMarketSector = () => {
  const queryClient = useQueryClient();
  return useMutation<void, HttpResponseError, types.MarketSector>(
    body => updateMarketSector(body),
    {
      mutationKey: ['updateMarketSectors'],
      onSuccess: () => queryClient.invalidateQueries(marketSectorQueryKeys.all()),
    }
  );
};
