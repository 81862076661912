import { Dispatch, Reducer, ReducerAction, useMemo, useReducer } from 'react';
import { Action } from 'redux';
import { sortBy } from 'lodash/fp';
import { DateTime } from 'luxon';
import {
  ShareRangeType,
  TemporalGroupShare,
  TemporalShare,
  TemporalSharesResult,
} from 'apis/rest/temporalShares/types';
import { isDefined } from 'utils/type';
import { useAssetLabel } from 'components/shared/assetLabel';

export enum Period {
  All = 'all',
  Present = 'present',
  Past = 'past',
  Future = 'future',
}

export const isInPeriod = (share: TemporalShare, period: Period, now: DateTime) => {
  if (period === Period.All) return true;

  if (share.shareRangeType === ShareRangeType.AllTime) {
    return period === Period.Present;
  }

  const start = DateTime.fromISO(share.shareStart);

  if (share.shareRangeType === ShareRangeType.OpenEnded) {
    if (period === Period.Past) return true;
    if (period === Period.Future && start > now) return true;
    return period === Period.Present && start <= now;
  }

  if (share.shareRangeType === ShareRangeType.SpecificRange) {
    const end = DateTime.fromISO(share.shareEnd);
    if (period === Period.Past && end < now) return true;
    if (period === Period.Future && start > now) return true;
    return period === Period.Present && (start <= now && end >= now);
  }
  return false;
};

export interface GroupedData {
  sharesById: Record<number, TemporalShare>
  groupSharesById: Record<number, TemporalGroupShare>
  sharesByOrganisationId: Record<string, (TemporalShare | TemporalGroupShare)[]>
  sharesByGroupId: Record<string, TemporalGroupShare[]>
  sharesByDeviceId: Record<number, (TemporalShare | TemporalGroupShare)[]>
  sharesByOrganisationIdDeviceId: Record<string, Record<number, (TemporalShare | TemporalGroupShare)[]>>
  sharesByGroupIdDeviceId: Record<string, Record<number, TemporalGroupShare[]>>
  sharesByGroupIdOrganisationId: Record<string, Record<string, TemporalGroupShare[]>>
  organisationIds: string[]
  groupIds: string[]
  deviceIds: number[]
}

export const isTemporalGroupShare = (share: TemporalShare | TemporalGroupShare): share is TemporalGroupShare => 'groupId' in share;

export const groupData = (data: TemporalSharesResult) => {
  const acc: GroupedData = {
    sharesById: {},
    groupSharesById: {},
    sharesByOrganisationId: {},
    sharesByGroupId: {},
    sharesByDeviceId: {},
    sharesByOrganisationIdDeviceId: {},
    sharesByGroupIdDeviceId: {},
    sharesByGroupIdOrganisationId: {},
    organisationIds: [],
    groupIds: [],
    deviceIds: [],
  };

  data.shares.forEach(share => {
    if (acc.sharesByDeviceId[share.deviceId]) {
      acc.sharesByDeviceId[share.deviceId].push(share);
    } else {
      acc.sharesByDeviceId[share.deviceId] = [share];
      acc.deviceIds.push(share.deviceId);
    }

    acc.sharesById[share.id] = share;
    if (acc.sharesByOrganisationId[share.organisationId]) {
      acc.sharesByOrganisationId[share.organisationId].push(share);
    } else {
      acc.sharesByOrganisationId[share.organisationId] = [share];
      acc.organisationIds.push(share.organisationId);
    }

    if (acc.sharesByOrganisationIdDeviceId[share.organisationId]) {
      if (acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId]) {
        acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId].push(share);
      } else {
        acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId] = [share];
      }
    } else {
      acc.sharesByOrganisationIdDeviceId[share.organisationId] = { [share.deviceId]: [share] };
    }
  });

  data.groupShares.forEach(share => {
    if (acc.sharesByDeviceId[share.deviceId]) {
      acc.sharesByDeviceId[share.deviceId].push(share);
    } else {
      acc.sharesByDeviceId[share.deviceId] = [share];
      acc.deviceIds.push(share.deviceId);
    }

    acc.groupSharesById[share.id] = share;
    if (acc.sharesByGroupId[share.groupId]) {
      acc.sharesByGroupId[share.groupId].push(share);
    } else {
      acc.sharesByGroupId[share.groupId] = [share];
      acc.groupIds.push(share.groupId);
    }

    if (acc.sharesByGroupIdDeviceId[share.groupId]) {
      if (acc.sharesByGroupIdDeviceId[share.groupId][share.deviceId]) {
        acc.sharesByGroupIdDeviceId[share.groupId][share.deviceId].push(share);
      } else {
        acc.sharesByGroupIdDeviceId[share.groupId][share.deviceId] = [share];
      }
    } else {
      acc.sharesByGroupIdDeviceId[share.groupId] = { [share.deviceId]: [share] };
    }

    if (acc.sharesByGroupIdOrganisationId[share.groupId]) {
      if (acc.sharesByGroupIdOrganisationId[share.groupId][share.organisationId]) {
        acc.sharesByGroupIdOrganisationId[share.groupId][share.organisationId].push(share);
      } else {
        acc.sharesByGroupIdOrganisationId[share.groupId][share.organisationId] = [share];
      }
    } else {
      acc.sharesByGroupIdOrganisationId[share.groupId] = { [share.organisationId]: [share] };
    }

    if (acc.sharesByOrganisationId[share.organisationId]) {
      if (!acc.sharesByOrganisationId[share.organisationId].includes(share)) acc.sharesByOrganisationId[share.organisationId].push(share);
    } else {
      acc.sharesByOrganisationId[share.organisationId] = [share];
      acc.organisationIds.push(share.organisationId);
    }

    if (acc.sharesByOrganisationIdDeviceId[share.organisationId]) {
      if (acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId]) {
        if (!acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId].includes(share)) acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId].push(share);
      } else {
        acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId] = [share];
      }
    } else {
      acc.sharesByOrganisationIdDeviceId[share.organisationId] = { [share.deviceId]: [share] };
    }
  });

  return acc;
};

const isAssetWithDevice = (asset: AssetBasic): asset is AssetWithDevice => asset.deviceId !== null;

export const selectAssetsByDeviceId = (assets: AssetBasic[]) => assets.reduce<Record<number, AssetWithDevice>>((acc, asset) => {
  if (isAssetWithDevice(asset)) acc[asset.deviceId] = asset;
  return acc;
}, {});

export const selectDevicesById = (devices: DeviceBasic[]) => devices.reduce<Record<number, DeviceBasic>>((acc, device) => {
  acc[device.id] = device;
  return acc;
}, {});

export const selectGroupAssetsByDeviceId = (assets: AssetBasic[]) => assets
  .reduce<Record<number, AssetWithDevice>>((acc, asset) => {
    if (typeof asset.deviceId === 'number') {
      acc[asset.deviceId] = asset as AssetWithDevice;
    }
    return acc;
  }, {});

export interface FilterPayload {
  type: 'device' | 'organisation' | 'period'
  deviceIds?: number[]
  organisationIds?: string[]
  groupIds?: string[]
  period?: Period
}

export interface FilterState {
  deviceIds: number[]
  organisationIds: string[]
  groupIds: string[]
  period: Period
}

const INITIAL_FILTER_STATE: FilterState = {
  deviceIds: [],
  organisationIds: [],
  groupIds: [],
  period: Period.All,
};

interface SetFiltersAction extends Action<'SET_FILTERS'> {
  payload: FilterPayload
}

const filtersReducer: Reducer<FilterState, SetFiltersAction | Action<'RESET'>> = (state, action) => {
  switch (action.type) {
    case 'SET_FILTERS': {
      const next = { ...state };
      if (action.payload.type === 'device') {
        next.deviceIds = action.payload.deviceIds ?? [];
      } else if (action.payload.type === 'organisation') {
        next.organisationIds = action.payload.organisationIds ?? [];
        next.groupIds = action.payload.groupIds ?? [];
      } else if (action.payload.type === 'period') {
        next.period = action.payload.period ?? Period.All;
      }
      return next;
    }
    case 'RESET': return INITIAL_FILTER_STATE;
    default: return state;
  }
};

export type SetFilters = Dispatch<ReducerAction<typeof filtersReducer>>;

export const useFilterState = () => useReducer(filtersReducer, INITIAL_FILTER_STATE);

export const anyInvalidOrganisationsOrGroupsInState = (state: FilterState, data: GroupedData) => {
  const validOrganisationIds = state.organisationIds.filter(id => data.organisationIds.includes(id));
  const validGroupIds = state.groupIds.filter(id => data.groupIds.includes(id));
  return validOrganisationIds.length < state.organisationIds.length || validGroupIds.length < state.groupIds.length;
};

export const anyInvalidDevicesInState = (state: FilterState, data: GroupedData) => {
  const validDeviceIds = state.deviceIds.filter(id => data.deviceIds.includes(id));
  return validDeviceIds.length < state.deviceIds.length;
};

// A cluster is a group of shares
// Each share must appear in only one cluster
// Each cluster must have at least one share

interface OrganisationCluster {
  organisationId: string
  organisationName: string
  shares: TemporalShare[]
}

interface GroupCluster {
  groupId: string
  groupName: string
  organisationId: string
  organisationName: string
  shares: TemporalGroupShare[]
}

export type RecipientCluster = OrganisationCluster | GroupCluster;

export const sortRecipientClusters = sortBy<RecipientCluster>(cluster => ('groupName' in cluster ? cluster.groupName : cluster.organisationName).toLowerCase());
export const sortSharerClusters = sortBy<RecipientCluster>(cluster => cluster.organisationName.toLowerCase());

export const useSortSharesByDevice = (assetsByDeviceId: Record<number, AssetWithDevice> | undefined) => {
  const assetLabel = useAssetLabel();
  return useMemo(() => sortBy<TemporalShare>(share => assetLabel(assetsByDeviceId?.[share.deviceId])?.toLowerCase()), [assetLabel, assetsByDeviceId]);
};

export const sortSharesByRecipient = sortBy<TemporalShare>(share => (isTemporalGroupShare(share) ? share.groupName : share.organisationName).toLowerCase());

export const useRecipientClusters = (data: GroupedData | undefined, filters: FilterState, sort: (clusters: RecipientCluster[]) => RecipientCluster[] = x => x) => useMemo(() => {
  if (!data) return [];

  const now = DateTime.now();

  const deviceIds = filters.deviceIds.length ? filters.deviceIds : data.deviceIds;

  const result = data.organisationIds.flatMap(organisationId => {
    const shares = deviceIds
      .flatMap(deviceId => data.sharesByOrganisationIdDeviceId[organisationId]?.[deviceId] ?? [])
      .filter(share => {
        // must be in filtered date period
        if (!isInPeriod(share, filters.period, now)) return false;

        // allow group share in filtered groups
        if (isTemporalGroupShare(share) && filters.groupIds.includes(share.groupId)) return true;

        // allow share in filtered organisations
        if (filters.organisationIds.includes(share.organisationId)) return true;

        // allow if there are no filtered groups or organisations
        return !filters.groupIds.length && !filters.organisationIds.length;
      });

    // discard if there are no shares
    if (!shares.length) return undefined;

    const byGroup = shares.reduce<Record<string, GroupCluster>>((acc, share) => {
      if (!isTemporalGroupShare(share)) return acc;
      if (acc[share.groupId]) {
        acc[share.groupId].shares.push(share);
      } else {
        acc[share.groupId] = {
          groupId: share.groupId,
          groupName: share.groupName,
          organisationId: share.organisationId,
          organisationName: share.organisationName,
          shares: [share],
        };
      }
      return acc;
    }, {});

    const clustersForOrganisation: RecipientCluster[] = Object.values(byGroup);

    const nonGroupShares = shares.filter(share => !isTemporalGroupShare(share));
    if (nonGroupShares.length) {
      const cluster: OrganisationCluster = {
        organisationId,
        organisationName: nonGroupShares[0].organisationName,
        shares: nonGroupShares,
      };
      clustersForOrganisation.push(cluster);
    }

    return clustersForOrganisation;
  }).filter(isDefined) ?? [];

  return sort(result);
}, [data, filters.organisationIds, filters.groupIds, filters.deviceIds, filters.period, sort]);

export interface DeviceCluster {
  deviceId: number
  shares: (TemporalShare | TemporalGroupShare)[]
}

const useSortDeviceClusters = (assetsByDeviceId: Record<number, AssetWithDevice> | undefined) => {
  const assetLabel = useAssetLabel();
  return useMemo(() => sortBy<DeviceCluster>(cluster => assetLabel(assetsByDeviceId?.[cluster.deviceId])?.toLowerCase()), [assetLabel, assetsByDeviceId]);
};

export const useDeviceClusters = (data: GroupedData | undefined, filters: FilterState, assetsByDeviceId: Record<number, AssetWithDevice> | undefined) => {
  const sortClusters = useSortDeviceClusters(assetsByDeviceId);

  return useMemo(() => {
    if (!data || !assetsByDeviceId) return [];

    const now = DateTime.now();

    const result = data.deviceIds
      .map<DeviceCluster | undefined>(deviceId => {
        if (filters.deviceIds.length && !filters.deviceIds.includes(deviceId)) return undefined;

        const shares = data.organisationIds
          .flatMap(organisationId => data.sharesByOrganisationIdDeviceId[organisationId][deviceId] ?? [])
          .filter(share => {
            // must be in filtered date period
            if (!isInPeriod(share, filters.period, now)) return false;

            // allow group share in filtered groups
            if (isTemporalGroupShare(share) && filters.groupIds.includes(share.groupId)) return true;

            // allow share in filtered organisations
            if (filters.organisationIds.includes(share.organisationId)) return true;

            // allow if there are no filtered groups or organisations
            return !filters.groupIds.length && !filters.organisationIds.length;
          });

        // discard if there are no shares
        if (!shares.length) return undefined;

        return {
          deviceId,
          shares,
        };
      })
      .filter(isDefined);

    return sortClusters(result);
  }, [data, filters.organisationIds, filters.groupIds, filters.deviceIds, filters.period, sortClusters, assetsByDeviceId]);
};

export const formatDate = (date: string, timezone: string) => {
  const dt = DateTime.fromISO(date, { zone: 'utc' }).setZone(timezone);
  return dt.toFormat('dd MMM yyyy');
};

export const formatDateTime = (date: string, timezone: string) => {
  const dt = DateTime.fromISO(date, { zone: 'utc' }).setZone(timezone);
  return dt.toFormat('dd MMM yyyy HH:mm:ss ZZZ');
};
