export type DropGroup = {
  id: string,
  drops: Drop[],
  lastDropId: number,
  trip?: Trip,
}

const calculateDropGroupsWithSplits = (trip: Trip): (DropGroup & { hasSplitDrops: boolean })[] => {
  const groups = [];
  let current = [];

  for (let i = 0; i < trip.drops.length; i++) {
    const drop = trip.drops[i];

    current.push(drop);

    if (drop.type === 'Drop') {
      groups.push({
        id: `${drop.id}:${trip.id}`,
        drops: current.slice(0),
        lastDropId: drop.id,
        trip,
        hasSplitDrops: drop.splitDrop,
      });
      current = [];
    }
  }

  return groups;
};

const collapseGroups = (groups: DropGroup[]) => (
  groups.reduce<DropGroup>((acc, g) => {
    acc.id = `${g.lastDropId}-${g.trip?.id ?? ''}`;
    acc.drops = acc.drops.concat(g.drops);
    acc.lastDropId = g.lastDropId;
    return acc;
  }, {
    id: '',
    drops: [],
    lastDropId: 0,
  })
);

const calculateDropGroupsForTrip = (trip: Trip): DropGroup[] => {
  const groups = calculateDropGroupsWithSplits(trip);

  const newGroups = [];
  let current = [];

  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    if (group.hasSplitDrops) {
      current.push(group);
      // eslint-disable-next-line no-continue
      continue;
    }

    if (current.length > 0) {
      newGroups.push(collapseGroups(current));
      current = [];
    }

    newGroups.push(group);
  }

  if (current.length > 0) {
    newGroups.push(collapseGroups(current));
  }

  return newGroups.map(g => ({
    id: `${g.lastDropId}:${g.trip?.id ?? ''}`,
    drops: g.drops,
    lastDropId: g.lastDropId,
    trip: g.trip,
  }));
};

export const calculateDropGroups = (trips: Trip[]): DropGroup[] => trips.flatMap(t => calculateDropGroupsForTrip(t));
