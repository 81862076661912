import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Modal from 'react-modal';
import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IntercomProvider } from 'react-use-intercom';
import { queryClient } from './queryClient';
import ErrorBoundary from 'components/shared/errorBoundary';
import { router } from 'router';
import { store, persistor } from 'store';

/* to initialise it on startup */
import 'firebaseApp';

import 'react-virtualized/styles.css';
import './index.css';

// NOTE: we polyfill for some unsupported browser versions only where practical and with no further workarounds in source code
import * as arrayAt from 'array.prototype.at';
import * as stringAt from 'string.prototype.at';
import * as arrayToSorted from 'array.prototype.tosorted';

// Chrome/Edge <92, Firefox <90, Safari <15.4
if (!Array.prototype.at) arrayAt.shim();
if (!String.prototype.at) stringAt.shim();
if (!Array.prototype.toSorted) arrayToSorted.shim();

const INTERCOM_APP_ID = 'u9wrr5iy';

window.newrelic?.addRelease('TracPlus Cloud', import.meta.env.PACKAGE_VERSION);

Modal.setAppElement('#root');

ReactDOM.render(
  <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary organisationId={null}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <RouterProvider router={router} />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </IntercomProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
