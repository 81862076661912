import React from 'react';
import {
  Box, Typography, Grid, Tooltip, Toolbar, Stack, Divider, IconButton,
} from '@mui/material';
import clsx from 'clsx';
import { HelpOutline } from '@mui/icons-material';
import { Link, Navigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { useTranslations } from 'use-intl';
import { AlertSmall } from 'components/shared/alertSmall';
import { goToFirmwareVersionOutdatedHelpArticle, goToTrackingSuspendedHelpArticle } from 'helpers/externalLinks';
import { supportsCellular, supportsOnlySatellite } from 'helpers/deviceSupport';
import { tryGetTrackingRates, tryGetFirmwareVersion, tryGetGprsStrategy } from 'helpers/config';
import { useAssetLabelKey } from 'components/shared/assetLabel';
import {
  useGetDeviceBasic,
  useGetLatestDeviceConfiguration,
  useLatestFirmwareVersionForDevice,
} from 'apis/rest/devices/hooks';
import { useTrackingSuspended } from 'hooks/device/useTrackingSuspended';
import ContextBoxImage from '../../modules/image';
import Module from '../../modules/module';
import useStyles from './details-styles';
import { GprsNeverAlert } from './alerts/GprsNeverAlert';
import { useUiSettings } from 'hooks/settings/useUiSettings';

interface ACBDetailsProps {
  asset: AssetBasic,
}

type AssetSerialType = 'deviceTracPlusSerial' | 'deviceManufacturerSerial' | 'deviceImei';
const serialTypeToAssetSerialType = (serialType: SerialType): AssetSerialType => ({
  tpSerial: 'deviceTracPlusSerial',
  imei: 'deviceImei',
  manufacturerSerial: 'deviceManufacturerSerial'
} as Record<SerialType, AssetSerialType>)[serialType];

const ACBDetails = ({
  asset,
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
}: ACBDetailsProps): JSX.Element => {
  const classes = useStyles();
  const defaultSerialType = useUiSettings().serialType;
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // const isInDistress = tagHelper.isInDistress(assetState);
  // const isOverdue = tagHelper.isOverdue(assetState);
  const isAssetEmergency = false; // isInDistress || isOverdue;
  const assetSerialType = serialTypeToAssetSerialType(defaultSerialType);
  const deviceQuery = useGetDeviceBasic(asset.deviceId ?? -1, asset.deviceId !== undefined).query;
  const device = deviceQuery.data;
  const labelKey = useAssetLabelKey();

  const configQuery = useGetLatestDeviceConfiguration(asset.deviceId ?? -1).query;
  const trackingRates = configQuery.data && tryGetTrackingRates(configQuery.data);
  const firmwareVersion = configQuery.data && tryGetFirmwareVersion(configQuery.data);
  const firmwareOutdated = useLatestFirmwareVersionForDevice(device).outdated;
  const trackingSuspended = useTrackingSuspended(asset.id, asset.deviceId || -1);
  const gprsStrategy = configQuery.data && tryGetGprsStrategy(configQuery.data);

  const t = useTranslations('contextbox.asset.details');

  if (!asset) return <Navigate to="/404" replace />;

  // Assigned Device Serial
  const deviceSerial = asset?.[assetSerialType]
    ? `${t(defaultSerialType)}: ${asset?.[assetSerialType]}`
    : t('noSerialSet', { serialType: t(defaultSerialType) });

  // TODO: check device capabilities
  const hasSatPhone = device?.make.toLowerCase() === 'flightcell';

  const assetLabelKey = asset[labelKey] ? labelKey : 'name';

  return (
    <Module
      contentClassname={clsx(classes.container, { [classes.emergencyBgColor]: isAssetEmergency })}
      // @ts-ignore
      customHeader={(
        <ContextBoxImage obj={asset}>
          <Toolbar className={classes.quickTasksBar} />
        </ContextBoxImage>
      )}
    >
      <Stack>
        <Grid
          className={classes.topRow}
          component={Link}
          to={`/manage/assets/${asset.id}`}
          justifyItems="stretch"
        >
          <Box className={classes.assetDetails} data-test="resultName">
            <Tooltip title={t(assetLabelKey)}>
              <Typography variant="h3">{asset[assetLabelKey]}</Typography>
            </Tooltip>
            <Tooltip title={t('makeModelVariantTooltip')}>
              <Typography variant="body1" gutterBottom>
                {[asset.make, asset.model, asset.variant].filter(Boolean).join(' ')}
              </Typography>
            </Tooltip>
            {assetLabelKey !== 'name' && (
              <Typography variant="body1">{t('name')}: {asset.name}</Typography>
            )}
            {assetLabelKey !== 'tailNumber' && asset.tailNumber && (
              <Typography variant="body1">{t('tailNumber')}: {asset.tailNumber}</Typography>
            )}
            {assetLabelKey !== 'callSign' && asset.callSign && (
              <Typography variant="body1">{t('callSign')}: {asset.callSign}</Typography>
            )}
            {asset.messagingHandle && (
              <Tooltip title={t('messagingHandleTooltip')}>
                <Typography variant="body1">
                  {`@${(asset.messagingHandle || '').replace(/^@?/, '')}`}
                </Typography>
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Divider
          sx={theme => ({
            borderColor: alpha(theme.palette.common.white, 0.5),
            margin: theme.spacing(0, 3),
          })}
        />
        <Grid
          className={classes.bottomRow}
          component={Link}
          to={`/manage/devices/${asset.deviceId}`}
        >
          <Stack spacing={1}>
            <Box>
              <Typography className={classes.deviceName}>
                {`${asset.deviceMake || ''} ${asset.deviceModel || ''} ${firmwareVersion ? `(v${firmwareVersion})` : ''}`}
              </Typography>
              <Typography className={classes.small}>{deviceSerial}</Typography>
              {hasSatPhone && device.satNumber && (
                <Typography className={classes.small}>{t('satNumber')}: {device.satNumber}</Typography>
              )}
              {hasSatPhone && device.smsNumber && (
                <Typography className={classes.small}>{t('smsNumber')}: {device.smsNumber}</Typography>
              )}
            </Box>

            {trackingRates && (
              <Box>
                <Typography className={classes.label}>{t('trackingRates')}</Typography>
                {trackingRates.cellular && (!supportsOnlySatellite(device))
                  && (
                  <Typography className={classes.small}>{t('cellular')}:&nbsp;
                    {trackingRates.inactive?.cellular ? <Tooltip title={t('inactiveRate')}><Typography className={classes.small} display="inline">{trackingRates.inactive.cellular} /&nbsp;</Typography></Tooltip> : ''}
                    {trackingRates.cellular}
                    {trackingRates.cellular !== trackingRates.burst?.cellular && trackingRates.burst && <Tooltip title={t('fixRate')}><Typography className={clsx(classes.small)} display="inline"> ({trackingRates.burst.cellular})</Typography></Tooltip>}
                  </Typography>
                  )}
                {trackingRates.satellite
                  && (
                    <Typography className={classes.small}>{t('satellite')}:&nbsp;
                      {trackingRates.inactive?.satellite ? <Tooltip title={t('inactiveRate')}><Typography className={classes.small} display="inline">{trackingRates.inactive.satellite} /&nbsp;</Typography></Tooltip> : ''}
                      {trackingRates.satellite}
                      {trackingRates.satellite !== trackingRates.burst?.satellite && trackingRates.burst
                        ? <Tooltip title={t('fixRate')}><Typography className={clsx(classes.small)} display="inline"> ({trackingRates.burst.satellite})</Typography></Tooltip>
                        : ''}
                    </Typography>
                  )}
              </Box>
            )}

            {trackingSuspended && (
              <AlertSmall
                severity="warning"
                variant="filled"
                icon={false}
                action={(
                  <Tooltip title={t('rock7SuspendedHelp')}>
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={e => {
                        e.preventDefault();
                        goToTrackingSuspendedHelpArticle();
                      }}
                    >
                      <HelpOutline fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              >{t('trackingSuspended')}
              </AlertSmall>
            )}

            {firmwareOutdated && (
              <AlertSmall
                severity="warning"
                variant="filled"
                icon={false}
                action={device?.make === 'Rock7' && (
                  <Tooltip title={t('firmwareOutdatedHelp')}>
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={e => {
                        e.preventDefault();
                        goToFirmwareVersionOutdatedHelpArticle(device.model);
                      }}
                    >
                      <HelpOutline fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              >
                {t('firmwareOutdated')}
              </AlertSmall>
            )}

            {gprsStrategy?.toLowerCase() === 'never' && supportsCellular(device) && (
              <GprsNeverAlert />
            )}
          </Stack>
        </Grid>
      </Stack>
    </Module>
  );
};

export default ACBDetails;
