import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { markersQueryKeys } from './queryKeys';
import { Marker, CreateMarkerVariables, UpdateMarkerVariables, DeleteMarkerVariables } from './types';
import { createMarker, deleteMarker, fetchAllMarkers, updateMarker } from './requests';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useMarkers = <T = Marker[]>(options?: Options<Marker[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = markersQueryKeys.allMarkers(organisationId);
  const query = useQuery<Marker[], HttpResponseError, T>(
    queryKey,
    () => fetchAllMarkers(organisationId),
    options ?? {},
  );
  return {
    query,
    queryKey,
  };
};

export const useCreateMarker = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation<void, HttpResponseError, CreateMarkerVariables, void>(
    ({ marker }: CreateMarkerVariables) => createMarker(organisationId, marker),
    {
      onSuccess: () => queryClient.invalidateQueries(markersQueryKeys.allMarkers(organisationId)),
    },
  );
};

export const useUpdateMarker = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation<void, HttpResponseError, UpdateMarkerVariables, void>(
    ({
      markerId,
      marker,
    }: UpdateMarkerVariables) => updateMarker(organisationId, markerId, marker),
    {
      onSuccess: () => queryClient.invalidateQueries(markersQueryKeys.allMarkers(organisationId)),
    },
  );
};

export const useDeleteMarker = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation<void, HttpResponseError, DeleteMarkerVariables, void>(
    ({ markerId }: DeleteMarkerVariables) => deleteMarker(organisationId, markerId),
    {
      onSuccess: () => queryClient.invalidateQueries(markersQueryKeys.allMarkers(organisationId)),
    },
  );
};
