import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Tooltip, Box, Stack, Typography, IconButton } from '@mui/material';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { isAssetWithDevice } from 'helpers/assets';
import { getLegsQueryFn, getLegsQueryKey } from 'helpers/legs';
import { useAssetsReports } from 'repositories/reports/hooks';
import { useQueries } from '@tanstack/react-query';
import { groupBy } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import useTimezone from 'hooks/session/useTimezone';
import { DateTime } from 'luxon';
import { useSize } from 'hooks/useSize';
import { selectItem, selectLeg, clearSelection } from 'actions/app';
import { setTrailHighlight, unassignItemFromMap, assignItemToMap } from 'actions/map';
import { ArrowBack } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { AssetTimelineGraph, MARGIN_TOP } from './AssetTimelineGraph';
import AssetColourMarker from '../../shared/assetColourMarker';
import AssetLabel from '../../shared/assetLabel';
import { AssetTimelineGraphAxis } from './AssetTimelineGraphAxis';
import Analysisbox from '../analysisbox/analysisbox-redux';
import LegPopper from './LegPopper';

interface AnalysisBoxTimelineProps {
  selectedAsset?: AssetWithDevice
  drawerHeight: number
}

export const AnalysisBoxTimeline = ({ drawerHeight, selectedAsset }: AnalysisBoxTimelineProps) => {
  const t = useTranslations('analysisbox.timeline');
  const assets = useGetAssetsList({ select: a => a.filter(isAssetWithDevice) }).query.data;
  const day = useSelector<ReduxState, string | null>(state => state.app.selectedDay);
  const selectedLeg = useSelector<ReduxState, Leg | null>(state => state.map.selectedLegs[state.map.selectedMapId]);
  const selectedReport = useSelector<ReduxState, Report | null>(state => state.reports.selectedReportPerMap[state.map.selectedMapId]);
  const selectedMapId = useSelector<ReduxState, string>(state => state.map.selectedMapId);
  const timezone = useTimezone();

  const startOfDay = useMemo(
    () => (day === null
      ? DateTime.now().setZone(timezone).startOf('day')
      : DateTime.fromISO(day, { zone: timezone })).toMillis(),
    [day, timezone]
  );

  const assetsToUse = useMemo(
    () => (selectedAsset ? [selectedAsset] : (assets ?? [])),
    [selectedAsset, assets]
  );

  const reportsForAssets = useAssetsReports(assetsToUse);
  const queryObjects = useMemo(() => assetsToUse
    .filter(a => (a.id in reportsForAssets))
    .map(a => ({
      queryKey: getLegsQueryKey(reportsForAssets[a.id]),
      queryFn: getLegsQueryFn(a, reportsForAssets[a.id])
    })), [assetsToUse, reportsForAssets]);

  const legsQueries = useQueries({ queries: queryObjects });
  const legsByAsset = useMemo(() => groupBy(
    'assetId',
    legsQueries
      .flatMap(l => l.data)
      .filter((l): l is Leg => !!l && (l.start * 1000) > startOfDay)
  ), [legsQueries, startOfDay]);

  const assetsWithLegs = useMemo(() => assetsToUse.filter(a => a.id in legsByAsset), [legsByAsset, assetsToUse]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [legPopper, setLegPopper] = useState<{ leg?: Leg, element?: SVGElement }>({});

  const dispatch = useDispatch();
  const dispatchSelectAsset = useCallback((a?: AssetWithDevice) => {
    dispatch(assignItemToMap(selectedMapId, a));
    selectItem(a)(dispatch);
  }, [dispatch, selectedMapId]);
  const dispatchClearSelection = useCallback(() => {
    dispatch(clearSelection());
    dispatch(unassignItemFromMap());
  }, [dispatch]);
  const { height: assetBoxHeight } = useSize(containerRef);

  const dispatchSelectLeg = useCallback((l: Leg) => {
    const legToSelect = (l === null || selectedLeg?.id === l?.id) ? null : l;
    if (selectedAsset?.id !== l.assetId) {
      const legAsset = assets?.find(a => a.id === l?.assetId);
      if (legAsset) {
        dispatchSelectAsset(legAsset);
      }
    }
    dispatch(selectLeg(legToSelect));
  }, [selectedLeg?.id, selectedAsset?.id, dispatch, assets, dispatchSelectAsset]);

  const dispatchTrailHighlight = useCallback((l?: Leg | null, e?: SVGSVGElement) => {
    setLegPopper({ leg: l ?? undefined, element: e });
    if (!l) {
      dispatch(setTrailHighlight(null));
      return;
    }
    const reports = reportsForAssets[l.assetId].filter(r => r.received >= l.start && r.received <= l.end);
    if (reports.length >= 0) {
      dispatch(setTrailHighlight({ assetId: l.assetId, reports }));
    }
  }, [dispatch, reportsForAssets]);

  return (
    <Box sx={theme => ({ backgroundColor: theme.palette.common.white })}>
      <Box sx={{
        overflow: 'scroll',
        overflowX: 'visible',
        scrollbarGutter: 'stable',
        pr: 3
      }}>
        {selectedAsset && (
          <Tooltip title={t('allAssets')} placement="top">
            <IconButton
              sx={{ position: 'absolute', top: 0, left: 0, mt: 3, ml: 2, zIndex: 1 }}
              size="small"
              onClick={dispatchClearSelection}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          position: 'sticky',
          top: 0,
          bgcolor: 'common.white'
        }}>
          <AssetTimelineGraphAxis startTimeIso={day} width={chartWidth} />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
            alignItems: 'top',
            maxHeight: drawerHeight - 30 - MARGIN_TOP
          }}
        >
          <Box
            display="grid"
            gridAutoRows="1fr"
            height="min-content"
            ref={containerRef}
          >
            {assetsWithLegs.map((a, i) => (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                py={1}
                pr={2}
                borderBottom={theme => (i === assetsWithLegs.length - 1 ? undefined : theme.border.default)}
              >
                <AssetColourMarker assetId={a.id} />
                <Typography variant="h3"><AssetLabel asset={a} /></Typography>
              </Stack>
            ))}
          </Box>
          <LegPopper leg={legPopper.leg} anchorEl={legPopper.leg ? legPopper.element : null} timezone={timezone} />
          <AssetTimelineGraph
            assets={assetsWithLegs}
            legsByAsset={legsByAsset}
            height={assetBoxHeight}
            startTime={startOfDay}
            setWidth={setChartWidth}
            onLegHover={dispatchTrailHighlight}
            onLegClick={dispatchSelectLeg}
            selectedLeg={selectedLeg}
            selectedReport={selectedReport}
          />
        </Box>
      </Box>
      {selectedAsset && (
        <Box mt={2} pt={2} borderTop={theme => theme.border.default}>
          { /* @ts-ignore */ }
          <Analysisbox drawerHeight={drawerHeight - 90} selectedAsset={selectedAsset} timeline />
        </Box>
      )}
    </Box>
  );
};
