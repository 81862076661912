import React from 'react';
import { useTranslations } from 'use-intl';
import { Alert, Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { updateSetting } from 'actions/settings';
import { generalSettingsSection } from 'components/statusbar/generalSettings/types';
import { Settings } from 'reducers/settings';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useAppDispatch } from 'store/types';
import {
  updateLocalUiSettingsField,
  updateUiSettingField,
} from 'slices/settings/uiSettings.slice';
import { useUiSettings } from 'hooks/settings/useUiSettings';

export const UiFirestoreSettingsSelectorList = () => {
  const t = useTranslations('pages.generalSettings');

  const uiSettings = useUiSettings();
  const appDispatch = useAppDispatch();
  const isUsercodeUser = useIsUsercodeLogin();

  const uiSettingsTemplate: generalSettingsSection = {
    id: 'ui',
    label: t('userInterfaceLabel'),
    description: t('userInterfaceDescription'),
    fields: [{
      id: 'darkMode',
      label: t('darkModeLabel'),
      options: [
        { id: 'system', label: t('systemMode') },
        { id: 'dark', label: t('darkMode') },
        { id: 'light', label: t('lightMode') },
      ]
    }, {
      id: 'assetLabel',
      label: t('assetLabel'),
      secondary: t('fallbackToName'),
      options: [
        { id: 'name', label: t('name') },
        { id: 'callSign', label: t('callSign') },
        { id: 'tailNumber', label: t('tailNumber') },
      ]
    }, {
      id: 'serialType',
      label: t('defaultSerialType'),
      options: [
        { id: 'tpSerial', label: t('tpSerial') },
        { id: 'imei', label: t('imei') },
        { id: 'manufacturerSerial', label: t('manufacturerSerial') },
      ]
    }, {
      id: 'rowsPerPage',
      label: t('rowsPerPage'),
      options: [
        { id: 10, label: '10' },
        { id: 25, label: '25' },
        { id: 50, label: '50' },
        { id: 100, label: '100' },
      ]
    }]
  };

  const parseColorToBool = (input: string | number | boolean | null, fieldId: string): boolean | string | number | null => {
    if (fieldId !== 'darkMode') {
      return input;
    }
    switch (input) {
      case 'system': return null;
      case 'dark': return true;
      case 'light': return false;
      default: return input;
    }
  };

  const parseColorToString = (input: string | number | boolean | null, fieldId: string): boolean | string | number | null => {
    if (fieldId !== 'darkMode') {
      return input;
    }
    switch (input) {
      case null: return 'system';
      case true: return 'dark';
      case false: return 'light';
      default: return input;
    }
  };

  const handleSettingChange = (event: React.MouseEvent<HTMLElement>, newValue: string | number, fieldId: keyof ReduxState['uiSettings']['ui']) => {
    if (fieldId !== 'darkMode' && newValue === null) return;
    const parsedValue = parseColorToBool(newValue, fieldId);
    if (isUsercodeUser) {
      appDispatch(updateSetting('ui', fieldId as keyof ReduxState['settings']['ui'], parsedValue));
      appDispatch(updateLocalUiSettingsField({ field: fieldId as keyof ReduxState['settings']['ui'], value: parsedValue }));
    } else {
      appDispatch(updateUiSettingField({ field: fieldId as keyof ReduxState['settings']['ui'], value: parsedValue }));
    }
  };

  return (
    <Stack spacing={2} sx={{ p: 3 }}>
      <Box>
        <Typography paragraph>{uiSettingsTemplate.description}</Typography>
      </Box>
      {isUsercodeUser && (<Alert severity="warning" variant="outlined" sx={{ alignItems: 'center' }}>{t('warnings.usercodeBrowserStorageOnly')}</Alert>)}
      {uiSettingsTemplate.fields.map(field => (
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom>{field.label}</Typography>
            {field.secondary && (
              <Typography variant="body1" gutterBottom>{field.secondary}</Typography>
            )}
          </Grid>
          <Grid item xs={6} container justifyContent="right">
            <ToggleButtonGroup
              value={parseColorToString(uiSettings[field.id as keyof Settings['ui']], field.id)}
              exclusive
              sx={{ m: 0.5 }}
              onChange={(event, newValue) => handleSettingChange(event, newValue, field.id as keyof ReduxState['uiSettings']['ui'])}
                  >
              {field.options.map(option => (
                <ToggleButton value={option.id} sx={{ textTransform: 'none' }}>
                  {option.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
};
