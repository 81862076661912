import React, { FC, ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

export interface DeleteDialogProps {
  open: boolean;
  title: ReactNode;
  snackText: string;
  confirmText?: ReactNode;
  cancelText?: ReactNode;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DeleteDialog: FC<DeleteDialogProps> = ({
  open,
  title,
  snackText,
  confirmText,
  cancelText,
  isLoading,
  onConfirm,
  onCancel,
  children
}) => {
  const snackbar = useSnackbar();

  const handleConfirm = (): void => {
    onConfirm();
    if (snackText) {
      snackbar.display({
        id: `deleteDialog.${Date.now()}`,
        text: snackText,
        type: 'error',
      });
    }
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent sx={{ p: 3, pb: 0 }}>
        <Stack spacing={3} my={3}>
          {children}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey' }}>
        <Stack spacing={3} flex={1} direction="row" justifyContent="flex-end" height="4rem">
          <Button
            onClick={onCancel}
            variant="outlined"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={isLoading} >
            {cancelText ?? 'Cancel'}
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            size="large"
            sx={{ minWidth: '10rem' }}
            color="error"
            disabled={isLoading} >
            {confirmText ?? 'Confirm'}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
