import React from 'react';
import { useTranslations } from 'use-intl';
import { useGetIceContactGroups, useMutateIceContactGroupAssignDevices } from 'apis/rest/iceContactGroups/hooks';
import {
  AssignAssetsDialog,
  AssignAssetsDialogComponents,
  AssignAssetsDialogStatus,
} from 'components/dialogs/contactGroups/assignAssets';
import PeopleTable from 'components/pages/manage/contactGroups/ice/edit/peopleTable-view';

interface AssignAssetsDialogProps {
  open: boolean
  onClose: (status: AssignAssetsDialogStatus, assetIds: number[], contactGroupId?: number) => void
  assetIds: number[]
}

const components: AssignAssetsDialogComponents = {
  PeopleTable
};

const IceAssignAssetsDialog = ({ open, onClose, assetIds }: AssignAssetsDialogProps): JSX.Element => {
  const t = useTranslations('dialogs.ice.assignAssets');
  const { query: groupsQuery } = useGetIceContactGroups();
  const mutation = useMutateIceContactGroupAssignDevices();

  const title = t('title', { count: assetIds.length });

  return (
    <AssignAssetsDialog
      open={open}
      onClose={() => {
        if (!mutation.isLoading) onClose();
      }}
      assetIds={assetIds}
      groupsQuery={groupsQuery}
      mutation={mutation}
      components={components}
      title={title}
      ariaLabel={title}
    />
  );
};

export default IceAssignAssetsDialog;

export { AssignAssetsDialogStatus };
