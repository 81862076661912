import { createContext } from 'react';
import type { OrganisationFeature } from 'apis/rest/features/types';

export interface FeaturesContextValue {
  isLoading: boolean
  data: OrganisationFeature[] | undefined
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const FeaturesContext = createContext<FeaturesContextValue>({ isLoading: true, data: undefined });
