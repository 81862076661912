import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ProvisioningProcessorRow, ProvisioningUploadResponse } from '@tracplus/serenity-client';
import { serenityApiClient } from 'apis/client/serenityApiClient';
import { HttpResponseError } from 'helpers/api';
import { provisioningQueryKeys } from './queryKeys';
import { uploadProvisioningCSV } from './requests';
import { ActivateProvisioningForDeviceParams, UploadProvisioningCSVParams } from './types';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;
type MutationOptions<MutationData, Variables> = Omit<UseMutationOptions<MutationData, HttpResponseError, Variables, void>, 'queryKey' | 'queryFn'>;

export const useGetAllProvisioningRows = <T = ProvisioningProcessorRow[]>(options?: Options<ProvisioningProcessorRow[], T>) => {
  const queryKey = provisioningQueryKeys.allRows;
  return useQuery<ProvisioningProcessorRow[], HttpResponseError, T>(
    queryKey,
    async () => (await serenityApiClient.provisioning()).rows ?? [],
    options ?? {},
  );
};

export const useUploadProvisioningCSV = (options?: MutationOptions<ProvisioningUploadResponse, UploadProvisioningCSVParams>) => {
  const queryClient = useQueryClient();

  return useMutation<ProvisioningUploadResponse, HttpResponseError, UploadProvisioningCSVParams, void>(
    ({ file }: UploadProvisioningCSVParams) => uploadProvisioningCSV(file),
    {
      onSuccess: () => queryClient.invalidateQueries(provisioningQueryKeys.allRows),
      ...options,
    },
  );
};

export const useActivateProvisioningForDevice = (options?: MutationOptions<void, ActivateProvisioningForDeviceParams>) => useMutation<void, HttpResponseError, ActivateProvisioningForDeviceParams, void>(
  async ({ deviceId }: ActivateProvisioningForDeviceParams) => {
    await serenityApiClient.provisioningActivate(deviceId);
  },
  options,
);

export const useActivateProvisioningForAll = (options?: MutationOptions<void, void>) => useMutation<void, HttpResponseError, void, void>(
  async () => {
    await serenityApiClient.provisioningActivateAll();
  },
  options,
);
