import React, { Dispatch, Fragment, SetStateAction, useCallback } from 'react';
import { useTranslations } from 'use-intl';
import { Box, Paper, Switch, Typography } from '@mui/material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import sumBy from 'lodash/fp/sumBy';
import useVolume from 'hooks/units/useVolume';
import { DropGroup } from 'apis/rest/firefighting/types';
import { useSuppressantColors } from './helpers';

interface DropsBySuppressantScorecardProps extends PaperOwnProps {
  dropGroups: DropGroup[]
  visibleSuppressants: Record<Suppressant, boolean>
  setVisibleSuppressants: Dispatch<SetStateAction<Record<Suppressant, boolean>>>
}

const DropsBySuppressantScorecard = ({ dropGroups, sx, visibleSuppressants, setVisibleSuppressants, ...props }: DropsBySuppressantScorecardProps): JSX.Element | null => {
  const t = useTranslations('pages.reporting.firefighting');
  const volume = useVolume();

  const suppressantColor = useSuppressantColors();

  const bySuppressant = dropGroups.reduce<Record<string, DropGroup[]>>((acc, drop) => {
    if (!acc[drop.suppressant]?.push(drop)) acc[drop.suppressant] = [drop];
    return acc;
  }, {});

  const allSameSuppressant = Object.keys(bySuppressant).length === 1;

  const setButKeepOneOn = useCallback((suppressant: string, value: boolean) => {
    setVisibleSuppressants(old => {
      if (Object.entries(old).filter(([k, v]) => bySuppressant[k] && v).length === 1 && !value) {
        return old;
      }
      return { ...old, [suppressant]: value };
    });
  }, [bySuppressant, setVisibleSuppressants]);

  if (!dropGroups.length) return null;

  return (
    <Paper elevation={0} {...props} sx={{ ...sx, p: 3, display: 'flex', alignItems: 'center' }}>
      <Box flex={1} display="grid" gridTemplateColumns="1fr max-content max-content max-content" columnGap={6} alignItems="center">
        {allSameSuppressant ? (
          <Typography fontSize="1.5rem" fontWeight="bold" sx={{ gridColumn: '1 / -1' }}>
            {t('allSuppressant', { name: t(`suppressant.${dropGroups[0].suppressant}`) })}
          </Typography>
        ) : (
          (Object.keys(visibleSuppressants) as Suppressant[]).filter(suppressant => bySuppressant[suppressant]).map(suppressant => {
            const visible = visibleSuppressants[suppressant];
            const color = visible ? suppressantColor[suppressant] : 'common.disabled';
            const suppressantDrops = bySuppressant[suppressant];
            return (
              <Fragment key={suppressant}>
                <Typography fontSize="1.5rem" fontWeight="bold" color={color} whiteSpace="nowrap">
                  {t(`suppressant.${suppressant}`)}
                </Typography>
                <Typography fontSize="1.5rem" fontWeight="bold" color={color} textAlign="right">
                  {t('nDrops', { n: suppressantDrops.length })}
                </Typography>
                <Typography fontSize="1.5rem" fontWeight="bold" color={color} textAlign="right">
                  {volume.create(sumBy('volumeDroppedLitres', suppressantDrops)).format()}
                </Typography>
                <Switch
                  checked={visible}
                  onChange={event => setButKeepOneOn(suppressant, event.target.checked)}
                  inputProps={{ 'aria-label': t('toggleSuppressantLabel', { name: t(`suppressant.${suppressant}`) }) }}
                />
              </Fragment>
            );
          })
        )}
      </Box>
    </Paper>
  );
};

export default DropsBySuppressantScorecard;
