import React, { useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';
import moment from 'utils/moment';
import { useQueryClient } from '@tanstack/react-query';
import DistressDialog from 'components/contextbox/asset/distress/distressDialog';
import useInterval from 'hooks/useInterval';
import useSnackbar from 'hooks/useSnackbar';
import { deviceQueryKeys } from 'apis/rest/devices/queryKeys';
import { getReports } from 'apis/rest/reports/requests';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useReportsDataRepository } from 'repositories/reports/hooks';
import useTimezone from "hooks/session/useTimezone";

interface LiveUpdateDummyProps {
  organisationId: string
  setUnreadTextMessage: (deviceId: number, reportId: number) => void
  mostRecentDeviceReport: number | null
  setMostRecentDeviceReport: (time: number | null) => void
}

const LiveUpdateDummy = ({
  organisationId,
  setUnreadTextMessage,
  mostRecentDeviceReport,
  setMostRecentDeviceReport,
}: LiveUpdateDummyProps): JSX.Element => {
  const timezone = useTimezone();
  const t = useTranslations('components.LiveUpdateDummy');
  const snackbar = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [distressReports, setDistressReports] = React.useState<Report[]>([]);
  const reportsRepo = useReportsDataRepository();
  const queryClient = useQueryClient();

  const assetLabel = useAssetLabel();
  const assets = useGetAssetsList().query;

  const [messagesSeen, setMessagesSeen] = useState<number[]>([]);

  useEffect(() => () => {
    console.info('clear reports');
    setMostRecentDeviceReport(null);
    reportsRepo.clear();
    reportsRepo.updateAllAssets();
  }, [reportsRepo, timezone, setMostRecentDeviceReport]);

  useInterval(async () => {
    const until = moment();
    const from = mostRecentDeviceReport ? moment(mostRecentDeviceReport) : until.clone().subtract('1', 'minute');
    console.log(`getting current reports for ${organisationId} from ${from.toISOString()} until ${until.toISOString()}`);
    const reportsSince = await getReports({ organisationId, from: from.valueOf(), until: until.valueOf() });

    const newDistressReports: Report[] = [];
    const reportsSinceForAsset = reportsSince.reduce((assetReports, report) => {
      assetReports[report.assetId] = [...(assetReports[report.assetId] || []), report];
      return assetReports;
    }, {} as Record<number, Report[]>);
    Object.values(reportsSinceForAsset).forEach(assetReports => {
      const latestReport = [...assetReports].sort((a, b) => b.received - a.received)[0];
      const latestReportMoment = moment.unix(latestReport?.received);

      if ((latestReportMoment.diff(moment(), 'h') >= -2)) {
        if (latestReport.events.some(e => e.includes('EVT_DISTRESS'))) {
          if (!distressReports.some(r => r.id === latestReport.id)) {
            newDistressReports.push(latestReport);
            setOpen(true);
          }
        }
      }

      // Update lastActive time on device query (used in device page)
      queryClient.setQueryData<DeviceBasic | undefined>(
        deviceQueryKeys.detail(organisationId, latestReport.deviceId),
        data => {
          if (!data) { return undefined; }
          if (moment(data.lastActive).isAfter(latestReportMoment)) { return undefined; }
          return { ...data, lastActive: latestReportMoment.tz(timezone).toISOString(true) };
        },
      );

      // If any of the new messages arriving are text messages set unread state for the device in redux
      assetReports.forEach(r => {
        if (r.events.includes('EVT_TEXT') && (moment.unix(r?.received).diff(moment(), 'm') >= -10)) {
          setUnreadTextMessage(r.deviceId, r.id);
          if (messagesSeen.includes(r.id)) {
            setMessagesSeen(messagesSeen.filter(id => r.id === id));
            return;
          }

          setMessagesSeen(messagesSeen.concat([r.id]));
          const asset = assets.data?.find(a => a.id === r.assetId);
          const text = asset ? t('receivedMessage', {
            asset: assetLabel(asset),
            message: r.text,
          }) : t('receivedMessageNoAsset', { message: r.text });
          snackbar.display({
            id: `message-received-${r.id}`,
            type: 'info',
            text,
          });
        }

        // update latest config (tracking rates in context box, device page)
        if (r.events.includes('EVT_CONFIG')) {
          queryClient.setQueryData(['config', organisationId, r.deviceId], {
            deviceId: r.deviceId,
            time: r.received,
            config: r.package,
          });
        }
      });
    });

    // use insertReports because it only triggers render once all are in.
    const todayStart = moment().tz(timezone).startOf('day');
    const count = reportsRepo.reportRepository.reports.size;
    reportsRepo.insertReports(reportsSince, todayStart.unix());
    console.info(`inserted ${reportsRepo.reportRepository.reports.size - count} reports for ${organisationId}`);

    setDistressReports([...distressReports, ...newDistressReports]);

    const latestReportTimestamp = reportsSince.length ? Math.max(...reportsSince.map(r => r.received)) : undefined;
    if (latestReportTimestamp) {
      setMostRecentDeviceReport(latestReportTimestamp * 1000); // to ms
    }
  }, 10 * 1000);

  // @ts-ignore
  return <DistressDialog open={open} setOpen={setOpen} reports={distressReports} />;
};

export default LiveUpdateDummy;
