import React from 'react';
import Alert from './alert';
import AscentExceedence from './ascentExceedence';
import Config from './config';
import ContainmentLineStart from './ContainmentLineStart';
import ContainmentLineStop from './ContainmentLineStop';
import CourseChange from './courseChange';
import DescentExceedence from './descentExceedence';
import DistressCancel from './distressCancel';
import DistressStart from './distressStart';
import DownArrow from './downArrow';
import Drop from './drop';
import DropEnd from './dropEnd';
import DropStart from './dropStart';
import DropStop from './dropStop';
import EngineExceedance from './engineExceedance';
import EngineStart from './engineStart';
import EngineStop from './engineStop';
import Fill from './fill';
import FillStart from './fillStart';
import FillStop from './fillStop';
import Form from './form';
import GeofenceSuspend from './geofenceSuspend';
import Hover from './hover';
import HoverStart from './hoverStart';
import HoverStop from './hoverStop';
import Landing from './landing';
import LoadSettled from './loadSettled';
import Manual from './manual';
import ManualLoad from './manualLoad';
import Minus from './minus';
import MissionStart from './missionStart';
import MissionStop from './missionStop';
import None from './none';
import Oooiin from './oooiin';
import Oooioff from './oooioff';
import Oooion from './oooion';
import Oooiout from './oooiout';
import Plus from './plus';
import PointOfInterest from './pointOfInterest';
import PumpStart from './pumpStart';
import PumpStop from './pumpStop';
import RotorBrakeOff from './rotorBrakeOff';
import RotorBrakeOn from './rotorBrakeOn';
import SarCancel from './sarCancel';
import SarRequest from './sarRequest';
import Shutdown from './shutdown';
import Startup from './startup';
import Switch from './switch';
import Takeoff from './takeoff';
import Text from './text';
import TrackingResumed from './trackingResumed';
import TrackingSuspended from './trackingSuspended';
import Unknown from './unknown';
import UpArrow from './upArrow';
import Winch from './winch';

const EventIcon = props => {
  // eslint-disable-next-line react/destructuring-assignment
  switch (props.type) {
    case 'EVT_ALERT': return <Alert {...props} />;
    case 'EVT_ASCENTEXCEEDENCE': return <AscentExceedence {...props} />;
    case 'EVT_CONFIG': return <Config {...props} />;
    case 'EVT_COURSE_CHANGE': return <CourseChange {...props} />;
    case 'EVT_DESCENTEXCEEDENCE': return <DescentExceedence {...props} />;
    case 'EVT_DISTRESS_CANCEL': return <DistressCancel {...props} />;
    case 'EVT_DISTRESS_START': return <DistressStart {...props} />;
    case 'EVT_DISTRESS': return <DistressStart {...props} />;
    case 'downArrow': return <DownArrow {...props} />;
    case 'EVT_DROP': return <Drop {...props} />;
    case 'EVT_DROP_END': return <DropEnd {...props} />;
    case 'EVT_DROP_START': return <DropStart {...props} />;
    case 'EVT_DROP_STOP': return <DropStop {...props} />;
    case 'EVT_ENGINEEXCEEDANCE': return <EngineExceedance {...props} />;
    case 'EVT_ENGINEON': return <EngineStart {...props} />;
    case 'EVT_ENGINEOFF': return <EngineStop {...props} />;
    case 'EVT_FILL': return <Fill {...props} />;
    case 'EVT_FILL_START': return <FillStart {...props} />;
    case 'EVT_FILL_STOP': return <FillStop {...props} />;
    case 'form':
    case 'EVT_FORM':
      return <Form {...props} />;
    case 'EVT_GEOFENCE_SUSPEND': return <GeofenceSuspend {...props} />;
    case 'EVT_HOVER': return <Hover {...props} />;
    case 'EVT_HOVER_START': return <HoverStart {...props} />;
    case 'EVT_HOVER_STOP': return <HoverStop {...props} />;
    case 'EVT_LANDING': return <Landing {...props} />;
    case 'EVT_LOAD_SETTLED': return <LoadSettled {...props} />;
    case 'EVT_MANUAL':
    case 'EVT_OPSNORMAL': // TODO: replace opsnormal with a tick or some other affirmative
      return <Manual {...props} />;
    case 'EVT_MANUAL_LOAD': return <ManualLoad {...props} />;
    case 'minus': return <Minus {...props} />;
    case 'EVT_MISSION_START': return <MissionStart {...props} />;
    case 'EVT_MISSION_STOP': return <MissionStop {...props} />;
    case 'none': return <None {...props} />;
    case 'EVT_OOOI_IN': return <Oooiin {...props} />;
    case 'EVT_OOOI_OFF': return <Oooioff {...props} />;
    case 'EVT_OOOI_ON': return <Oooion {...props} />;
    case 'EVT_OOOI_OUT': return <Oooiout {...props} />;
    case 'plus': return <Plus {...props} />;
    case 'EVT_POINTOFINTEREST': return <PointOfInterest {...props} />;
    case 'EVT_PUMP_ON': return <PumpStart {...props} />;
    case 'EVT_PUMP_OFF': return <PumpStop {...props} />;
    case 'EVT_CONTAINMENT_LINE_START': return <ContainmentLineStart {...props} />;
    case 'EVT_CONTAINMENT_LINE_STOP': return <ContainmentLineStop {...props} />;
    case 'EVT_ROTOR_BRAKE_OFF': return <RotorBrakeOff {...props} />;
    case 'EVT_ROTOR_BRAKE_ON': return <RotorBrakeOn {...props} />;
    case 'EVT_SARCANCEL': return <SarCancel {...props} />;
    case 'EVT_SARREQ': return <SarRequest {...props} />;
    case 'EVT_SHUTDOWN': return <Shutdown {...props} />;
    case 'EVT_STARTUP': return <Startup {...props} />;
    case 'EVT_SWITCH': return <Switch {...props} />;
    case 'EVT_TAKEOFF': return <Takeoff {...props} />;
    case 'EVT_TEXT': return <Text {...props} />;
    case 'EVT_TRACKING_RESUME': return <TrackingResumed {...props} />;
    case 'EVT_TRACKING_SUSPEND': return <TrackingSuspended {...props} />;
    case 'EVT_UNKNOWN': return <Unknown {...props} />;
    case 'upArrow': return <UpArrow {...props} />;
    case 'EVT_WINCH': return <Winch {...props} />;
    default: return <Unknown {...props} />;
  }
};

export default EventIcon;
