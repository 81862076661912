import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import { setOrganisationId } from 'slices/session/session.slice';
import { leaveOrganisationAction, joinOrganisationAction } from 'slices/session/thunks';
import OrganisationsListView from './organisationsList-view';

export default Smart(
  OrganisationsListView,
  state => ({
    user: state.session.user,
    organisationId: state.session.organisationId,
    permissions: permissionsForCurrentUserAndOrg(state),
  }),
  ac => ({
    setOrganisation: setOrganisationId,
    joinOrganisation: joinOrganisationAction,
    leaveOrganisation: leaveOrganisationAction,
    // createOrganisation: ac.organisations.createOrganisation
  })
);
