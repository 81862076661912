import { MapBaseLayerIds } from 'constants/map';
import { Style } from 'mapbox-gl';
import mapboxOutdoors from './mapbox-outdoors';
import mapboxStreets from './mapbox-streets';
import mapboxSatellite from './mapbox-satellite';
import mapboxSatelliteStreets from './mapbox-satellite-streets';
import mapboxDark from './mapbox-dark';

import nasaGibsSNPP from './nasa-gibs-satellite';

import svVFR from './skyvector-vfr';
import svIFRHi from './skyvector-ifr-hi';
import svIFRLo from './skyvector-ifr-lo';
import { IntermediaryMapTemplate, MapTemplate } from './types';

export const library = 'reactmapgl';

const createTemplate = (id: string, template: (lang: string, now: string | null) => IntermediaryMapTemplate) => ({
  create: (language: string, now: string | null): MapTemplate => {
    const result = template(language, now);
    return {
      template: result.mapboxStyle || (result as Style),
      library,
      id,
      ...result
    };
  },
  id
});

export default [
  createTemplate(MapBaseLayerIds.MapboxOutdoors, mapboxOutdoors),
  createTemplate(MapBaseLayerIds.MapboxStreets, mapboxStreets),
  createTemplate(MapBaseLayerIds.MapboxSatellite, mapboxSatellite),
  createTemplate(MapBaseLayerIds.MapboxSatelliteStreets, mapboxSatelliteStreets),
  createTemplate(MapBaseLayerIds.MapboxDark, mapboxDark),
  createTemplate(MapBaseLayerIds.SkyVectorVFR, svVFR),
  createTemplate(MapBaseLayerIds.SkyVectorIFRHi, svIFRHi),
  createTemplate(MapBaseLayerIds.SkyVectorIFRLo, svIFRLo),
  createTemplate(MapBaseLayerIds.GibsSNPP, nasaGibsSNPP)
].reduce((acc, x) => ({
  ...acc,
  [x.id]: x.create
}), {});
