import { useQueries, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { getEngineUsageForAsset } from './requests';
import { engineUsageQueryKeys } from './queryKeys';
import { EngineUsage } from './types';
import { useCallback } from 'react';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetEngineUsageForAsset = <T = EngineUsage[]>(assetId: number, from: number | undefined, until: number | undefined, options?: Options<EngineUsage[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = engineUsageQueryKeys.forAsset(organisationId, assetId, from, until);
  return useQuery<EngineUsage[], HttpResponseError, T>(
    queryKey,
    async () => {
      if (from === undefined || until === undefined) return [];
      return getEngineUsageForAsset(organisationId, assetId, from, until);
    },
    {
      ...options,
      enabled: options?.enabled !== false && from !== undefined && until !== undefined,
    },
  );
};

export const useGetEngineUsageForAssets = (assetIds: number[], from?: number, until?: number, options?: { enabled?: boolean }) => {
  const organisationId = useOrganisationId();
  return useQueries(
    {
      queries: assetIds.map<UseQueryOptions<EngineUsage[], HttpResponseError>>(id => ({
        queryKey: engineUsageQueryKeys.forAsset(organisationId, id, from, until),
        queryFn: () => {
          if (from === undefined || until === undefined) return [];
          return getEngineUsageForAsset(organisationId, id, from, until);
        },
        enabled: from !== undefined && until !== undefined,
        ...options,
      })),
    }
  );
};

// NOTE: This is for one-off on-demand fetching of engine usage
export const useFetchEngineUsage = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useCallback(async (assetIds: number[], from: number, until: number, staleTime = 0) => Promise.all(
    assetIds.map(assetId => queryClient.fetchQuery({
      queryKey: engineUsageQueryKeys.forAsset(organisationId, assetId, from, until),
      queryFn: () => getEngineUsageForAsset(organisationId, assetId, from, until),
      staleTime,
    }))
  ).then(results => results.flat(1)), [queryClient, organisationId]);
};
