import { Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import { Marker } from 'apis/rest/markers/types';
import { useLatestPosition } from 'repositories/reports/hooks';
import { calculateDistance } from 'helpers/unitsOfMeasure';
import Distance from 'components/shared/distance';
import Coordinate from 'components/shared/coordinate';
import { DateTime, Duration } from 'luxon';
import turfBearing from '@turf/bearing';
import useTimezone from 'hooks/session/useTimezone';
import { DetailsGrid, OverlayHeader, OverlayWrapper, ScoreCard } from './common';

interface HoveredMarkerOverlayProps {
  marker?: Marker,
  selectedAsset?: AssetBasic
  highContrast: boolean
}

export const HoveredMarkerOverlay = ({ marker, selectedAsset, highContrast }: HoveredMarkerOverlayProps) => {
  const t = useTranslations('pages.map.markers');
  const timezone = useTimezone();

  const latestPosition = useLatestPosition(selectedAsset?.id);
  const [distance, ete, bearing] = useMemo(() => {
    if (!marker || !latestPosition) {
      return [undefined, undefined, undefined];
    }
    const distanceToMarker = calculateDistance(marker, latestPosition, 'metres');
    if (distanceToMarker === '--') {
      return [undefined, undefined, undefined];
    }
    const estimatedTime = latestPosition.speed > 0 ? distanceToMarker / (latestPosition.speed / 3.6) : null;
    const southBearing = turfBearing([marker.longitude, marker.latitude], [latestPosition.longitude, latestPosition.latitude]);
    return [distanceToMarker, estimatedTime, 180 + southBearing];
  }, [marker, latestPosition]);

  const [time, updateTime] = useState(DateTime.now().setZone(timezone));
  const eta = useMemo(() => {
    if (!ete) {
      return undefined;
    }
    return (time.plus(Duration.fromObject({ seconds: ete }))).toFormat('HH:mm:ss');
  }, [ete, time]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTime(DateTime.now().setZone(timezone));
    }, 1000);

    return () => clearInterval(interval);
  }, [timezone]);

  return (marker) ? (
    <OverlayWrapper highContrast={highContrast}>
      <DetailsGrid>
        <ScoreCard label={t('marker')} wide>
          <Typography variant="h3">{marker.name}</Typography>
        </ScoreCard>
      </DetailsGrid>
      <DetailsGrid>
        <ScoreCard label={t('coordinates')} wide>
          <Coordinate latitude={marker.latitude} longitude={marker.longitude} />
        </ScoreCard>
        <ScoreCard label={t('type')} value={marker.type} />
      </DetailsGrid>
      {latestPosition && selectedAsset && (
        <>
          <OverlayHeader withTopBorder mb={-2}>
            <Typography variant="h5">{t('assetToMarker', { asset: selectedAsset.name, marker: marker.name })}</Typography>
          </OverlayHeader>
          <DetailsGrid withoutTopBorder>
            <ScoreCard label={t('distance')}>
              <Distance distanceInMetres={distance ?? 0} />
            </ScoreCard>
            <ScoreCard label={t('bearing')} value={`${bearing?.toFixed(0)} °T`} />
            <ScoreCard label={t('ete')} value={ete ? Duration.fromObject({ seconds: ete }).toFormat('hh:mm:ss') : '--:--:--'} />
            <ScoreCard label={t('eta')} value={eta} />
          </DetailsGrid>
        </>
      )}
    </OverlayWrapper>
  ) : null;
};
