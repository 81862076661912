import React, { Fragment, useMemo, useState } from 'react';
import { Box, Button, ButtonGroup, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import sumBy from 'lodash/fp/sumBy';
import max from 'lodash/fp/max';
import { scaleLinear } from 'd3';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import useDuration from 'hooks/units/useDuration';
import { Link } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import useVolume, { useVolumePerPeriod } from 'hooks/units/useVolume';
import { useSelector } from 'react-redux';
import { selectAssetsNoDrops } from 'slices/statsFilter.slice';
import { useSuppressantColors } from 'components/pages/reporting/firefighting/helpers';
import { DropGroup } from 'apis/rest/firefighting/types';
import { TripBasic } from 'apis/rest/trips/types';
import { EfficiencyStats, calculateEfficiencyStats, calculateTotalFlightTime } from './statistics';

const sumVolume = sumBy<DropGroup>('volumeDroppedLitres');

interface DropsByAssetScorecardProps extends PaperOwnProps {
  assetIds: number[]
  dropGroups: DropGroup[]
  trips: TripBasic[]
  visibleSuppressants: Record<Suppressant, boolean>
}

interface SuppressantBarProps {
  dropGroups: DropGroup[]
  visibleSuppressants: Record<Suppressant, boolean>
  getWidth: (drops: DropGroup[]) => string
}

interface StatsDisplayProps {
  tooltipKey?: 'averageDropsPerHour' | 'averageVolumePerHour' | 'averageDropsPerFlightHour' | 'averageVolumePerFlightHour';
  valueKey?: 'nDropsPerHour';
  value: number | string;
}

const StatsDisplay = ({ valueKey, value, tooltipKey }: StatsDisplayProps) => {
  const t = useTranslations('pages.reporting.firefighting.stats');

  if (!tooltipKey) {
    return (
      <Typography minWidth="18ch">{valueKey ? t(valueKey, { n: value }) : value}</Typography>
    );
  }

  return (
    <Box minWidth="18ch">
      <Tooltip title={t(`tooltips.${tooltipKey}`)} placement="bottom">
        <Box display="flex" gap={1}>
          <Typography>{value ? (valueKey ? t(valueKey, { n: value }) : value) : '—'}</Typography>
          <Stack direction="column" justifyContent="center">
            <HelpOutline sx={{ fontSize: '1rem' }} />
          </Stack>
        </Box>
      </Tooltip>
    </Box>
  );
};

const SuppressantBar = ({ dropGroups, visibleSuppressants, getWidth }: SuppressantBarProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const suppressantColor = useSuppressantColors();

  const bySuppressant = dropGroups.reduce<Record<string, DropGroup[]>>((acc, drop) => {
    if (!acc[drop.suppressant]?.push(drop)) acc[drop.suppressant] = [drop];
    return acc;
  }, {});

  const items = (Object.keys(visibleSuppressants) as Suppressant[])
    .map(suppressant => ({ suppressant, drops: bySuppressant[suppressant] ?? [] }));

  const itemsWithDrops = items.filter(item => item.drops.length);

  return (
    <Tooltip hidden={!itemsWithDrops.length} title={(
      <Stack display="grid" gridTemplateColumns="max-content max-content max-content max-content" columnGap={1} rowGap={2} my={1}>
        {itemsWithDrops.filter(item => item.drops.length).map(item => (
          <Fragment key={item.suppressant}>
            <Box bgcolor={suppressantColor[item.suppressant]} width="1rem" height="1rem" borderRadius="50%" />
            <Box>{t(`suppressant.${item.suppressant}`)}</Box>
            <Box>{t('nDrops', { n: item.drops.length })}</Box>
            <Box>{sumVolume(item.drops)?.toFixed(0) ?? 0} L</Box>
          </Fragment>
        ))}
      </Stack>
    )}>
      <Stack
        direction="row"
        bgcolor="common.lightGrey"
        borderRadius="4px"
        overflow="hidden"
        mb={0.5}
        sx={{ transition: 'opacity 300ms', '&:hover': { opacity: 0.7 } }}
      >
        {items.map(item => (
          <Box
            key={item.suppressant}
            height="1.5rem"
            width={getWidth(item.drops)}
            sx={{ transition: 'width 300ms' }}
            bgcolor={suppressantColor[item.suppressant]}
          />
        ))}
      </Stack>
    </Tooltip>
  );
};

const DropsByAssetScorecard = ({ assetIds, dropGroups, trips, sx, visibleSuppressants, ...props }: DropsByAssetScorecardProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const volume = useVolume();
  const volumePerPeriod = useVolumePerPeriod();
  const [metric, setMetric] = useState<'volume' | 'count'>('count');
  const assetsQuery = useGetAssetsList<AssetWithDevice[]>().query;

  const duration = useDuration();

  const allDropsByAssetId = useMemo(() => dropGroups.reduce<Record<number, DropGroup[]>>((acc, drop) => {
    if (!acc[drop.assetId]?.push(drop)) acc[drop.assetId] = [drop];
    return acc;
  }, {}), [dropGroups]);

  const dropsByAssetId = useMemo(() => dropGroups.filter(dg => visibleSuppressants[dg.suppressant]).reduce<Record<number, DropGroup[]>>((acc, drop) => {
    if (!acc[drop.assetId]?.push(drop)) acc[drop.assetId] = [drop];
    return acc;
  }, {}), [dropGroups, visibleSuppressants]);

  const assetsWithNoDrops = useMemo(() => assetIds.filter(assetId => !allDropsByAssetId[assetId]), [allDropsByAssetId, assetIds]);

  const filteredTripsByAssetId = useMemo(() => trips.reduce<Record<number, TripBasic[]>>((acc, trip) => {
    if (!acc[trip.assetId]?.push(trip)) acc[trip.assetId] = [trip];
    return acc;
  }, {}), [trips]);

  const statsByAssetId = useMemo(() => Object.keys(dropsByAssetId)
    .reduce<Record<number, EfficiencyStats>>((acc, assetId) => {
      acc[+assetId] = calculateEfficiencyStats(
        filteredTripsByAssetId[+assetId] ?? [],
        dropsByAssetId[+assetId],
        drop => visibleSuppressants[drop.suppressant],
      );
      return acc;
    }, {}), [dropsByAssetId, filteredTripsByAssetId, visibleSuppressants]);

  const flightTimeByAsset = useMemo(() => assetIds.reduce<Record<number, {
    millis: number,
    format: string,
  }>>((acc, assetId) => {
    const format = (ms: number) => {
      if (ms > 60 * 60 * 1000) return 'h\'h\' m\'m\' s\'s\'';
      if (ms > 60 * 1000) return 'm\'m\' s\'s\'';
      return 's\'s\'';
    };

    const flightTime = calculateTotalFlightTime(filteredTripsByAssetId[assetId] ?? [], dropsByAssetId[assetId] ?? []);

    if (flightTime > 0) {
      acc[assetId] = {
        millis: flightTime,
        format: format(flightTime),
      };
    }
    return acc;
  }, {}), [assetIds, filteredTripsByAssetId, dropsByAssetId]);

  const getWidth = useMemo(() => {
    if (metric === 'volume') {
      const maxAssetDropVolume = max(Object.values(dropsByAssetId).map(sumVolume)) ?? 1;
      const scale = scaleLinear([0, maxAssetDropVolume ?? 1], [0, 100]);
      return (dgs: DropGroup[]) => {
        if (dgs.length === 0 || !visibleSuppressants[dgs[0].suppressant]) { return '0%'; }
        return `${scale(sumVolume(dgs) ?? 0)}%`;
      };
    }

    const maxAssetDropCount = max(Object.values(dropsByAssetId).map(dgs => dgs.flatMap(dg => dg.drops).length)) ?? 1;
    const scale = scaleLinear([0, maxAssetDropCount ?? 1], [0, 100]);
    return (dgs: DropGroup[]) => {
      if (dgs.length === 0 || !visibleSuppressants[dgs[0].suppressant]) { return '0%'; }
      return `${scale(dgs.flatMap(dg => dg.drops).length)}%`;
    };
  }, [metric, dropsByAssetId, visibleSuppressants]);

  const assetNoDropsDisabled = useSelector(selectAssetsNoDrops);
  const relevantAssets = useMemo(() => assetsQuery.data?.filter(asset => {
    if (!assetNoDropsDisabled) {
      return assetIds.includes(asset.id);
    }
    return assetIds.includes(asset.id) && !assetsWithNoDrops.includes(asset.id);
  }), [assetsWithNoDrops, assetIds, assetNoDropsDisabled, assetsQuery.data]);
  const lastAsset = relevantAssets?.at(-1);

  return (
    <Paper elevation={0} {...props} sx={{ ...sx, p: 3 }}>
      <Stack direction="row" justifyContent="space-between" mb={4}>
        <Typography fontSize="1.5rem" fontWeight="bold" variant="h2">{t('dropsByAsset')}</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{t('chartBy')}</Typography>
          <ButtonGroup variant="outlined">
            <Button onClick={() => setMetric('count')} variant={metric === 'count' ? 'contained' : undefined}>{t('dropCount')}</Button>
            <Button onClick={() => setMetric('volume')} variant={metric === 'volume' ? 'contained' : undefined}>{t('volume')}</Button>
          </ButtonGroup>
        </Stack>
      </Stack>
      <Box display="grid" gridTemplateColumns="min(50%, 400px) 1fr max-content" columnGap={3} rowGap={2}>
        {(relevantAssets?.length ?? 0) > 0 ? relevantAssets?.map(asset => {
          const assetDrops = dropsByAssetId[asset.id] ?? [];
          const flightTime = flightTimeByAsset[asset.id];
          const stats = statsByAssetId[asset.id] ?? {
            dropsPerHour: 0,
            volumePerHour: 0,
            dropsPerFlightHour: 0,
            volumePerFlightHour: 0,
          };

          return (
            <Fragment key={asset.id}>
              <Box>
                <Stack direction="row" alignItems="center" spacing={1} mb={0.5}>
                  <AssetColourMarker assetId={asset.id} />
                  <Typography fontWeight="bold" fontSize="1.2rem" lineHeight="1.5rem" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"><AssetLabel asset={asset} /></Typography>
                </Stack>
                <Typography fontSize="1rem">{asset.make} {asset.model}</Typography>
              </Box>
              <Box>
                <SuppressantBar dropGroups={assetDrops} getWidth={getWidth} visibleSuppressants={visibleSuppressants} />
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                  <Typography minWidth="18ch">{t('nDrops', { n: assetDrops.length })}</Typography>
                  {flightTime && <Typography minWidth="18ch">{duration.fromMillis(flightTime.millis, undefined, flightTime.format)}</Typography>}
                  {assetDrops.length > 0 && (
                    <>
                      <Typography minWidth="18ch">{volume.create(sumVolume(assetDrops)).format()}</Typography>
                      <StatsDisplay
                        valueKey="nDropsPerHour"
                        value={stats.dropsPerHour}
                        tooltipKey="averageDropsPerHour"
                      />
                      <StatsDisplay
                        value={volumePerPeriod.create(stats.volumePerHour, 'h').format()}
                        tooltipKey="averageVolumePerHour"
                      />
                      <StatsDisplay
                        valueKey="nDropsPerHour"
                        value={stats.dropsPerFlightHour}
                        tooltipKey="averageDropsPerFlightHour"
                      />
                      <StatsDisplay
                        value={volumePerPeriod.create(stats.volumePerFlightHour, 'h').format()}
                        tooltipKey="averageVolumePerFlightHour"
                      />
                    </>
                  )}
                </Stack>
              </Box>
              <Button component={Link} disabled={assetDrops.length === 0} to={`asset/${asset.id}`} size="large" variant="outlined">{t('assetDetails')}</Button>
              {asset !== lastAsset && <Divider sx={{ gridColumn: '1 / -1', my: 1 }} />}
            </Fragment>
          );
        }) : <p>{t('noAssetsToDisplay')}</p>}
      </Box>
    </Paper>
  );
};

export default DropsByAssetScorecard;
