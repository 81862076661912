import React from 'react';
import { useTranslations } from 'use-intl';
import Arrow from '@mui/icons-material/KeyboardArrowDown';
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import BootstrapInput from 'components/shared/forms/inputs/bootstrap';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useUser } from 'hooks/session/useUser';
import useStyles from './organisationSelector-styles';

interface HeaderOrganisationSelectorProps {
  organisationId: string
  organisations: MemberOf[]
  setOrganisation: (id: string) => void
  selectedMapId: string
  orgHomeBases: Record<string, unknown>
  setSelectedGroupBy: (value: Record<string, unknown>) => void
  jumpToHomeBase: (mapId: string, base: unknown) => void
  setFollow: (mapId: string, follow: unknown) => void
}

const HeaderOrganisationSelector: React.FC<HeaderOrganisationSelectorProps> = ({
  organisationId,
  organisations = [],
  setOrganisation,
  selectedMapId,
  orgHomeBases,
  setSelectedGroupBy,
  jumpToHomeBase,
  setFollow
}) => {
  const t = useTranslations('header.modules.organisationSelector');
  const classes = useStyles();
  const usecodeLogin = useIsUsercodeLogin();
  const username = useUser()?.name;

  const organisationsSortedByName = [...organisations].sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1)) ?? [];

  const onChange = async e => {
    setOrganisation(e.target.value);
    setFollow(selectedMapId, null);
    setSelectedGroupBy({ assets: 'latestActivity' });
    jumpToHomeBase(selectedMapId, orgHomeBases[e.target.value]);
  };

  if (usecodeLogin) return <Box role="heading" px={2} fontWeight={500} ml={2} mr="auto">{username}</Box>;

  if (organisations.length === 1) return <Box role="heading" px={2} fontWeight={500} ml={2} mr="auto">{organisations[0]?.name ?? organisationId}</Box>;

  return (
    <FormControl variant="standard" className={classes.wrapper}>
      <Select
        variant="standard"
        labelId="organisationSelectLabel"
        value={organisationId}
        renderValue={id => <Box role="heading">{organisations.find(org => org.id === id)?.name ?? id}</Box>}
        onChange={onChange}
        input={<BootstrapInput className={classes.input} />}
        IconComponent={() => (<Arrow className={classes.icon} />)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          }
        }}
      >
        {organisationsSortedByName.map(o => (
          <MenuItem className={classes.menuItem} key={o.id} value={o.id}>{o.name}</MenuItem>
        ))}
      </Select>
      <InputLabel id="organisationSelectLabel" sx={{ display: 'none' }}>{t('selectOrganisation')}</InputLabel>
    </FormControl>
  );
};

export default HeaderOrganisationSelector;
