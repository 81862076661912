import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import Page from 'components/pages/page';
import LinkAbove from 'components/shared/linkAbove';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import LoadingPage from 'components/pages/loading';
import MissingPage from 'components/shared/missingPage';
import useLastDefined from 'hooks/useLastDefined';
import useValidateName from 'hooks/contactGroup/useValidateName';
import useSnackbar from 'hooks/useSnackbar';
import usePermissions from 'hooks/session/usePermissions';
import useFeature from 'hooks/features/useFeature';
import { PeopleGroupBackLinkState } from 'helpers/location';
import { BasicDetails } from 'components/pages/manage/contactGroups/common/contactGroupEdit/basicDetails-view';
import { Remove } from 'components/pages/manage/contactGroups/common/contactGroupEdit/remove-view';
import { ContactGroupPeopleTable } from 'components/pages/manage/contactGroups/common/contactGroupPeopleTable';
import { useGetPeopleGroups, useMutateDeletePeopleGroup, useMutateUpdatePeopleGroup } from 'apis/rest/peopleGroups/hooks';
import { AssignPeopleToPeopleGroupDialog } from 'components/dialogs/peopleGroups/assignPeople';
import DeletePeopleGroupDialog from 'components/dialogs/peopleGroups/deleteGroup';
import { NotificationViewTable } from 'components/shared/notificationGroup/NotificationViewTable';
import PageStaffAccessAlert from 'components/shared/pageStaffAccessAlert';

const removeComponents = { DeleteDialog: DeletePeopleGroupDialog };

const EditPeopleGroupPage = (): JSX.Element => {
  const featureEnabled = useFeature('manage.notifications');
  const permissions = usePermissions();
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id ?? '', 10);

  const { query: groupsQuery, queryKey: groupsQueryKey } = useGetPeopleGroups();
  const updateMutation = useMutateUpdatePeopleGroup();
  const deleteMutation = useMutateDeletePeopleGroup();

  const foundGroup = groupsQuery.data?.find(g => g.id === id);
  const group = useLastDefined<ContactGroup>(foundGroup, groupsQueryKey);

  const t = useTranslations('pages.manage.peopleGroups.edit');

  const [isAssignPeopleDialogOpen, setIsAssignPeopleDialogOpen] = useState<boolean>(false);

  const onAssignPeople = () => {
    setIsAssignPeopleDialogOpen(true);
  };

  const validateName = useValidateName(groupsQuery);

  const snackbar = useSnackbar();

  useEffect(() => {
    if (!updateMutation.isSuccess) return;
    snackbar.display({
      id: `peopleGroup.edit.${id}`,
      text: t('basicDetails.snackbar.success'),
      type: 'success',
    });
  }, [id, updateMutation.isSuccess, snackbar, t]);

  useEffect(() => {
    if (!updateMutation.isError) return;
    snackbar.display({
      id: `peopleGroup.error.edit.${id}`,
      text: t('basicDetails.snackbar.error'),
      type: 'error'
    });
  }, [id, updateMutation.isError, snackbar, t]);

  if (deleteMutation.isSuccess) return <Navigate to="/manage/people-groups" replace />;

  if (!group) {
    if (groupsQuery.isLoading || groupsQuery.isRefetching) return <LoadingPage />;
    return (
      <MissingPage
        to="/manage/people-groups"
        title={t('missing.title')}
        description={t('missing.description')}
        linkText={t('missing.link')}
      />
    );
  }

  return (
    <Page title={group?.name}>
      <SettingsMenuPageWrapper>
        <Container maxWidth="md">
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>
              {permissions.canEditContactGroups ? t('title', { name: group.name }) : group.name}
            </Typography>
            {featureEnabled === false && <PageStaffAccessAlert />}
          </Box>

          {permissions.canEditContactGroups && (
            <SettingsSection title={t('basicDetails.title')}>
              <DetailPanel spacing={3}>
                <BasicDetails
                  group={group}
                  mutation={updateMutation}
                  validateName={validateName}
                />
              </DetailPanel>

              <DetailPanel spacing={3} mt={3}>
                <Remove
                  group={group}
                  mutation={deleteMutation}
                  components={removeComponents}
                >
                  {group.isDefault ? (
                    <>
                      <Typography>{t('remove.isDefaultDescription')}</Typography>
                      <Typography>{t('remove.isDefaultBeforeRemoveDescription')}</Typography>
                    </>
                  ) : null}
                </Remove>
              </DetailPanel>
            </SettingsSection>
          )}

          <SettingsSection
            title={t('people.title')}
            description={t('people.description')}
          >
            {group.peopleWithOrder.length ? (
              <DetailPanel spacing={3} pt={0}>
                <ContactGroupPeopleTable
                  group={group}
                  edit={permissions.canEditContactGroups}
                  editLinkProps={personId => ({
                    to: `/manage/people/${personId}`,
                    state: new PeopleGroupBackLinkState(id),
                  })}
                  noEndBorder={!permissions.canEditContactGroups}
                />
                {permissions.canEditContactGroups && (
                  <Stack px={3} direction="row" justifyContent="flex-end" height="4rem">
                    <Button variant="contained" size="large" onClick={onAssignPeople}>
                      {t('people.assignButton')}
                    </Button>
                  </Stack>
                )}
              </DetailPanel>
            ) : (
              <DetailPanel spacing={3}>
                <Stack px={3} direction="row" justifyContent="space-between" height="4rem">
                  <Stack justifyContent="center">
                    <Typography>{t('people.noPeople')}</Typography>
                  </Stack>
                  {permissions.canEditContactGroups && (
                    <Button variant="contained" size="large" onClick={onAssignPeople}>
                      {t('people.addButton')}
                    </Button>
                  )}
                </Stack>
              </DetailPanel>
            )}
            {permissions.canEditContactGroups && (
              <AssignPeopleToPeopleGroupDialog
                title={t('people.dialog.title', { group: group.name })}
                ariaLabel={t('people.dialog.title', { group: group.name })}
                groupId={id}
                open={isAssignPeopleDialogOpen}
                onClose={() => setIsAssignPeopleDialogOpen(false)}
              />
            )}
          </SettingsSection>
          <SettingsSection
            title={t('notificationgroups.title')}
            description={t('notificationgroups.description')}
          >
            <DetailPanel spacing={3} pt={0}>
              <NotificationViewTable
                namesHeader={t('notificationgroups.namesHeader')}
                rulesHeader={t('notificationgroups.rulesHeader')}
                notAssignedText={t('notificationgroups.notAssigned')}
                filterGroup={g => g.notificationRules
                  .some(s => s.peopleGroupIds.includes(id))}
                filterRule={r => r.peopleGroupIds.includes(id)}
              />
            </DetailPanel>
          </SettingsSection>
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default EditPeopleGroupPage;
