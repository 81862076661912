import React from 'react';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { useGetPeople, useMutateDeleteContact } from 'apis/rest/people/hooks';
import { displaySnackbar } from 'actions/app';
import { useDispatch } from 'react-redux';
import useLastDefined from 'hooks/useLastDefined';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

export enum DeletePersonContactDialogStatus {
  Cancelled = 'cancelled',
  Removed = 'removed',
}

interface DeletePersonContactDialogProps {
  open: boolean
  onClose: (status: DeletePersonContactDialogStatus, personId: number, contactId: number) => void
  personId?: number
  contactId?: number
}

const DeletePersonContactDialog = ({ open, onClose, personId, contactId }: DeletePersonContactDialogProps): JSX.Element | null => {
  const t = useTranslations('dialogs.people.deletePersonContact');
  const dispatch = useDispatch();
  const { query: peopleQuery } = useGetPeople();
  const mutation = useMutateDeleteContact();

  const person = peopleQuery.data?.find(p => p.id === personId);
  const foundContact = person?.contacts.find(c => c.id === contactId);
  const [contact, contacts] = useLastDefined<[Contact, Contact[]]>(
    foundContact ? [foundContact, person!.contacts] : undefined,
    [personId, contactId],
  ) ?? [];

  const handleClose = (status: DeletePersonContactDialogStatus): void => {
    if (personId !== undefined && contactId !== undefined) onClose(status, personId, contactId);
  };

  const handleCancel = (): void => {
    handleClose(DeletePersonContactDialogStatus.Cancelled);
  };

  const handleRemove = (): void => {
    if (!contact) return;
    const { contactValue, contactType } = contact;

    mutation.mutate(contact, {
      onSuccess: () => {
        displaySnackbar({
          id: `personContactDeleted.${personId}.${contactId}`,
          text: t('snackbar.deletedSuccessfully', { value: contactValue, type: contactType }),
          type: 'success',
        })(dispatch);
        handleClose(DeletePersonContactDialogStatus.Removed);
      },
    });
  };

  const onExited = (): void => {
    mutation.reset();
  };

  const canConfirm = person && contact && !mutation.isLoading;

  if (!contact) return null;

  const type = contact.contactType;
  const value = contact.contactValue;

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!mutation.isLoading) handleCancel();
      }}
      aria-labelledby={type ? t('ariaLabel', { type }) : undefined}
      fullWidth
      maxWidth="sm"
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>{t('title', { type })}</TPDialogTitle>
      <DialogContent sx={{ p: 3, pb: 0 }}>
        <Stack spacing={3} my={3}>
          <Typography>
            {t.rich('message', { value, strong: chunks => <strong>{chunks}</strong> })}
          </Typography>
          {contacts?.length === 1 && <Alert severity="warning">{t('warning', { type, person: person?.name })}</Alert>}
          {mutation.isError && <Alert severity="error">{t('errorDeleting', { type })}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey' }}>
        <Stack spacing={3} flex={1} direction="row" justifyContent="flex-end" height="4em">
          <Button
            variant="outlined"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={mutation.isLoading}
            onClick={handleCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            size="large"
            color="error"
            sx={{ minWidth: '10rem' }}
            disabled={!canConfirm}
            onClick={handleRemove}
          >
            {mutation.isLoading ? <CircularProgress size="1.5rem" color="error" /> : t('confirm')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePersonContactDialog;
