import { useContext } from 'react';
import { FeaturesContext } from 'contexts/features/featuresContextValue';

const useFeatureTrial = (featureKey?: string) => {
  const { isLoading, data } = useContext(FeaturesContext);

  if (isLoading) return undefined;

  if (!data) return false;

  return data.find(value => value.featureKey === featureKey) ?? false;
};

export default useFeatureTrial;
