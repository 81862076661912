import React, { ReactNode, useMemo, useState } from 'react';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { HttpResponseError } from 'helpers/api';
import useSnackbar from 'hooks/useSnackbar';
import { useAssetLabel } from 'components/shared/assetLabel';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

export enum AssignAssetsDialogStatus {
  Cancelled = 'cancelled',
  Saved = 'saved',
}

export interface AssignAssetsDialogComponents {
  PeopleTable: (props: { group: ContactGroup, size: 'small' | 'medium' }) => JSX.Element
}

interface AssignAssetsDialogProps {
  open: boolean
  onClose: (status: AssignAssetsDialogStatus, assetIds: number[], groupId?: number) => void
  assetIds: number[]
  groupsQuery: UseQueryResult<ContactGroup[], unknown>
  mutation: UseMutationResult<void, HttpResponseError, Pick<ContactGroup, 'id' | 'deviceAndAssetIds' | 'deviceVersion'>, {previousGroups?: ContactGroup[] | undefined}>
  components: AssignAssetsDialogComponents
  title: ReactNode
  ariaLabel: string
}

export const AssignAssetsDialog = ({
  open,
  onClose,
  assetIds,
  groupsQuery,
  mutation,
  title,
  ariaLabel,
  components: { PeopleTable },
}: AssignAssetsDialogProps): JSX.Element => {
  const t = useTranslations('dialogs.contactGroups.assignAssets');
  const snackbar = useSnackbar();
  const assetsQuery = useGetAssetsList().query;
  const assetLabel = useAssetLabel();

  const [groupId, setGroupId] = useState<number>();
  const selectedGroup = groupsQuery.data?.find(g => g.id === groupId);

  const handleClose = (status: AssignAssetsDialogStatus) => onClose(status, assetIds, groupId);
  const handleCancel = () => handleClose(AssignAssetsDialogStatus.Cancelled);

  const assetsById = useMemo(() => assetsQuery.data?.reduce<Record<number, AssetBasic>>((acc, asset) => {
    acc[asset.id] = asset;
    return acc;
  }, {}) ?? {}, [assetsQuery.data]);

  const handleSave = (): void => {
    if (!selectedGroup) return;

    const existingDeviceIds = selectedGroup.deviceAndAssetIds.map(x => x.deviceId);

    const deviceAndAssetIds = assetIds.reduce<({ deviceId: number, assetId: number })[]>((acc, assetId) => {
      const asset = assetsById[assetId];
      if (asset?.deviceId && !existingDeviceIds.includes(asset?.deviceId)) {
        acc.push({ assetId, deviceId: asset.deviceId });
      }
      return acc;
    }, [...selectedGroup.deviceAndAssetIds]);

    const assetNames = assetIds.map(id => assetLabel(assetsById[id])).filter((s): s is string => s !== undefined);

    mutation.mutate(
      { ...selectedGroup, deviceAndAssetIds },
      {
        onSuccess: () => {
          snackbar.display({
            id: `groupAssetsAssigned.${selectedGroup.id}`,
            text: t('snackbar.success', {
              group: selectedGroup.name,
              count: assetNames.length,
              asset: assetNames[0],
            }),
            type: 'success',
          });
          handleClose(AssignAssetsDialogStatus.Saved);
        },
      },
    );
  };

  const onExited = (): void => {
    setGroupId(undefined);
    mutation.reset();
  };

  const canSave = groupId !== undefined && !mutation.isLoading;
  const groupHasPeople = selectedGroup && selectedGroup.peopleWithOrder.length > 0;
  const groupHasNoPeople = selectedGroup && !selectedGroup.peopleWithOrder.length;

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!mutation.isLoading) handleCancel();
      }}
      aria-label={ariaLabel}
      fullWidth
      maxWidth="md"
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent sx={{ p: 3, pb: 0 }}>
        <Stack spacing={3} my={3}>
          <Box>
            {assetsQuery.isLoading && <Typography>{t('loadingAssets', { count: assetIds.length })}</Typography>}
            {!assetsQuery.isLoading && (
              <>
                <Typography variant="h5">{t('selectedAssets', { count: assetIds.length })}:</Typography>
                <Box mt={1} maxHeight="8.5rem" sx={{ overflowY: 'scroll' }}>
                  <Grid container spacing={1} minHeight={38} flexWrap="wrap">
                    {assetIds.map(id => {
                      const asset = assetsById[id];
                      if (!asset) return null;
                      return (
                        <Grid key={id} item>
                          <Chip label={assetLabel(asset)} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </>
            )}
          </Box>
          <TextField
            select
            label={t('contactGroupLabel')}
            value={groupId ?? ''}
            onChange={event => setGroupId(parseInt(event.target.value, 10))}
            fullWidth
          >
            {groupsQuery.data?.map(group => (
              <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
            ))}
          </TextField>
          {groupHasPeople && <PeopleTable group={selectedGroup} size="small" />}
          {groupHasNoPeople && <Alert severity="warning">{t('noPeopleInContactGroup')}</Alert>}
          {mutation.isError && <Alert severity="error">{t('error')}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey' }}>
        <Stack my={1} spacing={3} direction="row" height="4em">
          <Button
            variant="outlined"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={mutation.isLoading}
            onClick={handleCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ minWidth: '10rem' }}
            color="primary"
            disabled={!canSave}
            onClick={handleSave}
          >
            {t(mutation.isLoading ? 'saving' : 'save')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
