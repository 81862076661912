import React, { ReactElement, useRef, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { uniqueId } from 'lodash';
import { useTranslations } from 'use-intl';

interface DownloadMenuProps {
  children: (close: () => void) => ReactElement<typeof MenuItem>[]
  disabled?: boolean
}

const DownloadMenu = ({ children, disabled = false }: DownloadMenuProps) => {
  const t = useTranslations('components.Download');
  const buttonId = useRef(uniqueId('download-button')).current;
  const menuId = useRef(uniqueId('menu-button')).current;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={menuOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleClick}
        sx={{ marginLeft: 'auto' }}
        size="large"
        variant="outlined"
        disabled={disabled}
        startIcon={<GetApp />}
      >
        {t('label')}
      </Button>
      <Menu
        id={menuId}
        aria-labelledby={buttonId}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 1 }}
      >
        {children(handleClose)}
      </Menu>
    </>
  );
};

export default DownloadMenu;
