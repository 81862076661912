import actionCreators from 'actions';
import { connect } from 'react-redux';

// A "Smart" component is merely a regular component connect()ed to Redux, which automatically
// provides the requested state/action creators as props.
// This helper HOC aims to make this syntactically tidier inside components using this functionality.
//
// Example Usage:
//
//   {... create MyComponent etc ...}
//
//   export default Smart(
//     MyComponent,
//     state => ({
//       myThingFromRedux: state.things.get('mine')
//     }),
//     ac => ({
//       myActionIWantToTrigger: ac.triggerable.myAction
//     })
//   );
//
// Note: both mappers are required; this avoids ambiguity.
/**
 * @deprecated Prefer Redux hooks because they are properly typed and avoid wrapping components
 */
const Smart = (Component, stateMapper, actionMapper) => {
  const result = connect(stateMapper, actionMapper(actionCreators))(Component);
  Component.whyDidYouRender = true;
  return result;
};

export default Smart;
