import React from 'react';
import { useTranslations } from 'use-intl';
import { useStaff } from 'hooks/session/useStaff';
import NavButton from './NavigationButton.view';
import { NavigationContainer } from './Navigation.styles';

interface NavigationProps {
  hasUnreadMessages: boolean
}

const NavigationView = ({ hasUnreadMessages }: NavigationProps) => {
  const t = useTranslations('navigation');

  const [open, setOpen] = React.useState(false);
  const isStaff = useStaff();

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <NavigationContainer
      onMouseOver={handleDrawerOpen}
      onMouseOut={handleDrawerClose}
      onFocus={handleDrawerOpen}
      onBlur={handleDrawerClose}
      open={open}
      component="nav"
      aria-label={t('menu')}
    >
      <NavButton iconName="Map" text={t('map')} link="/" />
      <NavButton iconName="Messaging" text={t('messaging')} link="/messaging" badge={hasUnreadMessages} />
      <NavButton iconName="Sharing" text={t('sharing')} link="/sharing" />
      <NavButton iconName="Friends" text={t('friends')} link="/friends" />
      <NavButton iconName="Reporting" text={t('reporting')} link="/insights" />
      <NavButton iconName="Manage" text={t('manage')} link="/manage" />
      <NavButton iconName="Settings" text={t('settings')} link="/settings" />
      {isStaff && (
        <NavButton iconName="StaffPage" text={t('staffPage')} link="/staff" />
      )}
    </NavigationContainer>
  );
};

export default NavigationView;
