import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import { DeviceSearchResult } from 'apis/rest/staff';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { createDefaultConfig, isConfigEmpty, isConfigValid, Rock7ConfigBlock } from 'apis/rest/rock7config/types';
import { useRock7ConfigSpec, useUpdateRock7Config } from 'apis/rest/rock7config/hooks';
import { Rock7ConfigForm } from './Rock7ConfigForm';

interface Rock7ConfigDialogProps {
  open: boolean;
  onClose: () => void;
  device: DeviceSearchResult;
}

export const Rock7ConfigDialog = ({
  open,
  onClose,
  device,
}: Rock7ConfigDialogProps) => {
  const snackbar = useSnackbar();

  const [selectedType, setSelectedType] = useState<string>();
  const [config, setConfig] = useState<Rock7ConfigBlock>();
  const [error, setError] = useState<string | undefined>();

  const spec = useRock7ConfigSpec({ enabled: open }).data;
  const mutation = useUpdateRock7Config();

  const onEnter = () => {
    if (spec && selectedType && !config) {
      setConfig(createDefaultConfig(spec[selectedType]));
      setError(undefined);
    }
  };
  useEffect(() => {
    if (spec && selectedType && !config) {
      setConfig(createDefaultConfig(spec[selectedType]));
      setError(undefined);
    }
  }, [config, selectedType, spec]);

  const onExited = () => {
    setSelectedType(undefined);
    setConfig(undefined);
    setError(undefined);
  };

  const onTypeChange = (newType: string) => {
    if (!spec || !Object.keys(spec)
      .includes(newType)) {
      return;
    }

    setSelectedType(newType);
    setConfig(createDefaultConfig(spec[newType]));
    setError(undefined);
  };

  const onSend = () => {
    if (!spec || !selectedType) {
      return;
    }

    if (!config || isConfigEmpty(spec[selectedType], config)) {
      setError('No changes have been made.');
      return;
    }

    if (!isConfigValid(spec[selectedType], config)) {
      setError('Configuration is invalid.');
      return;
    }

    const configToSend = Object.entries(config)
      .reduce<Rock7ConfigBlock>((acc, [key, val]) => {
        if (val !== 'unset') {
          acc[key] = val;
        }
        return acc;
      }, {});

    if (!configToSend) {
      setError('All of the fields are unset.');
      return;
    }

    setError(undefined);

    mutation.mutate({
      deviceId: device.id,
      config: {
        [selectedType]: configToSend,
      },
    }, {
      onSuccess: () => {
        snackbar.display({
          id: `updatedRock7Config.${device.id}`,
          text: `${device.assetName}: Request to update configuration sent`,
          type: 'success',
        });
        onClose();
      },
      onError: e => {
        setError(`API responded with a ${e.response.status} status code`);
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onEnter,
        onExited,
      }}
    >
      <TPDialogTitle id="form-dialog-title">Update Configuration: {device.assetName}</TPDialogTitle>
      <DialogContent>
        {!spec ? (
          <Stack alignItems="center" p={3} pt={6}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Stack spacing={3} py={3} sx={theme => (selectedType ? ({
              borderBottom: 1,
              borderColor: theme.palette.common.midGrey,
            }) : {})}>
              <TextField
                select
                value={selectedType ?? ''}
                label="Configuration Type"
                onChange={e => onTypeChange(e.target.value)}
              >
                {Object.keys(spec)
                  .map(key => (
                    <MenuItem key={key} value={key}>{key}</MenuItem>
                  ))}
              </TextField>
            </Stack>
            {selectedType && config && (
              <Rock7ConfigForm
                config={config}
                options={spec[selectedType]}
                onChange={(k, v) => setConfig({
                  ...config,
                  [k]: v,
                })}
              />
            )}
          </>
        )}
      </DialogContent>
      <TPDialogActions>
        {error && <Alert severity="error" sx={{ flexGrow: '1' }}>{error}</Alert>}
        <Button
          autoFocus
          onClick={onClose}
          disabled={mutation.isLoading}
          sx={{
            px: 6,
            py: 3,
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSend}
          color="primary"
          disabled={!selectedType || !spec || mutation.isLoading}
          sx={{
            px: 6,
            py: 3,
          }}
        >
          Send
        </Button>
      </TPDialogActions>
    </Dialog>
  );
};
