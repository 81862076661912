export const setBaseLayer = (mapId, baseLayer) => ({
  type: 'SET_BASE_LAYER',
  payload: { mapId, baseLayer }
});

export const jumpToHomeBase = (mapId, homeBase) => ({
  type: 'JUMP_TO_HOME_BASE',
  payload: {
    mapId,
    homeBase
  }
});

export const setFollow = (mapId, isFollowed) => ({
  type: 'SET_ASSET_FOLLOW',
  payload: {
    mapId,
    isFollowed
  }
});

export const closeMap = mapId => dispatch => {
  dispatch({
    type: 'CLOSE_MAP',
    payload: mapId
  });
};

export const updateConfig = (mapId, config) => ({
  type: 'UPDATE_MAP_CONFIG',
  payload: {
    mapId,
    config
  }
});

export const updateMapLayout = (layout, defaultMapSettings) => ({
  type: 'UPDATE_MAP_LAYOUT',
  payload: {
    layout,
    defaultMapSettings
  }
});

export const selectMap = mapId => dispatch => {
  dispatch({
    type: 'SELECT_MAP',
    payload: mapId
  });
};

export const setTrailsOption = (mapId, trailsOption) => ({
  type: 'SET_TRAILS_OPTION',
  payload: {
    mapId,
    trailsOption
  }
});

export const assignItemToMap = (mapId, item) => ({
  type: 'ASSIGN_ITEM_TO_MAP',
  payload: {
    mapId,
    item
  }
});

export const unassignItemFromMap = () => ({
  type: 'UNASSIGN_ITEM_FROM_MAP'
});

export const assignMarkerToAsset = (mapId, assetId, position) => ({
  type: 'ASSIGN_MARKER_TO_ASSET',
  payload: {
    mapId,
    assetId,
    lat: position.lat,
    lng: position.lng
  }
});

export const hideAssetsGroup = groupName => ({
  type: 'HIDE_ASSETS_GROUP',
  payload: groupName
});

export const removeFromHiddenAssetGroups = groupName => ({
  type: 'REMOVE_FROM_HIDDEN_ASSET_GROUPS',
  payload: groupName
});

export const hideAssetsOnMap = assets => ({
  type: 'HIDE_ASSETS_ON_MAP',
  payload: assets
});

export const showAssetsOnMap = assets => ({
  type: 'SHOW_ASSETS_ON_MAP',
  payload: assets
});

export const showAllAssetsOnMap = () => ({
  type: 'SHOW_ALL_ASSETS_ON_MAP'
});

export const updateHiddenInactiveAssets = assetIds => ({
  type: 'UPDATE_HIDDEN_INACTIVE_ASSETS',
  payload: assetIds
});

export const setTrailHighlight = highlightTrail => ({
  type: 'SET_TRAIL_HIGHLIGHT',
  payload: {
    highlightTrail
  }
});
