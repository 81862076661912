import React from 'react';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle, Box, Button, Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Page from 'components/pages/page';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { fetchUsercodes } from 'apis/trackstar/serenity';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import LinkAbove from 'components/shared/linkAbove';
import SettingsSection from 'components/shared/settingsSection';
import LoadingPage from 'components/pages/loading/loading-view';
import DetailPanel from 'components/shared/DetailPanel';
import Organisation from './organisation';

const OrganisationSettings = () => {
  const user = useSelector(state => state.session.user);
  const organisationId = useOrganisationId();
  const t = useTranslations('pages.organisationSettings');

  const organisations = useQuery(['organisations'], fetchUsercodes);
  const organisation = organisations.isSuccess && organisations.data.find(o => o.id === organisationId);

  if (organisations.isLoading || !organisation) return (<LoadingPage />);

  return (
    <Page>
      <SettingsMenuPageWrapper>
        <Container maxWidth="md">
          <LinkAbove />
          <Typography variant="h1" component="h1" gutterBottom>{t('title', { orgName: organisation.name })}</Typography>
          <Organisation organisation={organisation} user={user} />
          <SettingsSection title={t('emergencyContacts.title')}>
            <DetailPanel p={0} pt={0} pb={0}>
              <Alert severity="warning" icon={false} variant="outlined" sx={{ py: 2 }}>
                <AlertTitle>{t('emergencyContacts.movedTitle')}</AlertTitle>
                {t('emergencyContacts.movedMessage')}
                <Box mt={2}>
                  <Button
                    size="large"
                    variant="contained"
                    component={Link}
                    to="/manage"
                    sx={{ height: '4rem' }}
                  >
                    {t('emergencyContacts.movedLink')}
                  </Button>
                </Box>
              </Alert>
            </DetailPanel>
          </SettingsSection>
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default OrganisationSettings;
