/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DropAdditive, extractAdditive } from 'helpers/drops';
import { calculatePolylines, CatmulRomSpline, Coord, Polyline } from './spline';
import { KdTree } from './kdTree';

export interface Timespan {
  id: number
  start: number
  end: number
  complete: boolean
}

export interface Drop {
  additive?: DropAdditive
}

export interface Segment<T> {
  start_report: number
  start_time: number
  stop_report?: number
  stop_time?: number
  splines?: Polyline[]
  meta: T
}

export type SegmentEvent = 'DROP' | 'CONTAINMENT_LINE';

const SEGMENT_EVENTS = [
  {
    event: 'DROP' as SegmentEvent,
    metaFn: (r: Report) => ({ additive: extractAdditive(r) }),
  },
  {
    event: 'CONTAINMENT_LINE' as SegmentEvent,
    metaFn: () => ({}),
  },
] as const;

// Simple distance doesn't implement haversine, just euclidean in lat/lng space
const distance = (a: Report, b: Report): number => ((a.latitude - b.latitude) ** 2) + ((a.longitude - b.longitude) ** 2);

export const toCoord = (r: Pick<Report, 'longitude' | 'latitude' | 'altitude'>): Coord => [r.longitude, r.latitude, r.altitude] as Coord;

export const wrapLongitude = (longitude: number): number => {
  if (longitude < -180) {
    return longitude + 360;
  }

  if (longitude > 180) {
    return longitude - 360;
  }
  return longitude;
};

const isValidCoord = (report: Report): boolean => !(report.latitude < -90 || report.latitude > 90 || report.longitude < -180 || report.longitude > 180);

// If `event` is undefined, then check if related to any event.
const isEventRelated = (report: Report, event?: SegmentEvent): boolean => {
  if (!event) {
    return SEGMENT_EVENTS.some(s => report.events.some(e => e.includes(s.event)));
  }
  return report.events.some(e => e.includes(event));
};

const getRelatedEvent = (report: Pick<Report, 'events'>): SegmentEvent | undefined =>
  SEGMENT_EVENTS.find(s => report.events.some(e => e.includes(s.event)))?.event;

// flattens out bits that should be straight - i.e. drops, containment lines
export const calculatePolylinesFromReports = (reports: Pick<Report, 'events' | 'latitude' | 'longitude' | 'altitude'>[]): Polyline[] => {
  let withinEvent: SegmentEvent | undefined;

  return reports
    .reduce<Polyline[]>((polylines, r, currentIndex, allReports) => {
      if (polylines.length === 0) {
        return [[toCoord(r)]];
      }
      polylines.at(-1)?.push(toCoord(r));
      // start new polyline when segment change encountered
      const relatedEvent = getRelatedEvent(r);
      if (relatedEvent) {
        if (withinEvent === relatedEvent) { // we are leaving the segment - add previous report too
          withinEvent = undefined;
          return [...polylines, [toCoord(allReports[currentIndex - 1]), toCoord(r)]];
        }

        withinEvent = relatedEvent;
        // we are entering the segment - add the next report to the previous polyline before creating the next one
        if (currentIndex < allReports.length - 1) {
          polylines.at(-1)?.push(toCoord(allReports[currentIndex + 1]));
          return [...polylines, [toCoord(r)]];
        }
      }
      return polylines;
    }, [])
    .flatMap(calculatePolylines);
};

/*
* This class allows efficient storage and retrieval of reports.
* It has the main external methods:
*   clearReports (for when e.g. changing historic mode)
*   insertReport (for when live reports are coming in, recalculates splines, etc.)
*   insertReports (for the result of polling, insert multiple reports and do recalculations of e.g. kdtrees afterwards)
*   setAssetReports (for initial loads of data at a given time)
*/
export class ReportsRepository {
  reports: Map<number, Report>; // reportId -> report
  private assetsReports: Map<number, number[]>; // assetId -> reportId[]
  private assetsPositions: Map<number, number[]>; // assetId -> reportId[] with valid positions, ordered [newest, oldest]
  private assetSplines: Map<number, CatmulRomSpline[]>;
  private assetLegs: Map<number, Leg[]>;
  private assetSegments: Map<SegmentEvent, Map<number, Segment<object>[]>>;
  private kdTrees: Map<string, KdTree>;

  constructor() {
    this.reports = new Map<number, Report>();
    this.assetsReports = new Map<number, number[]>();
    this.assetsPositions = new Map<number, number[]>();
    this.assetSplines = new Map<number, CatmulRomSpline[]>();
    this.assetSegments = new Map<SegmentEvent, Map<number, Segment<object>[]>>();
    this.assetLegs = new Map<number, Leg[]>();
    this.kdTrees = new Map<string, KdTree>();
  }

  clearReports(): void {
    this.reports.clear();
    this.assetsReports.clear();
    this.assetsPositions.clear();
    this.assetSplines.clear();
    this.assetSegments.clear();
    this.assetLegs.clear();
    this.kdTrees.clear();
  }

  private static filterToTimespan(r: Report, timespan?: Timespan): boolean {
    return !timespan
      || (
        (r.received >= timespan.start && (!timespan.complete || r.received <= timespan.end))
      );
  }

  private addToBeginning(assetId: number, report: Report): void {
    if (report.isValid) {
      if (this.assetsPositions.has(assetId)) {
        this.assetsPositions.set(assetId, [report.id].concat(...this.assetsPositions.get(assetId)!));
      } else {
        this.assetsPositions.set(assetId, [report.id]);
      }
    }
    if (this.assetsReports.has(assetId)) {
      this.assetsReports.set(assetId, [report.id].concat(...this.assetsReports.get(assetId)!));
    } else {
      this.assetsReports.set(assetId, [report.id]);
    }
  }

  private sortReport(a: number, b: number): number {
    const aR = this.reports.get(a)?.received || 0;
    const bR = this.reports.get(b)?.received || 0;
    return bR - aR; // if b is newer (bigger received), this will be positive and b will be before a in the array.
  }

  private cullOldReports(assetId: number, oldestTime: number): void {
    const oldestReportId = this.assetsReports.get(assetId)?.at(-1);
    if (!oldestReportId) { return; }
    const oldestReportReceived = this.reports.get(oldestReportId)?.received;
    if (!oldestReportReceived) { return; }

    if (oldestReportReceived < oldestTime) {
      this.assetsReports.set(assetId, this.assetsReports.get(assetId)?.filter(id => (this.reports.get(id)?.received || 0) > oldestTime) || []);
      this.assetsPositions.set(assetId, this.assetsReports.get(assetId)?.filter(id => this.reports.get(id)?.isValid) || []);
    }
  }

  private regenerateSpline(assetId: number): void {
    const reports = this.assetsPositions.get(assetId)!
      .map(id => this.reports.get(id)!)
      .filter(r => r.isValid);
    const polylines = calculatePolylinesFromReports(reports);
    this.assetSplines.set(assetId, polylines.map(pl => new CatmulRomSpline(pl || [], 0.5, 15)));
  }

  private regenerateSegments(assetId: number, event: SegmentEvent, metaFn?: (report: Report) => object) {
    const positionsBackwards = this.assetsPositions.get(assetId)!
      .map(id => this.reports.get(id)!);

    const positions = [...positionsBackwards].reverse();

    const segments = positions
      .filter(r => isEventRelated(r, event))
      .reduce<Segment<object>[]>((acc, r) => {
        const previousLine = acc.at(-1);
        if (r.events.some(e => e.includes('START'))) {
          if ((previousLine && previousLine.stop_time) || !previousLine) {
            acc.push({
              start_report: r.id,
              start_time: r.received,
              meta: metaFn ? metaFn(r) : {},
            });
          }
          // else do nothing - continue until next stop
        } else if (r.events.some(e => e.includes('STOP'))) {
          if (previousLine && previousLine.start_time && !previousLine.stop_time) {
            previousLine.stop_time = r.received;
            previousLine.stop_report = r.id;
            previousLine.meta = metaFn ? metaFn(r) : {};
          }
          // else do nothing - continue until next start
        }
        return acc;
      }, []);

    const withSplines = segments
      .map(segment => ({
        ...segment,
        splines: ReportsRepository.getPolylineForSegment(positions, segment),
      }));

    let map = this.assetSegments.get(event);
    if (!map) {
      map = new Map<number, Segment<object>[]>();
    }
    map.set(assetId, withSplines);
    this.assetSegments.set(event, map);
  }

  private static getPolylineForSegment(positions: Report[], segment: Segment<object>): Polyline[] {
    const inLinePositions = positions
      .filter(p => p.received >= segment.start_time && (!segment.stop_time || p.received <= segment.stop_time))
      .reverse();

    return calculatePolylines(inLinePositions.map(toCoord)).map(pl => new CatmulRomSpline(pl, 0.5, 15).interpolated);
  }

  getLatestPosition(assetId: number, timespan?: Timespan): Report | undefined {
    if (timespan) {
      return this.assetsPositions.get(assetId)
        ?.map(r => this.reports.get(r))
        .filter(r => ReportsRepository.filterToTimespan(r!, timespan)).at(0);
    }
    const reportId = this.assetsPositions.get(assetId)?.at(0);
    if (!reportId) return undefined;
    return this.reports.get(reportId);
  }

  getLatestReport(assetId: number): Report | undefined {
    const reportId = this.assetsReports.get(assetId)?.at(0);
    if (!reportId) return undefined;
    return this.reports.get(reportId);
  }

  insertReport(report: Report, cullBeforeTime?: number): void {
    if (this.reports.has(report.id)) {
      return;
    }
    this.reports.set(report.id, report);

    if (report.isValid && isValidCoord(report)) {
      if (!this.assetsPositions.has(report.assetId)) this.assetsPositions.set(report.assetId, [report.id]);
    }

    if (!this.assetsReports.has(report.assetId)) {
      this.assetsReports.set(report.assetId, [report.id]);
      // @ts-ignore (undefined < number always returns false)
    } else if (this.getLatestReport(report.assetId)?.received < report.received) {
      this.addToBeginning(report.assetId, report);
    } else {
      this.assetsReports.set(report.assetId, this.assetsReports
        .get(report.assetId)
        ?.concat(report.id)
        .sort((a, b) => this.sortReport(a, b)) || []);

      if (report.isValid && isValidCoord(report)) {
        this.assetsPositions.set(report.assetId, this.assetsPositions
          .get(report.assetId)
          ?.concat(report.id)
          .sort((a, b) => this.sortReport(a, b)) || []);
      }
    }
    if (report.isValid && isValidCoord(report)) {
      if (cullBeforeTime !== undefined) {
        this.cullOldReports(report.assetId, cullBeforeTime);
      }

      // recalculate spline because manually inserting it is a pain
      this.regenerateSpline(report.assetId);

      SEGMENT_EVENTS.forEach(s => {
        if (isEventRelated(report, s.event) || this.assetHasCurrentEvent(report.assetId, s.event)) {
          this.regenerateSegments(report.assetId, s.event, s.metaFn);
        }
      });
    }
  }

  private assetHasCurrentEvent(assetId: number, event: SegmentEvent) {
    return !!this.assetSegments.get(event)?.get(assetId)?.filter(s => !s.stop_report).length;
  }

  insertReports(reports: Report[], cullBeforeTime?: number): void {
    reports.forEach(r => this.insertReport(r, cullBeforeTime));

    // only remove kdtrees relating to inserted reports
    reports
      .map(r => r.assetId)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map(a => this.clearKdTreesForAsset(a));
  }

  clearKdTreesForAsset(assetId: number): void {
    [...this.kdTrees.keys()].forEach(as => {
      if (as
        .split(',')
        .map(a => parseInt(a, 10))
        .includes(assetId)) {
        this.kdTrees.delete(as);
      }
    });
  }

  setAssetReports(assetId: number, newReports: Report[]): void {
    const reportsForAsset = newReports.filter(r => r.assetId === assetId);
    const valid = newReports.filter(r => r.isValid && isValidCoord(r));
    reportsForAsset.forEach(r => this.reports.set(r.id, r));
    const polylines = calculatePolylinesFromReports(valid);
    const splines = polylines.map(pl => new CatmulRomSpline(pl, 0.5, 15));
    this.assetSplines.set(assetId, splines);

    this.assetsReports.set(assetId, reportsForAsset.map(r => r.id));
    this.assetsPositions.set(assetId, valid.map(r => r.id));

    SEGMENT_EVENTS.forEach(s => {
      if (reportsForAsset.some(r => isEventRelated(r, s.event))) {
        this.regenerateSegments(assetId, s.event, s.metaFn);
      }
    });

    this.clearKdTreesForAsset(assetId);
  }

  getSortedReportsForAsset(assetId: number, timespan?: Timespan): Report[] {
    const reports = this.assetsReports.get(assetId)?.flatMap(r => this.reports.get(r)) || [];
    // @ts-ignore
    return timespan ? reports.filter(r => ReportsRepository.filterToTimespan(r, timespan)) : reports;
  }

  getSortedPositionsForAsset(assetId: number, timespan?: Timespan): Report[] {
    const positions = this.assetsPositions.get(assetId)?.flatMap(r => this.reports.get(r)) || [];
    // @ts-ignore
    return timespan ? positions.filter(r => ReportsRepository.filterToTimespan(r, timespan)) : positions;
  }

  getClosestReport(latitude: number, longitude: number, assetIds: number[], timespan?: Timespan): Report | undefined {
    let kdtree;
    const sortedAssetIds = assetIds.sort();
    // Arrays don't strictly compare as equal, but strings do ([1] !== [1], but "1" === o"1")
    const assetIdString = (timespan?.id ? `${timespan?.id},` : '') + sortedAssetIds.join(',');
    kdtree = this.kdTrees.get(assetIdString);
    if (!kdtree) {
      kdtree = this.getKdTreeForAssets(sortedAssetIds, timespan);
      this.kdTrees.set(assetIdString, kdtree);
    }
    return this.getClosestReportForKdTree(latitude, wrapLongitude(longitude), kdtree);
  }

  getClosestReportForKdTree(latitude: number, longitude: number, kdtree: KdTree): Report | undefined {
    const reportId = kdtree.nearest({ latitude, longitude }, 1)?.at(0)?.at(0).id;

    if (!reportId) return undefined;
    return this.reports.get(reportId);
  }

  getKdTreeForAssets(assetIds: number[], timespan?: Timespan): KdTree {
    if (timespan) { return this.getKdTreeForAssetsForTimespan(assetIds, timespan); }
    return ReportsRepository.generateKdTree(
      assetIds.map(a => this.assetsPositions
        ?.get(a)
        ?.map(r => this.reports.get(r)!)
        .map(r => ({
          id: r.id,
          latitude: r.latitude,
          longitude: r.longitude
        })) || []).flat()
    );
  }

  getKdTreeForAssetsForTimespan(assetIds: number[], timespan: Timespan): KdTree {
    return ReportsRepository.generateKdTree(
      assetIds.map(a => this.assetsPositions
        ?.get(a)
        ?.map(r => this.reports.get(r)!)
        .filter(r => ReportsRepository.filterToTimespan(r, timespan))
        .map(r => ({
          id: r.id,
          latitude: r.latitude,
          longitude: r.longitude
        })) || []).flat()
    );
  }
  static generateKdTree(positions: { id: number, longitude: number, latitude: number }[]): KdTree {
    return new KdTree(positions, distance, ['latitude', 'longitude']);
  }

  getReport(reportId: number): Report | undefined {
    return this.reports.get(reportId);
  }

  getSplineForAsset(assetId: number): Polyline[] {
    return this.assetSplines.get(assetId)?.map(s => s.interpolated) || [];
  }

  getSegmentsForAsset(assetId: number, event: SegmentEvent, timespan?: Timespan): Segment<object>[] {
    if (!timespan) {
      return this.assetSegments.get(event)?.get(assetId) ?? [];
    }

    const segments = this.assetSegments.get(event)?.get(assetId);
    if (!segments) {
      return [];
    }

    const positionsBackwards = this.assetsPositions.get(assetId)
      ?.flatMap(id => this.reports.get(id)!)
      .filter(r => ReportsRepository.filterToTimespan(r, timespan));

    if (!positionsBackwards) {
      return [];
    }

    const positions = positionsBackwards.slice(0).reverse();

    return segments.map(segment => ({
      ...segment,
      splines: ReportsRepository.getPolylineForSegment(positions, segment),
    }));
  }

  cullAllReportsBefore = (time: number): void => this.assetsReports
    .forEach((_, assetId) => this.cullOldReports(assetId, time));
}
