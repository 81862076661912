import Smart from 'hocs/smart';
import DistressView from './distressDialog-view';

export default Smart(
  DistressView,
  (state: any) => ({}),
  (ac: any) => ({
    fetchDistressIncident: ac.asset.fetchDistressIncident,
  })
);
