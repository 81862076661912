import Smart from 'hocs/smart';
import AssetResultView from './assetResult-view';

export default Smart(
  AssetResultView,
  state => ({
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // assetState: state.assetState[ownProps.asset?.id],
  }),
  ac => ({
    clearSelection: ac.app.clearSelection,
    unassignItemFromMap: ac.map.unassignItemFromMap,
  })
);
