import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, ButtonGroup, Chip, Menu, MenuItem, Popover, Radio, Stack, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { ExpandMore } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import useOrganisationId from 'hooks/session/useOrganisationId';
import {
  FilterPayload,
  FilterState,
  GroupedData,
  Period,
  SetFilters,
  anyInvalidDevicesInState,
  anyInvalidOrganisationsOrGroupsInState,
} from './helpers';
import SelectParticipants, { Participant, SelectParticipantsProps } from './selectParticipants';
import { SelectAssets } from './selectAssets';

interface FiltersProps {
  state: FilterState
  setFilters: SetFilters
  assetsByDeviceId: Record<number, AssetWithDevice>
  devicesById: Record<number, DeviceBasic>
  data: GroupedData | undefined
  participants: Participant[] | false
}

const Filters = ({ state, setFilters: dispatch, assetsByDeviceId, devicesById, participants, data }: FiltersProps) => {
  const t = useTranslations('pages.sharing.filters');

  const deviceButtonAnchor = useRef<HTMLButtonElement>(null);
  const friendButtonAnchor = useRef<HTMLButtonElement>(null);
  const periodButtonAnchor = useRef<HTMLButtonElement>(null);

  const [pendingFilter, setPendingFilter] = useState<FilterPayload>();

  const applyFilter = (payload: FilterPayload) => {
    setPendingFilter(undefined);
    dispatch({ type: 'SET_FILTERS', payload });
  };

  const ownOrganisationId = useOrganisationId();
  useEffect(() => {
    dispatch({ type: 'RESET' });
  }, [ownOrganisationId, dispatch]);

  // remove any applied filters for organisations, groups and devices that aren't present in the data
  useEffect(() => {
    if (!data) return;

    if (anyInvalidOrganisationsOrGroupsInState(state, data)) {
      const validOrganisationIds = state.organisationIds.filter(id => data.organisationIds.includes(id));
      const validGroupIds = state.groupIds.filter(id => data.groupIds.includes(id));
      dispatch({ type: 'SET_FILTERS', payload: { type: 'organisation', organisationIds: validOrganisationIds, groupIds: validGroupIds } });
    }

    if (anyInvalidDevicesInState(state, data)) {
      const validDeviceIds = state.deviceIds.filter(id => data.deviceIds.includes(id));
      dispatch({ type: 'SET_FILTERS', payload: { type: 'device', deviceIds: validDeviceIds } });
    }
  }, [state, data, dispatch]);

  const setSelectedParticipantIds: SelectParticipantsProps['setSelectedParticipantIds'] = useCallback(value => setPendingFilter({
    type: 'organisation',
    ...(typeof value === 'function' ? value({ organisationIds: pendingFilter?.organisationIds ?? [], groupIds: pendingFilter?.groupIds ?? [] }) : value),
  }), [setPendingFilter, pendingFilter?.organisationIds, pendingFilter?.groupIds]);

  const renderShareCountForParticipant = useCallback((participant: Participant) => {
    const shares = 'groupId' in participant ? data?.sharesByGroupId[participant.groupId] : data?.sharesByOrganisationId[participant.organisationId];
    return (
      <Typography variant="subtitle1">
        {t('nShares', { n: shares?.length ?? 0 })}
      </Typography>
    );
  }, [data?.sharesByGroupId, data?.sharesByOrganisationId, t]);

  const selectedRecipientCount = state.organisationIds.length + state.groupIds.length;

  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems="stretch" flexWrap="wrap" useFlexGap>
        <ButtonGroup>
          <Button
            ref={deviceButtonAnchor}
            variant="outlined"
            onClick={() => setPendingFilter(value => (value?.type === 'device' ? undefined : { type: 'device', deviceIds: state.deviceIds }))}
            sx={{ minHeight: '4rem' }}
            endIcon={<ExpandMore />}
            aria-label={t('labels.filterAssets')}
          >
            <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap" useFlexGap>
              {!state.deviceIds.length && t('allDevices')}
              {!!state.deviceIds.length && t.rich('nDevices', {
                n: state.deviceIds.length,
                c: chunks => <Chip variant="filled" color="primary" label={chunks} />,
              })}
            </Stack>
          </Button>

          {participants && (
            <Button
              ref={friendButtonAnchor}
              variant="outlined"
              onClick={() => setPendingFilter(value => (value?.type === 'organisation' ? undefined : { type: 'organisation', organisationIds: state.organisationIds, groupIds: state.groupIds }))}
              sx={{ minHeight: '4rem' }}
              endIcon={<ExpandMore />}
              aria-label={t('labels.filterRecipients')}
            >
              <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap" useFlexGap>
                {!selectedRecipientCount && t('allRecipients')}
                {!!selectedRecipientCount && t.rich('nRecipients', {
                  n: state.organisationIds.length + state.groupIds.length,
                  c: chunks => <Chip variant="filled" color="primary" label={chunks} />,
                })}
              </Stack>
            </Button>
          )}

          <Button
            ref={periodButtonAnchor}
            variant="outlined"
            onClick={() => setPendingFilter(value => (value?.type === 'period' ? undefined : { type: 'period', period: state.period }))}
            sx={{ minHeight: '4rem' }}
            endIcon={<ExpandMore />}
            aria-label={t('labels.filterPeriod')}
          >
            <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap" useFlexGap>
              {t.rich('selectedPeriod', {
                period: state.period,
                c: chunks => <Chip variant="filled" color="primary" label={chunks} />,
              })}
            </Stack>
          </Button>
        </ButtonGroup>
      </Stack>

      <Popover
        anchorEl={deviceButtonAnchor.current}
        open={pendingFilter?.type === 'device'}
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          if (isEqual(state.deviceIds, pendingFilter?.deviceIds)) setPendingFilter(undefined);
        }}
      >
        <Stack p={3} spacing={3} width="80ch">
          <SelectAssets
            deviceIds={Object.values(devicesById).filter(d => (data?.sharesByDeviceId[d.id]?.length ?? 0) > 0).map(d => d.id)}
            selectedDeviceIds={pendingFilter?.deviceIds ?? []}
            setSelectedDeviceIds={value => setPendingFilter({
              type: 'device',
              deviceIds: typeof value === 'function' ? value(pendingFilter?.deviceIds ?? []) : value,
            })}
            assetsByDeviceId={assetsByDeviceId}
            devicesById={devicesById}
            renderCount={deviceId => <Typography variant="subtitle1">{t('nShares', { n: data?.sharesByDeviceId[deviceId]?.length ?? 0 })}</Typography>}
          />
          <Stack spacing={3} direction="row" justifyContent="space-between" height="4rem">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              onClick={() => applyFilter({ type: 'device', deviceIds: [] })}
              disabled={!state.deviceIds.length}
            >
              {t('actions.clear')}
            </Button>
            <Stack spacing={3} direction="row">
              <Button
                variant="outlined"
                size="large"
                sx={{ minWidth: '10rem' }}
                onClick={() => setPendingFilter(undefined)}
              >
                {t('actions.cancel')}
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{ minWidth: '10rem' }}
                onClick={() => pendingFilter && applyFilter(pendingFilter)}
                disabled={isEqual(state.deviceIds, pendingFilter?.deviceIds)}
              >
                {t('actions.apply')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Popover>

      {participants && (
        <Popover
          anchorEl={friendButtonAnchor.current}
          open={pendingFilter?.type === 'organisation'}
          sx={{ mt: 1 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            if (isEqual(state.organisationIds, pendingFilter?.organisationIds) && isEqual(state.groupIds, pendingFilter?.groupIds)) setPendingFilter(undefined);
          }}
        >
          <Stack p={3} spacing={3} width="80ch">
            <SelectParticipants
              participants={participants}
              selectedOrganisationIds={pendingFilter?.organisationIds ?? state.organisationIds}
              setSelectedParticipantIds={setSelectedParticipantIds}
              selectedGroupIds={pendingFilter?.groupIds ?? state.groupIds}
              renderCount={renderShareCountForParticipant}
            />
            <Stack spacing={3} direction="row" justifyContent="space-between" height="4rem">
              <Button
                variant="outlined"
                size="large"
                sx={{ minWidth: '10rem' }}
                onClick={() => applyFilter({ type: 'organisation', organisationIds: [], groupIds: [] })}
                disabled={!state.organisationIds.length && !state.groupIds.length}
              >
                {t('actions.clear')}
              </Button>
              <Stack spacing={3} direction="row">
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ minWidth: '10rem' }}
                  onClick={() => setPendingFilter(undefined)}
                >
                  {t('actions.cancel')}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ minWidth: '10rem' }}
                  onClick={() => pendingFilter && applyFilter(pendingFilter)}
                  disabled={isEqual(state.organisationIds, pendingFilter?.organisationIds) && isEqual(state.groupIds, pendingFilter?.groupIds)}
                >
                  {t('actions.apply')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Popover>
      )}

      <Menu
        anchorEl={periodButtonAnchor.current}
        open={pendingFilter?.type === 'period'}
        onClose={() => setPendingFilter(undefined)}
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => applyFilter({ type: 'period', period: Period.All })}>
          <Radio checked={state.period === Period.All} />
          <Box>
            <Typography>{t('periodLabel.all')}</Typography>
            <Typography variant="caption">{t('periodDescription.all')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => applyFilter({ type: 'period', period: Period.Past })}>
          <Radio checked={state.period === Period.Past} />
          <Box>
            <Typography>{t('periodLabel.past')}</Typography>
            <Typography variant="caption">{t('periodDescription.past')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => applyFilter({ type: 'period', period: Period.Present })}>
          <Radio checked={state.period === Period.Present} />
          <Box>
            <Typography>{t('periodLabel.present')}</Typography>
            <Typography variant="caption">{t('periodDescription.present')}</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => applyFilter({ type: 'period', period: Period.Future })}>
          <Radio checked={state.period === Period.Future} />
          <Box>
            <Typography>{t('periodLabel.future')}</Typography>
            <Typography variant="caption">{t('periodDescription.future')}</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Filters;
