import React, { useState } from 'react';
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import clsx from 'clsx';
import TimeSince from 'components/shared/timeSince';
import { labelToDisplayLabel } from 'helpers/events';
import TPFormDialog from 'components/tpForms/formModal';
import Icon from 'components/shared/icons/eventIcons';
import { useSetViewport } from 'contexts/viewport/useViewport';
import useStyles from '../leg-styles';

const SignificantReport = ({
  report,
  highlightedReportId,
  setHighlightedReportId,
  setSelectedReport,
  selectedMapId
}) => {
  const classes = useStyles();
  const [openForm, setOpenForm] = useState();
  const patchViewport = useSetViewport(selectedMapId);

  const ReportIcon = () => {
    if (report?.events[0]) {
      return (
        <ListItemIcon className={clsx(classes.iconWrapper, { [classes.selected]: highlightedReportId === report.id })}>
          {/* Fragment required because ListItemIcon only allows one child */}
          <>
            <Icon type={report.events[0]} />
            {report.form
              && (
              <Box className={classes.formBadge}>
                <Box className={classes.badgeIconWrapper}>
                  <LaunchOutlinedIcon />
                </Box>
              </Box>
              )}
          </>
        </ListItemIcon>
      );
    }
    if (report?.form) {
      return (
        <ListItemIcon className={clsx(classes.iconWrapper, { [classes.selected]: highlightedReportId === report.id })}>
          <Icon type="form" />
        </ListItemIcon>
      );
    }
    return null;
  };

  return (
    <ListItem
      className={classes.listItem}
      onMouseEnter={() => {
        setSelectedReport(selectedMapId, report);
      }}
      onMouseLeave={() => {
        setSelectedReport(selectedMapId, null);
      }}
      onClick={ev => {
        ev.stopPropagation();
        if (report.isValid) patchViewport({ longitude: report.longitude, latitude: report.latitude });
        setSelectedReport(selectedMapId, report);
        if (highlightedReportId !== report.id) {
          setHighlightedReportId(report.id);
        } else {
          setHighlightedReportId(null);
          setSelectedReport(selectedMapId, null);
        }
      }}
    >
      <ReportIcon />
      <ListItemText className={classes.listText}>
        <Box className={classes.row}>
          <Box className={classes.reportTitle}>{report.form?.title ?? labelToDisplayLabel(report.events[0] || 'EVT_STANDARD')}</Box>
          <TimeSince time={report.received * 1000} Component={Box} className={classes.reportSince} tooltip />
        </Box>
        {report.form
          ? (
            <Box className={classes.row}>
              <Typography className={classes.formLink} onClick={() => setOpenForm(report)}>Form</Typography>
            </Box>
          )
          : null}
        {openForm
          ? <TPFormDialog report={openForm} onClose={() => setOpenForm(null)} />
          : null}
      </ListItemText>
    </ListItem>
  );
};

export default SignificantReport;
