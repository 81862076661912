import React, { useState } from 'react';
import Page from 'components/pages/page';
import { useNavigate, useParams } from 'react-router';
import { useTranslations } from 'use-intl';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import { Stack, Box, Button, Container, Typography, Alert } from '@mui/material';
import useFeature from 'hooks/features/useFeature';
import LinkAbove from 'components/shared/linkAbove';
import DetailPanel from 'components/shared/DetailPanel';
import * as Hooks from 'apis/rest/eventNotifications/hooks';
import usePermissions from 'hooks/session/usePermissions';
import SettingsSection from 'components/shared/settingsSection';
import LoadingPage from 'components/pages/loading/loading-view';
import MissingPage from 'components/shared/missingPage';
import PageStaffAccessAlert from 'components/shared/pageStaffAccessAlert';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { AssetGroupTable } from './components/edit/assetGroupTable';
import { DetailsSection } from './components/edit/detailsSection';
import { ManageAssetGroupsDialog } from './components/edit/manageAssetGroups';
import { NotificationRulesTable } from './components/edit/rulesTable';
import { AssetsGeofenceStatus, useAssetsGeofenceStatus } from './components/edit/helpers';

const EventNotificationEdit = (): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.edit');
  const permissions = usePermissions();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id ?? '', 10);
  const [isAssetEditDialogOpen, setIsAssetEditDialogOpen] = useState<boolean>();
  const [showAllWarnings, setShowAllWarnings] = useState<boolean>();
  const maxWarnings = 4;

  const onAssetGroupsSaved = () => { setIsAssetEditDialogOpen(false); };

  const { query } = Hooks.useGetEventNotificationGroup(id);
  const assetGroupQuery = useGetAssetGroupsForOrganisation();
  const assetGroupUpdateMutation = Hooks.useMutateUpdateAssetGroupsForEventNotificationGroup(onAssetGroupsSaved);
  const warnings = Hooks.useGetEventNotificationWarnings(id);

  const featureEnabled = useFeature('manage.notifications');

  const geofenceStatus = useAssetsGeofenceStatus(query.data?.assetGroupIds);

  const handleAssetGroupSave = (assetGroupIds: number[]) => {
    assetGroupUpdateMutation.mutate({
      notificationGroupId: id,
      assetGroupIds
    });
  };

  const handleDelete = () => { navigate('/manage/event-notifications'); };

  const isLoading = query.isLoading || assetGroupQuery.isLoading || query.isRefetching || assetGroupQuery.isRefetching;

  if (!query?.data) {
    if (isLoading) return <LoadingPage />;
    return (
      <MissingPage
        to="/manage/event-notifications"
        title={t('missing.title')}
        description={t('missing.description')}
        linkText={t('missing.link')}
      />
    );
  }

  const contactsMissingData = warnings.query.data?.contactsMissingData ?? [];
  const showWarningsSection = !warnings.query.isLoading && contactsMissingData.length > 0;

  const hasGeofenceRules = query.data?.notificationRules.some(rule => rule.sourceType === 'GEOFENCE') ?? false;

  const warningAlerts = [
    geofenceStatus === AssetsGeofenceStatus.Never && hasGeofenceRules ? (
      <Alert key="neverGeofenceAssets" severity="error">{t('warnings.neverGeofenceAssets')}</Alert>
    ) : undefined,
    geofenceStatus === AssetsGeofenceStatus.None && hasGeofenceRules ? (
      <Alert key="noGeofenceAssets" severity="error">{t('warnings.noGeofenceAssets')}</Alert>
    ) : undefined,
    geofenceStatus === AssetsGeofenceStatus.Some && hasGeofenceRules ? (
      <Alert key="someGeofenceAssets" severity="warning">{t('warnings.someGeofenceAssets')}</Alert>
    ) : undefined,
    ...contactsMissingData.map(w => (
      <Alert severity="warning" key={`missingContact_${w}`}>{t('warnings.noContact', { name: w })}</Alert>
    )),
  ].filter(x => !!x);

  const displayWarningAlerts = !showAllWarnings && contactsMissingData.length > maxWarnings
    ? warningAlerts.slice(0, maxWarnings)
    : warningAlerts;

  return (
    <Page title={t('title', { name: query.data.name })}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>{t(permissions.canEditEventNotifications ? 'titleEdit' : 'title', { name: query.data.name })}</Typography>
            {featureEnabled === false && <PageStaffAccessAlert />}
          </Box>

          {showWarningsSection && (
            <SettingsSection title="">
              <DetailPanel mt={3}>
                <Stack justifyContent="left" spacing={1} px={3}>
                  {displayWarningAlerts}
                  {!showAllWarnings && contactsMissingData.length > maxWarnings && (
                    <Button onClick={() => setShowAllWarnings(true)} variant="text">{t('warnings.more')}</Button>
                  )}
                </Stack>
              </DetailPanel>
            </SettingsSection>
          )}

          {permissions.canEditEventNotifications && (
            <SettingsSection title={t('detailsSection.name')}>
              <DetailsSection group={query.data} OnDelete={handleDelete} />
            </SettingsSection>
          )}

          <SettingsSection title={t('assetsSection.name')}>
            <DetailPanel spacing={3}>
              {permissions.canEditEventNotifications && (
                <Stack direction="row" spacing={3} px={3} justifyContent="space-between" height="4em">
                  <Stack justifyContent="center">
                    <Typography>{t('assetsSection.editText')}</Typography>
                  </Stack>
                  <Button variant="contained" size="large" color="primary" sx={{ minWidth: '10rem' }} onClick={() => setIsAssetEditDialogOpen(true)}
                    disabled={isAssetEditDialogOpen || assetGroupUpdateMutation.isLoading}>
                    {t('assetsSection.editButton')}
                  </Button>
                </Stack>
              )}
              <AssetGroupTable allAssetGroups={assetGroupQuery.data}
                selectedAssetGroupIds={query.data.assetGroupIds}
                isLoading={assetGroupQuery.isLoading}
                readonly={!permissions.canEditEventNotifications}
              />
            </DetailPanel>
          </SettingsSection>

          <SettingsSection title={t('rulesSection.name')}>
            <NotificationRulesTable
              isLoading={isLoading}
              isReadOnly={!permissions.canEditEventNotifications}
              notificationGroupId={id}
            />
          </SettingsSection>
        </Container>
      </SettingsMenuPageWrapper>

      <ManageAssetGroupsDialog
        title={<Typography>{t('assetGroupsDialog.title', { name: query.data?.name })}</Typography>}
        isLoaded={!isLoading}
        isSaving={assetGroupUpdateMutation.isLoading}
        assetGroups={assetGroupQuery.data ?? []}
        selectedAssetGroups={query.data?.assetGroupIds ?? []}
        open={isAssetEditDialogOpen ?? false}
        isReadOnly={!permissions.canEditEventNotifications}
        onClose={() => setIsAssetEditDialogOpen(false)}
        onSave={handleAssetGroupSave}
        ariaLabel="Assign asset groups to event notification group"
      />
    </Page>
  );
};

export default EventNotificationEdit;
