import { resetEverything } from "slices/session/session.slice";

/**
 * This reducer is for the current state of the application interface.
 * For example, which panels/popups are open.
 */
const initialState = Object.freeze({
  // Omnibox can be expanded
  omniboxOpen: true,
  contextboxOpen: false,
  analysisboxOpen: false,

  // Whether the System Status dialog is open (button at bottom-left of status bar)
  systemStatusOpen: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_OMNIBOX':
      return {
        ...state,
        omniboxOpen: !state.omniboxOpen
      };

    case 'TOGGLE_CONTEXTBOX':
      return {
        ...state,
        contextboxOpen: !state.contextboxOpen
      };

    case 'TOGGLE_SYSTEM_STATUS':
      return {
        ...state,
        systemStatusOpen: !state.systemStatusOpen
      };

    case 'TOGGLE_ANALYSISBOX':
      return {
        ...state,
        analysisboxOpen: !state.analysisboxOpen
      };

    case resetEverything.type: return initialState;

    default: return state;
  }
};
