import React, { useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useTranslations } from 'use-intl';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useOutletContext } from 'react-router';
import useVolume from 'hooks/units/useVolume';
import { Close, KeyboardArrowDown, WarningAmber } from '@mui/icons-material';
import { alpha, useTheme } from '@mui/material/styles';
import useTimezone from 'hooks/session/useTimezone';
import type { DropGroup } from 'apis/rest/firefighting/types';
import { getSuppressantColors } from '../helpers';
import { OutletContextValue } from '../layout';
import DropDetailSummary from './dropDetailSummary';
import { getDropGroupsOutsideOfTrips } from '../statistics';

interface DropRowProps {
  isSelectedDrop: boolean;
  isOutsideTrip: boolean;
  onOpen: () => void;
  group: DropGroup;
  lastDropTime?: number;
  nextFillTime?: number;
  setHoveredDropGroup: (id: string) => void
}

const DropRow = ({
  isSelectedDrop,
  isOutsideTrip,
  onOpen,
  group,
  lastDropTime,
  nextFillTime,
  setHoveredDropGroup
}: DropRowProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const tz = useTimezone();
  const volume = useVolume();

  const theme = useTheme();
  const suppressantColor = getSuppressantColors(theme);
  const selectedBgColor = alpha(theme.palette.primary.main, 0.05);

  return (
    <>
      <TableRow
        onClick={onOpen}
        sx={{
          cursor: 'pointer',
          bgcolor: isSelectedDrop ? selectedBgColor : undefined,
          ':hover': { bgcolor: selectedBgColor },
          '& > .MuiTableCell-root': { border: 0 },
        }}
        onMouseEnter={() => setHoveredDropGroup(group.id)}
      >
        <TableCell>
          <Stack direction="column" justifyContent="center">
            <Typography>{group.locations.averageDrop.placeName}</Typography>
            <Typography>
              {DateTime.fromMillis(group.startTimeMs)
                .setZone(tz)
                .toFormat('dd MMM yyyy, HH:mm:ss ZZZ')}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box width="1rem" height="1rem" borderRadius="50%" bgcolor={suppressantColor[group.suppressant]} />
            <Box>
              <Box>{t(`suppressant.${group.suppressant}`)}</Box>
              <Box>{t('nFills', { n: group.fills.length })}</Box>
            </Box>
          </Stack>
        </TableCell>
        <TableCell>
          {group.volumeDroppedLitres === undefined ? '—' : volume.create(group.volumeDroppedLitres).format()}
          {group.drops.some(d => d.splitDrop) && (<Typography>{t('nSplitDrops', { n: group.drops.filter(d => d.splitDrop).length })}</Typography>)}
        </TableCell>
        <TableCell>{
          isOutsideTrip && (
            <Tooltip title={t('notInTripDescription')}>
              <WarningAmber />
            </Tooltip>
          )
        }
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {isSelectedDrop ? <Close sx={{ width: '2rem', height: '2rem' }} /> : <KeyboardArrowDown sx={{ width: '2rem', height: '2rem' }} />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ bgcolor: selectedBgColor }}>
        <TableCell style={{
          paddingBottom: 0,
          paddingTop: 0,
        }} colSpan={5}>
          <Collapse in={isSelectedDrop} timeout="auto" unmountOnExit>
            <DropDetailSummary
              group={group}
              lastDropTime={lastDropTime}
              nextFillTime={nextFillTime}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const AssetDropsDetail = () => {
  const {
    visibleSuppressants,
    trips,
    dropGroups,
    setHoveredDropGroup,
    setSelectedDropGroup
  } = useOutletContext<OutletContextValue>();
  const t = useTranslations('pages.reporting.firefighting');

  const [openDrop, setOpenDrop] = React.useState<string | undefined>();

  useEffect(() => {
    setSelectedDropGroup(openDrop);
  }, [openDrop, setSelectedDropGroup]);

  const dropsOutsideTrips = useMemo(() => getDropGroupsOutsideOfTrips(trips, dropGroups), [trips, dropGroups]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dropGroups.length > 0 && (
        <Paper sx={{ gridColumn: '1/-1' }} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('columns.time')}</TableCell>
                <TableCell>{t('columns.suppressant')}</TableCell>
                <TableCell>{t('columns.dropVolume')}</TableCell>
                <TableCell sx={{ width: 0 }} />
                <TableCell sx={{ width: 0 }} />
              </TableRow>
            </TableHead>
            <TableBody onMouseLeave={() => setHoveredDropGroup(undefined)}>
              {dropGroups.map((group, i, a) => {
                if (!visibleSuppressants[group.suppressant]) return null;

                const lastDropTime = a.at(i - 1)?.endTimeMs;
                const nextFillTime = a.at(i + 1)?.startTimeMs;

                return (
                  <DropRow
                    key={group.id}
                    isOutsideTrip={dropsOutsideTrips.includes(group.id)}
                    isSelectedDrop={openDrop === group.id}
                    onOpen={() => (openDrop === group.id ? setOpenDrop(undefined) : setOpenDrop(group.id))}
                    group={group}
                    lastDropTime={lastDropTime}
                    nextFillTime={nextFillTime}
                    setHoveredDropGroup={setHoveredDropGroup}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
    </>
  );
};

export default AssetDropsDetail;
