import React, { createContext } from 'react';

export interface UserSettings {
  version: 1,
  map: {
    kml: {
      displayOverlay: boolean
      displayAdditionalData: boolean
    }
  }
}

export type UserSettingsState = [UserSettings, React.Dispatch<React.SetStateAction<UserSettings>>];

export const DefaultUserSettings: UserSettings = {
  version: 1,
  map: {
    kml: {
      displayOverlay: true,
      displayAdditionalData: false
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const UserSettingsContext = createContext<UserSettingsState>([DefaultUserSettings, () => {}]);
