import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BuildCircle, PauseCircleFilled } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import moment from 'utils/moment';
import AssetIcon from 'components/shared/icons/assetIcons';
import AssetLabel from 'components/shared/assetLabel';
import BatteryIndicator from 'components/shared/icons/batteryIndicator';
import TimeSince from 'components/shared/timeSince';
import useInterval from 'hooks/useInterval';
import { supportsBattery } from 'helpers/deviceSupport';
import { useBearingAtSpeed, useAltitudeAtSpeed, useCoordinates } from 'hooks/settings';
import { useLatestFirmwareVersionForDevice, useGetDeviceBasic } from 'apis/rest/devices/hooks';
import { useLatestPosition } from 'repositories/reports/hooks';
import { useTrackingSuspended } from 'hooks/device/useTrackingSuspended';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import useTimezone from 'hooks/session/useTimezone';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import useStyles from '../queryResult/queryResult-styles';

// Assets that haven't reported for this long are considered inactive
const INACTIVE_MILLISECONDS = 30 * 60 * 1000;
const getIsActive = (time: number | undefined, now: moment.Moment | undefined): boolean => {
  if (!time) return false;
  return Math.abs(moment(now || undefined).diff(moment.unix(time))) < INACTIVE_MILLISECONDS;
};
const updateIsActive = (time: number | undefined, now: moment.Moment | undefined, isActive: boolean, setIsActive: (active: boolean) => void): void => {
  const newIsActive = getIsActive(time, now);
  if (newIsActive !== isActive) setIsActive(newIsActive);
};

interface OBResultAssetProps {
  asset: AssetBasic
  selectAsset: (asset: AssetBasic) => void
  isHidden: boolean
  isSelected: boolean
  unassignItemFromMap: () => void
  clearSelection: () => void
}

const OBResultAsset = ({
  asset,
  selectAsset,
  isHidden,
  isSelected,
  unassignItemFromMap,
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  clearSelection,
}: OBResultAssetProps): JSX.Element => {
  const timezone = useTimezone();
  const t = useTranslations('omnibox.modules.results');
  const fallbackString = t('never');
  const fallbackStringHistoric = t('neverHistoric');
  // const tTag = useTranslation('tags');
  // const statesToDisplay = getStatesToDisplay(assetState, tTag);
  const classes = useStyles();
  const theme = useTheme();

  const latestPosition = useLatestPosition(asset.id);

  const selectedDay = useSelector<ReduxState, string | null>(state => state.app.selectedDay || null);
  const now = useMemo(() => (selectedDay ? moment.tz(selectedDay, timezone) : undefined), [selectedDay, timezone]);

  // For determining if the current asset is active or not
  const [isActive, setIsActive] = useState(getIsActive(latestPosition?.received, now));
  // Handle an asset going from inactive -> active
  useEffect(() => {
    updateIsActive(latestPosition?.received, now, isActive, setIsActive);
  }, [isActive, asset, now, setIsActive, latestPosition?.received]);
  // Handle an asset going from active -> inactive
  useInterval(() => {
    updateIsActive(latestPosition?.received, now, isActive, setIsActive);
  }, 60 * 1000);

  const { assetDetailSelected } = useUiSettings();
  const speedUnit = useSpeedByAsset(asset);
  const courseSpeed = useBearingAtSpeed(latestPosition, speedUnit);
  const altitudeSpeed = useAltitudeAtSpeed(latestPosition, speedUnit);
  const coordinates = useCoordinates(latestPosition?.latitude, latestPosition?.longitude);

  const assetDetails: Record<string, string | undefined> = {
    bearingAtSpeed: courseSpeed,
    altitudeAtSpeed: altitudeSpeed,
    position: coordinates,
    make: asset.make ?? undefined,
    model: asset.model ?? undefined,
    variant: asset.variant ?? undefined,
    owner: asset.ownerName,
    // @ts-ignore
    category: t(asset.category.toLowerCase())
  };

  const onAssetItemClick = () => {
    if (isHidden) return;
    selectAsset(asset);
    if (isSelected) {
      clearSelection();
      // eslint-disable-next-line no-unused-expressions
      unassignItemFromMap();
    }
  };

  const assetStateClasses = {
    hidden: isHidden,
    selected: isSelected,
    inactive: !isActive,
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    distress: latestPosition?.events?.includes('EVT_DISTRESS')
      && !latestPosition?.events?.includes('EVT_DISTRESS_CANCEL'), // sInDistress(assetState) || isOverdue(assetState)
  };
  const resultClasses = clsx(classes.result, assetStateClasses, {
    [classes.hiddenResult]: isHidden,
    [classes.visibleResult]: !isHidden
  });
  const resultIconWrapperClasses = clsx(classes.resultIconWrapper, assetStateClasses, {
    [classes.hiddenResultIconWrapper]: isHidden,
    [classes.visibleResultIconWrapper]: !isHidden
  });

  const trackingSuspended = useTrackingSuspended(asset.id, asset.deviceId || -1);
  const deviceQuery = useGetDeviceBasic(asset.deviceId ?? -1, !asset.deviceId).query;
  const firmwareOutdated = useLatestFirmwareVersionForDevice(deviceQuery.data).outdated;

  return (
    <div className={resultClasses} onClick={onAssetItemClick} role="button" onKeyDown={onAssetItemClick} tabIndex={0} aria-labelledby={`assetLabel-${asset.id}`}>
      <div className={classes.resultIconBackground} role="presentation">
        <div className={resultIconWrapperClasses}>
          <div className={classes.resultIcon}>
            <AssetIcon
              type={asset.assetIconId}
              fillColor={theme.palette.common.white}
              outline={false}
            />
          </div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.headerRow}>
          <Tooltip title={<AssetLabel asset={asset} />}>
            <Typography variant="body1" noWrap className={classes.titleText} id={`assetLabel-${asset.id}`}><AssetLabel asset={asset} /></Typography>
          </Tooltip>
          <Stack direction="row" spacing={1} alignItems="center">
            {trackingSuspended && (
              <Tooltip title={t('trackingSuspended')}>
                <PauseCircleFilled fontSize="small" className={clsx(classes.headerRow, 'warning')} />
              </Tooltip>
            )}
            {firmwareOutdated && (
              <Tooltip title={t('firmwareOutdated')}>
                <BuildCircle fontSize="small" className={clsx(classes.headerRow, 'warning')} />
              </Tooltip>
            )}
            {supportsBattery(asset) && latestPosition && (
              <Tooltip title={`${latestPosition?.battery}%`}>
                <div
                  role="meter"
                  aria-valuenow={latestPosition?.battery || 0}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  aria-label={t('batteryPercentage')}
                >
                  <BatteryIndicator level={latestPosition?.battery || 0} />
                </div>
              </Tooltip>
            )}
          </Stack>
        </div>
        <div className={classes.headerRow}>
          <TimeSince
            Component="p"
            time={latestPosition ? latestPosition.received * 1000 : undefined}
            fallbackString={now ? fallbackStringHistoric : fallbackString}
            tooltip
          />
          <span className={classes.truncate}>{assetDetails[assetDetailSelected] || courseSpeed}</span>
        </div>
      </div>
    </div>
  );
};

export default OBResultAsset;
