import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginResponse, login, loginWithUsercode } from 'apis/auth';
import { createInvitedUser } from 'apis/invite';
import { joinOrganisationOrUpdateRole, leaveOrganisation } from 'apis/rest/memberships/requests';
import { queryClient } from 'queryClient';
import { membershipQueryKeys } from 'apis/rest/memberships/queryKeys';
import {
  JoinOrganisationParams,
  LeaveOrganisationParams,
  LegacyLoginParams,
  LoginParams,
  SignupParams,
  StandardApiConfig
} from './types';

export const loginAction = createAsyncThunk<LoginResponse, LoginParams, StandardApiConfig>(
  'session/login',
  async ({ email, password, rememberMe }, { rejectWithValue }) => {
    try {
      const response = await login(email, password, rememberMe);
      return response;
    } catch (error) {
      const value = (error as { code: string }).code;
      return rejectWithValue({ code: value });
    }
  }
);

export const legacyLoginAction = createAsyncThunk<LoginResponse, LegacyLoginParams, StandardApiConfig>(
  'session/legacyLogin',
  async ({ usercode, password, rememberMe }, { rejectWithValue }) => {
    try {
      const response = await loginWithUsercode(usercode, password, rememberMe);
      return response;
    } catch (error) {
      const value = (error as { code: string }).code;
      return rejectWithValue({ code: value });
    }
  }
);

export const signupAction = createAsyncThunk<LoginResponse, SignupParams, StandardApiConfig>(
  'session/signup',
  async ({ name, email, password, token }, { rejectWithValue }) => {
    try {
      const response = await createInvitedUser(name, email, password, token);
      return response;
    } catch (error) {
      const value = (error as { code: string }).code;
      return rejectWithValue({ code: value });
    }
  }
);

export const joinOrganisationAction = createAsyncThunk<void, JoinOrganisationParams, StandardApiConfig>(
  'session/joinOrganisation',
  async ({ organisationId, userId, membershipRole }, { rejectWithValue }) => {
    try {
      await joinOrganisationOrUpdateRole(organisationId, userId, membershipRole);
      queryClient.invalidateQueries(membershipQueryKeys.all(organisationId));
    } catch (error) {
      rejectWithValue({ code: 'joinOrganisationError' });
    }
  }
);

export const leaveOrganisationAction = createAsyncThunk<void, LeaveOrganisationParams, StandardApiConfig>(
  'session/leaveOrganisation',
  async ({ organisationId, userId }, { rejectWithValue }) => {
    try {
      await leaveOrganisation(organisationId, userId);
      queryClient.invalidateQueries(membershipQueryKeys.all(organisationId));
    } catch (error) {
      rejectWithValue({ code: 'leaveOrganisationError' });
    }
  }
);
