import React, { useMemo } from 'react';
import { useTranslations } from 'use-intl';
import { Box, Paper, Stack, Typography } from '@mui/material';
import StepTimeline, { TimelineElementProps } from 'components/shared/stepTimeline';
import useVolume, { useVolumePerPeriod } from 'hooks/units/useVolume';
import { distance, speed } from 'helpers/unitsOfMeasure';
import useDuration from 'hooks/units/useDuration';
import useDistance from 'hooks/units/useDistance';
import { useSuppressantColors } from 'components/pages/reporting/firefighting/helpers';
import type { DropGroup, Drop, Fill, Flight } from 'apis/rest/firefighting/types';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import { maxBy } from 'lodash/fp';

interface DropDetailSummaryProps {
  group: DropGroup;
  lastDropTime?: number;
  nextFillTime?: number;
}

type StepItem = (Drop & { type: 'Drop', suppressant: Suppressant, skip: false })
  | Fill & { skip: false, location?: string }
  | (Flight & { type: 'Flight', skip: true, id: -1, suppressant: Suppressant })

const StepView = ({ item }: TimelineElementProps<StepItem>) => {
  const t = useTranslations('pages.reporting.firefighting.detailSummary');
  const volume = useVolume();
  const volumePerPeriod = useVolumePerPeriod();
  const duration = useDuration();

  const distanceUnits = useDistance();
  const speedUnits = useUnitSettings().speedAir;
  const shortDistanceUnits = distanceUnits.unit === 'kilometres' ? 'metres' : 'feet';

  if (item.type === 'Flight') {
    return (
      <Box display="grid" gridTemplateColumns="25ch 1fr" sx={{ gridColumnGap: 16 }} p={3}>
        <Stack direction="column" justifyContent="center">
          <Typography>{`${t('directDistance')}: ${item.distanceKm ? distance.withUnits(distance.fromSI(item.distanceKm * 1000, distanceUnits.unit), distanceUnits.unit, 1) : '—'}`}</Typography>
          <Typography fontStyle="italic">{t('directDistanceDescription')}</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography>{`${t('directFlightDuration')}: ${duration.fromMillis(item.durationMs, undefined, 'm\'m\' s\'s\'')}`}</Typography>
        </Stack>
      </Box>
    );
  }

  const volumeToShow = item.type === 'Drop' ? item.dropVolumeLitres : item.volumeFilledLitres;

  return (
    <Paper elevation={0}>
      <Box display="grid" gridTemplateColumns="25ch 50ch 30ch 1fr 30ch" sx={{ gridColumnGap: 16 }} p={3}>
        <Stack direction="column" justifyContent="center">
          <Typography fontWeight="bold">{item.type === 'Drop' ? (item.splitDrop ? t('type.SplitDrop') : t('type.Drop')) : t(`type.${item.type}`)}</Typography>
          {volumeToShow ? (
            <Typography>{volume.create(volumeToShow).format()} {t(`suppressant.${item.suppressant}`)}</Typography>
          ) : (
            <Typography>{t(`suppressantNoVolume.${item.suppressant}`)}</Typography>
          )}
        </Stack>
        {/*
          <Stack direction="column" justifyContent="center">
            {!item.location
              ? <Skeleton width="50ch" sx={{ display: 'inline-block' }} />
              : item.location !== 'Unknown' ? <Typography>{item.location}</Typography> : undefined}
            <Typography>{DateTime.fromMillis(item.startTimeMs).setZone(tz).toFormat('dd MMM yyyy, HH:mm:ss ZZZ')}</Typography>
          </Stack>
           */
        }
        {
          item.type === 'Drop' ? (
            <>
              <Stack direction="column" justifyContent="center">
                <Typography>{`${t('duration')}: ${duration.fromMillis(item.durationMs, undefined, 'm\'m\' s\'s\'')}`}</Typography>
                <Typography>{`${t('rate')}: ${volumePerPeriod.create((item.dropVolumeLitres ?? 0) / (item.durationMs / 1000), 's').format()}`}</Typography>
              </Stack>
              <Stack direction="column" justifyContent="center">
                <Typography>{`${t('speed')}: ${item.speedKph ? speed.withUnits(speed.fromKmh(item.speedKph, speedUnits), speedUnits) : '—'}`}</Typography>
                <Typography>{`${t('distance')}: ${item.distanceKm ? distance.withUnits(distance.fromSI(item.distanceKm * 1000, shortDistanceUnits), shortDistanceUnits) : '—'}`}</Typography>
              </Stack>
            </>
          ) : (
            <>
              <Stack direction="column" justifyContent="center">
                {item.location && <Typography>{item.location}</Typography>}
                <Typography>{`${t('containerVolume')}: ${volume.create(item.containerVolumeLitres).format()}`}</Typography>
              </Stack>
              {item.durationMs > 0 && (
                <Stack direction="column" justifyContent="center">
                  <Typography>{`${t('duration')}: ${duration.fromMillis(item.durationMs, undefined, 'm\'m\' s\'s\'')}`}</Typography>
                  <Typography>{`${t('rate')}: ${volumePerPeriod.create((item.volumeFilledLitres ?? 0) / (item.durationMs / 1000), 's').format()}`}</Typography>
                </Stack>
              )}
            </>
          )
        }
      </Box>
    </Paper>
  );
};

const DropDetailSummary = ({ group, lastDropTime, nextFillTime }: DropDetailSummaryProps) => {
  const t = useTranslations('pages.reporting.firefighting.detailSummary');
  const volume = useVolume();
  const volumePerPeriod = useVolumePerPeriod();
  const durationUnit = useDuration();

  const suppressantColor = useSuppressantColors();

  const totalDropVolume = useMemo(() => group.volumeDroppedLitres, [group]);
  const averageDropRate = useMemo(() => {
    const duration = group.drops.reduce<number>((acc, drop) => acc + drop.durationMs, 0);
    return duration > 0 ? totalDropVolume / (duration / 1000) : 0;
  }, [group, totalDropVolume]);

  const { lastDropDuration, nextFillDuration } = useMemo(() => {
    const lastMs = lastDropTime ? group.startTimeMs - lastDropTime : 0;
    const nextMs = nextFillTime ? nextFillTime - group.endTimeMs : 0;
    const last = lastMs > 0 ? durationUnit.fromMillis(lastMs, undefined, durationUnit.findFormat(lastMs)) : undefined;
    const next = nextMs > 0 ? durationUnit.fromMillis(nextMs, undefined, durationUnit.findFormat(nextMs)) : undefined;
    return { lastDropDuration: last, nextFillDuration: next };
  }, [group, lastDropTime, nextFillTime, durationUnit]);

  const stepItems: StepItem[] = useMemo(() => {
    const maxFillAmount = maxBy('volumeFilledLitres', group.fills);
    const fills: StepItem[] = group.fills.map(f => ({
      ...f,
      skip: false,
      location: f.volumeFilledLitres === maxFillAmount?.volumeFilledLitres ? group.locations.maxFill?.placeName : undefined
    }));
    const flights: StepItem[] = group.flight ? [{ ...group.flight, suppressant: group.suppressant, type: 'Flight', skip: true, id: -1 }] : [];
    const drops: StepItem[] = group.drops.map(d => ({ ...d, type: 'Drop', suppressant: group.suppressant, skip: false }));
    return [...fills, ...flights, ...drops];
  }, [group]);

  return (
    <Box display="flex" flexDirection="column" gap={3} pb={3}>
      <Box>
        <Box display="flex" flexDirection="row" gap={8} alignItems="start">
          <Box>
            <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">{t('totalFlightTime')}</Typography>
            <Typography fontSize="2.5rem" lineHeight={1.2} fontWeight="bold">
              {group.flight ? (
                group.flight.durationMs < 60 * 1000
                  ? durationUnit.fromMillis(group.flight.durationMs, undefined, 's\'s\'')
                  : durationUnit.fromMillis(group.flight.durationMs, undefined, 'm\'m\' s\'s\'')
              ) : '—'}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">{t('totalDropVolume')}</Typography>
            <Typography fontSize="2.5rem" lineHeight={1.2} fontWeight="bold">
              {totalDropVolume ? volume.create(totalDropVolume).format() : '—'}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">{t('averageDropRate')}</Typography>
            <Typography fontSize="2.5rem" lineHeight={1.2} fontWeight="bold">
              {volumePerPeriod.create(averageDropRate, 's').format()}
            </Typography>
          </Box>
          {lastDropDuration && <Typography flexGrow={1} align="right">{`${lastDropDuration} ${t('afterLastDrop')}`}</Typography>}
        </Box>
        <Typography fontSize="0.9rem" lineHeight={1} fontWeight="normal" fontStyle="italic">{t('flightTimeDescription')}</Typography>
      </Box>
      <StepTimeline steps={stepItems} stepView={StepView} colorFn={d => suppressantColor[d.suppressant]} />
      {nextFillDuration && <Typography align="right">{`${nextFillDuration} ${t('beforeNextFill')}`}</Typography>}
    </Box>
  );
};

export default DropDetailSummary;
