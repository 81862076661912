import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { doc, getFirestore, getDoc } from 'firebase/firestore';
import { debounce } from 'lodash';
import { createFirestoreUnitSettings, updateFirestoreUnitSettings } from 'apis/rest/settings/requests';
import { app } from '../../firebaseApp';
import { loggedOut } from '../session/session.slice';

type SpeedUnit = 'kmh' | 'km/h' | 'mph' | 'knots';

export interface unitSettingsUpdateRequest {
  uid: string
  payload: unitSettingsState['units']
}

export interface unitSettingsState {
  units: {
    distance: 'kilometres' | 'statuteMiles' | 'nauticalMiles' | 'metres' | 'feet'
    speed: SpeedUnit
    speedAir: SpeedUnit
    speedLand: SpeedUnit
    speedSea: SpeedUnit
    speedPerson: SpeedUnit
    altitude: 'metres' | 'feet'
    bearing: 'degreesTrue' | 'degreesGeographic' | 'degreesMagnetic'
    coordinate: 'coordinatesDD' | 'coordinatesDMS' | 'coordinatesDDM'
    area: 'squareKilometres' | 'acres' | 'hectares' | 'squareMiles' | 'squareNauticalMiles'
    volume: 'litres' | 'gallons'
    duration: 'hoursMinutes' | 'decimalTime'
    schema: number
  }
  loaded: boolean
}

export const initialUnitSettings: unitSettingsState = {
  units: {
    distance: 'kilometres',
    speed: 'kmh',
    speedAir: 'kmh',
    speedLand: 'kmh',
    speedSea: 'kmh',
    speedPerson: 'kmh',
    altitude: 'metres',
    bearing: 'degreesTrue',
    coordinate: 'coordinatesDD',
    area: 'squareKilometres',
    volume: 'litres',
    duration: 'hoursMinutes',
    schema: 0
  },
  loaded: false,
};

// get unit settings from firestore
export const getUnitSettings = createAsyncThunk<unitSettingsState['units'], string, { state: ReduxState }>(
  'getUnitSettings',
  async (uid: string, { getState }) => {
    const firestore = getFirestore(app);
    const settingsCategory = doc(firestore, `settings/${uid}/userSettings/unitSettings`);
    const unitSettingsDoc = await getDoc(settingsCategory);
    if (unitSettingsDoc.exists()) {
      return unitSettingsDoc.data() as unitSettingsState['units'];
    }
    // user does not exist in firestore - get setting from local storage, upload to firestore and update slice state
    const existingUnitSettings = getState().settings.units as unitSettingsState['units'];
    await createFirestoreUnitSettings(existingUnitSettings);
    return existingUnitSettings;
  }
);

export const manageUnitSettingsSlice = createSlice({
  name: 'unitSettings',
  initialState: initialUnitSettings,
  reducers: {
    updateLocalUnits: (state, action: PayloadAction<unitSettingsState['units']>) => {
      state.units = action.payload;
      state.loaded = true;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loggedOut, () => initialUnitSettings)
      .addCase(getUnitSettings.fulfilled, (state, payload) => {
        state.units = payload.payload;
        state.loaded = true;
      });
  },
  reducerPath: 'unitSettings',
  selectors: {
    selectUnitSettings: state => state.units,
  }
});

export const { selectUnitSettings } = manageUnitSettingsSlice.selectors;
export const { updateLocalUnits } = manageUnitSettingsSlice.actions;
export default manageUnitSettingsSlice.reducer;

const debouncedSetDoc = debounce(async (request: unitSettingsState['units']) => updateFirestoreUnitSettings(request), 3000);

export const updateUnitSettings = createAsyncThunk(
  'updateUnitSettings',
  async (request: unitSettingsState['units'], thunkAPI) => {
    thunkAPI.dispatch(updateLocalUnits(request));
    await debouncedSetDoc(request);
    return request;
  }
);
