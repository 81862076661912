import Smart from 'hocs/smart';
import { eventFilterSelector } from 'selectors/events';
import View from './activity-view';

export default Smart(
  View,
  state => ({
    reportFilter: eventFilterSelector(state),
  }),
  ac => ({
    updateFilter: ac.reports.filterAssetReports,
    setTrailHighlight: ac.map.setTrailHighlight,
  })
);
