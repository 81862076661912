import WebMercatorViewport from '@math.gl/web-mercator';
import { loggedOut, resetEverything, setOrganisationId } from 'slices/session/session.slice';
import { unProject } from 'utils/projection';

const distanceSq = (aX, aY, bX, bY) => ((aX - bX) ** 2) + ((aY - bY) ** 2);
const nearestPowerOf2 = n => 1 << 31 - Math.clz32(n);
const lowerBoundMinVisibleDistance = 0.0000000001;

// use the force lads!
const forceMutateObjectMap = (prevObjectMap, mutate, filter) => {
  let keys = Object.keys(prevObjectMap);
  if (filter) {
    keys = keys.filter(key => filter(prevObjectMap[key], key));
  }
  const nextObjectMap = keys.reduce((acc, key) => {
    const obj = prevObjectMap[key];

    const nextObj = mutate(obj, key);
    return { ...acc, [key]: nextObj };
  }, {});
  return nextObjectMap;
};

const mutateObjectMap = (prevObjectMap, mutate, filter) => {
  let hasChanges = false;
  let keys = Object.keys(prevObjectMap);
  if (filter) {
    keys = keys.filter(key => filter(prevObjectMap[key], key));
  }
  const nextObjectMap = keys.reduce((acc, key) => {
    const obj = prevObjectMap[key];

    const nextObj = mutate(obj, key);
    if (nextObj !== obj) {
      hasChanges = true;
    }
    return { ...acc, [key]: nextObj };
  }, {});
  return hasChanges ? nextObjectMap : prevObjectMap;
};

const initialState = {
  maps: {},
  mostRecentDeviceReport: null,
};

const positions = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CURSOR_POSITION': {
      let cursor = null;
      let prevCursor = null;
      let mapId = '';
      if (action.payload) {
        const {
          lat: latitude, lng: longitude, x, y
        } = action.payload;
        mapId = action.payload.mapId;
        cursor = {
          latitude, longitude, x, y
        };
      }

      // we keep track of the last known (good) previous cursor position,
      // which is used by closestReport selector to find the last known good report
      // which in turn ensures the positionDetails widget on each map doesn't dissappear
      if (mapId !== '' && state.maps[mapId]) {
        if (state.maps[mapId]?.cursor) {
          prevCursor = state.maps[mapId]?.cursor;
        } else {
          prevCursor = state.maps[mapId]?.prevCursor;
        }
      }

      return {
        ...state,
        cursor,
        maps: mutateObjectMap(state.maps, (map, id) => {
          if (id === mapId.toString()) {
            return {
              ...map,
              cursor,
              prevCursor
            };
          }
          return {
            ...map,
            cursor: null
          };
        })
      };
    }

    case 'UPDATE_VIEWPORT': {
      const { mapId } = action.payload;
      const prevMap = state.maps[mapId];
      if (prevMap && !prevMap.viewport && !prevMap.viewportBounds) {
        return state;
      }
      return {
        ...state,
        maps: {
          ...state.maps,
          [mapId]: {
            minVisibleDistance: 0,
            ...prevMap,
            viewport: null,
            viewportBounds: null
          }
        }
      };
    }

    case 'FOV_CHANGED': {
      if (!action.payload.viewport) {
        return state;
      }
      const { mapId } = action.payload;
      const viewport = new WebMercatorViewport(action.payload.viewport);
      const [x1, y1] = unProject(viewport, 0, 0);
      const [x2, y2] = unProject(viewport, viewport.width, 0);
      const [x3, y3] = unProject(viewport, viewport.width, viewport.height);
      const [x4, y4] = unProject(viewport, 0, viewport.height);
      const north = Math.max(y1, y2, y3, y4);
      const south = Math.min(y1, y2, y3, y4);
      let east = Math.max(x1, x2, x3, x4);
      let west = Math.min(x1, x2, x3, x4);

      // When the map is wider than 1 world, we need to draw multiple copies of things
      const additionalRenders = {
        west: west < -180 ? Math.ceil((west + 180) / -360) : 0,
        east: east > 180 ? Math.ceil((east - 180) / 360) : 0
      };

      if ((east - west) > 360) { // whole globe
        east = 180;
        west = -180;
      }

      const oldAdditionalRendersTheSame = additionalRenders.west === state.maps[mapId]?.viewportBounds.additionalRenders?.west
        && additionalRenders.east === state.maps[mapId]?.viewportBounds.additionalRenders?.east;
      const viewportBounds = {
        north,
        east: east > 180 ? east - 360 : east,
        south,
        west: west < -180 ? west + 360 : west,
        additionalRenders: oldAdditionalRendersTheSame ? state.maps[mapId].viewportBounds.additionalRenders : additionalRenders
      };
      const minVisibleDistance = Math.max(nearestPowerOf2(distanceSq(east, north, west, south)) / (128 ** 2), lowerBoundMinVisibleDistance); // 128 visible points along a diagonal line

      return {
        ...state,
        maps: {
          ...state.maps,
          [mapId]: {
            ...state.maps[mapId],
            minVisibleDistance,
            viewport,
            viewportBounds
          }
        }
      };
    }
    case setOrganisationId.type: {
      return {
        ...state,
        maps: forceMutateObjectMap(state.maps,
          map => ({
            ...map,
            viewport: {},
            viewportBounds: {}
          })),
      };
    }

    case 'SET_MOST_RECENT_DEVICE_REPORT': {
      return {
        ...state,
        mostRecentDeviceReport: action.payload.timestamp,
      };
    }

    case loggedOut.type:
    case resetEverything.type: return initialState;

    default:
      return state;
  }
};

export { lowerBoundMinVisibleDistance };

export default positions;
