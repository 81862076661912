import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import ReactMapGl, { AttributionControl } from 'react-map-gl';
import { Feature, FeatureCollection, Geometry, Point } from 'geojson';
import { useTranslations } from 'use-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import useMapTemplate from 'hooks/settings/map/useMapConfig';
import usePermissions from 'hooks/session/usePermissions';
import { GeofenceLayers } from 'components/maps/reactmapgl/layers/geofenceLayers';
import { manageGeofencesSlice as slice } from 'slices/manageGeofences.slice';
import * as types from '../types';
import GeofencesMapDrawControl from './GeofencesMapDrawControl';

const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

export interface GeofencingMapProps {
  fences: types.Geofence[]
  list: boolean
}

const GeofencesMap: React.FC<GeofencingMapProps> = ({ fences, list }) => {
  const isDrawing = useSelector(slice.selectors.selectIsDrawing);
  const mapMode = !list && isDrawing ? 'draw_polygon' : 'simple_select';
  const t = useTranslations('pages.manage.geofencing.map');
  const mapTemplate = useMapTemplate();
  const permissions = usePermissions();
  const dispatch = useDispatch();

  const onMapItemCreate = (event: { features: Feature[] }) => {
    dispatch(slice.actions.updateFeature(event.features));
    dispatch(slice.actions.stopDrawing());
  };
  const onMapItemDelete = (event: { features: Feature[] }) => {
    dispatch(slice.actions.deleteFeature(event.features));
    dispatch(slice.actions.stopDrawing());
  };
  const onMapItemUpdate = (event: { features: Feature[], action: string }) => {
    if (event.action === 'change_coordinates') {
      dispatch(slice.actions.updateFeature(event.features));
    } else {
      // Stops dragging shapes on map
      // Updating with empty collection causes refresh to state but doesn't change selection
      dispatch(slice.actions.updateFeature([]));
    }
  };
  const onMapItemSelect = (event: { features: Feature[], points: Point[], type: string }) => {
    dispatch(slice.actions.selectFeature({ features: event.features, points: event.points }));
  };

  const highlightedGeofenceIds = useSelector(slice.selectors.selectHighlightedGeofenceIds);

  const drawInstructionStyle = {
    position: 'absolute',
    left: '50%',
    bottom: '36px',
    transform: 'translateX(-50%)',
    display: 'inline-block',
    p: 1,
    borderRadius: 2,
    overflow: 'hidden',
    opacity: 1,
    textAlign: 'center',
    zIndex: 999,
    boxShadow: 'none',
    backgroundColor: 'rgba(0,0,0,.4)',
    '& p': {
      fontWeight: 500,
      fontSize: '1rem',
      color: 'common.whiteConstant'
    },
  };

  const mapDraw = useSelector(slice.selectors.selectMapDraw);
  const drawGeoJson = useMemo((): FeatureCollection<Geometry> => ({
    type: 'FeatureCollection',
    features: mapDraw.features,
  }), [mapDraw.features]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        mb: 2,
        '& a.mapboxgl-ctrl-logo': {
          display: 'none',
        },
      }}
    >
      <ReactMapGl
        id="geofencesMap"
        mapStyle={mapTemplate.template}
        mapboxAccessToken={mapboxToken}
        initialViewState={{ latitude: 0, longitude: 0 }}
        onLoad={event => {
          event.target.dragRotate.disable();
          event.target.touchZoomRotate.disableRotation();
        }}
        projection={{ name: 'mercator' }}
        attributionControl={false}
        logoPosition="top-left"
      >
        <Routes>
          <Route index element={(
            <GeofenceLayers
              hoveredGeofenceIds={highlightedGeofenceIds}
              setHoveredGeofences={value => dispatch(slice.actions.highlightGeofences(value.map(g => g.id)))}
              filterIds={fences.map(f => f.id)}
            />
          )} />
        </Routes>
        {mapTemplate.provider !== 'Mapbox' && (
          <AttributionControl
            customAttribution={t('customAttribution', { provider: mapTemplate.provider })}
          />
        )}
        {permissions.canEditGeofences ? (
          <GeofencesMapDrawControl
            position="top-left"
            featureCollection={drawGeoJson}
            displayControlsDefault={false}
            modeValue={mapMode}
            onCreate={onMapItemCreate}
            onDelete={onMapItemDelete}
            onUpdate={onMapItemUpdate}
            onSelect={onMapItemSelect}
          />
        ) : (
          <GeofenceLayers
            hoveredGeofenceIds={[]}
            setHoveredGeofences={() => undefined}
            filterIds={fences.map(f => f.id)}
          />
        )}
        <AttributionControl position="bottom-left" />
      </ReactMapGl>
      {mapMode === 'draw_polygon' && (
        <Box
          aria-label="drawingInstructions"
          sx={drawInstructionStyle}>
          <Typography>{t('drawingInstructions')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default GeofencesMap;
