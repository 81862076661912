import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import {
  archiveAsset,
  createAsset,
  CreateAssetBody,
  getAmsSettings,
  getAsset,
  getAssetAssignedToDevice,
  getAssets,
  getIncidentEscalationSettings,
  unregisterAms,
  updateAmsSettings,
  updateAsset,
  UpdateAssetBody,
  updateAssetCallsign,
  UpdateAssetCallsignBody,
  updateIncidentEscalationSettings,
} from './requests';
import { deviceQueryKeys } from '../devices/queryKeys';
import { assetsQueryKeys } from './queryKeys';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

// By default this filters out archived assets. Override the options.select parameter to change this behaviour.
export const useGetAssetsList = <T = AssetBasic[]>(options?: Options<AssetBasic[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = assetsQueryKeys.lists(organisationId);
  const filterArchivedAssets = useCallback((data: AssetBasic[]): T => data.filter(a => !a.archived) as T, []);

  const query = useQuery<AssetBasic[], HttpResponseError, T>(
    queryKey,
    () => getAssets(organisationId),
    { select: filterArchivedAssets, ...options },
  );
  return { query, queryKey };
};

export const useGetAssetBasic = (assetId: number, enabled = true) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => assetsQueryKeys.detail(organisationId, assetId), [organisationId, assetId]);
  const query = useQuery<AssetBasic, HttpResponseError>(
    queryKey,
    () => getAsset(organisationId, assetId),
    { enabled },
  );
  return { query, queryKey };
};

export const useGetAssetBasicAssignedToDevice = (deviceId: number, enabled = true) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => deviceQueryKeys.asset(organisationId, deviceId), [organisationId, deviceId]);
  const query = useQuery<AssetBasic, HttpResponseError>(
    queryKey,
    () => getAssetAssignedToDevice(organisationId, deviceId),
    { enabled },
  );

  return { query, queryKey };
};

export const useCreateAsset = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<AssetBasic, HttpResponseError, Omit<CreateAssetBody, 'ownerId'>>(
    value => createAsset(organisationId, { ...value, ownerId: organisationId }),
    {
      mutationKey: ['createAsset'],
      onSuccess: () => queryClient.invalidateQueries(assetsQueryKeys.all(organisationId)),
    },
  );
};

export const useUpdateAsset = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, UpdateAssetBody>(
    value => updateAsset(organisationId, value),
    {
      mutationKey: ['updateAsset'],
      onSuccess: () => queryClient.invalidateQueries(assetsQueryKeys.all(organisationId)),
    },
  );
};

export const useUpdateAssetCallsign = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, UpdateAssetCallsignBody>(
    value => updateAssetCallsign(organisationId, value),
    {
      mutationKey: ['updateAssetCallsign'],
      onSuccess: () => queryClient.invalidateQueries(assetsQueryKeys.all(organisationId)),
    },
  );
};

export const useArchiveAsset = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { assetId: number, archived: boolean }>(
    value => archiveAsset(organisationId, value.assetId, value.archived),
    {
      mutationKey: ['archiveAsset'],
      onSuccess: () => queryClient.invalidateQueries(assetsQueryKeys.all(organisationId)),
    },
  );
};

export const useGetAmsSettings = <T = AMSSettings>(assetId: number, options?: Options<AMSSettings, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => assetsQueryKeys.ams(organisationId, assetId), [organisationId, assetId]);

  return useQuery<AMSSettings, HttpResponseError, T>(
    queryKey,
    () => getAmsSettings(organisationId, assetId),
    options,
  );
};

export const useGetIncidentEscalationSettings = <T = IncidentEscalationSettings>(assetId: number, options?: Options<IncidentEscalationSettings, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => assetsQueryKeys.incident(organisationId, assetId), [organisationId, assetId]);

  return useQuery<IncidentEscalationSettings, HttpResponseError, T>(
    queryKey,
    () => getIncidentEscalationSettings(organisationId, assetId),
    options,
  );
};

export const useDeregisterAms = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, { assetId: number }>(
    ({ assetId }) => unregisterAms(organisationId, assetId),
    {
      mutationKey: ['deregisterAms'],
      onSuccess: (data, { assetId }) => queryClient.invalidateQueries(assetsQueryKeys.ams(organisationId, assetId)),
    },
  );
};

export const useSetAmsSettings = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, AMSSettings>(
    value => updateAmsSettings(organisationId, value.assetId, value),
    {
      mutationKey: ['updateAms'],
      onSuccess: (data, { assetId }) => queryClient.invalidateQueries(assetsQueryKeys.ams(organisationId, assetId)),
    },
  );
};

export const useSetIncidentEscalationSettings = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, IncidentEscalationSettings>(
    value => updateIncidentEscalationSettings(organisationId, value.assetId, value),
    {
      mutationKey: ['updateIncidentEscalation'],
      onSuccess: (data, value) => queryClient.invalidateQueries(assetsQueryKeys.incident(organisationId, value.assetId)),
    },
  );
};
