import Smart from 'hocs/smart';
import MapView from './map-view';

export default Smart(
  MapView,
  state => ({
    organisationId: state.session.organisationId,
    activeQuery: state.app.query,
    contextboxOpen: state.ui.contextboxOpen,
    selectedItem: state.app.selectedItem,
  }),
  ac => ({
    setActiveQuery: ac.app.setActiveQuery,
    setTextFilter: ac.app.setTextFilter,
    selectItem: ac.app.selectItem,
    displaySnackbar: ac.app.displaySnackbar,
    assignItemToMap: ac.map.assignItemToMap,
  })
);
