import React, { useMemo, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { useDispatch, useSelector } from 'react-redux';
import AnalysisboxContainer from 'components/analysisboxContainer';
import AssetContextbox from 'components/contextbox/asset';
import CreateMarkerDialog from 'components/dialogs/markers/editDialog';
import MapContainer from 'components/mapContainer';
import Omnibox from 'components/omnibox';
import Page from 'components/pages/page';
import { closeCreateMarkerDialog, selectCreateMarkerDialog, setIsPlacingMarker } from 'slices/markers.slice';
import { useCreateMarker } from 'apis/rest/markers/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import useStyles from './map-styles';
import moment from 'moment';
import useTimezone from 'hooks/session/useTimezone';

const MapPage = ({
  organisationId,
  setTextFilter,
  selectItem,
  selectedItem,
  contextboxOpen,
  assignItemToMap,
}) => {
  const classes = useStyles();
  const assetsQuery = useGetAssetsList().query;
  const selectedAsset = useMemo(() => (assetsQuery.data ?? []).find(a => a.id === selectedItem?.id), [assetsQuery, selectedItem]);

  const matchSearch = useMatch('/search/*');
  const [searchParams] = useSearchParams();

  const t = useTranslations('dialogs.markers.create');
  const dispatch = useDispatch();
  const createMarkerDialog = useSelector(selectCreateMarkerDialog);
  const createMarkerMutation = useCreateMarker();

  const timezone = useTimezone();
  const selectedDay = useSelector(state => state.app.selectedDay);
  const now = useMemo(() => (selectedDay ? moment.tz(selectedDay, timezone) : undefined), [selectedDay, timezone]);

  useEffect(() => {
    if (assetsQuery.isSuccess && matchSearch) {
      const searchQuery = searchParams.get('q');
      const selectedAssetId = searchParams.get('asset');
      const newSelectedAsset = assetsQuery.data.find(a => a.id.toString() === selectedAssetId);
      if (searchQuery) setTextFilter(searchQuery);
      if (selectedAssetId && newSelectedAsset) {
        selectItem(newSelectedAsset);
        assignItemToMap('default', newSelectedAsset);
      }
    }
  }, [assetsQuery, selectedAsset, matchSearch, searchParams, setTextFilter, selectItem, assignItemToMap]);

  return (
    <Page>
      <Omnibox />

      <Stack className={classes.mapWrapper}>
        <MapContainer key={organisationId} />
        <AnalysisboxContainer selectedAsset={selectedAsset} />
      </Stack>

      { selectedAsset && <AssetContextbox selectedAsset={selectedAsset} now={now} contextboxOpen={contextboxOpen} /> }

      <CreateMarkerDialog
        open={createMarkerDialog.open}
        title={t('title')}
        latLng={createMarkerDialog.latLng}
        onClose={() => {
          dispatch(setIsPlacingMarker(false));
          dispatch(closeCreateMarkerDialog());
        }}
        onSubmit={marker => {
          dispatch(setIsPlacingMarker(false));
          dispatch(closeCreateMarkerDialog());
          createMarkerMutation.mutate({ marker });
        }}
      />

    </Page>
  );
};

export default MapPage;
