import { Input } from 'csv-stringify/dist/esm/sync';
import { DateTime } from 'luxon';

type BareTrip = Pick<Trip, 'assetId' | 'start' | 'end' | 'startTime' | 'endTime'> & {
  reports: Pick<TripSlimReport, 'events' | 'coords'>[]
}
type BareAsset = Pick<AssetBasic, 'id'>

export const transfromTripsToCsvRecords = <T extends BareTrip = BareTrip, A extends BareAsset = BareAsset>(
  trips: T[],
  assets: A[],
  timezone: string,
  format: {
    assetLabel: (asset: A) => string
    duration: (trip: T) => string
    distance: (trip: T) => string
  },
): Input[] => {
  const records = assets.reduce<Input>((acc, asset) => {
    const tripsForAssets: T[] = trips.filter(trip => trip.assetId === asset.id);
    if (!tripsForAssets.length) return acc;
    return [
      ...acc,
      ...tripsForAssets.map(trip => {
        const startReport = trip.reports.at(0);
        const endReport = trip.reports.at(-1);
        return {
          asset,
          assetLabel: format.assetLabel(asset),
          from: trip.start,
          to: trip.end,
          start: {
            event: startReport?.events[0],
            time: DateTime.fromMillis(trip.startTime).setZone(timezone).toISO({ suppressMilliseconds: true }),
            coordinates: startReport ? `${startReport?.coords[1].toFixed(3)}, ${startReport?.coords[0].toFixed(3)}` : undefined,
          },
          end: {
            event: trip.endTime ? endReport?.events[0] : undefined,
            time: trip.endTime ? DateTime.fromMillis(trip.endTime).setZone(timezone).toISO({ suppressMilliseconds: true }) : undefined,
            coordinates: trip.endTime && endReport ? `${endReport.coords[1].toFixed(3)}, ${endReport.coords[0].toFixed(3)}` : undefined,
          },
          duration: format.duration(trip),
          distance: format.distance(trip),
        };
      }),
    ];
  }, []);

  return records;
};
