import React, { useMemo } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { useQueryClient } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import useSnackbar from 'hooks/useSnackbar';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { beaconQueryKeys } from 'apis/rest/beacon/queryKeys';
import { useUpdateAssignedSeats } from 'apis/rest/beacon/hooks';

interface SaveSeatsDialogProps {
  open: boolean;
  onClose: () => void;
  assignedUsers: Membership[];
  newAssignedUsers: Membership[];
}

export const SaveSeatsDialog = ({
  open,
  onClose,
  assignedUsers,
  newAssignedUsers,
}: SaveSeatsDialogProps) => {
  const t = useTranslations('pages.beacon');
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  const assignedSeatsMutation = useUpdateAssignedSeats({
    onSuccess: () => {
      queryClient.invalidateQueries(beaconQueryKeys.assignedSeats(organisationId));
      onClose();
    },
    onError: error => snackbar.display({
      id: 'beacon-seats-save-error',
      type: 'error',
      text: error.message,
    }),
  });

  const diff = useMemo(() => ({
    toDelete: assignedUsers.filter(user => !newAssignedUsers.includes(user)),
    toAdd: newAssignedUsers.filter(user => !assignedUsers.includes(user)),
  }), [assignedUsers, newAssignedUsers]);

  const onSave = () => {
    assignedSeatsMutation.mutate({
      toAdd: diff.toAdd.map(user => user.userId),
      toDelete: diff.toDelete.map(user => user.userId),
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Stack spacing={2}>
          <Typography>{t('dialogs.saveSeats.description')}</Typography>
          {diff.toDelete.length > 0 && (
            <Box>
              <Typography>{t('dialogs.saveSeats.listUnassigned')}</Typography>
              <List>
                {diff.toDelete.map(user => (
                  <ListItem key={user.userId}>{user.name} ({user.email})</ListItem>
                ))}
              </List>
            </Box>
          )}
          <Typography>{t('dialogs.saveSeats.confirmMessage')}</Typography>
        </Stack>
      </DialogContent>
      <TPDialogActions sx={{ p: 3 }}>
        <Stack direction="row" spacing={3} height="4rem" justifyContent="end">
          <Button
            onClick={onClose}
            variant="outlined"
            size="large"
            disabled={assignedSeatsMutation.isLoading}
            sx={{ minWidth: '10rem' }}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={onSave}
            variant="contained"
            size="large"
            disabled={assignedSeatsMutation.isLoading}
            sx={{ minWidth: '10rem' }}
          >
            {t('save')}
          </Button>
        </Stack>
      </TPDialogActions>
    </Dialog>
  );
};
