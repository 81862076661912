import React from 'react';
import { useTranslations } from 'use-intl';
import { Flight, LocalFireDepartment, Timeline, AirlineStops, Insights, Analytics } from '@mui/icons-material';
import Page from 'components/pages/page';
import {
  SettingsMenuContainer,
  SettingsMenuPanelGrid,
  SettingsMenuPanelItem,
  SettingsMenuPageWrapper,
} from 'components/shared/settingsMenu';
import LabsSettingsMenuContainer from 'components/shared/labs/SettingsMenuContainer';
import useFeature from 'hooks/features/useFeature';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useStaff } from 'hooks/session/useStaff';
import { TrialBadge } from './layout';

const ReportingPage = () => {
  const t = useTranslations('pages.reporting');
  const tripAnalysis = useFeature('reporting.newTripReports');
  const firefighting = useFeature('reporting.aerialFirefightingReport');
  const activitySummary = useFeature('reporting.activitySummaryReport');
  const activitySummaryFlag = useFeatureFlag('utilisationInsights');
  const geofenceFlag = useFeatureFlag('frontendgeofencereports');
  const geofenceToggle = useFeature('reporting.geofences');
  const isStaff = useStaff();

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper>
        <SettingsMenuContainer title={t('sections.reports')}>
          <SettingsMenuPanelGrid>
            <SettingsMenuPanelItem
              to="trip"
              icon={<Flight sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('tripReports.title')}
              description={t('tripReports.description')}
            />
            <SettingsMenuPanelItem
              to="mission"
              icon={<Timeline sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('missionReports.title')}
              description={t('missionReports.description')}
            />
          </SettingsMenuPanelGrid>
        </SettingsMenuContainer>

        <LabsSettingsMenuContainer description={t('sections.previewReports')}>
          <SettingsMenuPanelGrid>
            {activitySummaryFlag && (isStaff || activitySummary) && (
              <SettingsMenuPanelItem
                to="summary"
                icon={<Insights sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('utilisation.title')}
                description={t('utilisation.description')}
              />
            )}
            <SettingsMenuPanelItem
              to="trip-analysis"
              icon={<AirlineStops sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('tripAnalysis.title')}
              description={t('tripAnalysis.description')}
              badge={<TrialBadge featureKey="reporting.newTripReports" size="small" />}
              disabled={!tripAnalysis}
            />
            <SettingsMenuPanelItem
              to="daily-flight-summary"
              icon={<Flight sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('dailyFlightSummary.title')}
              description={t('dailyFlightSummary.description')}
              badge={<TrialBadge featureKey="reporting.newTripReports" size="small" />}
              disabled={!tripAnalysis}
            />
            <SettingsMenuPanelItem
              to="firefighting"
              icon={<LocalFireDepartment sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('firefighting.title')}
              description={t('firefighting.description')}
              disabled={!firefighting && !isStaff}
            />
            {geofenceFlag && (
              <SettingsMenuPanelItem
                to="geofence-events"
                icon={<Analytics sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('geofenceEvents.title')}
                description={t('geofenceEvents.description')}
                disabled={!(geofenceToggle || isStaff)}
              />
            )}

          </SettingsMenuPanelGrid>
        </LabsSettingsMenuContainer>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default ReportingPage;
