import React, { useMemo, forwardRef } from 'react';
import MaterialTable, { Action, MaterialTableProps, MTableAction } from '@material-table/core';
import { useQuery } from '@tanstack/react-query';
import {
  Container,
  Box, Button, Typography,
} from '@mui/material';
import {
  ArrowDownward,
  Clear,
  Search,
  ChevronLeft,
  ChevronRight,
  FilterList,
  FirstPage,
  LastPage,
  Refresh,
} from '@mui/icons-material';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { DateTime } from 'luxon';
import { getIncidents } from 'apis/rest/incidents';
import useTranslation from 'hooks/useTranslation';
import insensitiveSort from 'utils/insensitiveSort';
import DetailPanel from './detailPanel';
import useStyles from './incidentsList-styles';

type Row = Incident;
type TableProps = MaterialTableProps<Row>

const tableIcons: TableProps['icons'] = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />)
};

const sortByDate = (a: Row, b: Row) => new Date(a.createdUtc).valueOf() - new Date(b.createdUtc).valueOf();

const columns: TableProps['columns'] = [
  {
    title: 'Asset name',
    field: 'assetName',
    headerStyle: { textAlign: 'left' },
    cellStyle: { textAlign: 'left' },
    defaultSort: 'asc',
    customSort: (a, b) => insensitiveSort(a.assetName, b.assetName)
  },
  {
    title: 'Asset ID',
    field: 'assetId',
    headerStyle: { textAlign: 'left' },
    cellStyle: { textAlign: 'left' },
    defaultSort: 'asc',
  },
  {
    title: 'Incident type',
    field: ['context', 'kind'],
    headerStyle: { textAlign: 'left' },
    cellStyle: { textAlign: 'left' },
    defaultSort: 'asc',
  },
  {
    title: 'Status',
    field: 'state',
    headerStyle: { textAlign: 'left' },
    cellStyle: { textAlign: 'left' },
    defaultSort: 'asc',
  },
  {
    title: 'Start UTC',
    field: 'createdUtc',
    headerStyle: { textAlign: 'left' },
    cellStyle: { textAlign: 'left' },
    defaultSort: 'asc',
    customSort: sortByDate,
  },
  {
    title: 'State entered UTC',
    field: 'stateEnteredUtc',
    headerStyle: { textAlign: 'left' },
    cellStyle: { textAlign: 'left' },
    defaultSort: 'asc',
    customSort: sortByDate,
  }
];

// eslint-disable-next-line @typescript-eslint/ban-types
interface CustomActionProps<RowData extends object> {
  action: Action<RowData>
  data: RowData
  disabled?: boolean
  size?: number
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const CustomAction = (props: CustomActionProps<Row>): JSX.Element => {
  const { action, data } = props;
  const classes = useStyles();

  if (action.position === 'toolbar') {
    return (
      <Button
        onClick={event => action.onClick(event, data)}
        className={classes.newButton}
        variant="contained"
        startIcon={typeof action.icon === 'string' ? action.icon : action.icon({})}
      >
        {action.tooltip}
      </Button>
    );
  }
  return <MTableAction {...props} />;
};

const transformIncidents = (incidents: Incident[]): Row[] => incidents
  .filter(incident => incident.context.kind === 'Distress' || !['STARTING', 'CHECKED_IN'].includes(incident.state));

const IncidentsListPage = (): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('pages.organisationsList');

  const incidentsQuery = useQuery<Incident[], unknown, Row[]>(
    ['incidents'],
    () => getIncidents(),
    {
      select: transformIncidents
    }
  );

  const onClickRefresh = (): void => {
    incidentsQuery.refetch();
  };

  if (incidentsQuery.isError) {
    return (
      <Container className={classes.tableContainer} maxWidth="md">
        <Box className={classes.materialTable}>
          <Typography sx={theme => ({ marginBottom: theme.spacing(1) })}>There was an error loading data.</Typography>
          <Button variant="outlined" onClick={onClickRefresh}>Try again</Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container className={classes.tableContainer} maxWidth="md">
      <Box className={classes.materialTable}>
        <MaterialTable<Row>
          isLoading={incidentsQuery.isLoading || incidentsQuery.isFetching}
          title="Active Incidents"
          icons={tableIcons}
          columns={columns}
          data={incidentsQuery.data ?? []}
          detailPanel={[{
            tooltip: 'More details',
            render: ({ rowData }) => (
              <DetailPanel
                currentMessage={rowData.currentMessage}
                context={rowData.context}
              />
            )
          }]}
          actions={[
            {
              icon: () => <Refresh />,
              isFreeAction: true,
              onClick: onClickRefresh,
              tooltip: 'Refresh'
            }
          ]}
          options={{
            draggable: false,
            showTitle: false,
            search: false,
            actionsColumnIndex: -1,
            searchFieldStyle: {
              borderRadius: '4px',
              paddingLeft: '18px',
              paddingRight: '10px'
            },
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            pageSize: 10,
            pageSizeOptions: [10, 25, 100],
            emptyRowsWhenPaging: false
          }}
          localization={{
            pagination: {
              labelRows: t('rows'),
              labelDisplayedRows: ` {from}-{to} ${t('of')} {count}`,
              firstTooltip: t('firstPage'),
              previousTooltip: t('previousPage'),
              nextTooltip: t('nextPage'),
              lastTooltip: t('lastPage')
            }
          }}
          components={{ Action: CustomAction }}
        />
      </Box>
    </Container>
  );
};

export default IncidentsListPage;
