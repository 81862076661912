// Main contextually-current object
export const selectItem = item => dispatch => {
  if (!item) return;
  dispatch({
    type: 'SELECT_ITEM',
    // TODO: consider using only the ID to avoid storing entire asset in Redux
    payload: item,
  });
};

export const selectLeg = leg => ({
  type: 'SELECT_LEG',
  payload: {
    leg
  }
});

export const clearSelection = () => ({
  type: 'CLEAR_SELECTION'
});

// Filters/queries used by the Omnibox
export const setTextFilter = value => ({
  type: 'SET_TEXT_FILTER',
  payload: value,
  hide: true
});
export const setActiveQuery = query => ({
  type: 'SET_ACTIVE_QUERY',
  payload: query
});
export const setQueryGroupBy = field => ({
  type: 'SET_QUERY_GROUP_BY',
  payload: field
});
export const setQuerySortBy = field => ({
  type: 'SET_QUERY_SORT_BY',
  payload: field
});

export const setDay = field => ({
  type: 'SET_DAY',
  payload: field ?? null
});

export const setOmniboxGroupBy = config => ({
  type: 'SET_OMNIBOX_GROUP_BY',
  payload: config
});

export const setOmniboxSortBy = config => ({
  type: 'SET_OMNIBOX_SORT_BY',
  payload: config
});

// Clocks displayed in the Status Bar
export const addClock = timezoneCode => ({
  type: 'ADD_CLOCK',
  payload: timezoneCode
});
export const removeClock = timezoneCode => ({
  type: 'REMOVE_CLOCK',
  payload: timezoneCode
});

const SNACKBAR_TYPES = {
  info: true,
  error: true,
  warning: true,
  success: true
};

/**
 * @deprecated Use hooks/useSnackbar instead
 */
export const displaySnackbar = ({
  id,
  text,
  type = 'info',
  ...options
}) => dispatch => {
  if (!id) throw new Error('Cannot create a Snackbar without an ID.');
  if (!SNACKBAR_TYPES[type]) throw new Error(`Unknown Snackbar Type: '${type}'`);

  dispatch({
    type: 'DISPLAY_SNACKBAR',
    payload: {
      id,
      text,
      type,
      ...options
    }
  });
};

/**
 * @deprecated Use hooks/useSnackbar instead
 */
export const destroySnackbar = id => ({
  type: 'DESTROY_SNACKBAR',
  payload: {
    id
  }
});
