import { HttpResponseError, serenityFetch } from 'helpers/api';
import { Marker, MarkerRequest, MarkerResponse } from './types';

export const fetchAllMarkers = async (organisationId: string): Promise<Marker[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/markers`, null);
  HttpResponseError.detect(response);
  const markers = await response.json();
  return markers.markers.map((m: MarkerResponse) => ({
    id: m.id,
    name: m.name,
    type: m.type,
    longitude: m.position.coordinates[0],
    latitude: m.position.coordinates[1],
    altitude: m.position.coordinates[2],
    icon: m.icon,
    colour: m.colour,
  } satisfies Marker));
};

export const createMarker = async (organisationId: string, marker: MarkerRequest) => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/markers`, marker);
  HttpResponseError.detect(response);
};

export const updateMarker = async (organisationId: string, markerId: number, marker: MarkerRequest) => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/markers/${markerId}`, marker);
  HttpResponseError.detect(response);
};

export const deleteMarker = async (organisationId: string, markerId: number) => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/markers/${markerId}`, null);
  HttpResponseError.detect(response);
};
