import { produce } from 'immer';
import { Action } from 'redux';
import { loggedOut, resetEverything, setOrganisationId } from 'slices/session/session.slice';

// TODO: these were GQL queries (assetsQuery and devicesQuery), need to replace with something from trackstar
const queries = [
  {
    name: 'Assets',
    graphql: '', // graphqlAssetsQuery,
    query: 'type:asset',
    icon: 'A'
  },
  {
    name: 'Devices',
    graphql: '', // graphqlDevicesQuery,
    query: 'type:device',
    icon: 'D'
  }
];

export interface AppState {
  // The currently selected top-level item within the application; eg: Asset/Device/Leg/Mission
  selectedItem: AssetBasic | DeviceBasic | Leg | null
  selectedLeg: null
  // Omnibox search term / selected query
  textFilter: string
  query: Query
  queries: Query[]
  // grouping for each type of omnibox result
  groupBy: {
    assets: 'latestActivity' | 'make' | 'model' | 'owner' | 'category' | 'watchlistGroup' | 'operatorName' | 'assetGroup'
    devices: 'none' | 'make' | 'model' | 'status'
    missions: 'none' | 'type'
  }
  sortBy: {
    assets: 'activity' | 'name'
    devices: 'none'
    missions: 'none'
  }
  // Timezones for any clocks that to be displayed
  clocks: string[]
  // Day in ISO-8602 calendar date format YYYY-MM-DD or null for the current day
  selectedDay: string | null
  // application notifications (NOT snackbar notifications)
  notifications: []
  // snackbar notifications at bottom of screen
  snackbars: Record<string, Snack>
}

/**
 * This reducer is for state that needs to be shared application-wide and
 * doesn't fit into any of the other reducers.  For example, pagination
 * arguments for apollo queries belong here.
 *
 * Avoid adding state here if possible.
* */

const initialState: Readonly<AppState> = {
  selectedItem: null,
  selectedLeg: null,
  textFilter: '',
  // TODO: this was populated by a GQl query
  query: queries[0],
  queries,
  groupBy: {
    assets: 'latestActivity',
    devices: 'none',
    missions: 'none'
  },
  sortBy: {
    assets: 'activity',
    devices: 'none',
    missions: 'none'
  },
  clocks: ['utc'],
  selectedDay: null,
  notifications: [],
  snackbars: {}
};

export interface SetItemAction extends Action<'SELECT_ITEM'> {
  payload: AssetBasic
}

export interface DeleteAssetAction extends Action<'DELETE_ASSET'> {
  payload: Pick<AssetBasic, 'id'>
}

export interface SetTextFilterAction extends Action<'SET_TEXT_FILTER'> {
  payload: string
}

export interface SetActiveQueryAction extends Action<'SET_ACTIVE_QUERY'> {
  payload: Query
}

export interface SetQueryGroupByAction extends Action<'SET_QUERY_GROUP_BY'> {
  payload: unknown
}
export interface SetQuerySortByAction extends Action<'SET_QUERY_SORT_BY'> {
  payload: unknown
}
export interface SetOmniboxGroupByAction extends Action<'SET_OMNIBOX_GROUP_BY'> {
  payload: AppState['groupBy']
}
export interface SetOmniboxSortByAction extends Action<'SET_OMNIBOX_SORT_BY'> {
  payload: AppState['sortBy']
}
export interface SetDayAction extends Action<'SET_DAY'> {
  payload: AppState['selectedDay']
}
export interface ClockAction extends Action<'ADD_CLOCK' | 'REMOVE_CLOCK'> {
  payload: string
}
export interface DisplaySnackbarAction extends Action<'DISPLAY_SNACKBAR'> {
  payload: Snack
}
export interface DestroySnackbarAction extends Action<'DESTROY_SNACKBAR'> {
  payload: { id: string }
}
export interface SetPerformanceModeAction extends Action<'SET_PERFORMANCE_MODE'> {
  payload: { on: boolean }
}

type AppAction = SetItemAction
  | DeleteAssetAction
  | SetTextFilterAction
  | SetActiveQueryAction
  | SetQueryGroupByAction
  | SetQuerySortByAction
  | SetOmniboxGroupByAction
  | SetOmniboxSortByAction
  | SetDayAction
  | ClockAction
  | DisplaySnackbarAction
  | DestroySnackbarAction
  | SetPerformanceModeAction
  | typeof loggedOut.type
  | typeof setOrganisationId.type
  | typeof resetEverything.type
  | Action<'CLEAR_SELECTION'>;

// eslint-disable-next-line consistent-return
const appReducer = (state: AppState | undefined, action: AppAction) => produce(state ?? initialState, draft => {
  /* eslint-disable-next-line */
  switch (action.type) {
    case 'SELECT_ITEM':
      draft.selectedItem = action.payload;
      break;
    case 'DELETE_ASSET':
      // clear selected item if necessary
      if (draft.selectedItem?.id === action.payload.id) {
        draft.selectedItem = null;
      }
      break;
    case 'CLEAR_SELECTION':
      draft.selectedItem = null;
      break;

    case setOrganisationId.type:
      // De-select any selected object when switching organisation
      draft.selectedItem = null;
      draft.selectedLeg = null;
      break;

    case 'SET_TEXT_FILTER':
      draft.textFilter = action.payload;
      break;
    case 'SET_ACTIVE_QUERY':
      draft.query = action.payload;
      break;

    case 'SET_QUERY_GROUP_BY':
      draft.query.groupBy = action.payload;
      break;

    case 'SET_QUERY_SORT_BY':
      draft.query.sortBy = action.payload;
      break;

    case 'SET_OMNIBOX_GROUP_BY':
      draft.groupBy = action.payload;
      break;

    case 'SET_OMNIBOX_SORT_BY':
      draft.sortBy = action.payload;
      break;

    case 'SET_DAY':
      draft.selectedDay = action.payload ?? null;
      break;

    case 'ADD_CLOCK':
      draft.clocks = [
        ...new Set(...draft.clocks, action.payload)
      ];
      break;

    case 'REMOVE_CLOCK':
      draft.clocks = draft.clocks.filter(c => c !== action.payload);
      break;

    case 'DISPLAY_SNACKBAR':
      draft.snackbars[action.payload.id] = { ...action.payload };
      break;

    case 'DESTROY_SNACKBAR': {
      delete draft.snackbars[action.payload.id];
      break;
    }

    case loggedOut.type:
      draft.selectedItem = null;
      draft.notifications = [];
      break;
    case resetEverything.type: return initialState;
  }
});

const persistFields = ['clocks', 'groupBy', 'sortBy'];

export default {
  key: 'app',
  reducer: appReducer,
  version: 5,
  whitelist: persistFields,
  migrations: {
    0: () => initialState,
    1: state => ({
      ...state,
      // TODO: this was populated by a GQl query
      query: queries[0],
      availableQueries: null
    }),
    2: state => ({
      ...state,
      groupBy: {
        assets: 'latestActivity',
        devices: 'none',
        missions: 'none'
      }
    }),
    3: state => ({
      ...state,
      selectedLeg: null
    }),
    4: state => ({
      ...state,
      performanceMode: true,
    }),
    5: state => ({
      ...state,
      sortBy: initialState.sortBy,
    }),
    6: state => ({
      ...state,
      performanceMode: false,
    }),
  }
};
