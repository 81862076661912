import React, { useCallback, useState } from 'react';
import { Tooltip, Box, Stack } from '@mui/material';
import { PinDrop } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { useDispatch, useSelector } from 'react-redux';
import useFeature from 'hooks/features/useFeature';
import usePermissions from 'hooks/session/usePermissions';
import { selectIsPlacingMarker, setIsPlacingMarker } from 'slices/markers.slice';
import { useSetViewport } from 'contexts/viewport/useViewport';
import { MapboxLogoLink } from './attribution/mapbox';
import ControlButton from './control-button';
import {
  AccreditationWrapper,
  MapControlsButtonWrapper,
  MapControlsSpeedDail,
  MapControlsSpeedDailAction,
  ZoomButton,
} from './controls-styles';
import useFeatureFlag from "hooks/useFeatureFlag";

const CustomAttributionLogos = ['Mapbox'];

interface MapControlsProps {
  provider: string
  handleSettingsDrawerOpen: () => void
  followAsset: () => void
  follow: boolean
  toggleMeasurePanel: () => void
  toggleDistanceRings: () => void
  toggleVelocityLeaders: () => void
  measureToggle: boolean
  distanceRingsToggle: boolean
  velocityLeadersToggle: boolean
  closeMap: () => void
  isClosable: boolean
  activeQuery: { name: string }
  selectedItem: any
  highContrastControls: boolean
  controlsVisible: boolean
  mapId: string
  maxZoom: number | undefined
  minZoom: number | undefined
}

const MapControls: React.FC<MapControlsProps> = ({
  provider,
  handleSettingsDrawerOpen,
  followAsset,
  follow,
  toggleMeasurePanel,
  toggleDistanceRings,
  toggleVelocityLeaders,
  measureToggle,
  distanceRingsToggle,
  velocityLeadersToggle,
  closeMap,
  isClosable,
  activeQuery,
  selectedItem,
  highContrastControls,
  controlsVisible,
  mapId,
  maxZoom,
  minZoom,
}) => {
  // Handle SpeedDial open and close
  const [controlsSDOpen, setControlsSDOpen] = useState(false);
  const setViewport = useSetViewport(mapId);


  const zoom = useCallback(amount => {
    setViewport((existing = { zoom: 0 }) => {
      const next = { ...existing, transitionDuration: 500 };
      next.zoom += amount;
      if (next.zoom > maxZoom) next.zoom = maxZoom;
      if (next.zoom < minZoom) next.zoom = minZoom;
      return next;
    });
  }, [maxZoom, minZoom, setViewport]);

  const resetMap = useCallback(() => {
    setViewport({ pitch: 0, yaw: 0, bearing: 0 });
  }, [setViewport]);

  const toggleControlsSDOpen = () => {
    setControlsSDOpen(!controlsSDOpen);
  };

  const t = useTranslations('pages.map.mapControls');
  const permissions = usePermissions();

  const dispatch = useDispatch();
  const markersEnabled = useFeature('map.markers');
  const isPlacingMarker = useSelector(selectIsPlacingMarker);

  const shouldBeDisabled = ({ name }: { name: string }) => {
    switch (name) {
      case 'Devices':
        return true;
      case 'Missions':
        return true;
      default:
        return false;
    }
  };

  const disableMeasurementTool = (!useFeatureFlag('multipointMeasurementTool') && !selectedItem) || shouldBeDisabled(activeQuery);

  const Accreditation = () => {
    if (provider === 'Mapbox') {
      return (
        <Box>
          <MapboxLogoLink />
        </Box>
      );
    }

    return (
      <AccreditationWrapper highContrastControls={highContrastControls}>{provider}</AccreditationWrapper>
    );
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" zIndex={2} sx={{ position: 'relative' }}>
      { controlsVisible
      && (
        <>
          <Stack direction="row">
            <Box position="relative">
              <MapControlsSpeedDail
                ariaLabel="Map Controls"
                highContrastControls={highContrastControls}
                hidden={false}
                icon={(
                  <Tooltip title="Map Tools" placement="left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <path d="M13.2,5.2l-2.4-2.4l2.4-2.4C12.7,0.1,12.1,0,11.5,0C9,0,7,2,7,4.5C7,5,7.1,5.4,7.2,5.9l-6.6,4.9 c-0.8,0.6-0.8,1.7-0.2,2.4l2.3,2.4c0.7,0.7,1.8,0.6,2.4-0.2l4.9-6.6C10.5,8.9,11,9,11.5,9C14,9,16,7,16,4.5c0-0.6-0.1-1.2-0.3-1.7 L13.2,5.2z" />
                    </svg>
                  </Tooltip>
                )}
                open={controlsSDOpen}
                direction="up"
              >
                {isClosable && (
                  <MapControlsSpeedDailAction
                    key="closeMap"
                    tooltipTitle={t('closeMap')}
                    tooltipPlacement="right"
                    highContrastControls={highContrastControls}
                    onClick={closeMap}
                    icon={(
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z" />
                      </svg>
                    )}
                  />
                )}
                <MapControlsSpeedDailAction
                  highContrastControls={highContrastControls}
                  active={measureToggle}
                  key="measurementTool"
                  tooltipTitle={t('measurementTool')}
                  tooltipPlacement="right"
                  onClick={toggleMeasurePanel}
                  FabProps={{ disabled: disableMeasurementTool}}
                  icon={(
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <path d="M11,0H5C4.4,0,4,0.4,4,1v2h3v2H4v2h3v2H4v2h3v2H4v2c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1V1C12,0.4,11.6,0,11,0 z" />
                    </svg>
                )}
                />
                <MapControlsSpeedDailAction
                  highContrastControls={highContrastControls}
                  active={velocityLeadersToggle}
                  key="velocityLeaders"
                  tooltipTitle={t('velocityLeaders')}
                  tooltipPlacement="right"
                  onClick={toggleVelocityLeaders}
                  FabProps={{ disabled: !selectedItem || shouldBeDisabled(activeQuery) }}
                  icon={(
                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <rect height="3" width="16" y="6.5" x="0" />
                      <rect height="3" width="2" y="3.5" x="4" />
                      <rect height="4" width="2" y="2.5" x="9" />
                      <rect height="5" width="2" y="1.5" x="14" />
                      <rect height="2.5" width="2" y="9.5" x="4" />
                      <rect height="4" width="2" y="9.5" x="9" />
                      <rect height="4.5" width="2" y="9.5" x="14" />
                    </svg>
                  )}
                />
                <MapControlsSpeedDailAction
                  highContrastControls={highContrastControls}
                  active={distanceRingsToggle}
                  key="distanceRings"
                  tooltipTitle={t('distanceRings')}
                  tooltipPlacement="right"
                  onClick={toggleDistanceRings}
                  FabProps={{ disabled: !selectedItem || shouldBeDisabled(activeQuery) }}
                  icon={(
                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="m6 8.0262a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3z" />
                      <path d="m2.9992 1.0265c-3.8601 0-7 3.1399-7 7 0 3.8601 3.1399 7 7 7 3.8601 0 7-3.1399 7-7 0-3.8601-3.1399-7-7-7zm0 1c3.3196 0 6 2.6804 6 6 0 3.3196-2.6804 6-6 6s-6-2.6804-6-6c0-3.3196 2.6804-6 6-6z" />
                      <path d="m2.9992-1.9735c-5.5169 0-10 4.4831-10 10 0 5.5169 4.4831 10 10 10s10-4.4831 10-10c0-5.5169-4.4831-10-10-10zm0 1c4.9765 0 9 4.0235 9 9 0 4.9765-4.0235 9-9 9-4.9765 0-9-4.0235-9-9 0-4.9765 4.0235-9 9-9z" />
                      <path d="m2.9992-4.9735c-7.1738 0-13 5.8262-13 13 0 7.1738 5.8262 13 13 13 7.1738 0 13-5.8262 13-13 0-7.1738-5.8262-13-13-13zm0 1c6.6333 0 12 5.3667 12 12 0 6.6333-5.3667 12-12 12-6.6333 0-12-5.3667-12-12 0-6.6333 5.3667-12 12-12z" />
                    </svg>
                  )}
                />
                <MapControlsSpeedDailAction
                  highContrastControls={highContrastControls}
                  active={follow}
                  key="flightFollow"
                  tooltipTitle={t('flightFollow')}
                  tooltipPlacement="right"
                  onClick={followAsset}
                  FabProps={{ disabled: !selectedItem || shouldBeDisabled(activeQuery) }}
                  icon={(
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M9,13.9V12H7v1.9C4.5,13.5,2.5,11.5,2.1,9H4V7H2.1 C2.5,4.5,4.5,2.5,7,2.1V4h2V2.1c2.5,0.4,4.5,2.4,4.9,4.9H12v2h1.9C13.5,11.5,11.5,13.5,9,13.9z" />
                      <circle cx="8" cy="8" r="2" />
                    </svg>
                )}
                />
                {markersEnabled && permissions.canEditMarkers && (
                  <MapControlsSpeedDailAction
                    highContrastControls={highContrastControls}
                    active={isPlacingMarker}
                    key="createMarker"
                    tooltipTitle={t('createMarker')}
                    tooltipPlacement="right"
                    onClick={() => dispatch(setIsPlacingMarker(!isPlacingMarker))}
                    icon={(
                      <PinDrop />
                    )}
                  />
                )}
                <MapControlsSpeedDailAction
                  key="resetMap"
                  highContrastControls={highContrastControls}
                  tooltipTitle={t('resetMap')}
                  tooltipPlacement="right"
                  onClick={resetMap}
                  icon={(
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <path d="M 13.399182,0.01055162 11.782744,2.3390916 C 10.665002,1.571613 9.3164201,1.1179775 7.8630859,1.1179775 c -3.183142,0 -5.8763058,2.1578288 -6.6967488,5.0849608 H 3.1150555 C 3.8578853,4.3062822 5.695057,2.969544 7.8630859,2.969544 c 1.0678575,0 2.0547375,0.3253139 2.8711441,0.8810834 L 9.0273549,6.3099085 15.510165,6.2633998 Z M 6.4828084,9.8414765 0,9.8879845 2.1109824,16.140317 3.8002848,13.706357 c 1.1445134,0.829544 2.5465619,1.32395 4.0628011,1.32395 3.1827971,0 5.8770801,-2.158894 6.6982991,-5.0859945 h -1.948717 c -0.743513,1.8968905 -2.581769,3.2344285 -4.7495821,3.2344285 -1.1312081,0 -2.170584,-0.366596 -3.0132528,-0.984436 z" />
                    </svg>
                  )}
                />
              </MapControlsSpeedDail>
              <MapControlsButtonWrapper id="mapToolsButton" highContrastControls={highContrastControls} onClick={toggleControlsSDOpen} type="button">
                <Tooltip title={t('mapTools')} placement="left">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path d="M13.2,5.2l-2.4-2.4l2.4-2.4C12.7,0.1,12.1,0,11.5,0C9,0,7,2,7,4.5C7,5,7.1,5.4,7.2,5.9l-6.6,4.9 c-0.8,0.6-0.8,1.7-0.2,2.4l2.3,2.4c0.7,0.7,1.8,0.6,2.4-0.2l4.9-6.6C10.5,8.9,11,9,11.5,9C14,9,16,7,16,4.5c0-0.6-0.1-1.2-0.3-1.7 L13.2,5.2z" />
                  </svg>
                </Tooltip>
              </MapControlsButtonWrapper>
            </Box>
            <Box sx={CustomAttributionLogos.includes(provider) ? {
              '& button': {
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8
              },
            } : undefined}>
              <ControlButton id="mapSettingsButton" tooltipPlacement="top" text={t('mapSettings')} onClick={handleSettingsDrawerOpen} highContrastControls={highContrastControls}>
                <polygon points="8,13.4 2.1,9.7 0,11 8,16 16,11 13.9,9.7" />
                <polygon points="0,6 8,1 16,6 8,11" />
              </ControlButton>
            </Box>
            {!CustomAttributionLogos.includes(provider) && <Accreditation />}
          </Stack>
          <div>
            <ZoomButton zoom="in" highContrastControls={highContrastControls} onClick={() => zoom(+1)} type="button">
              <Tooltip title={t('zoomIn')} placement="top">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path d="M15,7H9V1c0-0.6-0.4-1-1-1S7,0.4,7,1v6H1C0.4,7,0,7.4,0,8s0.4,1,1,1h6v6c0,0.6,0.4,1,1,1s1-0.4,1-1V9h6 c0.6,0,1-0.4,1-1S15.6,7,15,7z" />
                </svg>
              </Tooltip>
            </ZoomButton>
            <ZoomButton zoom="out" highContrastControls={highContrastControls} onClick={() => zoom(-1)} type="button">
              <Tooltip title={t('zoomOut')} placement="top">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path d="M15,7H1C0.4,7,0,7.4,0,8s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,7,15,7z" />
                </svg>
              </Tooltip>
            </ZoomButton>
          </div>
        </>
      )}
      {CustomAttributionLogos.includes(provider) && <Accreditation />}
    </Stack>
  );
};

export default MapControls;
