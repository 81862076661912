import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { geofenceQueryKeys } from './queryKeys';
import {
  createGeofence,
  deleteGeofence,
  getGeofences,
  updateGeofence
} from './requests';
import type {
  GeofenceCreationRequestBody,
  GeofenceResponseItem,
  GeofenceUpdateRequestBody
} from './types';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetGeofences = <T = GeofenceResponseItem[]>(options?: Options<GeofenceResponseItem[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = geofenceQueryKeys.org(organisationId);
  return useQuery<GeofenceResponseItem[], HttpResponseError, T>(
    queryKey,
    async () => getGeofences(organisationId),
    options
  );
};

export const useCreateGeofence = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  return useMutation<number, HttpResponseError, GeofenceCreationRequestBody, void>(
    geofence => createGeofence(organisationId, geofence),
    {
      onSettled: () => queryClient.invalidateQueries(geofenceQueryKeys.org(organisationId))
    }
  );
};

export const useDeleteGeofence = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  return useMutation<void, HttpResponseError, number, void>(
    geofenceId => deleteGeofence(organisationId, geofenceId),
    {
      onSettled: () => queryClient.invalidateQueries(geofenceQueryKeys.org(organisationId))
    }
  );
};

export const useUpdateGeofence = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  return useMutation<void, HttpResponseError, GeofenceUpdateRequestBody, void>(
    geofence => updateGeofence(organisationId, geofence),
    {
      onSettled: () => queryClient.invalidateQueries(geofenceQueryKeys.org(organisationId))
    }
  );
};
