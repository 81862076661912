import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  gridContainer: {
    '& *': {
      scrollbarColor: `${theme.palette.common.scrollBar} ${theme.palette.common.scrollBarBackground}`
    },
    '& *::-webkit-scrollbar-track': {
      background: theme.palette.common.scrollBarBackground
    },
    '& *::-webkit-scrollbar': {
      backgroundColor: theme.palette.common.scrollBarBackground
    },
    '& *::-webkit-scrollbar-thumb': {
      background: theme.palette.common.scrollBar
    }
  },
  headerBar: {
    flexBasis: 'var(--header-bar-height)'
  },
  footerBar: {
    width: '100%',
    flexBasis: 'var(--footer-bar-height)',
    minHeight: 'var(--footer-bar-height)',
    zIndex: 12
  },
  contentContainer: {
    // In fullscreen mode the header and footer are removed
    flexBasis: 'calc(100vh - (var(--header-bar-height) + var(--footer-bar-height)))',
    maxHeight: 'calc(100vh - (var(--header-bar-height) + var(--footer-bar-height)))',
    display: 'flex',
    maxWidth: '100vw !important',
  },
  content: {
    display: 'flex',
    flex: 1,
    paddingLeft: '43px',
    maxWidth: '100%',
  },
  navWrapper: {
    display: 'flex'
  }
}));
