import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useGetIceContactGroups } from 'apis/rest/iceContactGroups/hooks';
import { useGetPeople, useMutateDeletePerson } from 'apis/rest/people/hooks';
import { displaySnackbar } from 'actions/app';
import useLastDefined from 'hooks/useLastDefined';
import useUndeletablePeopleIds from 'hooks/people/useUndeletablePeopleIds';
import { AssignPeopleIceDialog } from 'components/dialogs/iceContactGroups/assignPeople';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';
import { useGetMessagingWhitelistContactGroups } from 'apis/rest/messagingWhitelistContactGroups/hooks';
import { AssignPeopleMessagingWhitelistDialog } from '../../messagingWhitelistContactGroups/assignPeople';

export enum DeletePersonDialogStatus {
  Cancelled = 'cancelled',
  Removed = 'removed',
}

interface DeletePersonDialogProps {
  open: boolean
  onClose: (status: DeletePersonDialogStatus, personId: number) => void
  personId?: number
}

const DeletePersonDialog = ({ open, onClose, personId }: DeletePersonDialogProps): JSX.Element => {
  const t = useTranslations('dialogs.people.deletePerson');
  const tIceContactGroup = useTranslations('pages.manage.ice');
  const tMessagingWhitelist = useTranslations('pages.manage.messagingWhitelists');
  const dispatch = useDispatch();
  const { query: personQuery } = useGetPeople({ select: people => people.find(p => p.id === personId) });
  const { query: iceGroupsQuery } = useGetIceContactGroups();
  const { query: messagingWhitelistsQuery } = useGetMessagingWhitelistContactGroups();
  const mutation = useMutateDeletePerson();
  const undeletablePeopleIds = useUndeletablePeopleIds();
  const [manageGroup, setManageGroup] = useState<{ id: number, type: 'iceContactGroup' | 'messagingWhitelistContactGroup' }>();

  const foundPerson = personQuery.data;
  const person = useLastDefined(foundPerson, [personId]);

  const iceGroupsWithWarning = useMemo(
    () => {
      const iceGroups = personId !== undefined
        ? iceGroupsQuery.data?.filter(group => group.peopleWithOrder.some(x => x.personId === personId)) ?? []
        : [];

      return iceGroups.map(group => {
        let warning: ReactNode;

        if (group.isDefault) {
          const order = group.peopleWithOrder.find(x => x.personId === personId)?.order;
          if (order && order < 3) {
            warning = (
              <Box>
                <Typography color="error">{t('warnings.default', { order })}</Typography>
                <Typography color="error">{t('warnings.defaultAction', { name: person?.name })}</Typography>
              </Box>
            );
          }
        } else {
          warning = (
            <Box>
              {group.peopleWithOrder.length < 3 && <Typography>{t('warnings.personCount', { n: group.peopleWithOrder.length - 1 })}</Typography>}
              {group.peopleWithOrder.length < 2 && <Typography>{t('warnings.noPrimary')}</Typography>}
            </Box>
          );
        }

        return { group, warning, type: 'iceContactGroup' } as const;
      }).filter(x => x.warning);
    },
    [t, iceGroupsQuery.data, personId, person?.name],
  );

  const messagingWhitelistsWithWarning = useMemo(
    () => {
      const messagingWhitelists = personId !== undefined
        ? messagingWhitelistsQuery.data?.filter(group => group.peopleWithOrder.some(x => x.personId === personId)) ?? []
        : [];

      return messagingWhitelists.map(group => {
        let warning: ReactNode;

        if (group.peopleWithOrder.length < 2) {
          warning = <Typography>{t('warnings.personCount', { n: group.peopleWithOrder.length - 1 })}</Typography>;
        }

        return { group, warning, type: 'messagingWhitelistContactGroup' } as const;
      }).filter(x => x.warning);
    },
    [t, messagingWhitelistsQuery.data, personId],
  );

  const handleClose = (status: DeletePersonDialogStatus): void => {
    if (personId !== undefined) onClose(status, personId);
  };

  const handleCancel = (): void => {
    handleClose(DeletePersonDialogStatus.Cancelled);
  };

  const handleRemove = (): void => {
    if (!person) return;
    const { name } = person;

    mutation.mutate(person, {
      onSuccess: () => {
        displaySnackbar({
          id: `personDeleted.${personId}`,
          text: t('snackbar.deletedSuccessfully', { name }),
          type: 'success',
        })(dispatch);
        handleClose(DeletePersonDialogStatus.Removed);
      },
    });
  };

  const onExited = (): void => {
    mutation.reset();
  };

  const canDelete = personId !== undefined && undeletablePeopleIds?.includes(personId) === false;
  const canConfirm = canDelete && person && !mutation.isLoading && !iceGroupsQuery.isLoading;

  const isLoading = iceGroupsQuery.isLoading || messagingWhitelistsQuery.isLoading;
  const groupsWithWarnings = [...iceGroupsWithWarning, ...messagingWhitelistsWithWarning];

  const iceGroupToManage = manageGroup?.type === 'iceContactGroup' ? iceGroupsQuery.data?.find(g => g.id === manageGroup.id) : undefined;
  const messagingWhitelistToManage = manageGroup?.type === 'messagingWhitelistContactGroup' ? messagingWhitelistsQuery.data?.find(g => g.id === manageGroup.id) : undefined;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby={t('ariaLabel')}
        fullWidth
        maxWidth={groupsWithWarnings.length ? 'lg' : 'sm'}
        TransitionProps={{ onExited }}
      >
        <TPDialogTitle>{t('title')}</TPDialogTitle>
        <DialogContent sx={{ p: 3, pb: 0 }}>
          <Stack spacing={3} my={3}>
            {person ? (
              <>
                <Typography>
                  {t.rich('message', { name: person.name, strong: chunks => <strong>{chunks}</strong> })}
                </Typography>
                {isLoading && <Typography>{t('loadingGroups')}</Typography>}
                {!isLoading && !groupsWithWarnings.length && <Typography>{t('noWarnings')}</Typography>}
                {!isLoading && !!groupsWithWarnings.length && (
                  <Stack>
                    <Typography>{t('memberOfGroups', { name: person.name })}</Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('columns.groupType')}</TableCell>
                            <TableCell>{t('columns.group')}</TableCell>
                            <TableCell>{t('columns.warnings')}</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupsWithWarnings.map(({ group, warning, type }) => (
                            <TableRow key={group.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>
                                <Typography>{t(type)}</Typography>
                              </TableCell>
                              <TableCell>
                                <Stack direction="row" spacing={1} alignItems="center">
                                  <Typography>{group.name}</Typography>
                                  {group.isDefault && <Chip label={t('default')} color="primary" />}
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {warning}
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title={t('managePeople')}>
                                  <IconButton onClick={() => setManageGroup({ id: group.id, type })}>
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                )}
              </>
            ) : null}
            {mutation.isError && <Alert severity="error">{t('errorDeleting')}</Alert>}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey' }}>
          <Stack spacing={3} flex={1} direction="row" justifyContent="flex-end" height="4em">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              disabled={mutation.isLoading}
              onClick={handleCancel}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              size="large"
              color="error"
              sx={{ minWidth: '10rem' }}
              disabled={!canConfirm}
              onClick={handleRemove}
            >
              {t('confirm')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <AssignPeopleIceDialog
        groupId={manageGroup?.type === 'iceContactGroup' ? manageGroup.id : undefined}
        open={manageGroup?.type === 'iceContactGroup'}
        onClose={() => setManageGroup(undefined)}
        title={tIceContactGroup('edit.people.dialog.title', { group: iceGroupToManage?.name ?? '' })}
        ariaLabel={tIceContactGroup('edit.people.dialog.title', { group: iceGroupToManage?.name ?? '' })}
      />
      <AssignPeopleMessagingWhitelistDialog
        groupId={manageGroup?.type === 'messagingWhitelistContactGroup' ? manageGroup.id : undefined}
        open={manageGroup?.type === 'messagingWhitelistContactGroup'}
        onClose={() => setManageGroup(undefined)}
        title={tMessagingWhitelist('edit.people.dialog.title', { group: messagingWhitelistToManage?.name ?? '' })}
        ariaLabel={tMessagingWhitelist('edit.people.dialog.title', { group: messagingWhitelistToManage?.name ?? '' })}
      />
    </>
  );
};

export default DeletePersonDialog;
