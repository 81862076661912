import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useIntercom } from 'react-use-intercom';
import { isAssetWithDevice } from 'helpers/assets';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import useSnackbar from 'hooks/useSnackbar';
import { useGetTripsForAssets } from 'apis/rest/trips/hooks';
import { updateSetting } from 'actions/settings';
import OpenStreetMapCredit from 'components/shared/openStreetMapCredit';
import LabsAlert from 'components/shared/labs/Alert';
import useOrganisationId from 'hooks/session/useOrganisationId';
import mixpanel from 'mixpanel-browser';
import { SelectAssets } from 'components/pages/sharing/selectAssets';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { selectDevicesById } from 'components/pages/sharing/helpers';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import { useDeviceIdByAssets } from 'hooks/assets/useDeviceIdByAssets';
import { useAssetsByDeviceId } from 'hooks/assets/useAssetsByDeviceId';
import { DateRangeSelection } from './dateRangeSelection';
import Download from './download';
import { TripTimelineView } from './timeline/tripTimelineView';
import TripReportsTable from './table';
import useTimezone from "hooks/session/useTimezone";

const NO_ASSETS: never[] = [];

const selectAssetsWithDevices = (assets: AssetBasic[]) => assets.filter(isAssetWithDevice);

enum Layout {
  Timeline,
  Table,
}

type Query = ReduxState['settings']['tripAnalysis']['query'];

const isQueryValid = (query: Query, assets: AssetBasic[]): query is Required<Query> => !!query.assets?.some(id => assets.find(asset => asset.id === id)) && query.from !== undefined && query.until !== undefined;

const TripAnalysisReport = (): JSX.Element => {
  const persistedQuery = useSelector<ReduxState, Query>(state => state.settings.tripAnalysis.query);
  const query = useMemo<Query & { from: number, until: number }>(() => ({
    ...persistedQuery,
    from: persistedQuery.from ?? DateTime.now().startOf('day').minus({ days: 6 }).toMillis(),
    until: persistedQuery.until ?? DateTime.now().endOf('day').toMillis(),
  }), [persistedQuery]);

  const timezone = useTimezone();
  const dispatch = useDispatch();
  const setQuery = useCallback((newQuery: Query) => dispatch(updateSetting('tripAnalysis', 'query', newQuery)), [dispatch]);
  const [datePickerRange, setDatePickerRange] = useState<{ from: string, until: string }>({
    from: DateTime.fromMillis(query.from).toISODate(),
    until: DateTime.fromMillis(query.until).toISODate(),
  });
  const [displayUTC, setDisplayUTC] = useState(false);
  const [displayEngineUsage, setDisplayEngineUsage] = useState(false);
  const t = useTranslations('pages.reporting');

  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  const organisationId = useOrganisationId();
  const intercom = useIntercom();
  useEffect(() => {
    intercom.trackEvent('tripAnalysisView', {
      organisation_id: organisationId,
      time_at: Date.now(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intercom]);

  const snackbar = useSnackbar();

  const assetListQuery = useGetAssetsList<AssetWithDevice[]>({ select: selectAssetsWithDevices }).query;

  useEffect(() => {
    if (assetListQuery.isError) {
      snackbar.display({
        id: 'getAssetListFailedSnackbar',
        text: t('getAssetListFailed'),
        type: 'error',
      });
    }
  }, [assetListQuery, snackbar, t]);

  const assets = assetListQuery.data ?? NO_ASSETS;
  const deviceIds = useDeviceIdByAssets(assets);
  const assetsByDeviceId = useAssetsByDeviceId(assets);

  const tripsQuery = useGetTripsForAssets(query?.assets ?? NO_ASSETS, query.from, query.until);

  const relevantAssets = useMemo(() => (
    assets.filter(asset => query.assets?.includes(asset.id))
  ), [assets, query?.assets]);

  const [layout, setLayout] = useState<Layout>(Layout.Table);

  const displayTZ = displayUTC ? 'Etc/UTC' : timezone;

  const onSetDateRange = useCallback((from: string, until: string) => {
    setDatePickerRange({ from, until });
    const fromMillis = DateTime.fromISO(from, { zone: timezone }).startOf('day').toMillis();
    const untilMillis = DateTime.fromISO(until, { zone: timezone }).endOf('day').toMillis();
    setQuery({ ...query, from: fromMillis, until: untilMillis });
  }, [setQuery, query, timezone]);

  useEffect(() => {
    mixpanel.track('Trip Analysis Query Change', { query });
  }, [query]);

  useEffect(() => {
    mixpanel.track('Trip Analysis Layout Change', { layout: layout === Layout.Table ? 'table' : 'timeline' });
  }, [layout]);

  useEffect(() => {
    if (displayEngineUsage) {
      mixpanel.track('Trip Analysis Display Engine Usage');
    }
  }, [displayEngineUsage]);

  useEffect(() => {
    if (displayUTC) {
      mixpanel.track('Trip Analysis Display UTC Time');
    }
  }, [displayUTC]);

  useEffect(() => {
    mixpanel.track('Trip Analysis Page View');
  }, []);

  const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>(() => relevantAssets.map(a => a.deviceId));

  useEffect(() => {
    const selectedAssets = assets.filter(a => selectedDeviceIds.includes(a.deviceId)).map(a => a.id);
    setQuery({ ...query, assets: selectedAssets });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceIds, assets]);

  const queryAssets = useMemo(() => (query?.assets ?? []), [query?.assets]);

  const [displayMessage, setDisplayMessage] = useState(() => {
    try {
      return !JSON.parse(sessionStorage.getItem('tripAnalysis.betaMessageDismissed') ?? '');
    } catch (error) {
      return true;
    }
  });

  useEffect(() => {
    sessionStorage.setItem('tripAnalysis.betaMessageDismissed', (!displayMessage).toString());
  }, [displayMessage]);

  useEffect(() => {
    onSetDateRange(datePickerRange.from, datePickerRange.until);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  const devicesById = devicesByIdQuery.data ?? [];
  const assetGroups = assetGroupsQuery.data;

  const isLoading = devicesByIdQuery.isFetching || assetGroupsQuery.isFetching || assetListQuery.isLoading;

  if (devicesByIdQuery.isLoading) {
    return (<LoadingIcon size={5} />);
  }

  return (
    <>
      <Collapse in={displayMessage}>
        <LabsAlert
          sx={{ mb: 3, p: 3 }}
          action={(
            <IconButton
              aria-label={t('tripAnalysis.infoMessage.dismiss')}
              color="inherit"
              onClick={() => setDisplayMessage(false)}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
        >
          {t.rich('tripAnalysis.infoMessage.content', { break: () => <br /> })}
        </LabsAlert>
      </Collapse>

      <Paper sx={{ mb: 3 }} elevation={0}>
        <Stack direction="row" spacing={2} p={3}>
          <FormControl variant="outlined" sx={{ flex: 1 }}>
            <SelectAssets
              label={t('selectAssets.label')}
              deviceIds={deviceIds}
              selectedDeviceIds={selectedDeviceIds}
              assetsByDeviceId={assetsByDeviceId}
              devicesById={devicesById}
              setSelectedDeviceIds={value => {
                setSelectedDeviceIds(value);
              }}
              disabled={false}
              assetGroups={assetGroups}
              isLoading={isLoading}
            />
          </FormControl>
          <FormControl>
            <DateRangeSelection
              assets={assets.filter(a => queryAssets.includes(a.id))}
              from={datePickerRange.from}
              until={datePickerRange.until}
              onChange={onSetDateRange}
            />
          </FormControl>
        </Stack>
      </Paper>

      <Stack direction="row" spacing={3} alignItems="center">
        <ToggleButtonGroup
          sx={{ height: 38 }}
          value={layout}
          onChange={(event, value) => {
            if (value !== null) setLayout(value);
          }}
          exclusive
        >
          <ToggleButton value={Layout.Table}>{t('tripAnalysis.toggles.table')}</ToggleButton>
          <ToggleButton value={Layout.Timeline}>{t('tripAnalysis.toggles.timeline')}</ToggleButton>
        </ToggleButtonGroup>
        <FormControlLabel
          control={<Switch checked={displayEngineUsage} onChange={event => setDisplayEngineUsage(event.target.checked)} />}
          label={t('displayEngineUsage')}
          labelPlacement="start"
          sx={{ mr: 1 }}
        />
        {timezone !== 'Etc/UTC' && (
          <FormControlLabel
            control={<Switch checked={displayUTC} onChange={event => setDisplayUTC(event.target.checked)} />}
            label={t('displayTimesInUTC')}
            labelPlacement="start"
            sx={{ mr: 1 }}
          />
        )}
        <Box flex={1} />
        <Download
          timezone={displayTZ}
          query={query}
          assets={relevantAssets}
          disabled={assetListQuery.isLoading || tripsQuery.isLoading || !isQueryValid(query, assets)}
        />
      </Stack>

      {tripsQuery.isLoading && isQueryValid(query, assets) && (
        <Paper sx={{ my: 3, p: 6 }} elevation={0}>
          <Typography align="center">{t('loadingTripReports')}</Typography>
        </Paper>
      )}

      {!tripsQuery.isLoading && tripsQuery.data && isQueryValid(query, assets) && (
        <>
          {layout === Layout.Timeline && (
            <TripTimelineView
              assets={relevantAssets}
              trips={tripsQuery.data}
              displayEngineUsage={displayEngineUsage}
              query={query}
              timezone={displayTZ}
            />
          )}
          {layout === Layout.Table && (
            <TripReportsTable
              assets={relevantAssets}
              trips={tripsQuery.data}
              displayEngineUsage={displayEngineUsage}
              query={query}
              timezone={displayTZ}
            />
          )}
          <OpenStreetMapCredit />
        </>
      )}
    </>
  );
};

export default TripAnalysisReport;
