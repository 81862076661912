import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import AddContacts from 'components/dialogs/people/addContacts';
import Fields from 'components/pages/manage/people/common/fields-view';
import { PersonLanguageCode } from 'constants/person';
import { useMutateNewPerson } from 'apis/rest/people/hooks';
import useValidateName from 'hooks/people/useValidateName';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

interface AddPersonProps {
  open: boolean
  cancel: () => void
  created: (person: Person) => void
}

const AddPersonDialog = ({ open, created, cancel }: AddPersonProps) => {
  const t = useTranslations('dialogs.people.addPerson');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [languageCode, setLanguageCode] = useState(PersonLanguageCode.EN);
  const createMutation = useMutateNewPerson();
  const person = createMutation.data;
  const validation = useValidateName(person ?? null, name);

  const createNewPerson = (): void => {
    createMutation.mutate({
      name: name.trim(),
      role: role.trim(),
      languageCode,
    });
  };

  const onExited = () => {
    setName('');
    setRole('');
    setLanguageCode(PersonLanguageCode.EN);
  };

  return (
    <>
      <Dialog
        open={open && !person}
        aria-label={t('title')}
        fullWidth
        maxWidth="sm"
        TransitionProps={{ onExited }}
      >
        <TPDialogTitle>{t('title')}</TPDialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Stack spacing={3} pt={3}>
            <Fields
              name={name}
              setName={setName}
              role={role}
              setRole={setRole}
              languageCode={languageCode}
              setLanguageCode={setLanguageCode}
              disabled={createMutation.isSuccess || createMutation.isLoading}
              validation={validation}
              autoFocus
              allowMissingName
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
          <Box flex={1}>
            <Collapse in={createMutation.isError}>
              <Alert sx={{ mb: 3 }} severity="error">{t('alert.error')}</Alert>
            </Collapse>
            <Stack spacing={3} direction="row" height="4em" justifyContent="flex-end">
              <Button
                variant="outlined"
                size="large"
                sx={{ minWidth: '10rem' }}
                onClick={cancel}
                disabled={createMutation.isSuccess || createMutation.isLoading}
              >
                {t('cancel')}
              </Button>
              <Button
                size="large"
                variant="contained"
                sx={{ minWidth: '10rem' }}
                onClick={createNewPerson}
                disabled={createMutation.isSuccess || createMutation.isLoading || !validation.valid || validation.isLoading}
              >
                {t(createMutation.isLoading ? 'saving' : 'save')}
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>

      <AddContacts
        person={open ? person : undefined}
        done={() => {
          if (!person) return;
          created(person);
          createMutation.reset();
        }}
        cancelLabel={t('skip')}
      />
    </>
  );
};

export default AddPersonDialog;
