import React, {useMemo} from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { groupBy } from 'lodash/fp';
import CirclePicker from 'components/shared/circlePicker';
import { styled } from '@mui/material/styles';
import { useTranslations } from 'use-intl';
import { MapTemplate } from 'mapTemplates/reactmapgl/types';
import TracPlusLabsIcon from 'components/shared/labs/Icon';
import useFeatures from 'hooks/features/useFeatures';
import { excludeForwardProps } from 'utils/style';
import mapTemplates from 'mapTemplates';
import useLocaleLanguage from 'hooks/settings/useLocale';
import { MapBaseLayerIds } from 'constants/map';
import { MapSettingsTitle, MapSettingsSection } from '../../settingsDrawer-styles';

const labsProviders = ['NASA GIBS'];

const LabsBox = styled(Box, {
  shouldForwardProp: excludeForwardProps(['labs']),
})<{labs: boolean}>(({ theme, labs = false }) => `
  background-color: ${labs ? theme.palette.labs.light : 'inherit'};
  padding: ${theme.spacing(0, 3, 0, 3)};
  & h4 {
    ${labs ? `color: ${theme.palette.mode === 'light' ? theme.palette.labs.main : theme.palette.common.black};` : ''}
  }
`);

interface BaseLayerPickerProps {
  mapId: string
  setBaseLayer: (mapId: string, baseLayerId?: MapTemplate) => void
}

const BaseLayerPicker = ({
  mapId,
  setBaseLayer
}: BaseLayerPickerProps) => {
  const t = useTranslations('pages.map.settingsDialog');
  const features = useFeatures();
  const language = useLocaleLanguage();
  const selectedDay = useSelector<ReduxState, string | null>(state => state.app.selectedDay);
  const baseLayerId = useSelector<ReduxState, MapBaseLayerIds>(state => state.map.maps[mapId].baseLayerId);
  const baseLayers = useMemo<MapTemplate[]>(() => (
    Object.values(mapTemplates).map(template => template(language, selectedDay))
  ), [language, selectedDay]);

  const filteredLayers = baseLayers.filter(bl => (
    bl.feature !== undefined
      ? features?.some(f => f.featureKey === bl.feature)
      : true
  ));
  const providerGrouping = groupBy<MapTemplate>(bl => (labsProviders.includes(bl.provider) ? 'TracPlus Labs' : bl.provider));

  return (
    <MapSettingsSection sx={{ p: 0 }}>
      <MapSettingsTitle sx={{ px: 3, pt: 2 }}>{t('mapLayers')}</MapSettingsTitle>
      <Stack spacing={1}>
        {Object.entries(providerGrouping(filteredLayers))
          .map(([provider, layers]) => {
            const labs = labsProviders.includes(layers[0].provider);
            return (
              <LabsBox labs={labs} key={provider}>
                <Stack direction="row" alignItems="center" spacing={1} mb={-1}>
                  {labs && <TracPlusLabsIcon sx={{ fontSize: '1em' }} />}
                  <Typography
                    key={`${provider}-label`}
                    id={`baserLayerGroupLabel-${provider}`}
                    variant="overline"
                    fontWeight="bold"
                    component="h4">
                    {provider}
                  </Typography>
                </Stack>
                <CirclePicker
                  value={baseLayerId}
                  onChange={value => setBaseLayer(mapId, layers.find(layer => layer.id === value))}
                  options={layers.map(baseLayer => ({
                    value: baseLayer.id,
                    label: baseLayer.name,
                    image: <img alt={baseLayer.name} src={baseLayer.thumbnailUrl} />,
                  }))}
                  stackProps={{ 'aria-labelledby': `baserLayerGroupLabel-${provider}` }}
                />
              </LabsBox>
            );
          })}
      </Stack>
    </MapSettingsSection>
  );
};

export default BaseLayerPicker;
