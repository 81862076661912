import React, { useEffect } from 'react';
import { Navigate, useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { Container, Stack, Typography } from '@mui/material';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import LinkAbove from 'components/shared/linkAbove';
import { DateTime } from 'luxon';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetTripsForAssets } from 'apis/rest/trips/hooks';
import { useGetEngineUsageForAsset } from 'apis/rest/engineUsage/hooks';
import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { selectAviationAssetsWithDevices } from '../dailyFlightSummary-report';
import LoadingPage from '../../../loading';
import { TrialBadge } from '../../layout';
import { AssetFlightSummary } from './assetFlightSummary';
import useLocaleLanguage from "hooks/settings/useLocale";
import useTimezone from "hooks/session/useTimezone";

const AssetFlightSummaryPage = () => {
  const timezone = useTimezone();
  const t = useTranslations('pages.reporting.assetFlightSummary');
  const matches = useMatch('/insights/daily-flight-summary/:assetId/:day');
  const assetId = parseInt(matches?.params?.assetId ?? '0', 10);
  const day = matches?.params?.day ? DateTime.fromFormat(matches.params.day, 'yyyy-MM-dd').setZone(timezone) : null;
  const language = useLocaleLanguage();

  const assetListQuery = useGetAssetsList<AssetWithDevice | undefined>({
    select: a => selectAviationAssetsWithDevices(a).find(ad => ad.id === assetId)
  }).query;

  const engineUsageQuery = useGetEngineUsageForAsset(assetId, day?.startOf('day').toMillis(), day?.endOf('day').toMillis());

  const tripsQuery = useGetTripsForAssets([assetId], day?.startOf('day').toMillis(), day?.endOf('day').toMillis());
  const trips = tripsQuery.data ?? [];

  useEffect(() => {
    mixpanel.track('Daily Flight Summary Single-asset Page View', { assetId, day });
  }, [assetId, day]);

  if (!assetId || !day) {
    return <Navigate to="/insights/daily-flight-summary" />;
  }

  if (!assetListQuery.data) {
    return <LoadingPage />;
  }

  const title = t('title', { assetName: assetListQuery.data?.name, day: day.setLocale(language).toLocaleString(DateTime.DATE_FULL) });

  return (
    <Page title={title}>
      <SettingsMenuPageWrapper p={8} sx={{ scrollbarGutter: 'stable' }}>
        <Container maxWidth={false}>
          <LinkAbove />
          <>
            <Stack direction="row" spacing={1}>
              <Typography variant="h1" gutterBottom>{title}</Typography>
              <TrialBadge featureKey="reporting.newTripReports" />
            </Stack>
            <Typography paragraph>{t('description')}</Typography>
          </>
          <AssetFlightSummary asset={assetListQuery.data} trips={trips} isEngineUsageLoading={engineUsageQuery.isLoading} engineUsage={engineUsageQuery.data} day={day!} />
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default AssetFlightSummaryPage;
